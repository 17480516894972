import i18n from 'i18next'
//import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import Api from 'api'
i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: 'pt',
		fallbackLng: 'pt',
		interpolation: {
			escapeValue: false, // not needed for react!!
		},
		backend: {
			fallbackLng: 'pt-PT',
			allowMultiLoading: false,
			request: async function (options, url, payload, callback) {
				const response = await Api.Types.getTranslations()
				
				if (response.length > 0) {
					callback(undefined, {
						data: JSON.stringify(response)
					})
				}
			},
			parse: function (data) {
				const parseData = JSON.parse(data)

				const returnData = {}
				parseData.map(value =>  {
					{
						const reduced = value.properties.reduce((acum, e) => {
							acum[e.key] = e.value
							return acum
						}, {})
						returnData[value.name] = reduced
						return value
					}})
					
				return returnData
			}
		}
	})

export default i18n