import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'
import API from 'api'

export default kea({
	path: () => ['scenes', 'containers', 'CampingManagementRequestForm', 'BaseSportAndLeisure'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			CampsiteManagementLogic, [
				'campsiteId'
			],
			CampsiteManagementRequestLogic, [
				'current'
			]
		],
		actions: [
			CampsiteManagementRequestLogic, [
				'updateStep'
			]
		]
	},

	actions: () => ({
		fetchForm: () => true
	}),

	reducers: () => ({

	}),

	start: function * () {
		
	},

	takeLatest: ({actions, workers}) => ({
		[actions.fetchForm]: workers.fetchForm
	}),

	workers: {

		* fetchForm () {
			
			const { fetchData } = this.props
			const campsiteId = yield this.get('campsiteId')

			fetchData((data, config) => API.Campsites.steps.getSportAndLeisure(campsiteId, config))

		},
	}
})
