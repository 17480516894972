/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const Eye = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M12,9.5 C12.4400022,9.5 12.8533314,9.6133322 13.24,9.84 C13.6266686,10.0666678 13.9333322,10.3733314 14.16,10.76 C14.3866678,11.1466686 14.5,11.5599978 14.5,12 C14.5,12.4400022 14.3866678,12.8533314 14.16,13.24 C13.9333322,13.6266686 13.6266686,13.9333322 13.24,14.16 C12.8533314,14.3866678 12.4400022,14.5 12,14.5 C11.5599978,14.5 11.1466686,14.3866678 10.76,14.16 C10.3733314,13.9333322 10.0666678,13.6266686 9.84,13.24 C9.6133322,12.8533314 9.5,12.4400022 9.5,12 C9.5,11.5599978 9.6133322,11.1466686 9.84,10.76 C10.0666678,10.3733314 10.3733314,10.0666678 10.76,9.84 C11.1466686,9.6133322 11.5599978,9.5 12,9.5 Z M12,16.18 C12.7600038,16.18 13.4599968,15.9933352 14.1,15.62 C14.7400032,15.2466648 15.2466648,14.7400032 15.62,14.1 C15.9933352,13.4599968 16.18,12.7600038 16.18,12 C16.18,11.2399962 15.9933352,10.5400032 15.62,9.9 C15.2466648,9.2599968 14.7400032,8.7533352 14.1,8.38 C13.4599968,8.0066648 12.7600038,7.82 12,7.82 C11.2399962,7.82 10.5400032,8.0066648 9.9,8.38 C9.2599968,8.7533352 8.7533352,9.2599968 8.38,9.9 C8.0066648,10.5400032 7.82,11.2399962 7.82,12 C7.82,12.7600038 8.0066648,13.4599968 8.38,14.1 C8.7533352,14.7400032 9.2599968,15.2466648 9.9,15.62 C10.5400032,15.9933352 11.2399962,16.18 12,16.18 Z M12,5.74 C13.3733402,5.74 14.6799938,6.006664 15.92,6.54 C17.120006,7.0600026 18.1766621,7.7933286 19.09,8.74 C20.0033379,9.6866714 20.6999976,10.7733272 21.18,12 C20.6999976,13.2266728 20.0033379,14.3099953 19.09,15.25 C18.1766621,16.1900047 17.120006,16.9199974 15.92,17.44 C14.6799938,17.973336 13.3733402,18.24 12,18.24 C10.6266598,18.24 9.3200062,17.973336 8.08,17.44 C6.879994,16.9199974 5.8233379,16.1900047 4.91,15.25 C3.9966621,14.3099953 3.3000024,13.2266728 2.82,12 C3.3000024,10.7733272 3.9966621,9.6866714 4.91,8.74 C5.8233379,7.7933286 6.879994,7.0600026 8.08,6.54 C9.3200062,6.006664 10.6266598,5.74 12,5.74 Z" />
		</g>
	</svg>
)

Eye.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

Eye.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

export default Eye
