import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import CampsiteManagementLogic from '../logic'

export default kea({
	path: () => ['scenes', 'CampsiteManagement', 'List'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			CampsiteManagementLogic, [
				'changeLimit',
				'changePage'
			]
		],
		props: [
			CampsiteManagementLogic, [
				'isFetchingData',
				'limit',
				'offset', 
				'count',
				'hasNext',
				'hasPrevious',
				'campsites',
				'currentTab'
			]
		]
	}
})