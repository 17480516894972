//import Home from 'screens/Home'
import Empty from 'screens/Empty'
//import Message from 'screens/Message'
//import Financial from 'screens/Financial'
import ServiceArea from 'screens/ServiceArea'
//import NewServiceArea from 'screens/NewServiceArea'
import Downloads from 'screens/Downloads'

export default {
  
	home: {
		key: 'home',
		name: 'home',
		path: '/',
		component: Empty,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		iconName: 'Home'
	},

	processManagement: {
		key: 'processManagement',
		name: 'processManagement',
		path: '/gestao-processos',
		targetPath: ['/novas-gestao-processos', '/gestao-processos'],
		component: Empty,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isFather: true,
		iconName: 'Process'
	},

	/*financial: {
    key: 'financial',
    name: 'financial',
    path: '/financeira',
    component: Empty,
    crumb: [],
    exact: true,
    showInMenu: true,
    showSidebar: true,
    iconName: 'Financial'
  },
	message: {
    key: 'message',
    name: 'message',
    path: '/mensagens',
    component: Message,
    crumb: [],
    exact: true,
    showInMenu: true,
    showSidebar: true,
    iconName: 'Message'
  },*/

	faq: {
		key: 'faqs',
		name: 'faqs',
		defaultName: 'FAQs',
		path: '/faqs',
		component: Empty,
		hideBreadCrumb: true,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'FAQ'
	},

	contacts: {
		key: 'contacts',
		name: 'contacts',
		path: '/contactos',
		component: Empty,
		hideBreadCrumb: true,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'Contacts'
	},

	/*newServiceArea: {
    key: 'new_service_area',
    name: 'new_service_area',
    path: '/novas-gestao-processos',
    component: Empty,
    crumb: ['processManagement'],
    exact: true,
    showInMenu: true,
    showSidebar: true,
    isChildrenOf: 'processManagement',
    iconName: 'PENGRET'
  },*/

	serviceArea: {
		key: 'service_area',
		name: 'service_area',
		path: '/gestao-processos',
		component: ServiceArea,
		crumb: [/*'processManagement'*/],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		//isChildrenOf: 'processManagement',
		iconName: 'PENGRET'
	},

	/*profile: {
    key: 'profile',
    name: 'profile',
    path: '/editar-perfil',
    component: Empty,
    crumb: [],
    exact: true,
    showInMenu: false,
    showSidebar: true
  },*/
	downloads: {
		key: 'downloads',
		name: 'downloads',
		path: '/downloads',
		component: Downloads,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		iconName: 'Downloads'
	},
}