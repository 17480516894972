import moment from 'moment'

export const findServiceAreaObject = (id, serviceAreas) => {
	if (!id || serviceAreas.length === 0) {
		return {}
	}
	
	const findServiceAreas = serviceAreas.filter(item => item.id === id)

	if (findServiceAreas.length > 0) {
		return findServiceAreas[0]
	}
	
	return {}
}

export const getNumberNights = (defaultValue, checkInOut) => {
	const dates = checkInOut.split(' - ')	
	
	if (dates[0] && dates[1]) {
		return moment(dates[1], 'DD-MM-YYYY')
			.diff(moment(dates[0], 'DD-MM-YYYY'), 'days')
	} else {
		return defaultValue
	}
}

export const serviceAreasOptions = serviceAreas => {
	return serviceAreas.map(service => ({
		key: service.id,
		text: service.name,
		value: service.id
	}))
}

export const getGuestsOptions = (guestsString, guestString) => {
	const options = [
		{
			key: 1,
			text: `1 ${guestString}`,
			value: 1,
		},
		{
			key: 2,
			text: `2 ${guestsString}`,
			value: 2,
		},
		{
			key: 3,
			text: `3 ${guestsString}`,
			value: 3,
		},
		{
			key: 4,
			text: `4 ${guestsString}`,
			value: 4,
		},
		{
			key: 5,
			text: `5 ${guestsString}`,
			value: 5,
		},
		{
			key: 6,
			text: `6 ${guestsString}`,
			value: 6,
		},
		{
			key: 7,
			text: `7 ${guestsString}`,
			value: 7,
		},
		{
			key: 8,
			text: `8 ${guestsString}`,
			value: 8,
		},
		{
			key: 9,
			text: `9 ${guestsString}`,
			value: 9,
		},
		{
			key: 10,
			text: `10 ${guestsString}`,
			value: 10,
		},
	]
	
	return options
}