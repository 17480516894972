import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { 
	Grid,
	Radio,
	Dimmer,
	Loader
} from 'semantic-ui-react'

import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import Logic from './logic'
import { generatePriceStrings } from './utils'

class BookDetailsPayment extends Component {

	render () {
		const {
			t,
			prices,
			form,
			paymentMethods,
			reservationStatus,
			loading,
			datasheet
		} = this.props
		
		const {
			nights,
			guests,
			needsElectricitySupply,
			needsWaterSupply,
			paymentMethod,
			paymentDetails
		} = form
		
		let pricesAux

		Object.keys(prices).length > 0 ? pricesAux = prices : pricesAux = paymentDetails.value || {}

		const {
			spotPrice,
			guestPrice,
			electricityPrice,
			waterPrice,
			totalPrice,
			ccLastDigits,
			ccName,
			expirationMonth,
			expirationYear,
			mbEntity,
			mbReference
		} = pricesAux

		const { changeForm } = this.actions

		if (loading) {
			return (
				<Segment>
					<SegmentTitle margin={'0 0 26px 0'}>
						{t('booking_details.payment')}
					</SegmentTitle>
					<Dimmer inverted active>
						<Loader inverted/>
					</Dimmer>
				</Segment>
			)
		}

		return (
			<Segment>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.payment')}
				</SegmentTitle>
				<Grid>
					<Grid.Row columns={2}>
						{reservationStatus && paymentMethods.length > 0 &&
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{t('booking_details.payment_methods')}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<RadioLabel>
									{/* eslint-disable-next-line max-len */}
									{t('booking_details.payment_methods_select')}
								</RadioLabel>
							</Grid.Row>
							{paymentMethods.map(method => (
								<Grid.Row
									key={method.id}
									style={{ marginTop: '16px' }}
								>
									<Radio
										label={method.name}
										name={method.name}
										value={method.id}
										// eslint-disable-next-line max-len
										checked={paymentMethod.value === method.id}
										onChange={() => changeForm({
											paymentMethod: method.id
										})}
									/>
								</Grid.Row>
							))}
						</Grid.Column>
						}
						{reservationStatus && reservationStatus !== 'simulated' && (
							<Grid.Column>
								<Grid.Row>
									<InputLabel>
										{t('booking_details.payment_details')}		
									</InputLabel>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Grid>
											<Grid.Row columns={2}>
												<Grid.Column>
													<Label>
														{t('booking_details.payment_methods')}
													</Label>
												</Grid.Column>
												<Grid.Column>
													<Value>{pricesAux.method ? pricesAux.method : paymentMethod.value ? paymentMethod.value.name : ''}</Value>
												</Grid.Column>
											</Grid.Row>
											{ccLastDigits && ccLastDigits !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.cc_last_digits')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>XXXX-XXXX-XXXX-{ccLastDigits}</Value>
													</Grid.Column>
												</Grid.Row>)}
											{ccName && ccName !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.cc_name')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>{ccName}</Value>
													</Grid.Column>
												</Grid.Row>
											)}
											{expirationMonth && expirationYear && expirationMonth !== '' && expirationYear !== '' && 
								(
									<Grid.Row columns={2}>
										<Grid.Column>
											<Label>{t('booking_details.cc_expiration')}</Label>
										</Grid.Column>
										<Grid.Column>
											<Value>{expirationMonth} / {expirationYear}</Value>
										</Grid.Column>
									</Grid.Row>
								)}
											{mbEntity && mbEntity !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.mb_entity')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>{mbEntity}</Value>
													</Grid.Column>
												</Grid.Row>
											)}
											{mbReference && mbReference !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.mb_reference')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>{mbReference}</Value>
													</Grid.Column>
												</Grid.Row>
											)}
										</Grid>
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
						)}
						{pricesAux && Object.keys(pricesAux).length > 0 && 
					(
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{reservationStatus === 'confirmed' ? t('booking_details.values_paid'): t('booking_details.values_pay')}
								</InputLabel>
							</Grid.Row>
							<Grid>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Label>
											{/* eslint-disable-next-line max-len */}
											{t('booking_details.price_motorhome')}
										</Label>
									</Grid.Column>
									<Grid.Column>
										<Value>{`${spotPrice || 0} € x ${nights.value} ${nights.value == 1 ? t('booking_details.nights_singular') : t('booking_details.nights_plural')}`}</Value>
									</Grid.Column>
								</Grid.Row>
								{guestPrice && guestPrice !== 0 && (
									<Grid.Row columns={2}>
										<Grid.Column>
											<Label>
												{/* eslint-disable-next-line max-len */}
												{t('booking_details.price_guest')}
											</Label>
										</Grid.Column>
										<Grid.Column>
											<Value>{`${guestPrice || 0} € x ${guests.value} ${guests.value == 1 ? t('booking_details.guest') : t('booking_details.guests')}`}</Value>
										</Grid.Column>
									</Grid.Row>
								)}
								{datasheet.electricitySupply !== 'unavailable' && needsElectricitySupply.value && (
									<Grid.Row columns={2}>
										<Grid.Column>
											<Label>
												{/* eslint-disable-next-line max-len */}
												{t('booking_details.price_eletricity')}
											</Label>
										</Grid.Column>
										<Grid.Column>
											<Value>{generatePriceStrings(t, electricityPrice, datasheet.electricitySupply, nights)}</Value>
										</Grid.Column>
									</Grid.Row>
								)}
								{datasheet.potableWaterSupply !== 'unavailable' && needsWaterSupply.value && (
									<Grid.Row columns={2}>
										<Grid.Column>
											<Label>
												{t('booking_details.price_water')}
											</Label>
										</Grid.Column>
										<Grid.Column>
											<Value>{generatePriceStrings(t, waterPrice, datasheet.potableWaterSupply, nights)}</Value>
										</Grid.Column>
									</Grid.Row>
								)}
								<Grid.Row columns={2}>
									<Grid.Column>
										<TotalPriceLabel>
											{reservationStatus === 'confirmed' ? t('booking_details.total_paid') : t('booking_details.price_total')}
										</TotalPriceLabel>
									</Grid.Column>
									<Grid.Column>
										<Value>{`${totalPrice || 0} €`}</Value>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					)}
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}
}

export default withTranslation()(Logic(BookDetailsPayment))

const InputLabel = styled.p`
	color: #102526;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18px;
	padding-bottom: 27px;
`

const Label = styled.p`
  opacity: 0.6;
  color: #102526;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
	line-height: 12px;
	text-transform: uppercase;
`

const Value = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
`

const TotalPriceLabel = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
	line-height: 16px;
	font-weight: bold;
	text-transform: uppercase;
`

const RadioLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`