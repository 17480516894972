/**
 * Modal Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import React from 'react'
import styled from 'styled-components'
import { Modal as SemanticModal } from 'semantic-ui-react'

const Modal = styled(SemanticModal)`
  &&&& {
    > .header{
      font-weight: normal;
      color: #000000; 
      font-size: 20px;
      background-color: #F8F8F8;
      box-shadow: inset 0 -1px 0 0 rgba(196,196,196,0.4);
      border-bottom: none;
    }

    > .actions{
      background-color: #F8F8F8;
      box-shadow: inset 1px 0 0 0 rgba(196,196,196,0.4), inset -1px 0 0 0 rgba(196,196,196,0.4), inset 0 -1px 0 0 rgba(196,196,196,0.4), 0 0 20px 0 rgba(0,0,0,0.2);
    }

    ${({ closeIcon }) => closeIcon && `
      .close.icon{
        color: #616161;
        top: 10px;
        right: 3px;
        font-size: 20px;
      }
    `}

    ${({ loading }) => loading && `
      &:after{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #F8F8F8;
        opacity: .7;
        z-index: 10;
      }
      &:before{
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        margin: -1.5em 0 0 -1.5em;
        width: 3em;
        height: 3em;
        -webkit-animation: segment-spin .6s linear;
        animation: segment-spin .6s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        border-radius: 500rem;
        border-color: #767676 rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.1);
        border-style: solid;
        border-width: .2em;
        -webkit-box-shadow: 0 0 0 1px transparent;
        box-shadow: 0 0 0 1px transparent;
        visibility: visible;
        z-index: 101;
      }
    `}

    .content.scrolling{
      
      &::-webkit-scrollbar {
        width: 15px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #C4C4C4;
        border: 3px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-track{
        background-color: #F8F8F8;
        box-shadow: inset 0 1px 0 0 rgba(155,155,155,0.3), inset 1px 0 0 0 rgba(155,155,155,0.3), inset -1px 0 0 0 rgba(155,155,155,0.3);
      }
    }
  }
`

const Header = styled(SemanticModal.Header)`
`

const SubHeader = ({ children, hidden }) => (
	<SubHeaderStyle hidden={hidden}>
		{children}
	</SubHeaderStyle>
)

const SubHeaderStyle = styled.div`
  ${props => props.hidden ? 'display: none;' : 'display: inline-block;'}
  width: 100%;
  position: relative;
  padding: 15px;
  box-shadow: inset 0 -1px 0 0 rgba(196,196,196,0.4);
`

const Content = styled(SemanticModal.Content)`
  &&&& {
    ${({ nopadding }) => nopadding && `
      padding: 0!important;
    `}
  }
`

const Actions = styled(SemanticModal.Actions)`
`

export default {
	Modal,
	Header,
	SubHeader,
	Content,
	Actions
}
