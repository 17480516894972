// @flow

import { kea } from 'kea'
import Proptypes from 'prop-types'
import { mapValues, values } from 'lodash'
import { put, call } from 'redux-saga/effects'

import API from 'api'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'

export default kea({
	path: () => ['scenes', 'BookingUnavailability'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		getServiceAreaList: () => true,
		setServiceAreas: areas => areas,
		setSelectedServiceArea: area => area,
		getRules: () => true,
		setRules: rules => rules,
		reset: () => true,
		setSpots: spots => spots,
		deleteRule: rule => rule
	}),

	reducers: ({ actions }) => ({
		serviceAreas: [[], Proptypes.array, {
			[actions.setServiceAreas]: (_, payload) => payload,
			[actions.reset]: () => ([])
		}],
		
		selectedServiceArea: [null, Proptypes.number, {
			[actions.setSelectedServiceArea]: (_, payload) => payload,
			[actions.reset]: () => null 
		}],
		
		loading: [true, Proptypes.bool, {
			[actions.setSelectedServiceArea]: () => false,
			[actions.setSpots]: () => false,
			[actions.setRules]: () => false,
			[actions.getRules]: () => true,
			[actions.reset]: () => true,
			[actions.deleteRule]: () => true
		}],
		
		rules: [[], Proptypes.array, {
			[actions.setRules]: (_, payload) => payload,
			[actions.reset]: () => ([])
		}],
    
		spots: [[], Proptypes.array, {
			[actions.setSpots]: (_, payload) => payload,
			[actions.reset]: () => null
		}]
	}),

	selectors: ({ selectors }) => ({

		serviceAreaOptions: [ () => [selectors.serviceAreas],
			(serviceAreas) => values(mapValues(
				serviceAreas,
				area =>  { return { 
					text: area.name, value: area.id
				} }
			))
		]
	}),

	start: function * () {
		const { getServiceAreaList } = this.actions
	
		yield put(getServiceAreaList())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.getServiceAreaList]: workers.getServiceAreaList,
		[actions.setSelectedServiceArea]: workers.getRules,
		[actions.setRules]: workers.getSpots,
		[actions.getRules]: workers.getRules,
		[actions.deleteRule]: workers.deleteRule
	}),

	workers: {
		* getServiceAreaList () {
			const {
				setServiceAreas,
				setSelectedServiceArea,
				showToaster
			} = this.actions
			const { t } = this.props
			
			try {
				const params = { status: 'registered,homologated' }
				
				const response = yield call(API.Areas.get, { params })
				const { results } = response
				yield put(setServiceAreas(results))
				if (results.length > 0) {
					yield put(setSelectedServiceArea(results[0].id))
				} else {
					yield put(setSelectedServiceArea(null))
					yield put(
						showToaster(
							'error',
							t('common.error'),
							t('api_error.no_data')
						))
				}
			} catch (e) {
				console.log(e)
			}
		},
    
		* getRules () {
			const {
				setRules
			} = this.actions
			
			const selectedServiceArea = yield this.get('selectedServiceArea')
			if (selectedServiceArea) {
				try {
					const response = yield call(
						API.Reservations.getUnavailRules,
						selectedServiceArea
					)
					const { results } = response
					yield put(setRules(results))
				} catch (e) {
					console.log(e)
				}
			}
		},
		
		* getSpots () {
			const { setSpots } = this.actions
			
			const selectedServiceArea = yield this.get('selectedServiceArea')
			
			const response = yield call(API.Areas.getSpots, selectedServiceArea)
			const { results } = response
			
			yield put(setSpots(results))
		},
		
		* deleteRule (actionPayload) {
			const { payload } = actionPayload
			const {
				getRules
			} = this.actions
			
			const selectedServiceArea = yield this.get('selectedServiceArea')
			
			try {
				yield call(
					API.Reservations.deleteUnavailRule,
					selectedServiceArea,
					payload
				)
				
				yield put(getRules())
			} catch (e) {
				console.log(e)
			}
		}
	}

})
