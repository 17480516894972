import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import { DEFAULT_VALUES, VALIDATIONS, VALIDATIONS_NO_REQUIRED } from './utils'
import API, { ENDPOINTS } from 'api'

import {
	Form,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import FormError from 'components/FormError'
import SegmentTitle from 'components/SegmentTitle'
import FormField from 'components/FormField'
import Buttons from '../Buttons'
import FormRequest from 'components/FormRequest'
import Label from 'components/Label'
import Uploader from '../../ServiceAreaRequestForm/Photos/uploader'

class Photos extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	componentWillUnmount () {
		this.submitData()
	}

	submitData = (action) => {
		const {t, current, campsiteId, submit} = this.props
		const {setCurrent, showToaster, updateStep} = this.actions
		
		const navigate = () => {
			switch (action) {
				case 'next':
					//setCurrent(current + 1)
					break
				case 'previous':
					setCurrent(current - 1)
					break
				default:
					break
			}
		}

		submit(data => API.Campsites.steps.updatePhotos(campsiteId, data), {
			resolveData: data => {
				const newData = {...data}
				newData.images = data.images.map(img => img.image.id)
				return newData
			},
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
					navigate()
				}
				else if (success === false) {
					showToaster(
						'error',
						t('common.error'),
						t('error.error_save_data')
					)
				}
				else navigate()
			}
		})
	}

	addFile = (files) => {
		
		const { form, changeForm } = this.props
		const { images } = form

		const newImages = [...images.value, ...files]
		changeForm({
			images: newImages
		})
	}

	removeFile = index => {
		
		const { form, changeForm } = this.props
		const { images } = form

		const newImages = [...images.value]
		newImages.splice(index, 1)
		changeForm({
			images: newImages
		})
	}

	render () {

		//const { changeForm, t, form, districts, municipalities, loading, contacts } = this.props
		const { t, loading, form, formHasChanged } = this.props

		const {
			images
		} = form

		return (
			<>
				<BoxSegment loading= {loading}>
					<Form>
						<SegmentTitle>
							{t('form.photos')}
						</SegmentTitle>
						<HorizontalLine />
            
						<DocumentWrapper>
							<FormField required>
								<Label
									color={'#616161'}
								>
									{t('form.upload_photos')}
								</Label>

								<Uploader
									limit={1}
									uploadEndpoint={ENDPOINTS.MEDIA}
									value={images.value}
									accept={'image/png'}
									onChange={(files) => this.addFile(files)}
									onRemove={(index) => this.removeFile(index)}
								/>
							</FormField>
							{images.dirty && !images.valid && <FormError>
								{images.message}
							</FormError>}
						</DocumentWrapper>
					</Form>
				</BoxSegment>

				<Buttons
					previousButtonClick={() => {this.submitData('previous')}}
					nextButtonClick={() => {this.submitData('next')}}
					saveButtonClick={() => this.submitData('')}
					dirty={formHasChanged}
				/>
			</>
		)
	}
}

const PhotosWithLogic = withTranslation()(Logic(Photos))

class FormContainer extends Component {
	render () {

		const { campsite } = this.props
		const { status } = campsite || {}

		return (
			<FormRequest defaultForm={DEFAULT_VALUES} validations={status === 'draft' ? VALIDATIONS_NO_REQUIRED : VALIDATIONS} initialLoading>
				<PhotosWithLogic {...this.props} />
			</FormRequest>
		)
	}
}

export default FormContainer

const HorizontalLine = styled.hr`
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #F3F3F3;
  margin: 16px 0;
`

const DocumentWrapper = styled.div`
	background-color: #FAFAFA;
	padding: 24px;
`
