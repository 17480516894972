import * as Check from 'validations'

export const ENTITY_DEFAULT_VALUES = {
	nif: {
		value: ''
	},
	name: {
		value: ''
	},
	email: {
		value: ''
	},
	password: {
		value: ''
	},
	passwordConfirmation: {
		value: ''
	},
	type: {
		value: []
	}
}

export const USER_VALIDATIONS = {
	nif: [
		Check.isRequired
	],
	name: [
		Check.isRequired
	],
	email: [
		Check.Email,
		Check.isRequired
	],
	password: [
		Check.isStrongPassword,
		Check.isRequired
	],
	passwordConfirmation: [
		Check.isTheSamePassword,
		Check.isRequired
	],
	type: [
		Check.isRequired
	]
}