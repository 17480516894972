/**
 * Register Logic 
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 * 
 */

import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'

import Proptypes from 'prop-types'
import { STEPS } from './utils'

import API from 'api'
/** Logic */
import ServiceAreaLogic from 'screens/ServiceArea/logic'
import ServiceAreaRequestLogic from 'screens/ServiceAreaRequest/logic'
import ToasterLogic from 'containers/Toaster/logic'
import { cloneDeep } from 'lodash'

export default kea({
	path: () => ['scenes', 'containers', 'ModalServiceArea'],

	connect: { 
		props: [
			ServiceAreaLogic, [
				'serviceAreaProcess',
				'serviceAreaProcessId'
			],
		],
		actions : [
			ToasterLogic, [
				'show as showToaster'
			],
			ServiceAreaRequestLogic, [
				'loaded',
				'loading',
				'start as startServiceArea'
			]
		]
	},

	actions: () => ({
		nextStep: () => true,
		onCkeck: (step, value) => ({ step, value }),
		changeCheck: (isChecked) => ({ isChecked }),
		onClose: () => true,
		open: () => true,
		onSubmit: () => true,

		reset: () => true
	}),

	reducers: ({ actions }) => ({

		allSteps: [STEPS, Proptypes.array, {
			[actions.onCkeck] : (state, payload) => {
				const newState = cloneDeep(state)
				const findStep = newState.find(step => step.step === payload.step.step)
				if (findStep.step === 1 && payload.value === 'yes') {
					findStep.value = payload.value
					findStep.isSelect = false
				} else {
					findStep.value = payload.value
					findStep.isSelect = true
				}

				return [...newState, findStep]
			},
			[actions.changeCheck] : (state, payload) => {
				const newState = cloneDeep(state)

				if (payload.isChecked) {
					newState[0].checked++
				} else {
					newState[0].checked--
				}

				if (newState[0].checked === 20) {
					newState[0].isSelect = true
				} else {
					newState[0].isSelect = false
				}
				return newState
			},
			[actions.onClose]: () => STEPS,
			[actions.reset]: () => STEPS
		}],
	
		currentStep : [1, Proptypes.number, {
			[actions.nextStep]: (state) => state + 1,
			[actions.onClose]: () => 1,
			[actions.reset]: () => 1
		}],

		showModal: [false, Proptypes.bool, {
			[actions.open]: () => true,
			[actions.onSubmit]: () => false,
			[actions.onClose]: () => false,
			[actions.reset]: () => false
		}]

	}),

	selectors: ({ selectors }) => ({

		selectStep: [
			() => [
				selectors.allSteps,
				selectors.currentStep,
			],
			(allSteps, currentStep) => ( 
				allSteps[currentStep - 1]
			),
			Proptypes.object
		],

	}),

	stop: function * () {
		const { reset } = this.actions
		yield put(reset)
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.onSubmit]: workers.submit,
		[actions.open]: workers.startComponent
	}),

	workers: {
		* submit () {
			const { showToaster, loaded, loading, startServiceArea } = this.actions
			const { t } = this.props
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			const allSteps = yield this.get('allSteps')

			yield put(loading())
			try {
				const params = {
					cleanAndSafe: allSteps[0].value === 'yes' ? true : false,
					homologation: allSteps[1].value === 'yes' ? true : false,
					register: true
				}
				yield call(API.Areas.submit, serviceAreaProcessId, params)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.process_sent_success')
				))
				yield put(startServiceArea())
			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.process_sent_error')
				))
				yield put(loaded())
			}
		},

		* startComponent () {
			const { startServiceArea } = this.actions	
			yield put(startServiceArea())
		}
	}

})
