/**
 * Login Logic
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 * 
 */

import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'

import Proptypes from 'prop-types'
import * as Check from 'validations'
import mapValues from 'lodash/mapValues'
import Cookies from 'js-cookie'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'

import API from 'api'
import { mapOnChangeToState } from 'utils'

import {
	DEFAULT_LOGIN_TYPE,
	DEFAULT_VALUES,
	VALIDATIONS,
	LOGIN_TYPES,
} from './utils'
import { cloneDeep } from 'lodash'

const BASE_DOMAIN  = process.env.REACT_APP_BASE_DOMAIN

export default kea({
	path: () => ['scenes', 'Login'],

	connect: {
		props: [
			AppLogic, [
				'contacts'
			]
		],
		actions: [
			AppLogic, [
				'navigate', 
				'setCurrentUser'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		setForm: (form) => ({ form }),
		change: (field) => ({ field }),
    
		setRecoverNIF: field => ({ field }),
		setRecoverEmail: field => ({ field }),
		changeLoginType: (type) => ({ type }),
    
		submit: () => true,
		recoverPassword: () => true,
		toggleRecoverPassword: () => true,
		error: (error) => ({ error }),
		response: error => ({ error })
	}),

	reducers: ({ actions }) => ({
		loginTypesList: [LOGIN_TYPES, Proptypes.array, {}],

		loginType: [DEFAULT_LOGIN_TYPE, Proptypes.string, {
			[actions.changeLoginType]: (state, payload) => payload.type
		}],

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.change]: mapOnChangeToState,
			[actions.setRecoverNIF]: mapOnChangeToState,
			[actions.setRecoverEmail]: mapOnChangeToState,
			[actions.setForm]: (state, payload) => payload.form,
		}],

		dirty: [false, Proptypes.bool, {
			[actions.change]: () => true,
			[actions.response]: () => false,
			[actions.error]: () => true
		}],

		submiting: [false, Proptypes.bool, {
			[actions.submit]: () => true,
			[actions.recoverPassword]: () => true,
			[actions.error]: () => false,
			[actions.response]: () => false
		}],

		error: [null, Proptypes.any, {
			[actions.error]: (state, payload) => payload.error,
			[actions.recoverPassword]: () => null,
			[actions.response]: () => null
		}],

		response: [null, Proptypes.any, {
			[actions.response]: (state, payload) => payload.error,
		}],

		loading: [false, Proptypes.any, {}],

		showingRecover: [false, Proptypes.bool, {
			[actions.toggleRecoverPassword]: (state) => !state
		}],
	}),

	selectors: ({ selectors }) => ({
		isValid: [
			() => [selectors.form],
			(form) => !Check.checkValidation(form, VALIDATIONS).invalid,
			Proptypes.bool
		]
	}),

	takeLatest: ({ actions, workers }) => ({
		[actions.submit]: workers.submit,
		[actions.checkLogin]: workers.checkLogin,
		[actions.change]: workers.validateFormOnChange,
		[actions.recoverPassword]: workers.recoverPassword
	}),

	workers: {

		* submit () {
			const { setCurrentUser, error } = this.actions
			const { t } = this.props
			const form = yield this.get('form')
			const validation = Check.checkValidation(form, VALIDATIONS)

			if (validation.invalid) {
				yield put(error(validation.form))
				return
			}

			try {
				const params = mapValues(form, ({ value }) => value)

				const { emailNif } = params
				if (Check.isNif(emailNif).result) params.nif = emailNif
				else params.email = emailNif

				delete params.emailNif

				const { access, refresh } = yield call(API.Auth.Login, params)
      
				const user = yield call(API.Auth.User, access)
				if (user.type === 'entity') {
					Cookies.set('access', access, { domain: BASE_DOMAIN })
					Cookies.set('refresh', refresh, { domain: BASE_DOMAIN })
					yield put(setCurrentUser(user, !user.registrationComplete
						?'/finalizar-registo'
						: '/gestao-processos'
					))
				} else {					
					yield put(error([t('error.no_permissons')]))
				}
        
				/*if (!user.registrationComplete) {
          yield put(navigate('/finalizar-registo'))
        } else {
          yield put(navigate('/gestao-processos'))
        }*/

			} catch (err) {
				const status = err.response.status
				let errors = [
					t('error.something_went_wrong')
				]

				if (status === 400) {
					errors = [t('error.invalid_credentials')]
				}

				yield put(error(errors))
			}
		},

		/**
     * Validates form debounced
     */

		* validateFormOnChange () {
			const { setForm } = this.actions
			const form = yield this.get('form')
			yield put(setForm(Check.checkValidation(form, VALIDATIONS).form))
		},

		/**
     * Recovers passsword
     */

		* recoverPassword () {
			let errors
			const { t } = this.props
			// eslint-disable-next-line max-len
			const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  
			const { response, error } = this.actions
			let { email } = yield this.get('form')
			email = email.value

			switch (true) {
				case !email:
					errors = t('api_error.mandatory')
					break
				case !regex.test(email):
					errors = t('error.only_numbers')
					break
				default: break
			}

			try {
				if (errors) throw new Error()
				yield call(API.Auth.Recover, { email })
				yield put(response(true))
			} catch (err) {
				if (!errors) errors = t('api_error.invalid_email')
				yield put(error([errors]))
			}
      
		}

	}

})
