/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const Caravan = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M5.77777778,6 C4.79593822,6 4,6.82655123 4,7.84615385 L4,13.3846154 C4,14.404218 4.79593822,15.2307692 5.77777778,15.2307692 L7.55555556,15.2307692 C7.55555556,16.7601732 8.74946289,18 10.2222222,18 C11.6949816,18 12.8888889,16.7601732 12.8888889,15.2307692 L20,15.2307692 L20,13.3846154 L18.2222222,13.3846154 L18.2222222,7.84615385 C18.2222222,6.82655123 17.426284,6 16.4444444,6 L5.77777778,6 M5.77777778,7.84615385 L10.2222222,7.84615385 L10.2222222,10.6153846 L5.77777778,10.6153846 L5.77777778,7.84615385 M12.8888889,7.84615385 L16.4444444,7.84615385 L16.4444444,10.6153846 L12.8888889,10.6153846 L12.8888889,7.84615385 M10.2222222,14.3076923 C10.713142,14.3076923 11.1111111,14.7209679 11.1111111,15.2307692 C11.1111111,15.7405705 10.713142,16.1538462 10.2222222,16.1538462 C9.73130244,16.1538462 9.33333333,15.7405705 9.33333333,15.2307692 C9.33333333,14.7209679 9.73130244,14.3076923 10.2222222,14.3076923 Z" />
		</g>
	</svg>
)

Caravan.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

Caravan.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

export default Caravan
