import * as Check from 'validations'

const DEFAULT_VALUES = {
	hasFacilitiesRegulationDocument: {
		value: true
	},
	facilitiesRegulationDescription: {
		value: ''
	},
	facilitiesRegulationDocument: {
		value: ''
	},
	hasCityCouncilPermissionDocument: {
		value: true
	},
	cityCouncilPermissionDescription: {
		value: ''
	},
	cityCouncilPermissionDocument: {
		value: ''
	},
	hasMotorhomeServiceAreaBlueprint: {
		value: true
	},
	motorhomeServiceAreaDescription: {
		value: ''
	},
	motorhomeServiceAreaBlueprint: {
		value: true
	},
	hasPriceMenu: {
		value: true
	},
	priceMenuDescription: {
		value: ''
	},
	priceMenu: {
		value: ''
	},
	otherDocumentName: {
		value: ''
	},
	otherDescription: {
		value: ''
	},
	otherDocument: {
		value: ''
	}
}

const VALIDATIONS = {
	facilitiesRegulationDocument: [
		Check.isRequired
	],
	cityCouncilPermissionDocument: [
		Check.isRequired
	],
	motorhomeServiceAreaBlueprint: [
		Check.isRequired
	],
	priceMenu: [
		Check.hasPriceMenu,
		Check.isRequired,
	]
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}