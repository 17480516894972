import { kea } from 'kea'
/** Logic */
import AppLogic from 'containers/App/logic'
import ServiceAreaRequestLogic from 'screens/ServiceAreaRequest/logic'
import ServiceAreaLogic from 'screens/ServiceArea/logic'
import ModalServiceAreaLogic from 'containers/Modals/ModalServiceArea/logic'

export default kea({
	path: () => ['scenes', 'containers', 'ServiceAreaRequestForm', 'Buttons'],

	connect: {
		props: [
			ServiceAreaRequestLogic, [
				'current',
				'canSubmit'
			],
			ServiceAreaLogic, [
				'serviceAreaProcess'
			],
		],
		actions: [
			AppLogic, [
				'navigate'
			],
			ServiceAreaRequestLogic, [
				'toggleHomologation'
			],
			ModalServiceAreaLogic, [
				'open as openModal'
			]
		]
	}
})
