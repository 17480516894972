import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import { DEFAULT_VALUES, VALIDATIONS, VALIDATIONS_NO_REQUIRED } from './utils'
import API from 'api'
import { isGpsCoordinates } from 'validations'

import {
	Form,
	Input as SemanticInput,
	TextArea,
	Dropdown as SemanticDropdown,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import FormField from 'components/FormField'
import Buttons from '../Buttons'
import Label from 'components/Label'
import FormGroup from 'components/FormGroup'
import Map from 'components/Map'
import FormRequest from 'components/FormRequest'

class MemorizedMap extends Component {

	shouldComponentUpdate (nextProps) {
		if (JSON.stringify(nextProps.markers) === JSON.stringify(this.props.markers)) return false

		return true
	}

	render () {
		const { centerPosition, markers, getCoordinates, withSearch } = this.props

		return (
			<Map
				centerPosition={centerPosition}
				markers={markers}
				getCoordinates={getCoordinates}
				withSearch={withSearch}
			/>
		)
	}
}

class SummaryDescription extends Component {

	constructor (props) {
		super(props)

		this.position = [39.557, -6.20]
		this.markers = []
		this.optionsMunicipalities = []
		this.optionsDistricts = []
	}

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	componentWillUnmount () {
		this.submitData()
	}

	componentWillUpdate (nextProps) {

		const { form, municipalities, districts } = this.props
		const {
			form: nextForm,
			municipalities: nextMunicipalities,
			districts: nextDistricts
		} = nextProps

		const { gpsCoordinates, district } = form
		const { gpsCoordinates: nextGpsCoordinates, district: nextDistrict } = nextForm

		if (gpsCoordinates.value !== nextGpsCoordinates.value) {
			
			const coordinates = nextGpsCoordinates.value.split(',')

			if (coordinates.length === 2 && isGpsCoordinates(coordinates).result) {
				this.position = [coordinates[0], coordinates[1]]
				this.markers = [{ position: coordinates }]
			}
		}

		if ((district.value !== nextDistrict.value && nextMunicipalities.length)
			|| (municipalities.length !== nextMunicipalities.length && nextDistrict.value)) {
				
			this.optionsMunicipalities = nextMunicipalities
				.filter(municipality => municipality.district.id === nextDistrict.value)
				.map(({ id, name }) => ({ text: name, value: id }))

		}
		
		if (districts.length !== nextDistricts.length) {
			this.optionsDistricts = nextDistricts.map(
				({ id, name }) =>
					({ text: name, value: id })
			)

		}

	}

	onChangePostalCode = (field) => {
		const { changeForm } = this.props
		const { getDataToPrefillFields } = this.actions
		changeForm(field)
		getDataToPrefillFields()
	}

	onGetCoordinates = (coordinates) => {
		const { changeForm } = this.props
		const { lat, lng } = coordinates

		if (lat && lng) {
			const location = coordinates.lat + ',' + coordinates.lng
			changeForm({ gpsCoordinates: location })
		}
	}

	submitData = (action) => {
		const {t, current, campsiteId, submit} = this.props
		const {setCurrent, showToaster, updateStep, updateCampsite} = this.actions
		
		const navigate = () => {
			switch (action) {
				case 'next':
					setCurrent(current + 1)
					break
				case 'previous':
					setCurrent(current - 1)
					break
				default:
					break
			}
		}

		submit(data => API.Campsites.steps.updateSummary(campsiteId, data), {
			resolveData: data => {
				const coordinates = data.gpsCoordinates.split(',')
				data.gpsCoordinates = {
					latitude: coordinates[0],
					longitude: coordinates[1]
				}
				return data
			},
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
					updateCampsite({summary: data})
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
					navigate()
				}
				else if (success === false) {
					showToaster(
						'error',
						t('common.error'),
						t('error.error_save_data')
					)
				}
				else navigate()
			}
		})
	}

	render () {

		const { changeForm, t, form, districts, municipalities, loading, formHasChanged } = this.props

		const {
			designation,
			rnet,
			description,
			fullAddress,
			gpsCoordinates,
			postalCode,
			locality,
			district,
			municipality,
			website,
			email,
			telephone,
			mobile,
			facebook,
			instagram,
			twitter,
			tripadvisor
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('form.summary_description')}
						</SegmentTitle>

						<FormField
							id='designation'
							required
							name={'service_area_name'}
							label={t('campsite_details.campsite_name')}
							error={designation.dirty && !designation.valid && designation.message}
							control={Input}
							value={designation.value}
							onChange={(e, { value }) => changeForm(
								{ designation: value })
							}
						/>

						<FormField
							id='description'
							required
							margin={'28px 0 0 0'}
							name={'general_description'}
							label={t('form.general_description')}
							// eslint-disable-next-line max-len
							error={(description.dirty && !description.valid) && description.message}
							control={TextArea}
							//maxLength={600}
							value={description.value || ''}
							onChange={(e, { value }) => changeForm(
								{ description: value })
							}
						/>
						{/*<SmallLabel style={{ paddingTop: 8, paddingLeft: '0.5em' }}>
							{t('form.characters')} {''.length} / 600
						</SmallLabel>*/}

						<FormField
							id='rnet'
							required
							margin={'20px 0 28px 0'}
							name={'campsite_register'}
							label={t('form.campsite_register')}
							error={rnet.dirty && !rnet.valid && rnet.message}
							control={Input}
							value={rnet.value || ''}
							onChange={(e, { value }) => changeForm(
								{ rnet: value })
							}
						/>

						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('form.address_and_contacts')}
						</SegmentTitle>

						<FormField
							id='fullAddress'
							required
							name={'address'}
							control={Input}
							label={
								<>
									<Label color={'#616161'}>
										{t('form.full_address')}
									</Label>
									<SmallLabel>
										{t('form.full_address_description')}
									</SmallLabel>
								</>
							}
							value={fullAddress.value}
							onChange={(e, { value }) => changeForm(
								{ fullAddress: value }
							)}
							// eslint-disable-next-line max-len
							error={fullAddress.dirty && !fullAddress.valid && fullAddress.message }
						/>

						<FormField
							id='gpsCoordinates'
							required
							name={'gps'}
							label={t('form.gps_coordinates')}
							control={Input}
							value={gpsCoordinates.value}
							onChange={(e, { value }) => {
								changeForm({ gpsCoordinates: value })
							}}
							// eslint-disable-next-line max-len
							error={(gpsCoordinates.dirty && !gpsCoordinates.valid) && gpsCoordinates.message}
						/>

						<MapWrapper>
							<MemorizedMap
								centerPosition={this.position}
								markers={this.markers}
								getCoordinates={this.onGetCoordinates}
								withSearch
							/>
						</MapWrapper>

						<FormGroup widths='equal'>
							<FormField
								id='postalCode'
								required
								name={'postalCode'}
								label={t('form.postal_code')}
								// eslint-disable-next-line max-len
								error={(postalCode.dirty && !postalCode.valid) && postalCode.message}
								control={Input}
								value={postalCode.value}
								onChange={(e, { value }) =>
									this.onChangePostalCode(
										{ postalCode: value }
									)
								}
							/>

							<FormField
								id='locality'
								required
								name={'locality'}
								label={t('form.locality')}
								// eslint-disable-next-line max-len
								error={(locality.dirty && !locality.valid) && locality.message}
								control={Input}
								value={locality.value}
								onChange={(e, { value }) => changeForm(
									{ locality: value }
								)}
							/>
						</FormGroup>

						<FormGroup widths='equal'>
							<FormField
								id='district'
								required
								name={'district'}
								// eslint-disable-next-line max-len
								error={(district.dirty && !district.valid) && district.message}
								label={t('form.district')}
								control={Dropdown}
								placeholder={t('form.district_option')}
								options={this.optionsDistricts}
								onChange={(e, data) => 
									districts && changeForm(
										{ district: data.value }
									)}
								value={district.value}
								selection
							/>

							<FormField
								id='municipality'
								required
								name={'municipality'}
								control={Dropdown}
								// eslint-disable-next-line max-len
								error={(municipality.dirty && !municipality.valid) && municipality.message}
								label={t('form.municipality')}
								placeholder={t('form.municipality_option')}
								options={this.optionsMunicipalities}
								value={municipality.value}
								onChange={(e, data) =>
									municipalities &&
									changeForm({ municipality: data.value })
								}
								selection
							/>
						</FormGroup>

						<FormGroup widths='equal'>
							<FormField
								id='website'
								name={'website'}
								label={t('form.website')}
								error={(website.dirty && !website.valid) && website.message}
								control={Input}
								value={website.value}
								onChange={(e, { value }) => changeForm(
									{ website: value })
								}
							/>

							<FormField
								id='email'
								required
								name={'email_address'}
								label={t('form.email_address')}
								// eslint-disable-next-line max-len
								error={(email.dirty && !email.valid) && email.message}
								control={Input}
								value={email.value || ''}
								onChange={(e, { value }) => changeForm(
									{ email: value })
								}
							/>
						</FormGroup>

						<FormGroup widths='equal'>
							<FormField
								id='telephone'
								name={'phone'}
								label={t('form.phone')}
								// eslint-disable-next-line max-len
								error={telephone.dirty && !telephone.valid && telephone.message}
								control={Input}
								value={telephone.value}
								onChange={(e, { value }) => changeForm(
									{ telephone: value })
								}
							/>

							<FormField
								id='mobile'
								required
								name={'mobile'}
								label={t('form.mobile')}
								// eslint-disable-next-line max-len
								error={(mobile.dirty && !mobile.valid) && mobile.message}
								control={Input}
								value={mobile.value}
								onChange={(e, { value }) => changeForm(
									{ mobile: value })
								}
							/>
						</FormGroup>

						<FormGroup widths='equal'>
							<FormField
								id='facebook'
								name={'facebook'}
								label={'Facebook'}
								// eslint-disable-next-line max-len
								error={(facebook.dirty && !facebook.valid) && facebook.message}
								control={Input}
								value={facebook.value}
								onChange={(e, { value }) => changeForm(
									{ facebook: value })
								}
							/>

							<FormField
								id='instagram'
								name={'instragram'}
								label={'Instagram'}
								// eslint-disable-next-line max-len
								error={(instagram.dirty && !instagram.valid) && instagram.message}
								control={Input}
								value={instagram.value}
								onChange={(e, { value }) => changeForm(
									{ instagram: value })
								}
							/>
						</FormGroup>

						<FormGroup widths='equal'>
							<FormField
								id='twitter'
								name={'twitter'}
								label={'Twitter'}
								// eslint-disable-next-line max-len
								error={(twitter.dirty && !twitter.valid) && twitter.message}
								control={Input}
								value={twitter.value}
								onChange={(e, { value }) => changeForm(
									{ twitter: value })
								}
							/>

							<FormField
								id='tripadvisor'
								name={'tripadvisor'}
								label={'Tripadvisor'}
								// eslint-disable-next-line max-len
								error={(tripadvisor.dirty && !tripadvisor.valid) && tripadvisor.message}
								control={Input}
								value={tripadvisor.value}
								onChange={(e, { value }) => changeForm(
									{ tripadvisor: value })
								}
							/>
						</FormGroup>

					</Form>
				</BoxSegment>

				<Buttons
					previousButtonClick={() => {this.submitData('previous')}}
					nextButtonClick={() => {this.submitData('next')}}
					saveButtonClick={() => this.submitData('')}
					dirty={formHasChanged}
				/>
			</>
		)
	}
}

const SummaryDescriptionWithLogic = withTranslation()(Logic(SummaryDescription))

class FormContainer extends Component {
	render () {

		const { campsite } = this.props
		const { status } = campsite || {}

		return (
			<FormRequest submitChangedDataOnly defaultForm={DEFAULT_VALUES} validations={status === 'draft' ? VALIDATIONS_NO_REQUIRED : VALIDATIONS} initialLoading>
				<SummaryDescriptionWithLogic {...this.props} />
			</FormRequest>
		)
	}
}

export default FormContainer

const Input = styled(SemanticInput)`
  width: 100%;
`

const Dropdown = styled(SemanticDropdown)`
  width: 100%;
`

const SmallLabel = styled.span`
  display: block;
  opacity: 0.5;
  color: #616161;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 14px;
  padding: 5px 0 10px 0;
`

const MapWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  margin: 15px 0;

  .leaflet-container {
    height: 430px;
    width: 100%;
  }

  .leaflet-div-icon {
    background: #ACCF38;
  }
`