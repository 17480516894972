
import axios from 'axios'

export const fetchData = (url, config) => {
	return axios.get(url, config)
		.then(res => res)
		.catch(err => { throw err })
}

export const submit = (url, payload, type = 'post') => {
	return axios[type](url, payload)
		.then(res => res)
		.catch(err => { throw err })
}

export const mapErrorMessages = (errors, form) => {

	const newForm = {...form}

	errors.map(error => {
		Object.keys(error).map(key => {
			if (error[key].length && newForm[key]) {
				newForm[key].valid = false
				newForm[key].message = error[key][0]
			}
		})
	})

	return newForm
}

export const getDifferentData = (form, initialForm) => {
	const result = {}

	Object.keys(initialForm).map(key => {

		if (typeof form[key] === 'object') {
			if (JSON.stringify(form[key]) !== JSON.stringify(initialForm[key])) result[key] = form[key]
		}
		// form datas are flat at this point
		else if (initialForm[key] !== form[key]) {
			result[key] = form[key]
		}
	})

	return result

}

export const formIsDifferent = (form, initialForm) => {
		
	let isDiff = false

	Object.keys(initialForm).map(key => {

		if (typeof form[key] === 'object') {
			if (JSON.stringify(form[key]) !== JSON.stringify(initialForm[key])) {
				isDiff = true
				return
			}
		}
		else if (initialForm[key].value !== form[key].value) {
			isDiff = true
			return
		}
	})

	return isDiff
}