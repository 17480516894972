import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import { resolveLanguageOptions, resolveTerrainTypeOptions, resolveAccommodationTypeOptions } from './utils'
import { put } from 'redux-saga/effects'
import API from 'api'
import Proptypes from 'prop-types'

export default kea({
	path: () => ['scenes', 'containers', 'CampingManagementRequestForm', 'Characteristics'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			CampsiteManagementRequestLogic, [
				'current',
			],
			CampsiteManagementLogic, [
				'campsiteId'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			CampsiteManagementRequestLogic, [
				'setCurrent',
				'updateStep'
			],
		]
	},

	actions: () => ({
		fetchForm: () => true,
		setLanguageOptions: opts => opts,
		setTerrainTypeOptions: opts => opts,
		setAccommodationTypeOptions: opts => opts
	}),

	reducers: ({actions}) => ({
		languageOptions: [[], Proptypes.array, {
			[actions.setLanguageOptions]: (state, opts) => opts
		}],
		terrainTypeOptions: [[], Proptypes.array, {
			[actions.setTerrainTypeOptions]: (state, opts) => opts
		}],
		accommodationTypeOptions: [[], Proptypes.array, {
			[actions.setAccommodationTypeOptions]: (state, opts) => opts
		}]
	}),

	start: function * () {
		const { t } = this.props
		const { setLanguageOptions, setTerrainTypeOptions, setAccommodationTypeOptions } = this.actions

		const langOpts = resolveLanguageOptions(t)
		const terrainOpts = resolveTerrainTypeOptions(t)
		const accomodationOpts = resolveAccommodationTypeOptions(t)

		yield put(setLanguageOptions(langOpts))
		yield put(setTerrainTypeOptions(terrainOpts))
		yield put(setAccommodationTypeOptions(accomodationOpts))
	},

	takeLatest: ({actions, workers}) => ({
		[actions.fetchForm]: workers.fetchForm
	}),

	workers: {

		* fetchForm () {
			
			const { fetchData } = this.props
			const campsiteId = yield this.get('campsiteId')

			fetchData((data, config) => API.Campsites.steps.getCharacteristics(campsiteId, config), {
				resolveData: data => {

					const operatingPeriod = (data.operatingPeriod || '').split('-')
					
					if (operatingPeriod.length) data.operatingPeriodBegin = operatingPeriod[0]
					if (operatingPeriod.length === 2) data.operatingPeriodEnd = operatingPeriod[1]

					const regularReceptionHours = (data.regularReceptionHours || '').split('-')
					
					if (regularReceptionHours.length) data.regularReceptionHoursBegin = regularReceptionHours[0]
					if (regularReceptionHours.length === 2) data.regularReceptionHoursEnd = regularReceptionHours[1]

					const highSeasonReceptionHours = (data.highSeasonReceptionHours || '').split('-')
					
					if (highSeasonReceptionHours.length) data.highSeasonReceptionHoursBegin = highSeasonReceptionHours[0]
					if (highSeasonReceptionHours.length === 2) data.highSeasonReceptionHoursEnd = highSeasonReceptionHours[1]
	
					const langOptions = resolveLanguageOptions(l => l).map(lang => lang.value)

					const langs = langOptions.reduce((currLangs, lang) => {
						if (data[lang]) currLangs.push(lang)
						return currLangs
					}, [])
					
					data.languages = langs

					return data

				}
			})

		},
	}
})
