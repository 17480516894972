import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
import API from 'api'

import {
	Form,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import ListAdder from 'components/ListAdder'

class OtherServices extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	valueChangeService = (prop, value, index) => {

		const { changeForm, form } = this.props
		const { services: services } = form

		const newOtherServices = [...services.value]
		newOtherServices[index][prop] = value

		changeForm({services: newOtherServices})
	}

	addService = () => {

		const { form, changeForm } = this.props
		const { services: services } = form

		const newOtherServices = [...services.value]
		newOtherServices.push({name: ''})
		changeForm({
			services: newOtherServices
		})
	}

	removeService = index => {

		const { form, changeForm } = this.props
		const { services: services } = form

		const newOtherServices = [...services.value]
		newOtherServices.splice(index, 1)
		changeForm({
			services: newOtherServices
		})
	}

	submitData = (callback) => {
		const {campsiteId, submit} = this.props
	
		submit(data => API.Campsites.steps.updateOtherServices(campsiteId, data), {
			resolveData: data => {
				return {serviceName: data.services.map(service => service.name)}
			},
			callback: (data, success) => {
				callback(success)
			}
		})
	}

	render () {

		const { t, form, loading } = this.props

		const {
			services
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('campsite_details.other_services')}
						</SegmentTitle>

						<ListAdder
							data={services.value}
							options={[{
								name: 'name',
								label: t('campsite_details.service_name')
							}]}
							add={this.addService}
							remove={this.removeService}
							valueChange={this.valueChangeService}
						/>

					</Form>
				</BoxSegment>

			</>
		)
	}
}

export default withTranslation()(Logic(OtherServices))
