import React, { Component } from 'react'
import styled from 'styled-components'
import {
	Progress,
	Grid,
	Checkbox as SemanticCheckbox,
	Radio as SemanticRadio,
	Form
} from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Logic from './logic'

/** Component */
import Button from 'components/Button'
import Modal from 'components/Modal'

export class SumbitAsa extends Component {
	render () {
		const { serviceArea } = this.props
		return (
			<>
				<Header>Submeter a ASA</Header>
				<Subtitle>
					Ao submeter irá registar a sua nova ASA na plataforma Outdoor Routes
				</Subtitle>
				<Description>
					{/* eslint-disable-next-line max-len */}
					Registo desta ASA irá permite-lhe geri-la e ter acesso a funcionalidades que irão lhe dar visibilidade maior sobre a Área de Serviço que submeteu.
				</Description>
				<Subtitle>
					Área de Serviço de Autocaravanas que será submetida:
				</Subtitle>
				<Label>Nome da Área de Serviço</Label>
				<Info>{serviceArea.summary.name}</Info>
				<Label>Distrito</Label>
				<Info>{serviceArea.summary.municipality.district.name}</Info>
				<Label>Localidade</Label>
				<Info>{serviceArea.summary.municipality.name}</Info>
			</>
		)
	}
}

export class ChooseCleanSafe extends Component {
	state = {
		showChecks: false
	}

	render () {
		const { onCkeck, step, changeCheck } = this.props
	
		const handleChange = (e, { value }) => {
			onCkeck(step, value)
			if (value === 'yes') {
				this.setState({ showChecks: true })
			} else {
				this.setState({ showChecks: false })
			}
		}
	
		const { showChecks } = this.state
	
		return (
			<>
				<Header>ASA Declaração de Estabelecimento Saudável e Seguro</Header>
				<Subtitle>
					Pretede Submeter o pedido para ter acesso ao Selo Clean & Safe?
				</Subtitle>
				<Description>
					{/* eslint-disable-next-line max-len */}
					Ao submeter este pedido terá que respeitar um conjunto de normas estabelecidas pela DGS e comprometer-se com a segurança e higiene do espaço, tendo em atenção os tempos vividos atualmente.
				</Description>
				<Form>
					<Form.Field>
						<Radio
							label='Sim, pretendo ter acesso ao Selo Clean & Safe.'
							name='radioGroup'
							value='yes'
							checked={step.value === 'yes'}
							onChange={handleChange}
						/>
					</Form.Field>
					<Form.Field>
						<Radio
							label='Não, não pretendo submeter o pedido para aceder ao Selo Clean & Safe.'
							name='radioGroup'
							value='no'
							checked={step.value === 'no'}
							onChange={handleChange}
						/>
					</Form.Field>
					{showChecks &&
					<>
						<Separator />
						<Modal.Content scrolling>
							<div style={{ marginTop: 40 }} />
							<HeaderTitle>
								Orientações/Formação para todos os Gestores das Infraestruturas
							</HeaderTitle>

							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Protocolo interno relativo ao surto de coronavírus COVID-19. '
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Precauções básicas de prevenção e controlo de infeção relativamente ao surto de coronavírus COVID-19, incluindo os procedimentos:'
							/>

							<BulletContainer>
								<Bullet>
									{/* eslint-disable-next-line max-len */}
									<strong>higienização das mãos:</strong> lavar as mãos frequentemente com água e sabão, durante pelo menos 20 segundos ou usar desinfetante para as mãos que tenha pelo menos 70% de álcool, cobrindo todas as superfícies das mãos e esfregando-as até ﬁ lava secas.
								</Bullet>
								<Bullet>
									{/* eslint-disable-next-line max-len */}
									<strong>etiqueta respiratória:</strong> tossir ou espirrar para o antebraço ﬂetido ou usar lenço de papel, que depois deve ser imediatamente deitado ao lixo; Higienizar as mãos sempre após tossir ou espirrar e depois de se assoar; Evitar tocar nos olhos, nariz e boca com as mãos.
								</Bullet>
								<Bullet>
									{/* eslint-disable-next-line max-len */}
									<strong>conduta social:</strong> alterar a frequência e a forma de contacto entre os trabalhadores e entre estes e os clientes, evitando (quando possível) o contacto próximo, apertos de mão, beijos, postos de trabalho partilhados, reuniões presenciais e partilha de comida, utensílios, copos e toalhas.
								</Bullet>
							</BulletContainer>

							<Checkbox onChange={(e, { checked }) => changeCheck(checked)} label='As ações para o cumprimento e a auto monitorização diária que passam pela avaliação da febre, através da medição da temperatura corporal, duas vezes por dia, registando o valor e a hora de medição, assim como a veriﬁcação de tosse ou de diﬁculdade em respirar.' />
							<Checkbox onChange={(e, { checked }) => changeCheck(checked)} label='As orientações da Direção Geral de Saúde para a limpeza de todas as superfícies.' />

							<HeaderTitle>Disponibilidade de Informação para os Utilizadores da ASA</HeaderTitle>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Cumprimento das orientações básicas de prevenção e controlo de infeção relativamente ao surto de coronavírus COVID-19.'
							/>

							<BulletContainer>
								<Bullet>Qual o protocolo interno relativo ao surto de coronavírus COVID-19.</Bullet>
							</BulletContainer>

							<HeaderTitle>Requisitos de Material para a ASA</HeaderTitle>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Equipamentos de proteção individual em número suficiente para os trabalhadores.' 
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Stock de materiais de limpeza de uso único proporcional às suas dimensões., incluindo toalhetes de limpeza de uso único humedecidos em desinfetante, lixívia e álcool a 70º.'
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Dispensadores de solução antissética de base alcoólica ou solução à base de álcool junto aos pontos de entrada/saída e junto às estações de serviço.'
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Contentor de resíduos com abertura não manual e saco plástico.' 
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='Caso existam infraestruturas de apoio com zona para lavagem de mãos estas devem estar equipadas com sabão líquido e toalhetes de papel.'
							/>

							<HeaderTitle>Requisitos de Funcionamento e Protocolares para a ASA</HeaderTitle>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Lavagem e desinfeção, de acordo com o protocolo interno, das superfícies onde colaboradores e utilizadores do espaço circulam, garantindo o controlo e a prevenção de possíveis infeções e resistências aos antimicrobianos.'
							/>
							<Checkbox
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Limpeza das superfícies e objetos de utilização comum, várias vezes ao dia.' 
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Ser dada preferência à limpeza húmida, em detrimento da limpeza a seco.'
							/>
							<Checkbox
								onChange={(e, { checked }) => changeCheck(checked)}
								label='A renovação regular do ar em espaços fechados, quando existam.'
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Nas estaçõe de serviço da ASA, a higienização de todas as superfícies, equipamentos e materiais deve ser reforçada, de acordo com o protocolo interno.'
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Assegurar o cumprimento do distanciamento social e a ocupação máxima por m2, recomendada.' 
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)} 
								label='A distribuição de informação preferencialmente em suporte digital/online.' 
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='Contacto direto com o Serviço Nacional de Saúde, caso exista necessidade ou suspeita de infeção.' 
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='A descontaminação do espaço, o reforço da limpeza e da desinfeção, sempre que haja casos positivos de infeção, conforme indicações da Direção Geral de Saúde.'
							/>
							<Checkbox 
								onChange={(e, { checked }) => changeCheck(checked)}
								label='O armazenamento dos resíduos produzidos pelos doentes suspeitos de infeção em saco de plástico que, após fechado (ex. com abraçadeira) deve ser segregado e enviado para o operador capacitado para a gestão dos resíduos hospitalares com risco biológico. ' 
							/>
						</Modal.Content>
					</>
					}
				</Form>
			</>
		)
	}
}

export class ChooseHomologation extends Component {	
	render () {
		const { onCkeck, step } = this.props
		const handleChange = (e, { value }) => onCkeck(step, value)
		return (
			<>
				<Header>Pedido de Homologação da ASA criada</Header>
				<Subtitle>Pretende Submeter o pedido de homologação da ASA?</Subtitle>
				<Subtitle>
					{/* eslint-disable-next-line max-len */}
					Ao submeter este pedido irá iniciar o processo de homologação da sua ASA. A partir desse momento entraremos em contacto consigo para se proceder ao pedido efetuado por si.
				</Subtitle>
				<Form>
					<Form.Field>
						<Radio
							label='Sim, pretendo realizar o pedido de homologação da ASA.'
							name='radioGroupHomologation'
							value='yes'
							checked={step.value === 'yes'}
							onChange={handleChange}
						/>
					</Form.Field>
					<Form.Field>
						<Radio
							label='Não, não pretendo submeter o pedido de homologação da ASA.'
							name='radioGroupHomologation'
							value='no'
							checked={step.value === 'no'}
							onChange={handleChange}
						/>
					</Form.Field>
				</Form>
			</>
		)
	}
}

class ConfirmationModal extends Component {

	render () {
		const {
			t,
			selectStep,
			currentStep,
			serviceAreaProcess,
			showModal
		} = this.props

		const {
			nextStep,
			onCkeck,
			changeCheck,
			onClose,
			onSubmit
		} = this.actions

		const { progress, step, canIgnore, isSelect } = selectStep
		return (
			<Modal.Modal 
				open={showModal}
				closeIcon
				onClose={onClose}
				size={'small'}
			>
				<ModalHeader>
					<Grid>
						<Grid.Row>
							<Grid.Column width={5}>
								<TitleModal>Submissão nova ASA</TitleModal>
							</Grid.Column>
							<Grid.Column width={5}>
								<StepModalTitle>
									Passo {currentStep} de 3
								</StepModalTitle>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</ModalHeader>
				<ProgressBar 
					percent={progress} 
					size={'tiny'} 
					attached={'bottom'} 
				/>
				<Modal.Content>
					<Container>
						{step === 1 &&
							<ChooseCleanSafe onCkeck={onCkeck} step={selectStep} changeCheck={changeCheck} />
						}
						{step === 2 &&
							<ChooseHomologation onCkeck={onCkeck} step={selectStep} />
						}
						{step === 3 &&
							<SumbitAsa onCkeck={onCkeck} step={selectStep} serviceArea={serviceAreaProcess} />
						}
					</Container>
				</Modal.Content>
				<Modal.Actions>
					<CointanerButtons>
						<div>
							{canIgnore &&
								<SkipButton onClick={nextStep}>
									Ignorar Passo
								</SkipButton>
							}
						</div>
						<div style={{ display: 'flex' }}>
							<Button
								secondary
								onClick={onClose}
							>
								<Text>
									{t('common.buttons_back')}
								</Text>
							</Button>

							<Button
								primary
								onClick={ () => step === 3 ? onSubmit() : nextStep() }
								disabled={!isSelect}
							>
								{step === 3 ? t('common.submit') : t('common.buttons_next_step') }
							</Button>
						</div>
					</CointanerButtons>
				</Modal.Actions>
			</Modal.Modal>
		)
	}
}

export default withTranslation()(Logic(ConfirmationModal))

const Text = styled.p`
	text-align: left;
	font-size: 16px;
	color: #102526;
	margin-top: 20px;
	margin-bottom: 16px;
	font-weight: 600;
` 

const ModalHeader = styled(Modal.Header)`
	padding: 20px 32px !important;
	background-color: #fff !important;
	box-shadow: inset 0 -1px 0 0 rgba(155,155,155,0.15) !important;
`

const TitleModal = styled.p`
	color: #102526;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 14px;
	margin: 0;
`

const StepModalTitle = styled.p`
	color: #6F7C7C;
	font-family: "Open Sans";
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 14px;
	text-align: center;
	margin: 0;
`
const Checkbox = styled(SemanticCheckbox)`
	margin: 8px 0;
	label {
		text-align: left;
		font-size: 10px;
		color: #102526;
		font-family: Open Sans;
	}
`

const Radio = styled(SemanticRadio)`
label {
	text-align: left;
	font-size: 14px;
	color: #000;
	font-family: Open Sans;
}
`

const Header = styled.p`
  font-size: 24px;
  color: #102526;
  font-family: Open Sans;
`

const HeaderTitle = styled.p`
  text-align: left;
  font-size: 16px;
  color: #102526;
  margin-top: 20px;
  margin-bottom: 16px;
  font-weight: 600;
`

const BulletContainer = styled.ul`
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const Bullet = styled.li`
  margin-bottom: 8px;
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #102526;
  font-family: Open Sans;
` 

const Container = styled.div`
  padding: 48px 0px 40px 48px ;
  display: flex;
  flex-direction: column;
` 
const Subtitle = styled.p`
 	color: #3E3E3E;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
`

const Description = styled.p`
  color: #000000;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
`

const CointanerButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Label = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
	margin: 0 0 4px 0;
`

const Info = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`

const SkipButton = styled.div`
	display: flex;
	align-items: center;
 	color: #5B5B5B;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
	cursor: pointer;
	text-decoration: underline;
`

const Separator = styled.div`
	width: 100%;
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #102526;
	margin: 16px 0 0 0;
`

const ProgressBar = styled(Progress)`
	.bar {
		background: #102526 !important;
	}
`