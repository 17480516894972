/* eslint-disable react/display-name */
import React from 'react'

import SummaryDescription from './SummaryDescription'
import Characteristics from './Characteristics'
import Equipments from './Equipments'
import Services from './Services'
import SportAndLeisure from './SportAndLeisure'
import Contacts from './Contacts'
import Documents from './Documents'
import Photos from './Photos'
//import FormStatus from './FormStatus'

export default ({ current, ...props }) => {
	switch (current) {
		//case 0: return <FormStatus {...props} />
		case 1: return <SummaryDescription {...props} />
		case 2: return <Characteristics {...props} />
		case 3: return <Equipments {...props} />
		case 4: return <Services {...props} />
		case 5: return <SportAndLeisure {...props} />
		case 6: return <Contacts {...props} />
		case 7: return <Documents {...props} />
		case 8: return <Photos {...props} />
		default: return null
	}
}