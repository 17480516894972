import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import _ from 'lodash'

import API from 'api'
import {
	STEPS,
	INJECT_STATUS_ON_STEPS,
	STATUS_STEPS,
	INJECT_STATUS
} from './utils'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import ServiceAreaLogic from 'screens/ServiceArea/logic'

export default kea({
	path: () => ['scenes', 'ServiceAreaRequest'],

	connect: { 
		props: [
			ServiceAreaLogic, [
				'serviceAreaProcess',
				'serviceAreaProcessId'
			],
			AppLogic, [
				'currentRoute',
				'contacts'
			],
		],
		actions: [
			AppLogic, [
				'navigate'
			],
			ServiceAreaLogic, [
				'setServiceArea',
				'clearServiceArea'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		start: () => true,
		status: status => ({ status }),
		setName: name => ({ name }),
		setSteps: steps => ({ steps }),
		setCurrent: current => ({ current }),
		setLastPatchDate: date => ({ date }),
		setStatus: (key, status) => ({ key, status }),
		setCanSubmit: status => ({ status }),
		submit: () => true,
		onClose: () => true,

		toggleCleanAndSafe: () => true,
		toggleHomologation: () => true,
		requestHomologation: () => true,
		requestCleanAndSafe: () => true,

		cancelStamp: () => true,
		unarchive: () => true,
		archive : () => true,
		
		loading: () => true,
		loaded: () => true,

		confirmHomogated: (status) => ({ status }),

		reset: () => true

	}),

	reducers: ({ actions }) => ({

		areaName: ['', Proptypes.string, {
			[actions.setName]: (state, payload) => payload.name,
			[actions.setServiceArea]:
        (state, payload) => payload.process.summary.name,
			[actions.reset]: () => ''
		}],

		steps: [STEPS, Proptypes.array, {
			[actions.setSteps]: (state, payload) => payload.steps,
			[actions.setStatus]: (state, payload) => {
				let newState = _.cloneDeep(state)
				newState = newState.map(step => {
					if (step.title === payload.key) {
						step.status = payload.status
					} 
					return step
				})
				return newState
			},
			[actions.reset]: () => STEPS
		}],

		canSubmit: [false, Proptypes.bool, {
			[actions.setCanSubmit]: (state, payload) => payload.status
		}],

		status: [STATUS_STEPS, Proptypes.any, {
			[actions.status]: (state, payload) => payload.status,
			[actions.setStatus]: (state, payload) => {
				let newState = _.cloneDeep(state)
				newState = newState.map(step => {
					if (step.title === payload.key) {
						step.status = payload.status
					} 
					return step
				})
				return newState
			},
			[actions.reset]: () => STATUS_STEPS
		}],

		current: [1, Proptypes.number, {
			[actions.setCurrent]: (state, payload) => payload.current,
			[actions.reset]: () => 1
		}],

		lastPatchDate: [1589320600882,
			Proptypes.oneOfType[
				Proptypes.string,
				Proptypes.number
			], {
				[actions.setLastPatchDate]: (state, payload) => payload.date,
				[actions.reset]: () => 1589320600882
			}],

		loading: [true, Proptypes.bool, {
			[actions.setServiceArea]: () => false,
			[actions.cancelStamp]: () => true,
			[actions.setName]: () => false,
			[actions.loading]: () => true,
			[actions.loaded]: () => false,
			[actions.reset]: () => true,
		}],

		showCleanAndSafe: [false, Proptypes.bool, {
			[actions.toggleCleanAndSafe]: (state) => !state,
			[actions.reset]: () => false,
		}],

		showHomologation: [false, Proptypes.bool, {
			[actions.toggleHomologation]: (state) => !state,
			[actions.reset]: () => false,
		}],
	}),

	takeLatest: ({ actions, workers }) => ({
		[actions.start]: workers.startComponent,
		[actions.status]: workers.checkSubmit,
		[actions.setStatus]: workers.checkSubmit,
		[actions.cancelStamp]: workers.cancelStamp,
		[actions.archive]: workers.archive,
		[actions.unarchive]: workers.unarchive,
		[actions.requestHomologation]: workers.submitHomologated,
		[actions.requestCleanAndSafe]: workers.submitCleanAndSafe
	}),

	workers: {

		* cancelStamp () {
			const { navigate, showToaster, loaded } = this.actions
			const { t } = this.props
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')

			try {
				yield call(API.Areas.cancel, serviceAreaProcessId) 
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.badge_canceled_success')
				))
				yield put(navigate('/gestao-processos'))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.badge_canceled_error')
				))
				yield put(loaded())
			}
		},

		* archive () {
			const { navigate, showToaster, loaded } = this.actions
			const { t } = this.props
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')

			try {
				yield call(API.Areas.archive, serviceAreaProcessId)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.archive_success')
				))
				yield put(navigate('/gestao-processos'))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.archive_error')
				))
				yield put(loaded())
			}
		},

		* unarchive () {
			const { navigate, showToaster, loaded } = this.actions
			const { t } = this.props
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')

			try {
				yield call(API.Areas.unarchive, serviceAreaProcessId)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.unarchive_success')
				))
				yield put(navigate('/gestao-processos'))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.unarchive_error')
				))
				yield put(loaded())
			}
		},

		* checkSubmit () {
			const { setCanSubmit } = this.actions
			let status = yield this.get('status')
      
			status = status.filter(({ status }) => status !== 'complete')
			const canSubmit = !status.length
			yield put(setCanSubmit(canSubmit))
		},

		* startComponent () {
			const {
				setServiceArea,
				loaded,
				showToaster,
				setName,
				setSteps,
				status,
				setLastPatchDate
			} = this.actions 
			const { t } = this.props
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
      
			const currentRoute = yield this.get('currentRoute')
			const { params } = currentRoute
			const { id } = params
  
			if (!serviceAreaProcessId && id) {
				try {
					const response = yield call(API.Areas.getById, id)
					yield put(setServiceArea(response))
					// last update
					yield put(setLastPatchDate(response.updatedAt))
					const steps = yield this.get('steps')
					const stepStatus = yield this.get('status')
					const statusSteps = yield call(
						INJECT_STATUS,
						response,
						stepStatus
					) 
					const newSteps = yield call(
						INJECT_STATUS_ON_STEPS,
						response,
						steps
					)
					yield put(setSteps(newSteps))
					yield put(status(statusSteps))
          
				} catch (err) {

					yield put(showToaster(
						'error',
						t('common.error'),
						t('error.error_fetch')
					))
				}
			} else {
				const serviceAreaProcess = yield this.get('serviceAreaProcess')
				yield put(setName(serviceAreaProcess.summary.name))

				try {
					const response = yield call(API.Areas.getById, id)
					yield put(setServiceArea(response))

					const steps = yield this.get('steps')
					const stepStatus = yield this.get('status')
					const statusSteps = yield call(
						INJECT_STATUS,
						response,
						stepStatus
					) 
					const newSteps = yield call(
						INJECT_STATUS_ON_STEPS,
						response,
						steps
					)
					yield put(setSteps(newSteps))
					yield put(status(statusSteps))

				} catch (err) {
					yield put(showToaster(
						'error',
						t('common.error'),
						t('error.error_fetch')
					))
				}

			}

			yield put(loaded())
		},

		* submitHomologated () {
			const { 
				showToaster,
				loaded,
				toggleHomologation,
				start
			} = this.actions

			const { t } = this.props

			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			try {
				const params = {
					homologation: true,
					register: true,
					cleanAndSafe: false
				}
				yield call(API.Areas.submit, serviceAreaProcessId, params) 
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.homologation_success')
				))
				yield put(start())				
				
			} catch (e) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.homologation_errror')
				))
			}
			yield put(loaded())
			yield put(toggleHomologation())
		},

		* submitCleanAndSafe () {
			const { 
				showToaster,
				loaded,
				toggleCleanAndSafe,
				start
			} = this.actions

			const { t } = this.props

			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			try {
				const params = {
					homologation: false,
					register: true,
					cleanAndSafe: true
				}
				yield call(API.Areas.submit, serviceAreaProcessId, params) 
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.homologation_success')
				))
				yield put(start())				
			} catch (e) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.homologation_errror')
				))
			}
			yield put(loaded())
			yield put(toggleCleanAndSafe())
		}

	}

})
