/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const Caravan = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M19,11.0454545 L19,12.9545455 C19,13.4815341 18.5724432,13.9090909 18.0454545,13.9090909 L13.9090909,13.9090909 L13.9090909,18.0454545 C13.9090909,18.5724432 13.4815341,19 12.9545455,19 L11.0454545,19 C10.5184659,19 10.0909091,18.5724432 10.0909091,18.0454545 L10.0909091,13.9090909 L5.95454545,13.9090909 C5.42755682,13.9090909 5,13.4815341 5,12.9545455 L5,11.0454545 C5,10.5184659 5.42755682,10.0909091 5.95454545,10.0909091 L10.0909091,10.0909091 L10.0909091,5.95454545 C10.0909091,5.42755682 10.5184659,5 11.0454545,5 L12.9545455,5 C13.4815341,5 13.9090909,5.42755682 13.9090909,5.95454545 L13.9090909,10.0909091 L18.0454545,10.0909091 C18.5724432,10.0909091 19,10.5184659 19,11.0454545 Z" />
		</g>
	</svg>
)

Caravan.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

Caravan.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

export default Caravan
