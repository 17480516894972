import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	Grid,
	Input,
	Loader,
	Dimmer
} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import moment from 'moment'

import Segment from 'components/Segment'
import FormError from 'components/FormError'
import SegmentTitle from 'components/SegmentTitle'
import MemorizedDropdown from 'components/MemorizedDropdown'

import { findNationalityObject } from './utils'
import Logic from './logic'
class BookDetailsGuestInfo extends Component {

handleArrayChange = (property, value, index, fieldEdit) => {
	const { changeArray } = this.actions
	const { otherGuests } = this.props.form

	const newArray = JSON.parse(JSON.stringify(otherGuests.value))
	if (property === 'edit') {
		newArray[index][fieldEdit] = {
			...newArray[index][fieldEdit],
			edit: value
		}
	} else {
		newArray[index][property] = {
			...newArray[index][property],
			value: value
		}
	}
	changeArray({ otherGuests: { value: newArray } })
}

render () {
	const {
		t,
		form,
		changeEditForm,
		changeForm,
		nationalities,
		nationalitiesOptions,
		isFetching,
		loading
	} = this.props

	const {
		guest1Name,
		guest1Birthdate,
		guest1Birthplace,
		guest1Nationality,
		guest1Email,
		guest1Mobile,
		guest1IdentityDocument,
		guest1IdentityDocumentType,
		guest1IdentityDocumentCountry,
		guest1Nif,
		guest1ResidencePlace,
		guest1ResidenceCountry,
		otherGuests
	} = form
		
	const docTypeOptions = [{
		key: 'bi',
		text: t('booking_details.cc'),
		value: 'bi'
	},
	{
		key: 'passport',
		text: t('booking_details.passport'),
		value: 'passport'
	},
	{
		key: 'other',
		text: t('booking_details.other'),
		value: 'other'
	}]
	
	const docTypeValue = docTypeOptions.find(type =>
		type.key === guest1IdentityDocumentType.value
	)
	
	return (
		<Segment>
			<SegmentTitle
				margin={'0 0 26px 0'}
			>
				{t('booking_details.guest_info')}
			</SegmentTitle>
			{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
			}
			{!loading &&
				<Grid>
					<Grid.Row>
						<GuestLabel>
							{`${t('booking_details.guest')} 1`}
						</GuestLabel>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.full_name')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1Name.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1Name: !guest1Name.edit
											})
									}}
									value={guest1Name.value}
									disabled={!guest1Name.edit}
									onChange={(e, {value}) => changeForm({
										guest1Name: value
									})}
								/>
								{!guest1Name.valid &&
									<FormError>{guest1Name.message}</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.nationality')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guest1Nationality.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
												// eslint-disable-next-line max-len
												guest1Nationality: !guest1Nationality.edit
											})
										}
									}}
									// eslint-disable-next-line max-len
									value={guest1Nationality.value.designation || ''}
									disabled={!guest1Nationality.edit}
								/>
								}
								{guest1Nationality.edit &&
								<MemorizedDropdown
									style={{ width: '100%' }}
									selection
									search
									options={nationalitiesOptions}
									value={guest1Nationality.value.id}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guest1Nationality: findNationalityObject(
											value, nationalities
										)})}
									disabled={isFetching}
									loading={isFetching}
								/>
								}
								{!guest1Nationality.valid &&
									<FormError>
										{guest1Nationality.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.birthdate')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guest1Birthdate.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												// eslint-disable-next-line max-len
												guest1Birthdate: !guest1Birthdate.edit
											})
									}}
									// eslint-disable-next-line max-len
									value={moment(guest1Birthdate.value, 'YYYY-MM-DD').format('DD-MM-YYYY')}
									disabled={!guest1Birthdate.edit}
								/>
								}
								{guest1Birthdate.edit &&
								<DateInput
									style={{ width: '100%' }}
									name={'guest1birthdate'}
									placeholder={'DD-MM-YYYY'}
									animation='none'
									// eslint-disable-next-line max-len
									value={moment(guest1Birthdate.value, 'YYYY-MM-DD').format('DD-MM-YYYY')}
									iconPosition={'left'}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guest1Birthdate: moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
									})}
								/>
								}
								{!guest1Birthdate.valid &&
								<FormError>
									{guest1Birthdate.message}
								</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.birthplace')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1Birthplace.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1Birthplace: !guest1Birthplace.edit
											})
									}}
									value={guest1Birthplace.value}
									disabled={!guest1Birthplace.edit}
									onChange={(e, {value}) => changeForm({
										guest1Birthplace: value
									})}
								/>
								{!guest1Birthplace.valid &&
									<FormError>
										{guest1Birthplace.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.email')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1Email.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1Email: !guest1Email.edit
											})
									}}
									value={guest1Email.value}
									disabled={!guest1Email.edit}
									onChange={(e, {value}) => changeForm({
										guest1Email: value
									})}
								/>
								{!guest1Email.valid &&
									<FormError>
										{guest1Email.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>						
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.mobile')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1Mobile.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1Mobile: !guest1Mobile.edit
											})
									}}
									value={guest1Mobile.value}
									disabled={!guest1Mobile.edit}
									onChange={(e, {value}) => changeForm({
										guest1Mobile: value
									})}
								/>
								{!guest1Mobile.valid &&
									<FormError>
										{guest1Mobile.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.doc_type')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guest1IdentityDocumentType.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1IdentityDocumentType: !guest1IdentityDocumentType.edit
											})
										}}}
									// eslint-disable-next-line max-len
									value={(docTypeValue && docTypeValue.text)|| ''}
									disabled={!guest1IdentityDocumentType.edit}
								/>
								}
								{guest1IdentityDocumentType.edit &&
								<MemorizedDropdown
									style={{ width: '100%' }}
									selection
									options={docTypeOptions}
									value={guest1IdentityDocumentType.value}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guest1IdentityDocumentType: value
									})}
								/>
								}
								{!guest1IdentityDocumentType.valid &&
									<FormError>
										{guest1IdentityDocumentType.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.doc_number')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1IdentityDocument.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1IdentityDocument: !guest1IdentityDocument.edit
											})
									}}
									value={guest1IdentityDocument.value}
									disabled={!guest1IdentityDocument.edit}
									onChange={(e, {value}) => changeForm({
										guest1IdentityDocument: value
									})}
								/>
								{!guest1IdentityDocument.valid &&
									<FormError>
										{guest1IdentityDocument.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>						
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.doc_place')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guest1IdentityDocumentCountry.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1IdentityDocumentCountry: !guest1IdentityDocumentCountry.edit
											})
										}}}
									// eslint-disable-next-line max-len
									value={guest1IdentityDocumentCountry.value.designation || ''}
									// eslint-disable-next-line max-len
									disabled={!guest1IdentityDocumentCountry.edit}
								/>
								}
								{guest1IdentityDocumentCountry.edit &&
								<MemorizedDropdown
									style={{ width: '100%' }}
									selection
									search
									options={nationalitiesOptions}
									// eslint-disable-next-line max-len
									value={guest1IdentityDocumentCountry.value.id}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guest1IdentityDocumentCountry: findNationalityObject(
											value, nationalities
										)})}
									disabled={isFetching}
									loading={isFetching}
								/>
								}
								{!guest1IdentityDocumentCountry.valid &&
								<FormError>
									{guest1IdentityDocumentCountry.message}
								</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.nif')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1Nif.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1Nif: !guest1Nif.edit
											})
									}}
									value={guest1Nif.value}
									disabled={!guest1Nif.edit}
									onChange={(e, {value}) => changeForm({
										guest1Nif: value
									})}
								/>
								{!guest1Nif.valid &&
									<FormError>{guest1Nif.message}</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.residence_place')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guest1ResidencePlace.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1ResidencePlace: !guest1ResidencePlace.edit
											})
									}}
									value={guest1ResidencePlace.value}
									disabled={!guest1ResidencePlace.edit}
									onChange={(e, {value}) => changeForm({
										guest1ResidencePlace: value
									})}
								/>
								{!guest1ResidencePlace.valid &&
									<FormError>
										{guest1ResidencePlace.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.residence_country')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guest1ResidenceCountry.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
											// eslint-disable-next-line max-len
												guest1ResidenceCountry: !guest1ResidenceCountry.edit
											})
										}}}
									// eslint-disable-next-line max-len
									value={guest1ResidenceCountry.value.designation || ''}
									// eslint-disable-next-line max-len
									disabled={!guest1ResidenceCountry.edit}
								/>
								}
								{guest1ResidenceCountry.edit &&
								<MemorizedDropdown
									style={{ width: '100%' }}
									selection
									search
									options={nationalitiesOptions}
									// eslint-disable-next-line max-len
									value={guest1ResidenceCountry.value.id}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guest1ResidenceCountry: findNationalityObject(
											value, nationalities
										)})}
									disabled={isFetching}
									loading={isFetching}
								/>
								}
								{!guest1ResidenceCountry.valid &&
								<FormError>
									{guest1ResidenceCountry.message}
								</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					{/* eslint-disable-next-line max-len */}
					{otherGuests.value.length > 0 && otherGuests.value.map((guest, index) => (
						<Fragment key={index}>
							<Grid.Row>
								<GuestLabel>
									{`${t('booking_details.guest')} ${index+2}`}
								</GuestLabel>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column computer={8}>
									<Grid.Row>
										<InputLabel>
											{/* eslint-disable-next-line max-len */}
											{t('booking_details.full_name')}
										</InputLabel>
									</Grid.Row>
									<Grid.Row>
										<Input
											style={{ width: '100%' }}
											icon={!guest.name.edit && {
												name: 'pencil',
												link: true,
												// eslint-disable-next-line max-len
												onClick: () => this.handleArrayChange('edit', !guest.edit, index, 'name')
											}}
											value={guest.name.value}
											disabled={!guest.name.edit}
											// eslint-disable-next-line max-len
											onChange={(e, {value}) => this.handleArrayChange('name', value, index)}
										/>
										{!guest.name.valid &&
											<FormError>
												{guest.name.message}
											</FormError>	
										}
									</Grid.Row>
								</Grid.Column>
								<Grid.Column computer={4}>
									<Grid.Row>
										<InputLabel>
											{t('booking_details.birthdate')}
										</InputLabel>
									</Grid.Row>
									<Grid.Row>
										{!guest.birthdate.edit &&
										<Input
											style={{ width: '100%' }}
											icon={{
												name: 'pencil',
												link: true,
												// eslint-disable-next-line max-len
												onClick: () => this.handleArrayChange('edit', !guest.edit, index, 'birthdate')
											}}
											// eslint-disable-next-line max-len
											value={moment(guest.birthdate.value, 'YYYY-MM-DD').format('DD-MM-YYYY')}
											disabled={!guest.birthdate.edit}
										/>
										}
										{guest.birthdate.edit &&
										<DateInput
											style={{ width: '100%' }}
											name={`guest${index}birthdate`}
											placeholder={'DD-MM-YYYY'}
											animation='none'
											// eslint-disable-next-line max-len
											value={moment(guest.birthdate.value, 'YYYY-MM-DD').format('DD-MM-YYYY')}
											iconPosition={'left'}
											// eslint-disable-next-line max-len
											onChange={(e, {value}) => this.handleArrayChange(
												'birthdate',
												// eslint-disable-next-line max-len
												moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD'),
												index
											)}
										/>
										}
										{!guest.birthdate.valid &&
											<FormError>
												{guest.birthdate.message}
											</FormError>	
										}
									</Grid.Row>
								</Grid.Column>
								<Grid.Column computer={4}>
									<Grid.Row>
										<InputLabel>
											{/* eslint-disable-next-line max-len */}
											{t('booking_details.nationality')}
										</InputLabel>
									</Grid.Row>
									<Grid.Row>
										{!guest.nationality.edit &&
										<Input
											style={{ width: '100%' }}
											icon={{
												name: 'pencil',
												link: true,
												onClick: () => {
													// eslint-disable-next-line max-len
													this.handleArrayChange('edit', !guest.edit, index, 'nationality')
												}
											}}
											// eslint-disable-next-line max-len
											value={guest.nationality.value.designation}
											// eslint-disable-next-line max-len
											disabled={!guest.nationality.edit}
										/>
										}
										{guest.nationality.edit &&
										<MemorizedDropdown
											style={{ width: '100%' }}
											selection
											search
											options={nationalitiesOptions}
											value={guest.nationality.value.id}
											// eslint-disable-next-line max-len
											onChange={(e, { value }) => this.handleArrayChange(
												'nationality',
												// eslint-disable-next-line max-len
												findNationalityObject(value, nationalities),
												index
											)}
											disabled={isFetching}
											loading={isFetching}
										/>
										}
										{!guest.nationality.valid &&
											<FormError>
												{guest.nationality.message}
											</FormError>	
										}
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
						</Fragment>
					))}
				</Grid>
			}
		</Segment>
	)
}
}

export default withTranslation()(Logic(BookDetailsGuestInfo))

const InputLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
	line-height: 14px;
	padding: 8px 0;
`

const GuestLabel = styled.p`
	color: #102526;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18px;
	padding-left: 1rem;
`