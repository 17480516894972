import { kea } from 'kea'
/** Logic */
import AppLogic from 'containers/App/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import ModalServiceAreaLogic from 'containers/FormNavigation/CampsiteManagement/logic'

export default kea({
	path: () => ['scenes', 'containers', 'ServiceAreaRequestForm', 'Buttons'],

	connect: {
		props: [
			CampsiteManagementRequestLogic, [
				'current',
				'canSubmitRegistration'
			],
			CampsiteManagementLogic, [
				'campsite',
			]
		],
		actions: [
			AppLogic, [
				'navigate'
			],
			ModalServiceAreaLogic, [
				'setSubmitPopupOpen'
			]
		]
	}
})
