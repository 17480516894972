// @flow

import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import * as Check from 'validations'
import { cloneDeep, mapValues, values } from 'lodash'
import moment from 'moment'

import API from 'api'
import { DEFAULT_VALUES, VALIDATIONS } from './utils'
import { mapAPIFormStateToForm } from 'utils'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import BookingUnavailabilityLogic from 'screens/BookingUnavailability/logic'
import { mapApiErrors } from 'api/errors'
import { handleApiErrorMessage } from '../../../utils'

export default kea({
	path: () => ['scenes', 'BookingUnavailabilityRule'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			],
			BookingUnavailabilityLogic, [
				'getRules'
			]
		]
	},
	
	actions: () => ({
		setForm: form => ({ form }),
		changeForm: field => ({ field }),
		setLoading: loading => loading,
		fetchForm: () => true,
		saveForm: () => true,
		createRule: () => true,
		reset: () => true,
		setError: error => error,
		setIsNewRule: rule => rule,
		setSpots: spots => spots,
		getSpots: () => true
	}),

	reducers: ({ actions }) => ({

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.setForm]: (_, payload) => payload.form,
			[actions.changeForm]: (state, payload) =>
				Check.setAndCheckValidation(
					state,
					payload,
					VALIDATIONS
				),
			[actions.reset]: () => cloneDeep(DEFAULT_VALUES)
		}],
		
		dirty: [false, Proptypes.bool, {
			[actions.changeForm]: () => true,
			[actions.reset]: () => false
		}],

		loading: [true, Proptypes.bool, {
			[actions.setForm]: () => false,
			[actions.setLoading]: (state, payload) => payload,
			[actions.showToaster]: () => false,
			[actions.saveForm]: () => true,
			[actions.createRule]: () => true,
			[actions.getSpots]: () => true,
			[actions.setSpots]: () => false,
			[actions.reset]: () => true
		}],

		errors: [[], Proptypes.array, {
			[actions.setError]: (state, payload) => payload,
			[actions.saveForm]: () => [],
			[actions.createRule]: () => [],
			[actions.reset]: () => ([])
		}],
		
		isNewRule: [false, Proptypes.bool, {
			[actions.setIsNewRule]: (_, payload) => payload,
			[actions.reset]: () => false
		}],
    
		spots: [[], Proptypes.array, {
			[actions.setSpots]: (_, payload) => payload,
			[actions.reset]: () => null
		}]
	}),

	selectors: ({ selectors }) => ({

		spotOptions: [ () => [ selectors.spots],
			(spots) => values(mapValues(
				spots,
				spot =>  { return {
					key: spot.id,
					text: spot.name,
					value: spot.id
				} }
			))
		]
	}),
	
	start: function * () {
		const {
			fetchForm,
			setLoading,
			setIsNewRule,
			getSpots
		} = this.actions
				
		const { match } = this.props
		const id = match.params.id
		
		if (id !== 'nova-regra') {
			yield put(fetchForm())
		} else {
			yield put(setIsNewRule(true))
			yield put(setLoading(false))
		}
		
		yield put(getSpots())
	},
	
	stop: function * () {
		const { reset } = this.actions
		
		yield put(reset())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchForm]: workers.fetchForm,
		[actions.saveForm]: workers.saveForm,
		[actions.createRule]: workers.createRule,
		[actions.getSpots]: workers.getSpots
	}),

	workers: {
		* fetchForm () {
			const { setForm, showToaster } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			const areaid = match.params.areaid
			
			if (!id) {
				return
			}

			try {
				let data = yield call(
					API.Reservations.getUnavailRuleById,
					areaid,
					id
				)
				// eslint-disable-next-line max-len
				data.timeInterval = moment(data.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' - ' + moment(data.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
				data.spots = data.spots.map(({ id }) => id )
				const newForm = yield call(
					mapAPIFormStateToForm,
					data,
					cloneDeep(form)
				)
				
				yield put(setForm(newForm))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}
		},
		
		* saveForm () {
			const { showToaster, getRules, navigate } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			const areaid = match.params.areaid
			
			if (!id || !areaid) {
				return
			}
			
			const params = mapValues(form, ({ value }) => value)
			const dates = params.timeInterval.split(' - ')
			// eslint-disable-next-line max-len
			params.startDate = moment(dates[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
			params.endDate = moment(dates[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
			
			try {
				yield call(
					API.Reservations.patchUnavailRuleById,
					areaid,
					id,
					params
				)
				
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))
				yield put(getRules())
				yield put(navigate('/indisponibilidade-reservas'))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},
		
		* createRule () {
			const {
				showToaster,
				navigate,
				setError,
				setForm,
				getRules
			} = this.actions
			
			const form = yield this.get('form')
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, VALIDATIONS)
			
			if (dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(setError([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(setError([]))
				return false
			}
			const { match, t } = this.props
			const id = match.params.id
			const areaid = match.params.areaid
			
			if (!id || !areaid) {
				return
			}
			
			const params = mapValues(form, ({ value }) => value)
			const dates = params.timeInterval.split(' - ')
			// eslint-disable-next-line max-len
			params.startDate = moment(dates[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
			params.endDate = moment(dates[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
			
			try {
				yield call(
					API.Reservations.createUnavailRule,
					areaid,
					id,
					params
				)
				yield put(getRules())
				yield put(navigate('/indisponibilidade-reservas'))
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				const errors = mapApiErrors(
					err.response.data,
					t
				)
				yield put(setError(errors))
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},
		
		* getSpots () {
			const { setSpots } = this.actions
			const { match } = this.props
			const areaid = match.params.areaid
			
			const response = yield call(API.Areas.getSpots, areaid)
			const { results } = response
			
			yield put(setSpots(results))
			
		}
	}
})
