/* eslint-disable max-len */
import React from 'react'

function Icon () {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="10"
			height="5"
			viewBox="0 0 10 5"
		>
			<defs>
				<path id="path-1" d="M7 10L12 15 17 10z"></path>
			</defs>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g transform="translate(-1213 -46)">
					<g>
						<g transform="translate(998 24)">
							<g>
								<g transform="translate(65 4)">
									<g transform="translate(139 4)">
										<g transform="translate(4 4)">
											<g>
												<path d="M0 0H24V24H0z"></path>
												<mask id="mask-2" fill="#fff">
													<use xlinkHref="#path-1"></use>
												</mask>
												<use
													fill="#000"
													fillRule="nonzero"
													xlinkHref="#path-1"
												></use>
												<g mask="url(#mask-2)">
													<path d="M0 0H24V24H0z"></path>
													<path fill="#000" d="M0 0H24V24H0z"></path>
												</g>
											</g>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default Icon
