import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import 'translations/i18n'
import MockApi from 'api/mock'

import * as serviceWorker from './serviceWorker'
import './global.css'
import 'react-calendar-timeline/lib/Timeline.css'

// External Styling
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-day-picker/lib/style.css'

const app = document.getElementById('root')

if (app) {

	// eslint-disable-next-line max-len
	const SENTRY_URL = 'https://7f2bc6853d024b9dbc7026ceabe36eb4@sentry2.ubiwhere.com/119'

	// Mocks API 
	if (process.env.FCMP_ENV === 'mock') MockApi()

	if (process.env.FCMP_ENV === 'production') {
		const sentryConfig = {
			release: '1',
			environment: 'production'
		}
  
		const Raven = require('raven-js')
		Raven.config(SENTRY_URL, sentryConfig).install()
	}

	const Routes = require('./ui/routes').default
	const render = Component => {
		ReactDOM.render(
			<Suspense fallback={<div>Loading</div>}>
				<Component />
			</Suspense>,
			app
		)
	}

	render(Routes)
	serviceWorker.unregister()
}
