import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import moment from 'moment'
import { mapValues, isFunction } from 'lodash'
import Proptypes from 'prop-types'
import Cookies from 'js-cookie'

import API, { Interceptor } from 'api'
import { getRoute, log } from 'utils'
import { ROUTES } from 'routes'

const routeSelector = (state) => state.router

export default kea({
	path: () => ['scenes', 'app'],

	connect: {
		props: [
			routeSelector, [
				'location'
			],
		],
		actions: [
		],
	},

	actions: () => ({
		navigate: (path) => ({ path }),
		fetchContacts: () => true,
		setContacts: (contacts) => ({ contacts }),
		setCurrentUser: (user, path) => ({ user, path }),
		setFatalError: () => true,
		logout: () => true
	}),

	reducers: ({ actions }) => ({
		user: [null, Proptypes.any, {
			[actions.setCurrentUser]: (_, payload) => payload.user,
			[actions.logout]: () => null
		}],

		fatalError: [false, Proptypes.bool, {
			[actions.setFatalError]: () => true
		}],

		contacts: [null, Proptypes.object, {
			[actions.fetchContacts]: () => null,
			[actions.setContacts]: (state, payload) => payload.contacts,
		}],

		loading: [false, Proptypes.bool, {}]

	}),

	selectors: ({ selectors }) => ({

		currentRoutes: [
			() => [
				selectors.user
			],
			(user) => {
				let currentRoutes = ROUTES

				switch (true) {
					case user && !user.registrationComplete:
						currentRoutes = currentRoutes.unauthenticated
						break
					case user && user.type === 'entity':
						currentRoutes = currentRoutes.entities
						break
					case user && user.type === 'admin':
						currentRoutes = currentRoutes.administrator
						break
					default:
						currentRoutes = currentRoutes.unauthenticated
						break
				}
        
				return currentRoutes
			},
			Proptypes.object
		],

		currentRoute: [
			() => [
				routeSelector,
				selectors.currentRoutes,
				selectors.user,
			],
			({ location }, currentRoutes, user) => {
				let route = getRoute(currentRoutes, location.pathname)
				const excluded = ['component', 'createUrl']

				route = mapValues(route, (value, key) => {
					// This makes Router Helpers possible
					// Send selectores inside the object so you can use them
					if (isFunction(value) && excluded.indexOf(key) === -1) {
						return value({
							user
						}, route)
					}

					return value
				})

				return route
			},
			Proptypes.object
		],

	}),

	start: function * () {
		log('[CM-CORE] 🚀 Booting up ', 'cornflowerblue')
		const { navigate, setCurrentUser, fetchContacts } = this.actions
		const currentRoute = window.location.pathname

		// Add Interceptor
		Interceptor()
		/** adjust moment short months to start with small letter */
		moment.updateLocale('pt-PT', {
			weekdays: [
				'Domingo',
				'Segunda',
				'Terça',
				'Quarta',
				'Quinta',
				'Sexta',
				'Sábado'
			]
		})
		
		moment.locale('pt-PT', {
			week: {
				dow: 1
			}
		})

		let user = yield this.get('user')
    
		if (!user) {
			const access = Cookies.get('access')
			if (access) {
				try {
					user = yield call(API.Auth.User, access)

					if (user && user.type === 'camper') {
						yield put(navigate('/entrar'))
						return
					}

					yield put(setCurrentUser(user, !user.registrationComplete
						? '/finalizar-registo'
						: currentRoute
					))
					if (!user) {
						if (window.location.href.includes('registar')) {
							yield put(navigate('/registar'))
						}

						if (!user) {
							if (window.location.href.includes('registar')) {
								yield put(navigate('/registar'))
							}
							else {
								yield put(navigate('/entrar')) 
							}
						}
					}
				} catch (e) {
					yield put(navigate('/entrar')) 
				}
			} else {
				yield put(navigate('/entrar')) 
			}
		}

		yield put(fetchContacts())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchContacts]: workers.fetchContacts,
		[actions.setCurrentUser]: workers.setCurrentUser,
		[actions.navigate]: workers.navigate,
		[actions.logout]: workers.logout
	}),

	workers: {

		* fetchContacts () {
			const { setContacts } = this.actions
			try {
				const response = yield call(API.Types.getContacts)
				yield put(setContacts(response))
			} catch (e) {
				console.log(e)
			}
		},

		* setCurrentUser ({ payload }) {
			const { path } = payload

			if (!path) return 

			if (path === '/') {
				yield put(push('/gestao-processos'))
			} else {
				yield put(push(path))
			}
		},

		* navigate (action) {
			const { path } = action.payload
			yield put(push(path))
		},

		* logout () {
			const { navigate } = this.actions
			Cookies.remove('access')
			Cookies.remove('refresh')
			yield put(navigate('/entrar'))
		}
	}
})
