/* eslint-disable max-len */
import React, { Component } from 'react'
import styled from 'styled-components'

import { Checkbox as SemanticCheckbox } from 'semantic-ui-react'

import Modal from 'components/Modal'
import Button from 'components/Button'
import { withTranslation } from 'react-i18next'

class SubmitFormModal extends Component {
state = {
	checked: 0
}

handleCheck = (ischecked) => {
	if (ischecked) this.setState({ checked: this.state.checked + 1 })
	else this.setState({ checked: this.state.checked - 1 })
}

render () {
	const { open, onClose, onSubmit, t } = this.props
	return (
		<Modal.Modal 
			open={open}
			closeIcon
			onClose={onClose}
			size={'large'}
		>
			<Modal.Content scrolling>
				<Container>
					<Header>ASA Declaração de Estabelecimento Saudável e Seguro</Header>
					<div style={{ marginTop: 40 }} />
					<HeaderTitle>Orientações/Formação para todos os Gestores das Infraestruturas</HeaderTitle>

					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Protocolo interno relativo ao surto de coronavírus COVID-19. ' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Precauções básicas de prevenção e controlo de infeção relativamente ao surto de coronavírus COVID-19, incluindo os procedimentos:' />

					<BulletContainer>
						<Bullet><strong>higienização das mãos:</strong> lavar as mãos frequentemente com água e sabão, durante pelo menos 20 segundos ou usar desinfetante para as mãos que tenha pelo menos 70% de álcool, cobrindo todas as superfícies das mãos e esfregando-as até ﬁ lava secas.</Bullet>
						<Bullet><strong>etiqueta respiratória:</strong> tossir ou espirrar para o antebraço ﬂetido ou usar lenço de papel, que depois deve ser imediatamente deitado ao lixo; Higienizar as mãos sempre após tossir ou espirrar e depois de se assoar; Evitar tocar nos olhos, nariz e boca com as mãos.</Bullet>
						<Bullet><strong>conduta social:</strong> alterar a frequência e a forma de contacto entre os trabalhadores e entre estes e os clientes, evitando (quando possível) o contacto próximo, apertos de mão, beijos, postos de trabalho partilhados, reuniões presenciais e partilha de comida, utensílios, copos e toalhas.</Bullet>
					</BulletContainer>

					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='As ações para o cumprimento e a auto monitorização diária que passam pela avaliação da febre, através da medição da temperatura corporal, duas vezes por dia, registando o valor e a hora de medição, assim como a veriﬁcação de tosse ou de diﬁculdade em respirar.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='As orientações da Direção Geral de Saúde para a limpeza de todas as superfícies.' />

					<HeaderTitle>Disponibilidade de Informação para os Utilizadores da ASA</HeaderTitle>
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Cumprimento das orientações básicas de prevenção e controlo de infeção relativamente ao surto de coronavírus COVID-19.' />

					<BulletContainer>
						<Bullet>Qual o protocolo interno relativo ao surto de coronavírus COVID-19.</Bullet>
					</BulletContainer>

					<HeaderTitle>Requisitos de Material para a ASA</HeaderTitle>
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Equipamentos de proteção individual em número suficiente para os trabalhadores.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Stock de materiais de limpeza de uso único proporcional às suas dimensões., incluindo toalhetes de limpeza de uso único humedecidos em desinfetante, lixívia e álcool a 70º.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Dispensadores de solução antissética de base alcoólica ou solução à base de álcool junto aos pontos de entrada/saída e junto às estações de serviço.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Contentor de resíduos com abertura não manual e saco plástico.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Caso existam infraestruturas de apoio com zona para lavagem de mãos estas devem estar equipadas com sabão líquido e toalhetes de papel.' />

					<HeaderTitle>Requisitos de Funcionamento e Protocolares para a ASA</HeaderTitle>
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Lavagem e desinfeção, de acordo com o protocolo interno, das superfícies onde colaboradores e utilizadores do espaço circulam, garantindo o controlo e a prevenção de possíveis infeções e resistências aos antimicrobianos.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Limpeza das superfícies e objetos de utilização comum, várias vezes ao dia.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Ser dada preferência à limpeza húmida, em detrimento da limpeza a seco.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='A renovação regular do ar em espaços fechados, quando existam.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Nas estações de serviço da ASA, a higienização de todas as superfícies, equipamentos e materiais deve ser reforçada, de acordo com o protocolo interno.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Assegurar o cumprimento do distanciamento social e a ocupação máxima por m2, recomendada.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='A distribuição de informação preferencialmente em suporte digital/online.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='Contacto direto com o Serviço Nacional de Saúde, caso exista necessidade ou suspeita de infeção.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='A descontaminação do espaço, o reforço da limpeza e da desinfeção, sempre que haja casos positivos de infeção, conforme indicações da Direção Geral de Saúde.' />
					<Checkbox onChange={(e, { checked }) => this.handleCheck(checked)} label='O armazenamento dos resíduos produzidos pelos doentes suspeitos de infeção em saco de plástico que, após fechado (ex. com abraçadeira) deve ser segregado e enviado para o operador capacitado para a gestão dos resíduos hospitalares com risco biológico. ' />
				</Container>
			</Modal.Content>

			<Modal.Actions>
				<Button
					secondary
					onClick={onClose}
				>
					{t('common.buttons_back')}
				</Button>

				<Button
					primary
					disabled={this.state.checked < 20}
					onClick={(() => onSubmit())}
				>
					{t('common.submit')}
				</Button>
			</Modal.Actions>
		</Modal.Modal>
	)
}
}

export default withTranslation()(SubmitFormModal)

const Header = styled.p`
  text-align: center;
  font-size: 24px;
  color: #102526;
  margin-top: 40px !important;
  font-family: Open Sans;
`

const HeaderTitle = styled.p`
  text-align: left;
  font-size: 16px;
  color: #102526;
  margin-top: 20px;
  margin-bottom: 16px;
  font-weight: 600;
` 

const Checkbox = styled(SemanticCheckbox)`
  margin: 8px 0;

  label {
    text-align: left;
    font-size: 10px;
    color: #102526;
    font-family: Open Sans;
  }
`

const BulletContainer = styled.ul`
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const Bullet = styled.li`
  margin-bottom: 8px;
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #102526;
  font-family: Open Sans;
` 

const Container = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
` 