/* eslint-disable react/display-name */
import React from 'react'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'

import { Store, history } from 'store'
import toArray from 'lodash/toArray'

import App from 'containers/App'
import ErrorBoundaryRoute from 'containers/ErrorBoundaryRoute'

import entities from './entities'
import administrator from './admin'
import unauthenticated from './unauthenticated'

import Theme from 'src/globalStyles'

const GET_USER_ROUTES = user => {
	switch (true) {
		case user && !user.registrationComplete: return unauthenticated
		case user && user.type === 'entity': return entities
		case user && user.type === 'admin':return administrator
		default: return unauthenticated
	}
}

export default function () {
	return (
		<ThemeProvider theme={Theme}>
			<Provider store={Store}>
				<ConnectedRouter history={history}>
					<App>
						{({ user }) => {
							const routes = GET_USER_ROUTES(user)
							return toArray(routes).map(route => (
								<ErrorBoundaryRoute
									key={route.key}
									{...route}
								>
									<Route
										exact={!!route.exact}
										key={route.key}
										path={route.path}
										component={route.component}
									/>
								</ErrorBoundaryRoute>
							))
						}}
					</App>    
				</ConnectedRouter>
			</Provider>
		</ThemeProvider>
	)
}

export const ROUTES = {
	entities,
	administrator,
	unauthenticated
}