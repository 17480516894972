import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Radio } from 'semantic-ui-react'

import Label from 'components/Label'
import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import InputUnity from 'components/InputUnity'

class ServiceArea extends Component {
	render () {
		const { t, form, changeForm } = this.props
    
		const { 
			wastewaterRunoff,
			disposalUrbanWaste,
			disposalSanitaryCassettes,
			emptyingChemicals,
			washingSystem,
			potableWaterSupplyServiceArea,
			grids,
			quantity
		} = form

		return (
			<Wrapper>
				
				<FormField required>
					<Label>
						{t('form.wastewater_runoff')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='wastewaterRunoff_no'
							checked={!wastewaterRunoff.value}
							control={Radio}
							onChange={() => changeForm(
								{ wastewaterRunoff: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='wastewaterRunoff_yes'
							checked={wastewaterRunoff.value}
							control={Radio}
							onChange={() => changeForm(
								{ wastewaterRunoff: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.disposal_urban_waste')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='disposalUrbanWaste_no'
							checked={!disposalUrbanWaste.value}
							control={Radio}
							onChange={() => changeForm(
								{ disposalUrbanWaste: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='disposalUrbanWaste_yes'
							checked={disposalUrbanWaste.value}
							control={Radio}
							onChange={() => changeForm(
								{ disposalUrbanWaste: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.disposal_sanitary_cassettes')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='disposalSanitaryCassettes_no'
							checked={!disposalSanitaryCassettes.value}
							control={Radio}
							onChange={() => changeForm(
								{ disposalSanitaryCassettes: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='disposalSanitaryCassettes_yes'
							checked={disposalSanitaryCassettes.value}
							control={Radio}
							onChange={() => changeForm(
								{ disposalSanitaryCassettes: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.emptying_chemicals')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='emptyingChemicals_no'
							checked={!emptyingChemicals.value}
							control={Radio}
							onChange={() => changeForm(
								{ emptyingChemicals: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='emptyingChemicals_yes'
							checked={emptyingChemicals.value}
							control={Radio}
							onChange={() => changeForm(
								{ emptyingChemicals: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.washing_system')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='washingSystem_no'
							checked={!washingSystem.value}
							control={Radio}
							onChange={() => changeForm(
								{ washingSystem: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='washingSystem_yes'
							checked={washingSystem.value}
							control={Radio}
							onChange={() => changeForm(
								{ washingSystem: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.driking_water_supply')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='potableWaterSupply_no'
							checked={!potableWaterSupplyServiceArea.value}
							control={Radio}
							onChange={() => changeForm(
								{ potableWaterSupplyServiceArea: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='potableWaterSupply_yes'
							checked={potableWaterSupplyServiceArea.value}
							control={Radio}
							onChange={() => changeForm(
								{ potableWaterSupplyServiceArea: true })
							}
						/>
					</FormGroup>
				</FormField>
								
				<FormField required>
					<Label>
						{t('form.grids')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='grids_no'
							checked={!grids.value}
							control={Radio}
							onChange={() => changeForm(
								{ grids: false,
										quantity: ''
								}
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='grids_yes'
							checked={grids.value}
							control={Radio}
							onChange={() => changeForm(
								{ grids: true })
							}
						/>
						<FormGroup inline>
							<Label
								style={{
									borderLeft: '1px solid',
									paddingLeft: '6px'
								}}
							>
								{t('form.quantity')}
							</Label>
							<InputUnity 
								unity='uni.'
								name="quantity"
								// eslint-disable-next-line max-len
								disabled={!grids.value}
								// eslint-disable-next-line max-len
								value={quantity.value || ''}
								// eslint-disable-next-line max-len
								placeholder={t('form.quantity_placeholder')}
								// eslint-disable-next-line max-len
								onChange={(e, { value }) => changeForm({ quantity: value })}
								unityWidth={'60px'}
								// eslint-disable-next-line max-len
								error={quantity.dirty && !quantity.valid && quantity.message }
							/>
						</FormGroup>
					</FormGroup>
				</FormField>
    
			</Wrapper>
		)
	}
}

export default withTranslation()(ServiceArea)

const Wrapper = styled.div`
  margin-top: 24px;
` 
