import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
import { DEFAULT_VALUES, DEFAULT_CONTACT_VALUES, VALIDATIONS, getResolvedOptions } from './utils'
import API from 'api'

import {
	Form,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import Buttons from '../Buttons'
import FormRequest from 'components/FormRequest'
import ListAdder from 'components/ListAdder'

class Contacts extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	componentWillUnmount () {
		this.submitData()
	}

	submitData = (action) => {
		const {t, current, campsiteId, submit} = this.props
		const {setCurrent, showToaster, updateStep} = this.actions
		
		const navigate = () => {
			switch (action) {
				case 'next':
					setCurrent(current + 1)
					break
				case 'previous':
					setCurrent(current - 1)
					break
				default:
					break
			}
		}

		submit(data => API.Campsites.steps.updateContacts(campsiteId, data), {
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
					navigate()
				}
				else if (success === false) {
					showToaster(
						'error',
						t('common.error'),
						t('error.error_save_data')
					)
				}
				else navigate()
			}
		})
	}

valueChange = (prop, value, index) => {

	const { changeForm, form } = this.props
	const { contacts } = form

	const newContacts = [...contacts.value]
	newContacts[index][prop] = value

	changeForm({contacts: newContacts})

}

addContact = () => {

	const { form, changeForm } = this.props
	const { contacts } = form

	const newContacts = [...contacts.value]
	newContacts.push({...DEFAULT_CONTACT_VALUES})
	changeForm({
		contacts: newContacts
	})
}

removeContact = index => {

	const { form, changeForm } = this.props
	const { contacts } = form

	const newContacts = [...contacts.value]
	newContacts.splice(index, 1)
	changeForm({
		contacts: newContacts
	})
}

render () {

	const { t, loading, form, formHasChanged } = this.props

	const {
		contacts
	} = form

	return (
		<>
			<BoxSegment loading={loading}>
				<Form>
					<SegmentTitle
						margin={'8px 0 30px 0'}
					>
						{t('form.useful_contacts')}
					</SegmentTitle>
					<ListAdder
						data={contacts.value}
						options={getResolvedOptions(t)}
						add={this.addContact}
						remove={this.removeContact}
						errors={contacts.message}
						valueChange={this.valueChange}
					/>

				</Form>
			</BoxSegment>

			<Buttons
				previousButtonClick={() => {this.submitData('previous')}}
				nextButtonClick={() => {this.submitData('next')}}
				saveButtonClick={() => this.submitData('')}
				dirty={formHasChanged}
			/>
		</>
	)
}
}

const ContactsWithLogic = withTranslation()(Logic(Contacts))

class FormContainer extends Component {
	render () {

		return (
			<FormRequest defaultForm={DEFAULT_VALUES} validations={VALIDATIONS} initialLoading>
				<ContactsWithLogic {...this.props} />
			</FormRequest>
		)
	}
}

export default FormContainer