import * as React from 'react'

function IcSanitariosAdaptativos (props) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>{'icons/servicos/ic-sanitarios-adaptativos'}</title>
			<path
				// eslint-disable-next-line max-len
				d="M7.208 9.687v2.217a5.235 5.235 0 00-3.125 4.783 5.235 5.235 0 005.23 5.229 5.235 5.235 0 005.021-3.785l.785-.007.826 1.623c-1.161 2.505-3.694 4.252-6.633 4.252C5.28 23.999 2 20.719 2 16.687c0-3.3 2.199-6.094 5.208-7zm3.472-3.965c1.442 0 2.082 1.22 2.082 2.083v3.138h3.492c.576 0 1.042.466 1.042 1.041 0 .228-.088.426-.21.597-.19.263-.483.445-.832.445h-3.492v.925h4.057c.523 0 1.001.293 1.238.76l3.167 6.227a1.387 1.387 0 01-1.236 2.02c-.506 0-.994-.279-1.238-.76l-2.782-5.47h-5.246a2.125 2.125 0 01-2.125-2.124v-6.8c0-1.15.932-2.082 2.083-2.082zm0-5.722a2.365 2.365 0 11-.002 4.731A2.365 2.365 0 0110.68 0z"
				fill="#102526"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default IcSanitariosAdaptativos
