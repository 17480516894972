import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icon,
	Loader,
	Dimmer as SegmentDimmer
} from 'semantic-ui-react'

/** components */
import ComponentDropdown from 'components/Dropdown'
import CalendarUsageReservation from 'components/CalendarUsageReservation'
import { getCalendarMonths } from './utils'
import Logic from './logic'

class BookOvernightCalendarView extends Component {

	render () {
		
		const {
			t,
			reservations,
			timeFilter,
			visibleTime,
			setTimeFilter,
			prevClick,
			nextClick,
			loading
		} = this.props

		const { navigate } = this.actions

		const { visibleTimeStart, visibleTimeEnd } = visibleTime

		const timeFilterOptions = [
			{
				text: t('booking_management.week'),
				value: 'week'
			}
		]
		
		return (
			<Wrapper>
				<FiltersWrapper>
					<CalendarHeader>
						<IconButton onClick={prevClick}>
							<Icon name={'angle left'} />
						</IconButton>
						<IconButton
							onClick={nextClick}
							margin={'0 0 0 16px'}
						>
							<Icon name={'angle right'} />
						</IconButton>
						<CalendarTitle>
							{getCalendarMonths(
								visibleTimeStart,
								visibleTimeEnd
							)}
						</CalendarTitle>
					</CalendarHeader>
					<FiltersRight>
						<DropdownBordered
							options={timeFilterOptions}
							defaultValue={timeFilter}
							onChange={(e, { value }) => setTimeFilter(value)}
						/>
					</FiltersRight>
				</FiltersWrapper>
				{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
				}
				{!loading &&
				<CalendarUsageReservation 
					data={reservations}
					startDay={visibleTimeStart} 
					endDay={visibleTimeEnd}
					navigate={navigate}
				/>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(BookOvernightCalendarView))

const Wrapper = styled.div``

const DropdownBordered = styled(ComponentDropdown)`
	&&&& {
		box-sizing: border-box;
		border: 1px solid #D0D0D0!important;
		border-radius: 4px;
		background-color: #FFFFFF;
		padding: 6px 16px;
		
		.text {
			font-family: "Open Sans";
			letter-spacing: 0;
			line-height: 20px;
			font-size: 14px;
			color: #102526;
		}
		
		.dropdown.icon {
			height: 5px;
		}
		
		.item .text {
			line-height: 20px;
			font-size: 14px;
		}
	}
`

const IconButton = styled.button`
  height: 32px;
  width: 32px;
	border-radius: 2px;
	cursor: pointer;
	background-color: #F4F4F4;
	border: 0;
	
	margin: ${({ margin }) => margin};
	
	> i {
		margin: 0;
	}
`

const CalendarHeader = styled.div`
	margin-bottom: 34px;
	display: flex;
	align-items: center;
`

const CalendarTitle = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
	text-align: center;
	margin-left: 32px;
`

const FiltersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const FiltersRight = styled.div`
	display: flex;
	
	> div {
		padding:0 18px;
	}
`

const Dimmer = styled(SegmentDimmer)`
	&&&& {
		background-color: transparent;
		height: 40vh;
	}
`