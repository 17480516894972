import { kea } from 'kea'
import UsageReservationDetailsLogic from 'screens/UsageReservationDetails/logic'

export default kea({
	path: () => ['scenes', 'containers', 'UsageDetailsMotorhome'],

	connect: {
		props: [
			UsageReservationDetailsLogic, [
				'form',
				'loading'
			]
		],
		actions : [
			UsageReservationDetailsLogic, [
				'changeEditForm',
				'changeForm'
			]
		]
	}
})
