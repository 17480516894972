/**
 * Empty Container Logic
 * Please write a description
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 */

import { kea } from 'kea'
import AppLogic from 'containers/App/logic'

export default kea({
	path: () => ['scenes', 'Empty'],

	connect: {
		props: [
			AppLogic, [
				'currentRoute'
			]
		],
		actions: []
	}
})
