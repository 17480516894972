import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

/** component */
import Title from 'components/Title'
/** container */
import ListDownloadsServicesAreas from 'containers/ListDownloads/ServicesAreas'
import ListDownloadsCampSites from 'containers/ListDownloads/CampSites'

class Downloads extends Component {
	render () {
		const { t } = this.props
		return (
			<Wrapper>
				<Title>
					{t('download_management.title')}
				</Title>
				<ListDownloadsServicesAreas />
				<ListDownloadsCampSites />
			</Wrapper>
		)
	}
}

export default withTranslation()(Downloads)

const Wrapper = styled.div``
