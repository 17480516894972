import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
	Grid,
	Input,
	Radio,
	Dimmer,
	Loader
} from 'semantic-ui-react'
import { DatesRangeInput } from 'semantic-ui-calendar-react'

import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import MemorizedDropdown from 'components/MemorizedDropdown'
import FormError from 'components/FormError'
import IcWater from 'assets/components/IcWater'
import IcEnergy from 'assets/components/IcEnergy'
import IcSanitariosAdaptativos from 'assets/components/IcSanitariosAdaptativos'

import Logic from './logic'
import {
	getNumberNights,
	getGuestsOptions,
	serviceAreasOptions,
	findServiceAreaObject
} from './utils'

class BookDetailsCharacteristics extends Component {

	render () {
		const {
			t,
			parkingPlaces,
			form,
			changeEditForm,
			changeForm,
			loading,
			serviceAreas,
			isNewReservation
		} = this.props
				
		const {
			checkInOut,
			nights,
			guests,
			spotName,
			needsElectricitySupply,
			needsWaterSupply,
			serviceAreaIsAdapted,
			serviceArea
		} = form

		const spotOptions = parkingPlaces.map(parking => ({
			key: parking.parkId,
			text: parking.title,
			value: parking.parkId			
		}))
		
		const spot = parkingPlaces.filter(parking =>
			parking.parkId === spotName.value
		)[0]
			
		return (
			<Segment>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.booking_characteristics')}
				</SegmentTitle>
				{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
				}
				{!loading &&
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.service_area')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!serviceArea.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												// eslint-disable-next-line max-len
												serviceArea: !serviceArea.edit
											})
									}}
									value={findServiceAreaObject(
										serviceArea.value,
										serviceAreas
									).name || ''}
									disabled={!serviceArea.edit}
								/>
								}
								{serviceArea.edit &&
								<MemorizedDropdown
									// eslint-disable-next-line max-len
									placeholder={t('booking_details.service_area_placeholder')}
									fluid
									selection
									value={serviceArea.value}
									options={serviceAreasOptions(serviceAreas)}
									onChange={(e, {value}) => 
										changeForm({ serviceArea: value }
										)}
								/>
								}
								{!serviceArea.valid &&
									<FormError>
										{serviceArea.message}
									</FormError>	
								}	
							</Grid.Row>
						</Grid.Column>
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{t('booking_details.spot')}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{(!spotName.edit || isNewReservation) &&
								<Input
									style={{ width: '100%' }}
									icon={!isNewReservation && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												// eslint-disable-next-line max-len
												spotName: !spotName.edit
											})
									}}
									value={isNewReservation
										? t('booking_details.random_spot')
										: ((spot && spot.title) || '')
									}
									// eslint-disable-next-line max-len
									disabled={isNewReservation || !spotName.edit}
								/>
								}
								{(!isNewReservation && spotName.edit) &&
								<MemorizedDropdown
									// eslint-disable-next-line max-len
									placeholder={t('booking_details.spot_placeholder')}
									fluid
									selection
									value={spotName.value}
									options={spotOptions}
									onChange={(e, {value}) => 
										changeForm({ spotName: value }
										)}
								/>
								}
								{!spotName.valid &&
									<FormError>{spotName.message}</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.checkin_checkout')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!checkInOut.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												// eslint-disable-next-line max-len
												checkInOut: !checkInOut.edit
											})
									}}
									value={checkInOut.value}
									disabled={!checkInOut.edit}
								/>
								}
								{checkInOut.edit &&
								<DatesRangeInput
									style={{ width: '100%' }}
									name={'dateRange'}
									placeholder="De - a"
									value={checkInOut.value}
									iconPosition="left"
									animation='none'
									minDate={new Date()}
									// eslint-disable-next-line max-len
									onChange={(e, {value}) => changeForm({
										checkInOut: value,
										nights: getNumberNights(
											nights.value,
											value
										)
									})}
								/>
								}
								{!checkInOut.valid &&
									<FormError>{checkInOut.message}</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{t('booking_details.nights')}
								</InputLabel>
								{nights.value > 0 &&
									<InputText>
										{`${nights.value} ${nights.value === 1 ? t('booking_details.nights_singular') : t('booking_details.nights_plural')}`}
									</InputText>
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.guests')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guests.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												// eslint-disable-next-line max-len
												guests: !guests.edit
											})
									}}
									value={`${guests.value} ${guests.value === 1 ? t('booking_details.guests_options_singular') : t('booking_details.guests_options_plural')}`}
									disabled={!guests.edit}
								/>
								}
								{guests.edit &&
								<MemorizedDropdown
									// eslint-disable-next-line max-len
									placeholder={t('booking_details.guests_placeholder')}
									fluid
									selection
									value={guests.value}
									options={getGuestsOptions(
										// eslint-disable-next-line max-len
										t('booking_details.guests_options_plural'),
										// eslint-disable-next-line max-len
										t('booking_details.guests_options_singular')
									)}
									onChange={(e, {value}) => 
										changeForm({ guests: value }
										)}
								/>
								}
								{!guests.valid &&
									<FormError>{guests.message}</FormError>	
								}	
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Grid>
								<Grid.Row>
									<Grid.Column computer={2}>
										<IconWrapper>
											<IcEnergy />
										</IconWrapper>
									</Grid.Column>
									<Grid.Column computer={14}>
										<Grid.Row>
											<RadioLabel>
												{/* eslint-disable-next-line max-len */}
												{t('booking_details.need_electricity')}
											</RadioLabel>
										</Grid.Row>
										<Grid.Row style={{ marginTop: '16px' }}>
											<Radio
												label={t('booking_details.yes')}
												name='eletricityyes'
												value={t('booking_details.yes')}
												// eslint-disable-next-line max-len
												checked={needsElectricitySupply.value}
												onChange={() => changeForm({
													needsElectricitySupply: true
												})}
											/>
											<Radio
												style={{ paddingLeft: '16px' }}
												label={t('booking_details.no')}
												name='eletricityno'
												value={t('booking_details.no')}
												// eslint-disable-next-line max-len
												checked={!needsElectricitySupply.value}
												onChange={() => changeForm({
													// eslint-disable-next-line max-len
													needsElectricitySupply: false
												})}
											/>
											
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
						<Grid.Column>
							<Grid>
								<Grid.Row>
									<Grid.Column computer={2}>
										<IconWrapper>
											<IcWater />
										</IconWrapper>
									</Grid.Column>
									<Grid.Column computer={14}>
										<Grid.Row>
											<RadioLabel>
												{/* eslint-disable-next-line max-len */}
												{t('booking_details.need_water')}
											</RadioLabel>
										</Grid.Row>
										<Grid.Row style={{ marginTop: '16px' }}>
											<Radio
												label={t('booking_details.yes')}
												name='wateryes'
												value={t('booking_details.yes')}
												checked={needsWaterSupply.value}
												onChange={() => changeForm({
													needsWaterSupply: true
												})}
											/>
											<Radio
												style={{ paddingLeft: '16px' }}
												label={t('booking_details.no')}
												name='waterno'
												value={t('booking_details.no')}
												// eslint-disable-next-line max-len
												checked={!needsWaterSupply.value}
												onChange={() => changeForm({
													// eslint-disable-next-line max-len
													needsWaterSupply: false
												})}
											/>
											
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{ marginTop: 30, paddingLeft: '1rem' }}>
						<InfoTitle>
							{t('booking_details.aditionalinfo')}
						</InfoTitle>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Grid>
								<Grid.Row>
									<Grid.Column computer={2}>
										<IconWrapper>
											<IcSanitariosAdaptativos />
										</IconWrapper>
									</Grid.Column>
									<Grid.Column computer={14}>
										<Grid.Row>
											<RadioLabel>
												{t('booking_details.guest_mr')}
											</RadioLabel>
										</Grid.Row>
										<Grid.Row style={{ marginTop: '16px' }}>
											<Radio
												label={t('booking_details.yes')}
												name='guestmryes'
												value={t('booking_details.yes')}
												// eslint-disable-next-line max-len
												checked={serviceAreaIsAdapted.value}
												onChange={() => changeForm({
													serviceAreaIsAdapted: true
												})}
											/>
											<Radio
												style={{ paddingLeft: '16px' }}
												label={t('booking_details.no')}
												name='guestmrno'
												value={t('booking_details.no')}
												// eslint-disable-next-line max-len
												checked={!serviceAreaIsAdapted.value}
												onChange={() => changeForm({
													// eslint-disable-next-line max-len
													serviceAreaIsAdapted: false
												})}
											/>
											
										</Grid.Row>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				}
			</Segment>
		)
	}
}

export default withTranslation()(withRouter(Logic(BookDetailsCharacteristics)))

const InputLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
	line-height: 14px;
	padding: 8px 0;
`

const InputText = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`

const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 4px;
	background-color: rgba(208,208,208,0.3);
	
	display: flex;
	justify-content: center;
	align-items: center;
`

const RadioLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`

const InfoTitle = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
`