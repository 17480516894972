export const STEPS = [
	{
		id: 1,
		title: 'summary_description',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 2,
		title: 'datasheet',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 3,
		title: 'documents',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 4,
		title: 'photos',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
]

export const STATUS_STEPS = [
	{
		id: 1,
		title: 'summary_description',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 2,
		title: 'datasheet',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 3,
		title: 'documents',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 4,
		title: 'photos',
		linked: false,
		hide: false,
		status: 'ongoing'
	},
	{
		id: 5,
		title: 'installation',
		linked: false,
		hide: false,
		status: 'complete'
	},
	{
		id: 6,
		title: 'serviceArea',
		linked: false,
		hide: false,
		status: 'complete'
	},
	{
		id: 7,
		title: 'equipments',
		linked: false,
		hide: false,
		status: 'complete'
	},
	{
		id: 8,
		title: 'otherEquipments',
		linked: false,
		hide: false,
		status: 'complete'
	},
]

export const INJECT_STATUS_ON_STEPS = ({
	summary,
	datasheet,
	documents,
	photographs
}, steps) => {
	return steps.map(step => {
		switch (step.title) {
			case 'summary_description': return {
				...step,
				status: summary.status
			}
			case 'datasheet': return { ...step, status: datasheet.status }
			case 'documents': return { ...step, status: documents.status }
			case 'photos': return { ...step, status: photographs.status }
			default: return step
		}
	})
}

export const INJECT_STATUS = ({
	summary,
	datasheet,
	facilities,
	serviceStation,
	equipments,
	otherEquipments,
	documents,
	photographs
}, status) => {
	return status.map(step => {
		switch (step.title) {
			case 'summary_description': return {
				...step,
				status: summary.status
			}
			case 'datasheet': return { ...step, status: datasheet.status }
			case 'documents': return { ...step, status: documents.status }
			case 'photos': return { ...step, status: photographs.status }
			case 'installation': return { ...step, status: facilities.status }
			case 'serviceArea': return {
				...step,
				status: serviceStation.status
			}
			case 'equipments': return { ...step, status: equipments.status }
			case 'otherEquipments': return {
				...step,
				status: otherEquipments.status
			}
			default: return step
		}
	})
}