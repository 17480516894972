/* eslint-disable react/display-name */
//import React from 'react'
//import Tab from 'components/TabFilter'

//import List from './List'

//import Installation from './Installation'
//import ServiceArea from './ServiceArea'
//import Equipments from './Equipments'
//import OtherEquipments from './OtherEquipments'

export const PANES = (t) => [
	{
		menuItem: t('campsite_management.all_status'),
		render: () => null
		//render: () => (
		//	<Tab.Pane>
		//		<List />
		//	</Tab.Pane>
		//)
	},
	{
		menuItem: t('campsite_management.issued_label'),
		render: () => null
		//render: () => (
		//	<Tab.Pane>
		//		<List />
		//	</Tab.Pane>
		//)
	},
	{
		menuItem: t('campsite_management.unfinished_processes'),
		render: () => null
	}
]