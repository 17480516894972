import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import {
	Grid,
	Loader,
	Dimmer as SegmentDimmer,
	Tab as SemanticTab
} from 'semantic-ui-react'
import styled from 'styled-components'
import moment from 'moment'

import Logic from './logic'
import Title from 'components/Title'
import BookMngCalendarView from 'containers/BookMngCalendarView'
import BookMngListView from 'containers/BookMngListView'
import BookMngFilters from 'containers/BookMngFilters'

class BookingManagement extends Component {
	
	constructor (props) {
		super(props)

		this.state = {
			timeFilter: 'week'
		}
	}

	onPrevClick = () => {
		const { getReservations, setVisibleTime } = this.actions
		const { visibleTime } = this.props
		const { visibleTimeStart, visibleTimeEnd } = visibleTime
		const zoom = (visibleTimeEnd + 1) - visibleTimeStart
		const newVisibleTimeStart = visibleTimeStart - zoom
		const newVisibleTimeEnd = visibleTimeEnd - zoom
		
		setVisibleTime({
			visibleTimeStart: newVisibleTimeStart,
			visibleTimeEnd: newVisibleTimeEnd
		})
		
		getReservations()
		
	};

	onNextClick = () => {
		const { getReservations, setVisibleTime } = this.actions
		const { visibleTime } = this.props
		const { visibleTimeStart, visibleTimeEnd } = visibleTime
		
		const zoom = (visibleTimeEnd + 1) - visibleTimeStart
		const newVisibleTimeStart = visibleTimeStart + zoom
		const newVisibleTimeEnd = visibleTimeEnd + zoom
		
		setVisibleTime({
			visibleTimeStart: newVisibleTimeStart,
			visibleTimeEnd: newVisibleTimeEnd
		})
		
		getReservations()
	};
	
	setTimeFilter = (value) => {
		const { getReservations, setVisibleTime } = this.actions
		
		const visibleTimeStart = moment().startOf(value).valueOf()	
		const visibleTimeEnd = moment().endOf(value).valueOf()
		
		this.setState({
			timeFilter: value
		})
		
		setVisibleTime({
			visibleTimeEnd,
			visibleTimeStart
		})
		
		getReservations()
	}
	
	render () {
		const {
			timeFilter
		} = this.state
		
		const {
			t,
			loading,
			serviceAreaOptions,
			selectedServiceArea,
			visibleTime
		} = this.props
		
		const {
			visibleTimeStart,
			visibleTimeEnd
		} = visibleTime
		
		const { setSelectedServiceArea } = this.actions
		
		const panes = [
			{ menuItem: t('booking_management.calendar_view'), render: () => (
				<Tab.Pane>
					<BookMngFilters
						timeFilter={timeFilter}
						serviceAreaOptions={serviceAreaOptions}
						selectedServiceArea={selectedServiceArea}
						setSelectedServiceArea={setSelectedServiceArea}
					>
						<BookMngCalendarView
							timeFilter={timeFilter}
							prevClick={this.onPrevClick}
							nextClick={this.onNextClick}
							visibleTimeStart={visibleTimeStart}
							visibleTimeEnd={visibleTimeEnd}
							setTimeFilter={this.setTimeFilter}
						/>
					</BookMngFilters>
				</Tab.Pane>
			)},
			{ menuItem: t('booking_management.list_view'), render: () => (
				<Tab.Pane>
					<BookMngFilters
						serviceAreaOptions={serviceAreaOptions}
						selectedServiceArea={selectedServiceArea}
						setSelectedServiceArea={setSelectedServiceArea}
					>
						<BookMngListView />
					</BookMngFilters>
				</Tab.Pane>
			)}
		]

		return (
			<Wrapper>
				<Grid>
					<Grid.Row>
						<Title>
							{t('booking_management.title')}
						</Title>
					</Grid.Row>
					<Grid.Row>
						{loading && 
							<Dimmer inverted active>
								<Loader inverted/>
							</Dimmer>
						}
						{!loading &&
							<Tab
								panes={panes}
								onTabChange={(event, data) => {
									const { activeIndex } = data
									
									if (activeIndex === 0) {
										this.setTimeFilter('week')
									} else {
										this.setTimeFilter('month')
									}
								}}
							/>
						}
					</Grid.Row>
				</Grid>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(BookingManagement))

const Wrapper = styled.div``

const Dimmer = styled(SegmentDimmer)`
	&&&& {
		background-color: transparent;
		height: 40vh;
	}
`

const Tab = styled(SemanticTab)`
	width: 100%;
	
	.ui.attached.tabular.menu {
		border: 0;
		margin-left: 15px;
	}
	
	.ui.bottom.attached.segment {
		background: transparent;
		padding: 0;
		border: 0;
	}
	
	.ui.tabular.menu .active.item {
		border: 1px solid rgb(244, 244, 244);
		background-color: rgb(244, 244, 244);
		color: #102526;
		font-family: "Open Sans";
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 16px;
		
		&:after {
			position: absolute;
			content: '';
			border-bottom: 3px solid #102526;
			width: 70%;
			transform: translateX(-50%);
			bottom: 0;
			left: 50%;
		}
	}
	
	.ui.tabular.menu .item {
		color: #9B9B9B;
		font-family: "Open Sans";
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
	}
`
