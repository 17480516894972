export const transformReservations = data => {
	const newData = []
	data.forEach(element => {
		const findSpot = newData.find(item => item.number === element.spot.number)
		if (element.spot) {
			if (findSpot) {
				findSpot.schedule.push({
					date: element.date,
					endingTime: element.endingTime,
					startingTime: element.startingTime,
					licensePlateNumber: element.licensePlateNumber,
					name: element.name,
					id: element.id
				})
			} else {
				newData.push({ 
					number: element.spot.number,
					id: element.spot.id,
					name: element.spot.name,
					schedule: [{
						date: element.date,
						endingTime: element.endingTime,
						startingTime: element.startingTime,
						licensePlateNumber: element.licensePlateNumber,
						name: element.name,
						id: element.id
					}]
				})
			}
		}
	})

	newData.map(item => {
		item.schedule.sort((a,b) => new Date(a.date) - new Date(b.date))
	})
	return newData
}