import React, { Component } from 'react'
import styled from 'styled-components'
import { Input } from 'semantic-ui-react'

class SearchInput extends Component {
	state = {
		search: ''
	}
	
	handleSearch = e => {
		e.preventDefault()
		
		const { search } = this.state
		const { onSubmit, params } = this.props
		
		const reservationsParams = {
			...params,
			search
		}
		onSubmit(reservationsParams)
	}
	
	render () {
		const { search } = this.state
		
		return (
			<Wrapper onSubmit={this.handleSearch}>
				<Input
					icon='search'
					iconPosition='left'
					placeholder='Pesquise por nome ou matrícula'
					value={search}
					onChange={e => this.setState({ search: e.target.value })}
				/>
			</Wrapper>
		)
	}
}

export default SearchInput

const Wrapper = styled.form`
	width: 250px;
	
	> div {
		width: 100%;
	}
`