import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import Button from 'components/Button'

class Buttons extends Component {

	renderSaveButton = () => {
		const { t, current, canSubmitRegistration, dirty, saveButtonClick } = this.props

		if (current !== 8 || (current === 8 && !canSubmitRegistration)) {
			return (
				<Button
					primary
					onClick={saveButtonClick}
					content={t('common.buttons_save')}
					disabled={!dirty}
				/>)
		}
	}

	render () {
		const {
			t,
			previousButtonClick,
			nextButtonClick,
			current,
			canSubmitRegistration,
			campsite
		} = this.props

		const {
			navigate,
			setSubmitPopupOpen
		} = this.actions

		const { status } = campsite || {}

		return (
			<NavigationWrapper>
				<Button
					secondary
					onClick={() => navigate('/parques-campismo')}
				>
					{t('common.buttons_back')}
				</Button>
				<RightWrapper>
					<Button
						secondary
						onClick={previousButtonClick}
						content={t('form.buttons_previousStep')}
						icon={'angle left'}
						disabled={current === 1}
					/>
					{this.renderSaveButton()}
					{canSubmitRegistration && status == 'draft' ? (
						<Button
							primary
							onClick={() => setSubmitPopupOpen(true)}
							content={t('common.submit')}
						/>
					) : current !== 8 ? (
						<Button
							primary
							onClick={nextButtonClick}
							content={t('common.buttons_next_step')}
						/>
					) : null
					}
				</RightWrapper>
			</NavigationWrapper>
		)
	}
}

export default withTranslation()(Logic(Buttons))

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
`

const RightWrapper = styled.div`
  display: flex;
`