/**
 * Label Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import React from 'react'
import styled from 'styled-components'

const Label = ({ children, ...props }) => (
	<StyledLabel {...props}>
		{children}
	</StyledLabel>
)

export default Label

const StyledLabel = styled.label`
  display:block;
  font-family: "Open Sans";
  ${({ textTransform }) => (textTransform) ? `text-transform: ${textTransform};` : 'text-transform: none;'}
  ${({ color }) => (color) ? `color: ${color};` : 'color: #9B9B9B;'}
  ${({ fontSize }) => (fontSize) ? `font-size: ${fontSize};` : 'font-size: 14px;'}
  ${({ fontWeight }) => (fontWeight) ? `font-weight: ${fontWeight};` : 'font-weight: normal;'}
  ${({ lineHeight }) => (lineHeight) ? `line-height: ${lineHeight};` : 'line-height: 19px;'}
  ${({ align }) => (align) ? `text-align: ${align};` : 'text-align: left;'}

  ${({ required }) => required && `
    &::after {
      content: ' *';
      color: #db2828;
    }
  `}
`
