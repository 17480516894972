import React, { Component } from 'react'
import styled from 'styled-components'
import Logic from './logic'
import { withTranslation } from 'react-i18next'

import { Form, Grid, Input, TextArea as SemanticTextArea } from 'semantic-ui-react'

import Segment from 'components/Segment'
import Button from 'components/Button'
import SegmentTitle from 'components/SegmentTitle'
import FormField from 'components/FormField'
import Dropdown from 'components/Dropdown'
import Label from 'components/Label'
import FormError from 'components/FormError'
import Title from 'components/Title'
import PhotoUploader from './uploader.js'

import Loading from 'containers/Loading'

import { ENDPOINTS } from 'api'

class EditProfile extends Component {

onChangePostalCode = (field) => {
	const { change, getDataToPrefillFields } = this.actions
	change(field)
	getDataToPrefillFields()
}

render () {
	const {
		form,
		dirty,
		districts,
		municipalities,
		loading,
		t
	} = this.props

	const {
		change,
		submit
	} = this.actions

	const {
		name,
		nif,
		address,
		postalCode,
		locality,
		district,
		municipality,
		website,
		email,
		telephone,
		mobile,
		representativeName,
		representativeRole,
		representativePhone,
		representativeEmail,
		operatingHours,
		avatar,
	} = form

	if (loading) {
		return (
			<Wrapper>
				<WrapperBoxSegment>
					<Loading t={t} />
				</WrapperBoxSegment>
			</Wrapper>
		)
	}

	return (
		<Wrapper>
			<Title bolder uppercase>
				{t('edit_profile.title')}
			</Title>
			<WrapperBoxSegment>
				<BoxSegment>
					<GridWrapper>
						<SegmentTitle>
							{t('edit_profile.entity_data')}
						</SegmentTitle>
						<Row>
							<Column width={16}>
								<PhotoUploader
									t={t}
									uploadEndpoint={ENDPOINTS.MEDIA}
									value={avatar}
									accept={'image/png'}
									onChange={(file) => change({ avatar: file[0].image })}
									onRemove={() => change({ avatar: null })}
								/>
							</Column>
						</Row>
						<Row style={{ paddingTop: '30px' }}>
							<Column width={16}>
								<Form>
									<FormField
										required
										name={'name'}
										label={t('form.company_name')}
										valid={name.dirty && name.valid}
										error={name.dirty && !name.valid}
										control={Input}
										value={name.value}
										onChange={(e, { value }) => change({ name: value })}
									/>
									{name.dirty && !name.valid &&
									<FormError>{name.message}</FormError>
									}
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={8}>
								<Form>
									<FormField
										required
										name={'nif'}
										label={t('form.nif_company')}
										valid={nif.dirty && nif.valid}
										error={nif.dirty && !nif.valid}
										control={Input}
										value={nif.value}
										maxLength={9}
										disabled
									/>

									{nif.dirty && !nif.valid &&
									<FormError>{nif.message}</FormError>
									}
								</Form>
							</Column>
							<Column width={8}>
								<Form>
									<FormField
										required
										name={'name'}
										label={t('form.email')}
										valid={email.dirty && email.valid}
										error={email.dirty && !email.valid}
										control={Input}
										value={email.value}
										disabled
									/>
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={16}>
								<Form>
									<FormField
										required
										label={t('edit_profile.address')}
										name={'address'}
										valid={address.dirty && address.valid}
										error={address.dirty && !address.valid}
										control={Input}
										value={address.value}
										onChange={(e, { value }) => change({
											address: value
										})}
									/>
									{address.dirty && !address.valid &&
										<FormError>{address.message}</FormError>
									}
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'Código Postal'}
										name={'postalCode'}
										// eslint-disable-next-line max-len
										error={postalCode.dirty && !postalCode.valid}
										control={Input}
										value={postalCode.value}
										onChange={(e, { value }) =>
											this.onChangePostalCode(
												{ postalCode: value }
											)}
									/>
									{postalCode.dirty && !postalCode.valid &&
										<FormError>
											{postalCode.message}
										</FormError>
									}
								</Form>
							</Column>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'Localidade'}
										name={'locality'}
										valid={locality.dirty && locality.valid}
										// eslint-disable-next-line max-len
										error={locality.dirty && !locality.valid}
										control={Input}
										value={locality.value}
										onChange={(e, { value }) => change({
											locality: value
										})}
										loading={loading}
									/>
									{locality.dirty && !locality.valid &&
										<FormError>
											{locality.message}
										</FormError>
									}
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={8}>
								<Form>
									<FormField
										name={'district'}
										// eslint-disable-next-line max-len
										error={district.dirty && !district.valid}
										required
									>
										<Label>{'Distrito'}</Label>
										<Dropdown
											placeholder='Selecione o distrito'
											selection
											options={districts && districts.map(
												({ id, name }) => (
													{
														text: name,
														value: id 
													})
											)}
											value={district.value}
											// eslint-disable-next-line max-len
											onChange={(e, data) => districts && change({district: data.value })}
											loading={loading}
											style={{ width: '100%' }}
										/>
									</FormField>
									{district.dirty && !district.valid &&
										<FormError>
											{district.message}
										</FormError>
									}
								</Form>
							</Column>
							<Column width={8}>
								<Form>
									<FormField
										name={'municipality'}
										// eslint-disable-next-line max-len
										error={municipality.dirty && !municipality.valid}
										required
									>
										<Label>{'Concelho'}</Label>
										<Dropdown
											disabled={!district.value}
											placeholder='Selecione o concelho'
											selection
											options={municipalities &&
												municipalities
													.filter((municipality) =>
														// eslint-disable-next-line max-len
														municipality.district.id === district.value
													)
													.map(({ id, name }) => ({
														text: name,
														value: id
													}))
											}
											value={municipality.value}
											// eslint-disable-next-line max-len
											onChange={(e, data) => municipalities && change({ municipality: data.value })}
											loading={loading}
											style={{ width: '100%' }}
										/>
									</FormField>
									{/* eslint-disable-next-line max-len */}
									{municipality.dirty && !municipality.valid &&
										<FormError>
											{municipality.message}
										</FormError>
									}
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={16}>
								<Form>
									<FormField
										label={t('edit_profile.operating_hours')}
										name={'operatingHours'}
										// eslint-disable-next-line max-len
										error={(operatingHours.dirty && !operatingHours.valid) && operatingHours.message}
										control={TextArea}
										maxLength={20}
										value={operatingHours.value}
										onChange={(e, { value }) => change(
											{ operatingHours: value })
										}
									/>
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={16}>
								<Form>
									<FormField
										label={'Website'}
										name={'website'}
										control={Input}
										value={website.value}
										// eslint-disable-next-line max-len
										onChange={(e, { value }) => change({ website: value })}
									/>
								</Form>
							</Column>
						</Row>
						<Row style={{ 'paddingBottom': '30px' }}>
							<Column width={8}>
								<Form>
									<FormField
										label={'Telefone'}
										name={'telephone'}
										control={Input}
										value={telephone.value}
										onChange={(e, { value }) => change({
											telephone: value
										})}
									/>
								</Form>
							</Column>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'Telemóvel'}
										name={'mobile'}
										valid={mobile.dirty && mobile.valid}
										error={mobile.dirty && !mobile.valid}
										control={Input}
										value={mobile.value}
										onChange={(e, { value }) => change({
											mobile: value
										})}
									/>
									{mobile.dirty && !mobile.valid &&
										<FormError>{mobile.message}</FormError>
									}
								</Form>
							</Column>
						</Row>
						<SegmentTitle>{'Representante'}</SegmentTitle>
						<Row>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'Nome do representante'}
										name={'representativeName'}
										// eslint-disable-next-line max-len
										error={representativeName.dirty && !representativeName.valid}
										control={Input}
										value={representativeName.value}
										onChange={(e, { value }) => change({
											representativeName: value
										})}
									/>
									{/* eslint-disable-next-line max-len */}
									{representativeName.dirty && !representativeName.valid &&
										<FormError>
											{representativeName.message}
										</FormError>
									}
								</Form>
							</Column>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'Cargo do representante'}
										name={'representativeRole'}
										// eslint-disable-next-line max-len
										error={representativeRole.dirty && !representativeRole.valid}
										control={Input}
										value={representativeRole.value}
										onChange={(e, { value }) => change({
											representativeRole: value
										})}
									/>
									{/* eslint-disable-next-line max-len */}
									{representativeRole.dirty && !representativeRole.valid &&
										<FormError>
											{representativeRole.message}
										</FormError>
									}
								</Form>
							</Column>
						</Row>
						<Row>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'Contacto Telf./Telm.'}
										name={'representativePhone'}
										// eslint-disable-next-line max-len
										error={representativePhone.dirty && !representativePhone.valid}
										control={Input}
										value={representativePhone.value}
										onChange={(e, { value }) => change({
											representativePhone: value
										})}
									/>
									{/* eslint-disable-next-line max-len */}
									{representativePhone.dirty && !representativePhone.valid &&
										<FormError>
											{representativePhone.message}
										</FormError>
									}
								</Form>
							</Column>
							<Column width={8}>
								<Form>
									<FormField
										required
										label={'E-mail'}
										name={'representativeEmail'}
										// eslint-disable-next-line max-len
										valid={representativeEmail.dirty && representativeEmail.valid}
										// eslint-disable-next-line max-len
										error={representativeEmail.dirty && !representativeEmail.valid}
										control={Input}
										value={representativeEmail.value}
										onChange={(e, { value }) => change(
											{ representativeEmail: value })
										}
									/>
									{/* eslint-disable-next-line max-len */}
									{representativeEmail.dirty && !representativeEmail.valid &&
										<FormError>
											{representativeEmail.message}
										</FormError>
									}
								</Form>
							</Column>
						</Row>
					</GridWrapper>
				</BoxSegment>
				<SubmitWrapper>
					<SubmitButton primary onClick={() => submit(t)} disabled={!dirty}>
						{t('common.buttons_save')}
					</SubmitButton>
				</SubmitWrapper>
			</WrapperBoxSegment>
		</Wrapper>
	)
}
}

export default withTranslation()(Logic(EditProfile))

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const TextArea = styled(SemanticTextArea)`
  width: 100%;
  border: 1px solid #DDDDDD;
`

const WrapperBoxSegment = styled.div`
  width: 800px;
`

const BoxSegment = styled(Segment)`
  &&&& {
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    margin-top: 40px;
    padding: 40px;
    padding-bottom: 60px;
  }
`

const GridWrapper = styled(Grid)`
  padding: 25px !important;
`

const Row = styled(Grid.Row)``

const Column = styled(Grid.Column)`
  justify-content: center;
`

const SubmitButton = styled(Button)`
  &&&& {
    width: 305px;
    margin-top: 8px;
    text-align: center;
    max-height: 48px;
    padding: 18px 0;
  }
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: -45px;
`
