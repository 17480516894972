import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import Logic from './logic'

import List from 'containers/ServiceLocationList'
import ListCamping from '../../../ui/screens/CampsiteManagement/List'
import Title from 'components/Title'
import Button from 'components/Button'

class ServiceLocation extends Component {
	render () {
		const { t, user } = this.props
		const { createServiceArea, createCampsite } = this.actions
		return (
			<Wrapper>
				<Container>
					<Row>
						<Grid.Column width={10}>
							<Title>
								{user.isMotorhomeServiceAreaManager ? t('navigation.service_areas_managment') : t('navigation.processManagement')}
							</Title>
						</Grid.Column>

						<RightColumn width={6}>
							{user.isMotorhomeServiceAreaManager ? <Button primary onClick={() => createServiceArea()}>{('options.create_service_area')}</Button> : <Button primary onClick={() => createCampsite()}>{t('options.create_campsite')}</Button>}
						</RightColumn>
					</Row>

					<Row>
						<Grid.Column>
							{user.isMotorhomeServiceAreaManager ? <List status={''} /> : <ListCamping status={''} />}
						</Grid.Column>
					</Row>
				</Container>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(ServiceLocation))

const Wrapper = styled.div``

const Container = styled(Grid)`
  margin-top: 16px !important;
`

const Row = styled(Grid.Row)``

const RightColumn = styled(Grid.Column)`
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
`
