import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import API from 'api'
import { ENDPOINTS } from 'api'

import {
	Form,
	Input,
	Radio,
	TextArea
} from 'semantic-ui-react'

import FormError from 'components/FormError'
import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import FormField from 'components/FormField'
import Buttons from '../Buttons'
import FileUploader from 'containers/FileUploader'
import FormRequest from 'components/FormRequest'
import { DEFAULT_VALUES, VALIDATIONS, VALIDATIONS_NO_REQUIRED } from './utils'
import Label from 'components/Label'
import { getFilenameOnFullPath } from 'utils'
import FormGroup from 'components/FormGroup'

class DocumentComponent extends Component {

	render () {

		const { t, document, show, prop, changeForm, id } = this.props

		if (!show) return null

		return (
			<DocumentWrapper>
				<FormField required>
					<Label
						id={id}
						color={'#616161'}
					>
						{t('form.upload_pdf')}
					</Label>

					<FileUploader
						single
						limit={1}
						value={document.value && 
							document.value.url &&
								[{ name: getFilenameOnFullPath(document.value.url) }]
						}
						accept={'application/pdf'}
						uploadEndpoint={ENDPOINTS.MEDIA}
						onChange={
						// eslint-disable-next-line max-len
							file => changeForm({ [prop]: file[0] })
						}
						onRemove={
						// eslint-disable-next-line max-len
							() => changeForm({ [prop]: null })
						}
					/>

					{/* eslint-disable-next-line max-len */}
					{(document.dirty && !document.valid) && (
						<FormError>
							{document.message}
						</FormError>
					)}
				</FormField>
			</DocumentWrapper>

		)
	}

}

class Documents extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	componentWillUnmount () {
		this.submitData()
	}

	submitData = (action) => {
		const {t, current, campsiteId, submit} = this.props
		const {setCurrent, showToaster, updateStep} = this.actions
		
		const navigate = () => {
			switch (action) {
				case 'next':
					setCurrent(current + 1)
					break
				case 'previous':
					setCurrent(current - 1)
					break
				default:
					break
			}
		}
	
		submit(data => API.Campsites.steps.updateDocuments(campsiteId, data), {
			resolveData: data => {
				
				const newData = {...data}
				
				if (data.facilitiesRegulationDocument)
					newData.facilitiesRegulationDocument = data.facilitiesRegulationDocument.id

				if (data.cityCouncilPermissionDocument)
					newData.cityCouncilPermissionDocument = data.cityCouncilPermissionDocument.id

				if (data.campsiteBlueprint)
					newData.campsiteBlueprint = data.campsiteBlueprint.id

				if (data.priceMenu)
					newData.priceMenu = data.priceMenu.id

				if (data.otherDocument)
					newData.otherDocument = data.otherDocument.id

				return newData
			},
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
					navigate()
				}
				else if (success === false) {
					showToaster(
						'error',
						t('common.error'),
						t('error.error_save_data')
					)
				}
				else navigate()
			}
		})
	}

	render () {

		const { t, form, loading, changeForm, formHasChanged } = this.props

		const {
			hasFacilitiesRegulationDocument,
			facilitiesRegulationDocument,
			hasCityCouncilPermissionDocument,
			cityCouncilPermissionDocument,
			hasCampsiteBlueprint,
			campsiteBlueprint,
			hasPriceMenu,
			priceMenu,
			otherDocumentName,
			otherDescription,
			otherDocument
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle>
							{t('form.documents')}
						</SegmentTitle>
						<HorizontalLine />

						<SegmentTitle
							margin={'30px 0'}
						>
							{t('form.facilities_regulations')}
						</SegmentTitle>

						<FormLabel style={{marginBottom: 15}}>
							{t('campsite_details.has_facility_regulation_document')} *
						</FormLabel>
						<FormGroup inline style={{marginBottom: 30}}>
							<FormField
								control={Radio}
								label={t('options.no')}
								name='has_facilities_regulation_document_no'
								checked={hasFacilitiesRegulationDocument.value === false}
								onChange={() => changeForm({
									hasFacilitiesRegulationDocument: false, facilitiesRegulationDocument: null
								})}
							/>
							<FormField
								control={Radio}
								label={t('options.yes')}
								name='has_facilities_regulation_document_yes'
								checked={hasFacilitiesRegulationDocument.value === true}
								onChange={() => changeForm({ hasFacilitiesRegulationDocument: true })}
							/>
						</FormGroup>

						<DocumentComponent
							id='facilitiesRegulationDocument'
							show={hasFacilitiesRegulationDocument.value === true}
							t={t}
							changeForm={changeForm}
							prop='facilitiesRegulationDocument'
							document={facilitiesRegulationDocument}
						/>

						<SegmentTitle
							margin={'30px 0'}
						>
							{/* eslint-disable-next-line max-len*/}
							{t('form.council_city_permission_document')}
						</SegmentTitle>

						<FormLabel style={{marginBottom: 15}}>
							{t('campsite_details.has_council_city_permission_document')} *
						</FormLabel>
						<FormGroup inline style={{marginBottom: 30}}>
							<FormField
								control={Radio}
								label={t('options.no')}
								name='has_city_council_permission_document_no'
								checked={hasCityCouncilPermissionDocument.value === false}
								onChange={() => changeForm({ 
									hasCityCouncilPermissionDocument: false, cityCouncilPermissionDocument: null
								})}
							/>
							<FormField
								control={Radio}
								label={t('options.yes')}
								name='has_city_council_permission_document_yes'
								checked={hasCityCouncilPermissionDocument.value === true}
								onChange={() => changeForm({ hasCityCouncilPermissionDocument: true })}
							/>
						</FormGroup>

						<DocumentComponent
							id='cityCouncilPermissionDocument'
							show={hasCityCouncilPermissionDocument.value === true}
							t={t}
							changeForm={changeForm}
							prop='cityCouncilPermissionDocument'
							document={cityCouncilPermissionDocument}
						/>

						<SegmentTitle
							margin={'30px 0'}
						>
							{/* eslint-disable-next-line max-len*/}
							{t('campsite_details.campsite_blueprints')}
						</SegmentTitle>

						<FormLabel style={{marginBottom: 15}}>
							{t('campsite_details.has_campsite_blueprints')} *
						</FormLabel>
						<FormGroup inline style={{marginBottom: 30}}>
							<FormField
								control={Radio}
								label={t('options.no')}
								name='has_campsite_blueprint_no'
								checked={hasCampsiteBlueprint.value === false}
								onChange={() => changeForm({ hasCampsiteBlueprint: false, campsiteBlueprint: null })}
							/>
							<FormField
								control={Radio}
								label={t('options.yes')}
								name='has_campsite_blueprint_yes'
								checked={hasCampsiteBlueprint.value === true}
								onChange={() => changeForm({ hasCampsiteBlueprint: true })}
							/>
						</FormGroup>

						<DocumentComponent
							id='campsiteBlueprint'
							show={hasCampsiteBlueprint.value === true}
							t={t}
							changeForm={changeForm}
							prop='campsiteBlueprint'
							document={campsiteBlueprint}
						/>

						<SegmentTitle
							margin={'30px 0'}
						>
							{t('form.price_menu')}
						</SegmentTitle>

						<FormLabel style={{marginBottom: 15}}>
							{t('form.has_price_menu')} *
						</FormLabel>
						<FormGroup inline style={{marginBottom: 30}}>
							<FormField
								control={Radio}
								label={t('options.no')}
								name='has_price_menu_no'
								checked={hasPriceMenu.value === false}
								onChange={() => changeForm({ hasPriceMenu: false, priceMenu: null })}
							/>
							<FormField
								control={Radio}
								label={t('options.yes')}
								name='has_price_menu_yes'
								checked={hasPriceMenu.value === true}
								onChange={() => changeForm({ hasPriceMenu: true })}
							/>
						</FormGroup>

						<DocumentComponent
							id='priceMenu'
							show={hasPriceMenu.value === true}
							t={t}
							changeForm={changeForm}
							prop='priceMenu'
							document={priceMenu}
						/>

						<SegmentTitle
							margin={'30px 0'}
						>
							{t('form.other_documents')}
						</SegmentTitle>

						<FormField
							name={'document_name'}
							label={t('form.document_name')}
							control={Input}
							value={otherDocumentName.value}
							onChange={
								// eslint-disable-next-line max-len
								(e, { value }) => changeForm({ otherDocumentName: value })
							}
						/>

						<FormField
							name={'description'}
							label={t('form.description')}
							control={TextArea}
							value={otherDescription.value}
							onChange={
								// eslint-disable-next-line max-len
								(e, { value }) => changeForm({ otherDescription: value })
							}
						/>

						<DocumentComponent
							id='otherDocument'
							show
							t={t}
							changeForm={changeForm}
							prop='otherDocument'
							document={otherDocument}
						/>
						
					</Form>
				</BoxSegment>

				<Buttons
					previousButtonClick={() => this.submitData('previous')}
					nextButtonClick={() => this.submitData('next')}
					saveButtonClick={() => this.submitData('')}
					dirty={formHasChanged}
				/>
			</>
		)
	}
}

const DocumentsWithLogic = withTranslation()(Logic(Documents))

class FormContainer extends Component {
	render () {

		const { campsite } = this.props
		const { status } = campsite || {}

		return (
			<FormRequest submitChangedDataOnly defaultForm={DEFAULT_VALUES} validations={status === 'draft' ? VALIDATIONS_NO_REQUIRED : VALIDATIONS} initialLoading>
				<DocumentsWithLogic {...this.props} />
			</FormRequest>
		)
	}
}

export default FormContainer

const HorizontalLine = styled.hr`
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #F3F3F3;
  margin: 16px 0;
`

const FormLabel = styled(Label)`
	color: #616161;
	margin-bottom: 2px;
`

const DocumentWrapper = styled.div`
	background-color: #FAFAFA;
	padding: 24px;
`