import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import { downloadFile } from 'utils'
/** components */
import { PDFFile, ImageFile, OthersFile } from 'components/Icons'
import ContentBox from 'components/ContentBox'
import Table from 'components/Table'
import Button from 'components/Button'

const getIcon = (type) => {
	switch (type) {
		/* probably never be used */
		case 'Imagem':
			return (<ImageFile fill={'#9B9B9B'} />)
		case 'PDF':
			return (<PDFFile fill={'#9B9B9B'} />)
		default:
			return (<OthersFile fill={'#9B9B9B'} />)
	}
}

class ListDownloadsCampSites extends Component {
	render () {
		const { 
			downloads,
			t, 
			limit,
			offset,
			count,
			hasNext,
			hasPrevious,
			tableLoading
		} = this.props
		const {
			changeLimit,
			changePage
		} = this.actions
		return (
			<Wrapper>
				<ContentBox 
					loading={tableLoading}
					title={t('form.camping_sites')}
					noMarginBottom
				>
					<Table.Table>
						<Table.Body>
							{downloads.map((download, i) => (
								<React.Fragment key={`areas-${i}`}>
									<Row>
										<Cell>
											<div style={{display: 'flex'}}>
												<div style={{display: 'flex', marginRight: '8px'}}>
													{getIcon(download.type)}
												</div>
												<div style={{display: 'flex', flexDirection: 'column'}}>
													<Name>{download.name || '-'}</Name>
													<Description>{download.description || '-'}</Description>
												</div>
											</div>
										</Cell>
										<Cell>{download.designation || '-'}</Cell>
										<Cell style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
											<DownloadButton
												onClick={() =>
													downloadFile(download.url)
												}
											>
												{getIcon(download.type)} 
												<DownloadText>
													{t('download_management.download')} {download.type}
												</DownloadText>
											</DownloadButton>
										</Cell>
									</Row>
								</React.Fragment>
							))}

							{!downloads.length &&
								<Table.Row>
									<Cell>{t('error.noResults')}</Cell>
								</Table.Row>
							}
						</Table.Body>

						{!!downloads.length && (
							<Table.Footer>
								<Table.Row>
									<PagContainer colSpan={9}>
										<Table.Pagination
											style={{ width: 'auto' }}
											resultsPerPage={[
												{ text: '5', value: 5 },
												{ text: '10', value: 10 },
												{ text: '20', value: 20 }
											]}
											limit={limit}
											offset={offset}
											total={count}
											hasNext={hasNext}
											hasPrevious={hasPrevious}
											onChangeResults={changeLimit}
											onChangePage={payload => {
												// eslint-disable-next-line max-len
												if (payload === 'next' && hasNext) changePage(payload)
												// eslint-disable-next-line max-len
												if (payload === 'prev' && hasPrevious) changePage(payload)
												else return
											}}
										/>
									</PagContainer>
								</Table.Row>
							</Table.Footer>
						)}
					</Table.Table>
				</ContentBox>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(ListDownloadsCampSites))

const Wrapper = styled.div``

const Row = styled(Table.Row)`
  &&&& {
    &:hover {
      background-color: #f9f9f9;
    }
  }
`
const Cell = styled(Table.Cell)`
  &&&&{
    color: #3E3E3E;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 14px;
  }
`
const PagContainer = styled.th`
  div.menu {
    width: auto !important;
    align-items: center;
  }

  div.ui.right {
    background: none;
  }
`

const Name = styled.p`
	color: #3E3E3E;
	font-family: 'Open Sans';
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	margin: 0;
`

const Description = styled.p`
	color: #9B9B9B;
	font-family: 'Open Sans';
	font-size: 12px;
	line-height: 14px;
	margin-top: 4px;
`

const DownloadButton = styled(Button)`
	color: #616161;
	border: 1px solid #ddd !important;
	border-radius: 100px !important;
	background-color: #fff !important;
	box-shadow: 0 4px 2px 0 rgba(97,97,97,0.2);
	transition: all 0.2s ease-in;
	max-height: 30px;
	margin: 0;
	&:hover {
		background-color: #102526 !important;
		* {
			color: #fff;
			fill: #fff;
		}
	}
`

const DownloadText = styled.p`
color: #616161;
font-family: 'Open Sans';
font-weight: 600;
font-size: 14px;
line-height: 14px;
margin: 0 8px;
`