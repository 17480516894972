// @flow

import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import * as Check from 'validations'
import { cloneDeep, mapValues } from 'lodash'
import moment from 'moment'

import API from 'api'
import { mapApiErrors } from 'api/errors'
import { DEFAULT_VALUES, VALIDATIONS } from './utils'
import { mapAPIFormStateToForm } from 'utils'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import BookingSettingsLogic from 'screens/BookingSettings/logic'
import { handleApiErrorMessage, resolveDecimalString } from '../../../utils'

export default kea({
	path: () => ['scenes', 'BookingRule'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			],
			BookingSettingsLogic, [
				'getRules'
			]
		]
	},
	
	actions: () => ({
		setForm: form => ({ form }),
		changeForm: field => ({ field }),
		setLoading: loading => loading,
		fetchForm: () => true,
		saveForm: () => true,
		createRule: () => true,
		reset: () => true,
		setError: error => error,
		setIsNewRule: rule => rule
	}),

	reducers: ({ actions }) => ({

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.setForm]: (_, payload) => payload.form,
			[actions.changeForm]: (state, payload) =>
				Check.setAndCheckValidation(
					state,
					payload,
					VALIDATIONS
				),
			[actions.reset]: () => cloneDeep(DEFAULT_VALUES)
		}],
		
		dirty: [false, Proptypes.bool, {
			[actions.changeForm]: () => true,
			[actions.reset]: () => false
		}],

		loading: [true, Proptypes.bool, {
			[actions.setForm]: () => false,
			[actions.setLoading]: (state, payload) => payload,
			[actions.showToaster]: () => false,
			[actions.saveForm]: () => true,
			[actions.createRule]: () => true,
			[actions.reset]: () => true,
			[actions.setError]: () => false
		}],

		errors: [[], Proptypes.array, {
			[actions.setError]: (state, payload) => payload,
			[actions.saveForm]: () => [],
			[actions.createRule]: () => [],
			[actions.reset]: () => ([])
		}],
		
		isNewRule: [false, Proptypes.bool, {
			[actions.setIsNewRule]: (_, payload) => payload,
			[actions.reset]: () => false
		}]
	}),
	
	start: function * () {
		const {
			fetchForm,
			setLoading,
			setIsNewRule
		} = this.actions
				
		const { match } = this.props
		const id = match.params.id
		
		if (id !== 'nova-regra') {
			yield put(fetchForm())
		} else {
			yield put(setIsNewRule(true))
			yield put(setLoading(false))
		}
		
	},
	
	stop: function * () {
		const { reset } = this.actions
		
		yield put(reset())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchForm]: workers.fetchForm,
		[actions.saveForm]: workers.saveForm,
		[actions.createRule]: workers.createRule
	}),

	workers: {
		* fetchForm () {
			const { setForm, showToaster } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			const areaid = match.params.areaid
			
			if (!id) {
				return
			}

			try {
				let data = yield call(
					API.Reservations.getRuleById,
					areaid,
					id
				)
				// eslint-disable-next-line max-len
				data.timeInterval = moment(data.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' - ' + moment(data.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
				const newForm = yield call(
					mapAPIFormStateToForm,
					data,
					cloneDeep(form)
				)
				
				yield put(setForm(newForm))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}
		},
		
		* saveForm () {
			const { showToaster, navigate, getRules } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			const areaid = match.params.areaid
			
			if (!id || !areaid) {
				return
			}
			
			const params = mapValues(form, ({ value }) => value)
			
			params.spotPrice = resolveDecimalString(params.spotPrice)
			params.electricityPrice = resolveDecimalString(params.electricityPrice)
			params.waterPrice = resolveDecimalString(params.waterPrice)
			params.cleaningPrice = resolveDecimalString(params.cleaningPrice)
			//params.guestPrice = resolveDecimalString(params.guestPrice)

			const dates = params.timeInterval.split(' - ')
			// eslint-disable-next-line max-len
			params.startDate = moment(dates[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
			params.endDate = moment(dates[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
			
			try {
				yield call(
					API.Reservations.patchRuleById,
					areaid,
					id,
					params
				)
				yield put(getRules())
				yield put(navigate('/definicoes-reservas'))
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},
		
		* createRule () {
			const {
				showToaster,
				navigate,
				setError,
				setForm,
				getRules
			} = this.actions
			
			const form = yield this.get('form')
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, VALIDATIONS)
			
			if (dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(setError([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(setError([]))
				return false
			}
			const { match, t } = this.props
			const id = match.params.id
			const areaid = match.params.areaid
			
			if (!id || !areaid) {
				return
			}
			
			const params = mapValues(form, ({ value }) => value)

			params.spotPrice = resolveDecimalString(params.spotPrice)
			params.electricityPrice = resolveDecimalString(params.electricityPrice)
			params.waterPrice = resolveDecimalString(params.waterPrice)
			params.cleaningPrice = resolveDecimalString(params.cleaningPrice)
			//params.guestPrice = resolveDecimalString(params.guestPrice)

			const dates = params.timeInterval.split(' - ')
			// eslint-disable-next-line max-len
			params.startDate = moment(dates[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
			params.endDate = moment(dates[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
			
			try {
				yield call(
					API.Reservations.createRule,
					areaid,
					id,
					params
				)
				yield put(getRules())
				yield put(navigate('/definicoes-reservas'))
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				const errors = mapApiErrors(
					err.response.data,
					t
				)
				yield put(setError(errors))
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		}
	}
})
