import React from 'react'
import styled from 'styled-components'
import Label from 'components/Label'

class Item extends React.Component {
	constructor () {
		super()
		this.input = null
	}

	render () {
		const props = {...this.props}
		return (
			<Li
				id={`${props.name}-${props.value}`}
				onClick={props.handleSelection.bind(this, props)}
				ref={(c) => { this.input = c }}
				width={props.width}
				style={props.style}
				height={props.height}
				disabled={props.disabled}
			>
				<Input
					id={`${props.name}-${props.value}`}
					name={props.name}
					checked={props.selected}
					onChange={props.handleSelection.bind(this, props)}
				/>
				<Box
					active={props.selected}
					className={'box'}
				/>
				<TextWrapper>
					<StyledLabel htmlFor='f-option' disabled={props.disabled}>
						{props.text}
					</StyledLabel>
					<StyledLabelSecondary
						htmlFor='f-option'
						disabled={props.disabled}
					>
						{(props.subname) ? props.subname : ' '}
					</StyledLabelSecondary>
					<StyledParagraph disabled={props.disabled}>
						{props.description}
					</StyledParagraph>
					{props.disabled && (
						<StyledHighlight disabled={props.disabled}>
							Brevemente
						</StyledHighlight>
					)}
				</TextWrapper>
				<Check className={'check'}  disabled={props.disabled} />
			</Li>
		)
	}
}

export default class MultipleSelector extends React.Component {
	constructor (props) {
		super(props)
		this.itemsLoaded = 0
		this.maxHeight = 180
		this.state = {
			itemHeight: this.maxHeight
		}
	}

	onSelection = (e) => {
		if (this.props.onSelectionChange) {
			this.props.onSelectionChange({...e})
		}
	}

	render () {
		const { items } = this.props

		return (
			<Container>
				{items && items.map((item) => {
					return (
						<Item
							style={this.props.style}
							componentLoaded={this.componentLoaded}
							key={`MultipleSelector-${item.value}${item.text}`}
							name={this.props.name}
							width={this.props.itemSize}
							handleSelection={this.onSelection}
							value={item.value}
							text={item.text}
							subname={item.sub_name}
							description={item.description}
							height={`${this.state.itemHeight}px`}
							disabled={item.disabled}
							selected={!!this.props.value.length &&
                this.props.value.includes(item.value)}
						/>
					)
				})}
			</Container>
		)
	}
}

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 23px;
  padding-bottom: 3px;

  @media screen and (max-width: 568px) {
    flex-direction: column;
    align-items: center;
  }
`
const Li = styled.li`
  display: block;
  position: relative;

  width: 220px;

  height: auto;

  &:hover {
    div.check {
      //border: 1px solid rgba(0,0,0,0.15);
      //background: #102526;
      &::before  {
        background: #FFFFFF;
      }

      ${({ disabled }) => !disabled && `
        background: #102526;
        border: 1px solid rgba(0,0,0,0.15);
      `}
    }
     div.box {
      margin: 2px;

      &:before {
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        //background: #102526;
        z-index: -2;

        ${({ disabled }) => !disabled && `
          background: #102526;
        `}
      }
      &:after {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFFFFF;
        z-index: -1;
      }
     }
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 30px;
  }

`
const TextWrapper = styled.div`
  position: relative;
  display:block;
  cursor: pointer;
  pointer-events: none;
  margin-bottom: 26px;
  z-index: 4;
`
const Box = styled.div`
  margin: 2px;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 26px;

  padding: 2px;
  cursor: pointer;


  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  ${({active}) => {
		if (active) {
			return (`
        &:before {
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          background-color: #FFFFFF;
          z-index: -2;
          border-radius: 4px;
        }
        &:after {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #FFFFFF;
          z-index: -1;
        }
      `)
		} else {
			return (`
      &:before {
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: #DEDEDE;
        z-index: -2;
        border-radius: 4px;
      }
      &:after {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FFFFFF;
        z-index: -1;
      }
      `)
		}
	}}
`

const Input = styled.input.attrs({
	type: 'radio'
})`
  display: block;
  position: absolute;

  width: 14px;
  height: 14px;
  visibility: hidden;
  z-index: 6;

  &:checked {
    ~ div.check {

      border: 1px solid rgba(0,0,0,0.15);
      background: #102526;

      &::before  {
          background: #FFFFFF;
      }
    }
    ~ div.box {
      margin: 2px;
      &:before {
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          background: #102526;
          z-index: -2;
        }
        &:after {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #FFFFFF;
          z-index: -1;
        }
    }

  }
`
const Check = styled.div`
  display: block;
  position: absolute;
  width: 37px;
  height: 37px;
  border: 1px solid #DEDEDE;
  bottom: 5px;
  left: 50%;
  border-radius: 4px;
  margin-left: -19px;
  z-index: 6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({active, disabled }) => {
		if (active && !disabled) {
			return `
        border: 1px solid rgba(0,0,0,0.15);
        background: linear-gradient(180deg, #B4EC51 0%, #429321 100%);
      `
		} else {
			return `
        background-color: #FFFFFF;
      `
		}
	}}

  &::after {
    display: block;
    border: solid #FFF;
    border-radius: 2px;
    border-width: 0 5px 5px 0;
    transform: rotate(45deg);
    display: block;
    content: "";
    height: 18px;
    width: 10px;
  }



`
const StyledLabel = styled(Label)`
  margin: 35px 10px 0px 10px;
  color: #3E3E3E !important;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  font-family: "Open Sans";
  font-weight: 600 !important;
  width: 200px !important;

  ${({ disabled }) => disabled && `
    color: grey !important;
  `}
`
const StyledLabelSecondary = styled(Label)`
  margin: 0px 10px 0px 10px;
  color: #3E3E3E;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;

  ${({ disabled }) => disabled && `
    color: grey !important;
  `}
`
const StyledParagraph = styled.p`
  margin: 12px 10px 0px 10px;
  padding-bottom: 46px;
  position: relative;
  color: #616161;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;

  ${({ disabled }) => disabled && `
    color: grey !important;
  `}
`
const StyledHighlight = styled.p`
  margin: 4px 10px 0px 10px;
  padding-bottom: 20px;
  position: relative;
  color: #616161;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  font-style: italic;
`