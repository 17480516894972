/**
 * Fatal Error Route Scene
 *
 */

import React, { Component } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
/** components */
import Segment from 'components/Segment'
import Button from 'components/Button'

class FatalError extends Component {
	render () {
		const { t } = this.props
		return (
			<Wrapper>
				<BigIcon name={'heartbeat'} size={'huge'} />
				<Title>
					{t('error.fatal_error_title')}
				</Title>
				<SubTitle>
					{t('error.fatal_error_subtitle')}
				</SubTitle>
				<Text>
					{t('error.fatal_error_description')}
				</Text>
				<Buttons>
					<Button
						secondary
						onClick={() => window.history.back()}
					>
						{t('error.fatal_error_back')}
					</Button>
					<Button
						primary
						onClick={() => window.location.reload()}
					>
						{t('error.fatal_error_reload')}
					</Button>
				</Buttons>
			</Wrapper>
		)
	}
}

export default withTranslation()(FatalError)

const Wrapper = styled(Segment)`
  &&& {
    text-align: center;
    padding: 100px 60px;
  }
`

const Title = styled.h1`
  text-transform: uppercase;
  color: rgba(194,52,52,0.5);
  margin: 0;
`

const SubTitle = styled.h4`
  text-transform: uppercase;
  color: #ccc;
  margin: 0;
`

const Text = styled.p`
  color: #222;
  font-size: 12px;
  margin-top: 20px;
`

const BigIcon = styled(Icon)`
  &&&& {
    color: rgba(194,52,52,0.5);
    margin-bottom: 50px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  margin-top: 20px;
`
