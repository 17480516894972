import * as React from 'react'

const IcParqueInfantil = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-parque-infantil</title>
			<g id="icons/servicos/ic-parque-infantil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M14.0788125,7.63823438 L14.0788125,23.9972344 L12.6725625,23.9972344 L12.6725625,16.2564844 L10.6011094,16.2564844 L10.6011094,23.9972344 L9.19485937,23.9972344 L9.19485937,22.5964219 L4.884,22.5964219 L4.884,23.9972344 L3.47775,23.9972344 L3.47775,16.2564844 L4.884,16.2564844 L4.884,18.3717656 L9.19485937,18.3717656 L9.19485937,16.2564844 L10.6010625,16.2564844 L10.6010625,13.3979531 C10.6010625,11.4340313 9.00332812,9.83629688 7.03940625,9.83629688 C5.07548437,9.83629688 3.47775,11.4340313 3.47775,13.3979531 L3.47775,13.3979531 L3.47775,16.2564844 L1.40625,16.2564844 L1.40625,23.9972344 L-4.79616347e-14,23.9972344 L-4.79616347e-14,7.63823438 L14.0788125,7.63823438 Z M15.4851094,13.1235469 L20.980875,19.6636875 L24,19.6636875 L24,23.9972344 L18.9619688,23.9972344 L15.4851094,19.8596719 L15.4851094,13.1235469 Z M9.19485937,19.7780156 L4.884,19.7780156 L4.884,21.1901719 L9.19485937,21.1901719 L9.19485937,19.7780156 Z M7.03945313,11.2425469 C8.22796875,11.2425469 9.19485938,12.2094375 9.19485938,13.3979531 L9.19490625,13.3979531 L9.19490625,16.2564844 L4.88404688,16.2564844 L4.88404688,13.3979531 C4.88404688,12.2094375 5.8509375,11.2425469 7.03945313,11.2425469 Z M7.03945312,0.002765625 L14.0788594,4.8384375 L14.0788594,6.23198438 L-4.79616347e-14,6.23198438 L-4.79616347e-14,4.8384375 L7.03945312,0.002765625 Z" id="icon" fill="#000000"/>
			</g>
		</svg>
	)

export default IcParqueInfantil
