import React, { Component } from 'react'
import styled from 'styled-components'
import {
	Grid
} from 'semantic-ui-react'

class ImageGridColumn extends Component {
	render () {

		const { data: {component, image}, width } = this.props

		return (
			<Grid.Column style={{width: `${width}%`}}>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column computer={3}>
							<IconWrapper>
								{image}
							</IconWrapper>
						</Grid.Column>
						<Grid.Column computer={13}>
							{component}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Grid.Column>
		)
	}
}

class ImageGrid extends Component {

	render () {

		const { data, columnWidth } = this.props

		if (!data) return null

		const width = columnWidth || 100 / data.length

		return (
			<Grid>
				{data.map((item, key) => (
					<ImageGridColumn width={width} data={item} key={key} />
				))}
			</Grid>
		)
	}
}

const IconWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 4px;
	background-color: rgba(208,208,208,0.3);
	
	display: flex;
	justify-content: center;
	align-items: center;
`

export default ImageGrid