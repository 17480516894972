import * as React from 'react'

const IcGolf = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/iic-golf</title>
			<g id="icons/servicos/iic-golf" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M6.66861272,0.371807437 C6.93407129,-0.0123693583 7.46494421,-0.115529579 7.85460167,0.141045006 L7.85460167,0.141045006 L17.6981818,6.62873561 C17.7167895,6.645266 18.0282591,6.86334335 18.098447,7.24287926 C18.1829553,7.73653672 17.8529221,8.20818313 17.3617398,8.29476876 L17.3617398,8.29476876 L12.7797729,9.11978534 L14.538447,16.1218627 C14.5752205,16.2664373 14.6069111,16.6028793 14.5815851,16.7558516 L14.5815851,16.7558516 L13.783574,23.0986141 C13.6842591,23.6738627 13.1330989,24.0648019 12.5494525,23.9667688 C11.9640823,23.8695312 11.5692978,23.3264594 11.6652536,22.7512108 L11.6652536,22.7512108 L12.443331,16.5711887 L12.1749553,15.4888019 C12.1650246,15.5157726 10.8167388,17.5489565 9.49951803,19.5324224 L9.17165251,20.0260633 C8.0319358,21.7418528 7.00337515,23.2884041 7.00337515,23.2884041 C6.70582819,23.7934649 6.05451328,23.9739732 5.5376072,23.6802273 C5.02618178,23.3919621 4.84611549,22.7402494 5.14410444,22.2347467 L5.14410444,22.2347467 L10.081121,14.8273212 L8.55449118,8.91991793 C8.44333095,8.43474666 8.80173979,7.93600633 9.30048013,7.85569694 L9.30048013,7.85569694 L16.3178061,6.58860302 L7.49535305,0.755586443 L7.11537515,1.69176324 C6.93446908,1.97534335 6.62635858,2.11527705 6.34529781,1.9292881 C6.06591659,1.74586269 5.98476742,1.37773009 6.17031438,1.09454777 L6.17031438,1.09454777 Z M8.98901051,3.74033782 C9.9814083,3.74033782 10.7848558,4.53282401 10.7848558,5.51001738 C10.7848558,6.49264722 9.97968455,7.28765274 8.98901051,7.28769694 C7.99785029,7.28769694 7.19440278,6.49264722 7.19440278,5.51001738 C7.19440278,4.53238202 7.99789449,3.74033782 8.98901051,3.74033782 Z" id="icon" fill="#102526"/>
			</g>
		</svg>
	)

export default IcGolf
