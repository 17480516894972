/* eslint-disable max-len */
/**
 * Map Component
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 */

import React, { createRef, Component } from 'react'
import styled from 'styled-components'

import {
	Map as LeafletMap,
	Marker,
	TileLayer,
	LayersControl,
	Popup,
	FeatureGroup,
	withLeaflet
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { ReactLeafletSearch } from 'react-leaflet-search'

import 'react-leaflet-markercluster/dist/styles.min.css'

const { BaseLayer } = LayersControl

export default class Map extends Component {

	constructor (props) {
		super(props)

		this.mapRef = createRef()
		this.groupRef = createRef()

		this.state = {
			layers: [
				{
					name: 'Google Maps',
					attribuition: 'Map data &copy; <a href="https://www.google.com/">Google</a>',
					url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
					subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
				},
				{
					name: 'OTM',
					attribuition: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>\n'
                         + 'contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">\n'
                         + 'Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/"target="_blank">OpenStreetMap France</a>',
					url: 'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
					subdomains: []
				},
				{
					name: 'OSM',
					attribuition: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors,\n'
                         +'<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
					url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
					subdomains: []
				}
			],
			zoom: 6
		}
	}

	// TO-DO: improve this (maybe search/develop another package)

	// The package we using (ReactLeafletSearch) only expose the coordinates
	// of the place we search for inside this method

	// This method let us insert a popup on a marker inserted by the package

	// Currently we are ignoring the marker inserted 'automagically' by the package
	// and we are setting a marker ourselves at the getCoordinates() callback
	// so that we have more control over the markers (this marker is now fully customizable @ line ~130)

	// By 'have more control' we mean: be able to pass a prop 'draggable' to the marker
	// so that we can choose the location with more precision

searchMarkersPopUp = (SearchInfo) => {
	const currentMap = this.mapRef.current
	const map = currentMap.leafletElement
	// this is fixing the 'Cannot update during an existing state react' error
	setTimeout(() => {
		this.setState({ zoom: map.getZoom() })
		this.props.getCoordinates({lat: SearchInfo.latLng.lat, lng: SearchInfo.latLng.lng})
	}, 300)
}

handleMarkerAfterDrag = (event) => {
	const currentMap = this.mapRef.current
	const map = currentMap.leafletElement
	this.setState({ zoom: map.getZoom() })
	// get updated marker LatLng
	const latLng = event.target.getLatLng()
	// update POIS location input
	this.props.getCoordinates(latLng, map.getZoom())
}

componentDidUpdate (prevProps) {
	const {
		markers,
		markersFetchingIsOver,
		withSearch
	} = this.props

	const currentMap = this.mapRef.current
	const map = currentMap.leafletElement
	
	// Fit to bounds after markers position fetching process is over
	if (markers.length && markersFetchingIsOver !== prevProps.markersFetchingIsOver) {
		const group = this.groupRef.current.leafletElement
		map.fitBounds(group.getBounds())
	}

	// Map With Search Box (associated with a text input for the place's coordinates searched)
	// zoom/navigate to marker, after marker updating by changing coordinates value @ input
	if (withSearch && markers.length) {
		if ((markers[0] && markers[0].position) !== (prevProps.markers[0] && prevProps.markers[0].position)) {
			
			if (isNaN(markers[0].position[0]) || isNaN(markers[0].position[1])) {
				return
			}
			
			map.setView(markers[0].position, map.getZoom())
		}
		// whenever there's no markers just reset the map view
	} else if (withSearch && !markers.length) {
		map.setView([39.557, -6.20], map.getZoom())
	}
}

render () {
	const {
		centerPosition,
		markers,
		withSearch,
		resumeHomologationProcess,
		navigate
	} = this.props

	const WrappedSearch = withLeaflet(ReactLeafletSearch)
	const { layers, zoom } = this.state

	return (
		<LeafletMap
			scrollWheelZoom
			center={centerPosition}
			zoom={zoom}
			maxZoom={18}
			ref={this.mapRef}
		>
			{withSearch &&
				<WrappedSearch
					position='topright'
					popUp={this.searchMarkersPopUp}
					closeResultsOnClick
					inputPlaceholder={'Pesquise aqui'}
				/>
			}
			<LayersControl position='topright'>
				{layers.map((layer, index) => (
					<BaseLayer
						key={index}
						checked={index === 0}
						name={layer.name}
					>
						{!!layer.subdomains.length &&
							<TileLayer
								attribution={layer.attribuition}
								url={layer.url}
								subdomains={layer.subdomains}
							/>
						}
						{!layer.subdomains.length &&
							<TileLayer
								attribution={layer.attribuition}
								url={layer.url}
							/>
						}
					</BaseLayer>
				))}
			</LayersControl>

			<FeatureGroup ref={this.groupRef}>
				<MarkerClusterGroup
					showCoverageOnHover={false}
					spiderfyDistanceMultiplier={2}
				>
					{markers && !!markers.length && markers.map((marker, index) => {
						return (
							<Marker
								//icon={Icon}
								key={index}
								position={marker.position}
								draggable={withSearch}
								onDragend={this.handleMarkerAfterDrag}
							>
								{marker.name && marker.id &&
									<Popup>
										<PopupContent>
											<PopupTitle>
												{marker.name}
											</PopupTitle>
											{resumeHomologationProcess &&
											<PopupGoTo onClick={() => resumeHomologationProcess({ id: marker.id })}>
												{'Ir para processo'}
											</PopupGoTo>
											}
											{navigate &&
											<PopupGoTo onClick={() => navigate(`/percursos/detalhes/${marker.id}`)}>
												{'Ir para detalhe'}
											</PopupGoTo>
											}
										</PopupContent>
									</Popup>
								}
							</Marker>
						)
					})
					}
				</MarkerClusterGroup>
			</FeatureGroup>
		</LeafletMap>
	)
}
}

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
`

const PopupTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
`

const PopupGoTo = styled.a`
  color: #000 !important;
  font-size: 12px;

  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
`