import { kea } from 'kea'

import ToasterLogic from 'containers/Toaster/logic'
import AppLogic from 'containers/App/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'

import Proptypes from 'prop-types'
import { STEPS } from './utils'
import { call, put } from 'redux-saga/effects'
import API from 'api'
import { isNumber } from 'validations'

export default kea({
	path: () => ['scenes', 'CampsiteManagementRequest'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			],
			CampsiteManagementLogic,  [
				'setCampsite',
				'setCampsiteId'
			]
		],
		props: [
			AppLogic, [
				'currentRoute'
			],
			CampsiteManagementLogic, [
				'campsite',
				'campsiteId',
				'campsites'
			]
		]
	},
	actions: () => ({
		reset: () => true,
		setSteps: steps => steps,
		updateStep: payload => payload,
		updatecanSubmitRegistration: canSubmit => canSubmit,
		setCurrent: current => current,
		getCampsite: () => true,
	}),

	reducers: ({actions}) => ({
		steps: [STEPS, Proptypes.array, {
			[actions.setSteps]: (state, steps) => steps,
			[actions.updateStep]: (state, {current, data}) => {
				const index = current - 1
				const newSteps = [...state]
				newSteps[index] = {...newSteps[index], ...data}
				return newSteps
			},
			[actions.reset]: () => STEPS
		}],
		canSubmitRegistration: [false, Proptypes.bool, {
			[actions.setSteps]: (state, steps) => !steps.some(e => e.status !== 'complete'),
			[actions.updatecanSubmitRegistration]: (state, canSubmit) => canSubmit,
			//[actions.updateStep]: (state, {current, data}) => {
				
			//	console.log(values.current)
			//	const steps = []
			//	const index = current - 1
			//	const newSteps = [...steps]
			//	newSteps[index] = {...newSteps[index], ...data}
				
			//	const newCanSubmit = !newSteps.some(e => e.status !== 'complete')

			//	return newCanSubmit
			//},
			[actions.reset]: () => false
		}],
		current: [1, Proptypes.number, {
			[actions.setCurrent]: (state, current) => current,
			[actions.reset]: () => 1
		}],
	}),

	start: function * () {

		const {getCampsite} = this.actions

		yield put(getCampsite())
	},

	takeLatest: ({actions, workers}) => ({
		[actions.getCampsite]: workers.getCampsite,
		[actions.updateStep]: workers.updatecanSubmitRegistration
	}),

	workers: {

		* updatecanSubmitRegistration () {

			const { updatecanSubmitRegistration } = this.actions

			const steps = yield this.get('steps')
			const canSubmitRegistration = yield this.get('canSubmitRegistration')

			const newCanSubmit = !steps.some(e => e.status !== 'complete')

			if (canSubmitRegistration !== newCanSubmit) yield put(updatecanSubmitRegistration(newCanSubmit))

		},

		* getCampsite () {

			const {
				setCampsite,
				setCampsiteId,
				showToaster,
				setSteps,
				navigate
			} = this.actions
			
			const { t } = this.props
			const {params: {id}} = yield this.get('currentRoute')
			const steps = yield this.get('steps')
			
			if (!isNumber(id).result) {
				yield put(navigate('/parques-campismo'))
				return
			}

			yield put(setCampsiteId(parseInt(id)))

			try {
				
				const campsite = yield call(API.Campsites.getById, id)
				yield put(setCampsite(campsite))

				const { steps: resSteps } = yield call(API.Campsites.getAllStepsById, id)
				resSteps.push({status: 'status'})

				const resolvedSteps = steps.map((step, index) => ({...step, status: resSteps[index].status}))
				yield put(setSteps(resolvedSteps))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.process_start_error')
				))
			}

		}
	}
})
