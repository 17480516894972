import * as Check from 'validations'

const DEFAULT_CONTACT_VALUES = {
	name: '',
	contact: ''
}

const DEFAULT_VALUES = {
	contacts: {
		value: [{...DEFAULT_CONTACT_VALUES}]
	}
}

const VALIDATIONS = {
	contacts: [
		contacts => {
			
			const messages = {}
			let hasErrors = false

			for (let i = 0; i < contacts.length; i++) {

				messages[i] = {}
				const { contact, name } = contacts[i]
				
				const contact_required = Check.isRequired(contact)
				const name_required = Check.isRequired(name)

				if (!name_required.result) {
					hasErrors = true
					messages[i].name = name_required.message
				}
				
				if (!contact_required.result) {
					hasErrors = true
					messages[i].contact = contact_required.message
				}
				else {
					const validPhone = Check.isValidPhoneNumber(contact)
					
					if (!validPhone.result) {
						hasErrors = true
						messages[i].contact = validPhone.message
					}
				}

			}
			
			if (hasErrors) return {result: false, message: messages}

			return { result: true, message: undefined }
		}
	],
}

const VALIDATIONS_NO_REQUIRED = {
	contacts: [
		contacts => {
			
			const messages = {}
			let hasErrors = false

			for (let i = 0; i < contacts.length; i++) {

				messages[i] = {}
				const { contact } = contacts[i]
				
				const validPhone = Check.isValidPhoneNumber(contact)
				
				if (!validPhone.result) {
					hasErrors = true
					messages[i].contact = validPhone.message
				}
			
			}
			
			if (hasErrors) return {result: false, message: messages}

			return { result: true, message: undefined }
		}
	],
}

const getResolvedOptions = t => [
	{
		name: 'name',
		required: true,
		label: t('form.contact_name')
	},
	{
		name: 'contact',
		required: true,
		label: t('form.contact')
	}
]

export {
	DEFAULT_VALUES,
	DEFAULT_CONTACT_VALUES,
	getResolvedOptions,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}