import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import { Segment, Loader } from 'semantic-ui-react'
/** Components */
import Title from 'components/Title'
/** Container */
//import CampsiteSubmit from 'containers/Modals/CampsiteSubmit'
import FormNavigation from 'containers/FormNavigation/CampsiteManagement'
import CampsiteRequestForm from 'containers/CampsiteManagementRequestForm'

class CampsiteManagementRequest extends Component {

	render () {
		const { 
			t,
			current,
			loading,
			//campsiteId,
			campsite,
		} = this.props

		const { summary: { designation: campsiteName } } = campsite || { summary: {} }

		return (
			<Wrapper>

				<FormContainer>
					<FormNavigation />
				</FormContainer>

				<FormWrapper>
					{campsite && !loading && 
					<Title>
						{campsiteName || t('campsite_details.new_campsite')}
					</Title>
					}
					<Loader active={!campsite && !loading} size='large' />
          
					{campsite && !loading && (
						<CampsiteRequestForm
							campsite={campsite}
							current={current}
							loading={loading}
						/>
					)}
				</FormWrapper>

			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(CampsiteManagementRequest))

const Wrapper = styled(Segment)`
	&&&& {
	  display: flex;
	  border: none !important;
		box-shadow: none !important;
		background: transparent;
	}
`

const FormContainer = styled.div`
  flex: 0.3;
  min-width: 30%;
  @media screen and (max-width: 1240px){
    flex: 0.45;
  }
`

const FormWrapper = styled.div`
  flex: 0.7;
  min-width: 70%;
  position: relative;
  @media screen and (max-width: 1240px){
    flex: 0.55;
  }
`