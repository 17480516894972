/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const Download = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M16.3333333,18 C16.3333333,17.6354167 16.03125,17.3333333 15.6666667,17.3333333 C15.3020833,17.3333333 15,17.6354167 15,18 C15,18.3645833 15.3020833,18.6666667 15.6666667,18.6666667 C16.03125,18.6666667 16.3333333,18.3645833 16.3333333,18 Z M19,18 C19,17.6354167 18.6979167,17.3333333 18.3333333,17.3333333 C17.96875,17.3333333 17.6666667,17.6354167 17.6666667,18 C17.6666667,18.3645833 17.96875,18.6666667 18.3333333,18.6666667 C18.6979167,18.6666667 19,18.3645833 19,18 Z M20.3333333,15.6666667 L20.3333333,19 C20.3333333,19.5520833 19.8854167,20 19.3333333,20 L4,20 C3.44791667,20 3,19.5520833 3,19 L3,15.6666667 C3,15.1145833 3.44791667,14.6666667 4,14.6666667 L8.84375,14.6666667 L10.25,16.0833333 C10.6354167,16.4583333 11.1354167,16.6666667 11.6666667,16.6666667 C12.1979167,16.6666667 12.6979167,16.4583333 13.0833333,16.0833333 L14.5,14.6666667 L19.3333333,14.6666667 C19.8854167,14.6666667 20.3333333,15.1145833 20.3333333,15.6666667 Z M16.9479167,9.73958333 C17.0520833,9.98958333 17,10.28125 16.8020833,10.46875 L12.1354167,15.1354167 C12.0104167,15.2708333 11.8333333,15.3333333 11.6666667,15.3333333 C11.5,15.3333333 11.3229167,15.2708333 11.1979167,15.1354167 L6.53125,10.46875 C6.33333333,10.28125 6.28125,9.98958333 6.38541667,9.73958333 C6.48958333,9.5 6.72916667,9.33333333 7,9.33333333 L9.66666667,9.33333333 L9.66666667,4.66666667 C9.66666667,4.30208333 9.96875,4 10.3333333,4 L13,4 C13.3645833,4 13.6666667,4.30208333 13.6666667,4.66666667 L13.6666667,9.33333333 L16.3333333,9.33333333 C16.6041667,9.33333333 16.84375,9.5 16.9479167,9.73958333 Z" />
		</g>
	</svg>
)

Download.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

Download.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

export default Download
