/* eslint react/prop-types: 0 */
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import {
	Form,
	Radio,
	Input as SemanticInput, 
	TextArea as SemanticTextArea,
} from 'semantic-ui-react'

import Label from 'components/Label'
import FormField from 'components/FormField'
import FormError from 'components/FormError'
import FormGroup from 'components/FormGroup'
import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'

import FileUploader from 'containers/FileUploader'
import Buttons from 'containers/ServiceAreaRequestForm/Buttons'

import { getFilenameOnFullPath } from 'utils'
import { ENDPOINTS } from 'api'

class Documents extends Component {
	render () {
		const { loading, t, form, dirty } = this.props
		const { changeForm, patchForm } = this.actions

		const {
			facilitiesRegulationDocument,
			cityCouncilPermissionDocument,
			motorhomeServiceAreaBlueprint,
			hasPriceMenu,
			priceMenuDescription,
			priceMenu,
			otherDocumentName,
			otherDescription,
			otherDocument
		} = form

		return (
			<>
				<BoxSegment loading= {loading}>
					<Form>
						<SegmentTitle>
							{t('form.documents')}
						</SegmentTitle>
						<HorizontalLine />

						<SegmentTitle
							margin={'30px 0'}
						>
							{t('form.facilities_regulations')}
						</SegmentTitle>

						<DocumentWrapper>
							<FormField required>
								<Label
									color={'#616161'}
								>
									{t('form.upload_pdf')}
								</Label>

								<FileUploader
									single
									id={'FacilitiesRegulationDocument'}
									limit={1}
									value={
									// eslint-disable-next-line max-len
										(facilitiesRegulationDocument.value && facilitiesRegulationDocument.value.url) &&
										// eslint-disable-next-line max-len
                      [{name: getFilenameOnFullPath(facilitiesRegulationDocument.value.url)}]
									}
									accept={'application/pdf'}
									uploadEndpoint={ENDPOINTS.MEDIA}
									onChange={file => changeForm({
									// eslint-disable-next-line max-len
										facilitiesRegulationDocument: file[0]
									})}
									onRemove={() => changeForm({
										facilitiesRegulationDocument: null
									})}
								/>
								{/* eslint-disable-next-line max-len*/}
								{(facilitiesRegulationDocument.dirty && !facilitiesRegulationDocument.valid) && (
									<FormError>
										{/* eslint-disable-next-line max-len*/}
										{facilitiesRegulationDocument.message}
									</FormError>
								)}
							</FormField>
						</DocumentWrapper>
						
						<SegmentTitle
							margin={'30px 0'}
						>
							{/* eslint-disable-next-line max-len*/}
							{t('form.council_city_permission_document')}
						</SegmentTitle>

						<DocumentWrapper>
							<FormField required>
								<Label
									color={'#616161'}
								>
									{t('form.upload_pdf')}
								</Label>

								<FileUploader
									id={'CityCouncilPermissionDocument'}
									single
									limit={1}
									value={
									//eslint-disable-next-line max-len 
										cityCouncilPermissionDocument.value && cityCouncilPermissionDocument.value.url &&
                      // eslint-disable-next-line max-len
                      [{name: getFilenameOnFullPath(cityCouncilPermissionDocument.value.url) }]
									}
									accept={'application/pdf'}
									uploadEndpoint={ENDPOINTS.MEDIA}
									onChange={
									// eslint-disable-next-line max-len
										(file) => changeForm({ cityCouncilPermissionDocument: file[0] })
									}
									onRemove={
									// eslint-disable-next-line max-len
										() => changeForm({ cityCouncilPermissionDocument: null })
									}
								/>
								{/* eslint-disable-next-line max-len*/}
								{cityCouncilPermissionDocument.dirty && !cityCouncilPermissionDocument.valid && (
									<FormError>
										{/* eslint-disable-next-line max-len*/}
										{cityCouncilPermissionDocument.message}
									</FormError>
								)}           
							</FormField>
						</DocumentWrapper>
						
						<SegmentTitle
							margin={'30px 0'}
						>
							{/* eslint-disable-next-line max-len*/}
							{t('form.autocaravan_area_service_blueprint')}
						</SegmentTitle>

						<DocumentWrapper>
							<FormField required>
								<Label
									color={'#616161'}
								>
									{t('form.upload_pdf')}
								</Label>

								<FileUploader
									id={'MotorhomeServiceAreaBlueprint'}
									single
									limit={1}
									value={
									// eslint-disable-next-line max-len
										motorhomeServiceAreaBlueprint.value && motorhomeServiceAreaBlueprint.value.url &&
                      // eslint-disable-next-line max-len
                      [{ name: getFilenameOnFullPath(motorhomeServiceAreaBlueprint.value.url) }]
									}
									accept={'application/pdf'}
									uploadEndpoint={ENDPOINTS.MEDIA}
									onChange={
									// eslint-disable-next-line max-len
										(file) => changeForm({ motorhomeServiceAreaBlueprint: file[0] })
									}
									onRemove={
									// eslint-disable-next-line max-len
										() => changeForm({ motorhomeServiceAreaBlueprint: null })
									}
								/>
									
								{/* eslint-disable-next-line max-len*/}
								{motorhomeServiceAreaBlueprint.dirty && !motorhomeServiceAreaBlueprint.valid && (
									<FormError>
										{/* eslint-disable-next-line max-len*/}
										{motorhomeServiceAreaBlueprint.message}
									</FormError>
								)}    
							</FormField>
						</DocumentWrapper>
            
						<SegmentTitle
							margin={'30px 0'}
						>
							{t('form.price_menu')}
						</SegmentTitle>
						<FormField required>
							<Label>
								{t('form.has_price_menu')}
							</Label>
							<FormGroup inline>
								<FormField
									label={t('options.no')}
									name='hasPriceMenu_no'
									checked={!hasPriceMenu.value}
									control={Radio}
									onChange={() => changeForm(
										{ hasPriceMenu: false,
												priceMenuDescription: '',
												priceMenu: ''
										}
									)}
								/>
								<FormField
									label={t('options.yes')}
									name='hasPriceMenu_yes'
									checked={hasPriceMenu.value}
									control={Radio}
									onChange={() => changeForm(
										{ hasPriceMenu: true })
									}
								/>
							</FormGroup>
						</FormField>

						{hasPriceMenu.value && (
							<>
								<FormField
									name={'description'}
									label={t('form.description')}
									//valid={priceMenuDescription.dirty && priceMenuDescription.valid}
									//error={priceMenuDescription.dirty && !priceMenuDescription.valid}
									control={TextArea}
									value={priceMenuDescription.value}
									onChange={
										// eslint-disable-next-line max-len
										(e, { value }) => changeForm({ priceMenuDescription: value })
									}
								/>
								<DocumentWrapper>
									<FormField required>
										<Label
											color={'#616161'}
										>
											{t('form.upload_pdf')}
										</Label>

										<FileUploader
											id={'PriceMenu'}
											single
											limit={1}
											// eslint-disable-next-line max-len
											value={priceMenu.value && priceMenu.value.url && [{ name: getFilenameOnFullPath(priceMenu.value.url) }]}
											accept={'application/pdf'}
											uploadEndpoint={ENDPOINTS.MEDIA}
											onChange={
												// eslint-disable-next-line max-len
												file => changeForm({ priceMenu: file[0] })
											}
											onRemove={
												// eslint-disable-next-line max-len
												() => changeForm({ priceMenu: null })
											}
										/>

										{(priceMenu.dirty && !priceMenu.valid) && (
											<FormError>
												{priceMenu.message}
											</FormError>
										)}
									</FormField>
								</DocumentWrapper>
							</>
						)}

						<SegmentTitle
							margin={'30px 0'}
						>
							{t('form.other_documents')}
						</SegmentTitle>

						<FormField
							label={t('form.document_name')}
							name='document_name'
							control={Input}
							value={otherDocumentName.value}
							onChange={
								// eslint-disable-next-line max-len
								(e, { value }) => changeForm({ otherDocumentName: value })
							}
						/>

						<FormField
							name={'description'}
							label={t('form.description')}
							control={TextArea}
							value={otherDescription.value}
							onChange={
								// eslint-disable-next-line max-len
								(e, { value }) => changeForm({ otherDescription: value })
							}
						/>

						<DocumentWrapper>
							<FormField>
								<Label
									color={'#616161'}
								>
									{t('form.upload_pdf')}
								</Label>

								<FileUploader
									id={'OtherDocument'}
									single
									limit={1}
									value={otherDocument.value && 
                      otherDocument.value.url &&
                      [{ name: getFilenameOnFullPath(otherDocument.value.url) }]
									}
									accept={'application/pdf'}
									uploadEndpoint={ENDPOINTS.MEDIA}
									onChange={
									// eslint-disable-next-line max-len
										file => changeForm({ otherDocument: file[0] })
									}
									onRemove={
									// eslint-disable-next-line max-len
										() => changeForm({ otherDocument: null })
									}
								/>

								{/* eslint-disable-next-line max-len */}
								{(otherDocument.dirty && !otherDocument.valid) && (
									<FormError>
										{otherDocument.message}
									</FormError>
								)}
							</FormField>
						</DocumentWrapper>
					</Form>
				</BoxSegment>
				
				<Buttons
					previousButtonClick={
						() => patchForm('previous')
					}
					nextButtonClick={() => patchForm('next')}
					saveButtonClick={() => patchForm()}
					dirty={dirty}
				/>
			</>
		)
	}
}

export default withTranslation()(Logic(Documents))

const Input = styled(SemanticInput)`
  width: 100%;
`

const TextArea = styled(SemanticTextArea)`
  width: 100%;
  border: 1px solid #DDDDDD;
`

const HorizontalLine = styled.hr`
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #F3F3F3;
  margin: 16px 0;
`

const DocumentWrapper = styled.div`
	background-color: #FAFAFA;
	padding: 24px;
`