import * as Check from 'validations'

const DEFAULT_VALUES = {
	designation: {
		value: ''
	},
	rnet: {
		value: ''
	},
	description: {
		value: ''
	},
	fullAddress: {
		value: ''
	},
	gpsCoordinates: {
		value: ''
	},
	postalCode: {
		value: ''
	},
	locality: {
		value: ''
	},
	district: {
		value: ''
	},
	municipality: {
		value: ''
	},
	website: {
		value: ''
	},
	email: {
		value: ''
	},
	telephone: {
		value: ''
	},
	mobile: {
		value: ''
	},
	facebook: {
		value: ''
	},
	instagram: {
		value: ''
	},
	twitter: {
		value: ''
	},
	tripadvisor: {
		value: ''
	}
}

const VALIDATIONS = {
	designation: [
		Check.isRequired
	],
	description: [
		Check.isRequired,
	],
	rnet: [
		Check.isRequired,
		Check.isNumber
	],
	fullAddress: [
		Check.isRequired,
	],
	gpsCoordinates: [
		Check.isRequired,
		Check.isGpsCoordinates
	],
	postalCode: [
		Check.isRequired,
		Check.isValidPostalCode
	],
	locality: [
		Check.isRequired,
		(value) => Check.hasMaxSize(value, 200)
	],
	district: [
		Check.isRequired,
		Check.isNumber
	],
	municipality: [
		Check.isRequired,
		Check.isNumber
	],
	website: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	email: [
		Check.isRequired,
		Check.Email,
		(value) => Check.hasMaxSize(value, 254)
	],
	telephone: [
		Check.isValidPhoneNumber
	],
	mobile: [
		Check.isRequired,
		Check.isValidPhoneNumber
	],
	facebook: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	instagram: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	twitter: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	tripadvisor: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	]
}

const VALIDATIONS_NO_REQUIRED = {
	rnet: [
		Check.isNumber
	],
	postalCode: [
		Check.isValidPostalCode
	],
	locality: [
		(value) => Check.hasMaxSize(value, 200)
	],
	district: [
		Check.isNumber
	],
	gpsCoordinates: [
		Check.isGpsCoordinates
	],
	municipality: [
		Check.isNumber
	],
	website: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	email: [
		Check.Email,
		(value) => Check.hasMaxSize(value, 254)
	],
	telephone: [
		Check.isValidPhoneNumber
	],
	mobile: [
		Check.isValidPhoneNumber
	],
	facebook: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	instagram: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	twitter: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	],
	tripadvisor: [
		Check.isValidWebsite,
		(value) => Check.hasMaxSize(value, 250)
	]
}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}