import axios from 'axios'
import ENDPOINTS from '../endpoints'
import { generateUrl } from 'utils'

const get = params => axios.get(ENDPOINTS.MUNICIPALITIES, params)
	.then(res => res.data)
	.catch(err => { throw err })

const byPostalCode = params => axios.get(ENDPOINTS.POSTALCODE, params)
	.then(res => res.data)
	.catch(err => { throw err })

const getCountries = params => axios.get(ENDPOINTS.COUNTRIES, params)
	.then(res => res.data)
	.catch(err => { throw err })

const getCountriesById = id => {
	const endpoint = generateUrl(ENDPOINTS.COUNTRIESID, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

export default {
	get,
	byPostalCode,
	getCountries,
	getCountriesById
}