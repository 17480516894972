// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
	Grid,
	Message,
	Dimmer,
	Loader,
	Form,
	FormGroup,
	Input,
	Dropdown as SemanticDropdown
} from 'semantic-ui-react'
import { DatesRangeInput } from 'semantic-ui-calendar-react'

import Button from 'components/Button'
import FormField from 'components/FormField'
import Segment from 'components/Segment'
import Logic from './logic'
import Title from 'components/Title'
import SegmentTitle from 'components/SegmentTitle'

type Props = {

}

class BookingUnavailabilityRule extends Component<Props> {

static defaultProps = {}

render () {
	const {
		errors,
		t,
		loading,
		form,
		isNewRule,
		dirty,
		spotOptions
	} = this.props
	const { changeForm, fetchForm, createRule, saveForm } = this.actions
	const {
		name,
		timeInterval,
		spots
	} = form || {}

	return (
		<Wrapper>
			<Grid>
				<Grid.Row>
					<Title>
						{isNewRule
							? t('booking_unavailability_rule.title')
							: name.value
						}
					</Title>
				</Grid.Row>

				{errors && !!errors.length &&
					<Message
						name={'errorMessage'}
						error
						header={'Corrija os seus erros antes de prosseguir'}
						list={errors}
					/>
				}
				<Grid.Row>
					<Segment>
						<SegmentTitle
							margin={'0 0 26px 0'}
						>
							{/* eslint-disable-next-line max-len */}
							{t('booking_unavailability_rule.unavailability_settings')}
						</SegmentTitle>
						{loading && 
							<Dimmer inverted active>
								<Loader inverted/>
							</Dimmer>
						}
						{!loading &&
							<Form>
								<FormGroup widths='equal'>
									<FormField
										style={{ width: '100%' }}
										value={name.value}
										required
										// eslint-disable-next-line max-len
										label={t('booking_unavailability_rule.name')}
										control={Input}
										onChange={(e, {value}) => 
											changeForm({ name: value })
										}
										// eslint-disable-next-line max-len
										error={name.dirty && !name.valid && name.message }
									/>
									<FormField
										required
										name={'spots'}
										control={Dropdown}
										// eslint-disable-next-line max-len
										label={t('booking_unavailability_rule.spots')}
										// eslint-disable-next-line max-len
										placeholder={t('booking_unavailability_rule.spots_placeholder')}
										// eslint-disable-next-line max-len
										onChange={(e, data) => changeForm({ spots: data.value })}
										options={spotOptions}
										value={spots.value}
										selection
										multiple
										// eslint-disable-next-line max-len
										error={(spots.dirty && !spots.valid) && spots.message}
									/>
                  
								</FormGroup>
								<FormField
									width={8}
									value={timeInterval.value}
									required
									placeholder="De - a"
									iconPosition="left"
									popupPosition={'bottom left'}
									// eslint-disable-next-line max-len
									label={t('booking_unavailability_rule.time_interval')}
									control={DatesRangeInput}
									animation='none'
									onChange={(e, {value}) => 
										changeForm({ timeInterval: value })
									}
									// eslint-disable-next-line max-len
									error={timeInterval.dirty && !timeInterval.valid && timeInterval.message }
								/>
							</Form>
						}
					</Segment>
				</Grid.Row>
				<Grid.Row columns={2}>
					<Grid.Column>
						{/* eslint-disable-next-line max-len */}
						{!isNewRule &&
						<Button
							secondary
							onClick={fetchForm}
							disabled={!dirty || loading}
						>
							{t('booking_unavailability_rule.cancel')}
						</Button>
						}
					</Grid.Column>
					<Grid.Column textAlign={'right'}>
						<Button
							primary
							disabled={!dirty || loading}
							style={{ display: 'inline-block' }}
							onClick={isNewRule
								? createRule
								: saveForm
							}
						>
							{t('booking_unavailability_rule.save')}
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Wrapper>
	)
}
}

export default withTranslation()(Logic(BookingUnavailabilityRule))

const Wrapper = styled.div`
`

const Dropdown = styled(SemanticDropdown)`
  width: 100%;
`