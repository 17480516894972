import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import moment from 'moment'
import Proptypes from 'prop-types'
import API from 'api'
import { getUnavailableFilterDates, scheduleAreaOptions } from './utils'

/** Logic */
import ToasterLogic from 'containers/Toaster/logic'
import UsageReservationDetails from 'screens/UsageReservationDetails/logic'
import UsageReservationLogic  from 'screens/UsageReservation/logic'

export default kea({
	path: () => ['scenes', 'containers', 'UsageDetailsCharacteristics'],

	connect: {
		props: [
			UsageReservationDetails, [
				'loading',
				'form',
				'selectedServiceArea'
			],
			UsageReservationLogic, [
				'serviceAreas'
			]
		],
		actions : [
			ToasterLogic, [
				'show as showToaster'
			],
			UsageReservationDetails, [
				'changeEditForm',
				'changeForm',
				'setSelectedServiceArea'
			],
		]
	},

	// Obter os Lugares e dates indesponiveis desse lugares
	actions: () => ({
		//getUnavailCleaningDates : () => true,
		setUnavailableCleaningDates: dates => ({ dates }),
		
		getUnavailableDates : () => true,
		setUnavailableDates: dates => ({ dates }),

		setSelectCleaningDate: date => date,
		setSheduleCleaning: shedule => ({ shedule }),

		reset: () => true,

		refreshUnavailableDates: date => date,
		setUnavailableDatesFetched: dates => dates
	}),

	reducers: ({ actions }) => ({

		unavailableDatesFetched: [[{}, Proptypes.object], {
			[actions.setUnavailableDatesFetched]: (state, payload) => payload
		}],

		unavailabilityDates: [[], Proptypes.array, {
			[actions.setUnavailableCleaningDates] : (state, payload) => payload.dates,
			[actions.reset]: () => []
		}],

		scheduleAvailability: [[], Proptypes.array, {
			[actions.setSheduleCleaning] : (state, payload) => scheduleAreaOptions(payload.shedule),
			[actions.setSelectCleaningDate]: () => [],
			[actions.reset]: () => []
		}],
	}),

	takeLatest: ({ actions, workers }) => ({
		[actions.refreshUnavailableDates] : workers.getUnavailCleaningDates,
		[actions.setSelectedServiceArea] : workers.getCleaningSchedules,
		[actions.setSelectCleaningDate]: workers.getCleaningSchedules,
	}),

	workers: {
		* getUnavailCleaningDates ({payload}) {
			
			const { setUnavailableCleaningDates, setUnavailableDatesFetched } = this.actions
			const selectedServiceArea = yield this.get('selectedServiceArea')
			const dates = yield this.get('unavailableDatesFetched')
			const unavailabilityDates = yield this.get('unavailabilityDates')

			let filterDates = []
			
			try {
				
				if (!dates[selectedServiceArea]) dates[selectedServiceArea] = {}

				filterDates = getUnavailableFilterDates(payload, dates[selectedServiceArea])

				if (!filterDates.length) return

				for (const filterDate of filterDates) {
					dates[selectedServiceArea][filterDate.format('MM-YYYY')] = true
				}

				yield put (setUnavailableDatesFetched(
					dates
				))

				const now = moment()

				const start = filterDates[0]

				const params = {
					start_date: (start.isSameOrBefore(now) ? now : start).format('YYYY-MM-DD'),
					end_date: filterDates[filterDates.length - 1].endOf('month').format('YYYY-MM-DD')
				}
				
				const response = yield call(API.Reservations.getAreaUnavailCleaning, selectedServiceArea, params)
				//const response2 = yield call(API.Reservations.getAreaUnavailableDates, selectedServiceArea, params)

				const result = response //[...response2, ...response]

				// transform to array moment
				const momentResponse = result.map(item => !!item && moment(item))

				yield put(setUnavailableCleaningDates([...unavailabilityDates, ...momentResponse]))
			} catch (e) {
				for (const filterDate of filterDates) {
					delete dates[selectedServiceArea][filterDate.format('MM-YYYY')]
				}
				yield put (setUnavailableDatesFetched(
					dates
				))
				console.log(e)
			}
		},

		* getCleaningSchedules () {
			const { setSheduleCleaning, showToaster } = this.actions
			const selectedServiceArea = yield this.get('selectedServiceArea')
			const form = yield this.get('form')
			const dateSelect = form.date.value

			let params  = {
				date: dateSelect
			}

			try {
				// verify is same or after day esle doesn't request
				if (moment(dateSelect, 'YYYY-MM-DD').endOf('day').isSameOrAfter(moment())) {
					const response = yield call(API.Reservations.getAreaAvailCleaningSchedule, selectedServiceArea, params)

					if (response.length > 0) {
						yield put(setSheduleCleaning(response))
					} else {
						//tradução
						yield put(showToaster(
							'error',
							'Erro ao obter período',
							'Tentar outro dia ou asa'
						))
					}
				}
			} catch (e) {
				console.log(e)
			}
		}

	}
})
