import moment from 'moment'
import * as Check from 'validations'

const DEFAULT_VALUES = {
	name: {
		value: ''
	},
	timeInterval: {
		value: `${moment().format('DD-MM-YYYY')} - ${moment().add(1, 'day').format('DD-MM-YYYY')}`
	},
	spotPrice: {
		value: 0
	},
	cleaningPrice: {
		value: 0
	},
	//guestPrice: {
	//	value: 0
	//},
	electricitySupply: {
		value: 'unavailable'
	},
	electricityPrice: {
		value: null
	},
	potableWaterSupply: {
		value: 'unavailable'
	},
	waterPrice: {
		value: null
	}
}

const VALIDATIONS = {
	name: [
		Check.isRequired
	],
	timeInterval: [
		Check.isRequired,
		Check.hasDateIntervalFilled
	],
	spotPrice: [
		Check.isMonetaryNumber
	],
	cleaningPrice: [
		Check.isMonetaryNumber
	],
	electricityPrice: [
		Check.isMonetaryNumber
	],
	waterPrice: [
		Check.isMonetaryNumber
	]
	//guestPrice: [
	//	Check.isNumber
	//]
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}