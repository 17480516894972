//import * as Check from 'validations'

const DEFAULT_VALUES = {
	sportAndLeisures: {
		value: []
	}
}

const VALIDATIONS = {
	
}

const VALIDATIONS_NO_REQUIRED = {
}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}