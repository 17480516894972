let API_URL = ''
let MEDIA_URL = ''

switch (process.env.FCMP_ENV) {
	case 'mock': 
		API_URL = '/api'
		MEDIA_URL = '/api'
		break
	case 'development': 
		API_URL = process.env.API_ENDPOINT_DEV
		MEDIA_URL = process.env.API_ENDPOINT_DEV
		break
	case 'production': 
		API_URL = process.env.API_ENDPOINT_PROD
		MEDIA_URL = process.env.API_ENDPOINT_PROD
		break
	default: break
}

console.log(process.env.FCMP_ENV, API_URL, MEDIA_URL)

export default {
	BASE: API_URL,
	MEDIA: `${MEDIA_URL}/files/`,
	AREAS: `${API_URL}/service-areas/`,
	MYAREAS: `${API_URL}/service-areas/my/`,
	CANCEL_STAMP: `${API_URL}/service-areas/{id}/cancel/`,
	ARCHIVE: `${API_URL}/service-areas/{id}/archive/`,
	UNARQUIVE: `${API_URL}/service-areas/{id}/unarchive/`,
	CAMPSITE: {
		LIST: `${API_URL}/campsites/`,
		SUBMIT_REGISTRATION: `${API_URL}/campsites/{id}/submit/`,
		ARCHIVE: `${API_URL}/campsites/{id}/archive/`,
		UNARCHIVE: `${API_URL}/campsites/{id}/unarchive/`,
		STEPS: {
			ALL: `${API_URL}/campsites/{id}/steps/`,
			SUMMARY: `${API_URL}/campsites/{id}/steps/summary/`,
			EQUIPMENTS: `${API_URL}/campsites/{id}/steps/equipments/`,
			SERVICES: `${API_URL}/campsites/{id}/steps/services/`,
			CHARACTERISTICS: `${API_URL}/campsites/{id}/steps/characteristics/`,
			SPORT_AND_LEISURE: `${API_URL}/campsites/{id}/steps/leisure/`,
			CONTACTS: `${API_URL}/campsites/{id}/steps/contacts/`,
			DOCUMENTS: `${API_URL}/campsites/{id}/steps/documents/`,
			PHOTOS: `${API_URL}/campsites/{id}/steps/photographs/`,
			OTHER_EQUIPMENTS: `${API_URL}/campsites/{id}/steps/other-equipments/`,
			OTHER_SERVICES: `${API_URL}/campsites/{id}/steps/other-services/`,
			OTHER_SPORT_AND_LEISURE: `${API_URL}/campsites/{id}/steps/other-leisure/`,
		}
	},
	AREAS_STEPS: {
		SUMMARY: `${API_URL}/service-areas/{id}/steps/summary/`,
		DATASHEET: `${API_URL}/service-areas/{id}/steps/datasheet/`,
		CONTACTS: `${API_URL}/service-areas/{id}/steps/contacts/`,
		DOCUMENTS: `${API_URL}/service-areas/{id}/steps/documents/`,
		PHOTOS: `${API_URL}/service-areas/{id}/steps/photographs/`,
		INSTALLATION: `${API_URL}/service-areas/{id}/steps/facilities/`,
		SERVICEAREA: `${API_URL}/service-areas/{id}/steps/service-station/`,
		EQUIPMENTS: `${API_URL}/service-areas/{id}/steps/equipments/`,
		OTHEREQUIPMENTS: `${API_URL}/service-areas/{id}/steps/other-equipments/`,
		SUBMIT: `${API_URL}/service-areas/{id}/submit/`,
		SPOTS: `${API_URL}/service-areas/{id}/spots/`,
		RESERVATIONS: `${API_URL}/service-areas/{id}/reservations/`,
		CLEANRESERVATIONRULES: `${API_URL}/service-areas/{id}/cleaning-reservation-rules/`
	},
	RESERVATIONS: {
		CHECK_IN: `${API_URL}/private/reservations/{id}/check-in/`,
		CHECK_OUT: `${API_URL}/private/reservations/{id}/check-out/`,
		RESERVATIONS: `${API_URL}/private/reservations/`,
		RESERVATIONID: `${API_URL}/private/reservations/{id}/`,
		RESERVATIONID_QRCODE: `${API_URL}/private/reservations/{id}/download-code/`,
		RESERVATION_SIMULATE: `${API_URL}/service-areas/{id}/private/simulate-reservation/`,
		RESERVATION_RULES: `${API_URL}/service-areas/{id}/reservation-rules/`,
		RESERVATION_RULEID: `${API_URL}/service-areas/{id}/reservation-rules/{ruleid}/`,
		RESERVATION_UNAVAILABLEDATES: `${API_URL}/service-areas/{id}/unavailability/`,
		RESERVATION_UNAVAILRULES: `${API_URL}/service-areas/{id}/unavailability-rules/`,
		RESERVATION_UNAVAILRULEID: `${API_URL}/service-areas/{id}/unavailability-rules/{ruleid}/`,
		CLEANING : {
			RESERVATIONS: `${API_URL}/private-cleaning-reservations/`,
			RESERVATIONS_DOWN_QRCODE: `${API_URL}/private-cleaning-reservations/{id}/download-code/`,
			RESERVATIONS_NEW_QRCODE: `${API_URL}/private-cleaning-reservations/{id}/generate-code/`,
			RESERVATION_BY_ID: `${API_URL}/private-cleaning-reservations/{id}/`,
			CANCEL: `${API_URL}/private-cleaning-reservations/{id}/cancel/`,
			RESERVATION_UNAVAILABILITY: `${API_URL}/service-areas/{id}/cleaning-unavailability/`,
			AREA_RESERVATIONS: `${API_URL}/service-areas/{id}/cleaning-reservations/`,
			RESERVATION_SIMULATION: `${API_URL}/service-areas/{id}/private/simulate-cleaning-reservation/`,
			RESERVATION_AVAILABILITY_SCHEDULE: `${API_URL}/service-areas/{id}/cleaning-availability`
		}
	},
	MARKERS: `${API_URL}/markers`,
	TRANSLATIONS: `${API_URL}/types/translations/`,
	MUNICIPALITIES: `${API_URL}/types/municipalities/`,
	POSTALCODE: `${API_URL}/types/postal-codes/`,
	COUNTRIES: `${API_URL}/types/countries/`,
	COUNTRIESID: `${API_URL}/types/countries/{id}/`,
	PAYMENTMETHODS: `${API_URL}/types/payment-methods/`,
	CONTACTS: `${API_URL}/types/contacts/`,
	DOWNLOADS: `${API_URL}/downloads/`,
	HOMOLOGATION: `${API_URL}/service-areas/{id}/request-homologation/`,
	AUTH: {
		USER: `${API_URL}/auth/me/`,
		LOGIN: `${API_URL}/auth/login/`,
		REFRESH: `${API_URL}/auth/refresh/`,
		RECOVER_PASSWORD: `${API_URL}/auth/recover_password/`,
		REGISTER: {
			ENTITY: `${API_URL}/auth/register/entity/`,
		}
	}
}