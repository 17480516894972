import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import Logic from './logic'
import ComponentDropdown from 'components/Dropdown'
import Button from 'components/Button'

class BookMngFilters extends Component {
	render () {		
		const {
			serviceAreaOptions,
			selectedServiceArea,
			setSelectedServiceArea,
			children,
			t
		} = this.props
		
		const { navigate } = this.actions
		
		return (
			
			<CalendarWrapper>
				<FiltersWrapper>
					<Dropdown
						options={serviceAreaOptions}
						defaultValue={selectedServiceArea}
						// eslint-disable-next-line max-len
						onChange={(e, { value }) => setSelectedServiceArea(value)}
					/>
					<Button
						primary
						onClick={() => navigate(
							'/gestao-reservas/nova-reserva'
						)}
					>
						{t('booking_management.add_new')}
					</Button>
				</FiltersWrapper>
				<HorizontalLine />
				{children}
			</CalendarWrapper>
		)
	}
}

export default withTranslation()(Logic(BookMngFilters))

const CalendarWrapper = styled.div`
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05);
	width: 100%;
	padding: 40px;
`

const Dropdown = styled(ComponentDropdown)`
	&&&& {
		.text {
			color: #000000;
			font-family: "Open Sans";
			font-size: 24px;
			letter-spacing: 0;
			line-height: 30px;
		}
		
		.dropdown.icon {
			height: 5px;
		}
		
		.item .text {
			font-size: 16px;
			line-height: 20px;
		}
	}
`

const HorizontalLine = styled.hr`
	height: 1px;
	background: #F3F3F3;
	border: 0;
	margin: 14px 0 24px 0;
`

const FiltersWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`