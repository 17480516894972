import * as React from 'react'

const IcCampoJogos = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-campo-jogos</title>
			<defs>
				<filter id="filter-1">
					<feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
				</filter>
			</defs>
			<g xmlns="http://www.w3.org/2000/svg" id="icons/servicos/ic-campo-jogos" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g filter="url(#filter-1)" id="icon">
					<g transform="translate(0.002391, 1.021211)">
						<path d="M9.45464063,12.5474297 L20.8329844,12.5474297 L22.7564063,17.8786641 L23.0947031,17.8786172 C23.5933594,17.8786172 23.9976094,18.2828672 23.9976094,18.7815234 L23.9976094,18.8358984 C23.9976094,19.3345547 23.5933594,19.7388047 23.0947031,19.7388047 L7.19292188,19.7388047 C6.69426563,19.7388047 6.29001563,19.3345547 6.29001563,18.8358984 L6.29001563,18.7815234 C6.29001563,18.2828672 6.69426563,17.8786172 7.19292188,17.8786172 L7.53121875,17.8786641 L9.45464063,12.5474297 Z M16.7005313,1.09328906 L19.5290156,8.935 L10.7580156,8.935 L13.5871406,1.09328906 C14.1130781,-0.364429688 16.1746406,-0.364429688 16.7005313,1.09328906 Z" fill="#FEA95C" fillRule="nonzero"/>
						<path d="M7.78692187,19.7388047 C12.0875201,19.7388047 15.5738437,16.252481 15.5738437,11.9518828 C15.5738437,7.65128461 12.0875201,4.16496094 7.78692187,4.16496094 C3.48632368,4.16496094 -3.90798505e-14,7.65128461 -3.90798505e-14,11.9518828 C-3.90798505e-14,16.252481 3.48632368,19.7388047 7.78692187,19.7388047 Z M9.161,14.7959609 L10.956,16.8949609 L10.4609314,18.4229993 C9.70712633,18.7348332 8.88699149,18.9187896 8.02757163,18.9478238 L7.78692187,18.9518828 C6.83989095,18.9518828 5.93678476,18.7638186 5.11291235,18.4229993 L4.617,16.8959609 L6.412,14.7959609 L9.161,14.7959609 Z M13.461,10.4599609 L14.7660495,11.4072752 C14.7798791,11.5869955 14.7869219,11.7686167 14.7869219,11.9518828 C14.7869219,13.6856451 14.156608,15.2721898 13.1126319,16.494865 L11.562,16.4939609 L9.766,14.3939609 L10.627,11.7439609 L13.461,10.4599609 Z M2.112,10.4599609 L4.946,11.7439609 L5.807,14.3949609 L4.012,16.4939609 L2.46121181,16.494865 C1.46573342,15.3289889 0.846376746,13.832265 0.790980893,12.1925326 L0.786921875,11.9518828 C0.786921875,11.7686167 0.793964621,11.5869955 0.807794231,11.4072752 L2.112,10.4599609 Z M9.42716965,5.1451154 C11.2511782,5.58306361 12.7973021,6.73513583 13.7533232,8.28911387 L13.277,9.75196094 L10.442,11.0359609 L8.147,9.36896094 L8.147,6.07596094 Z M6.1466741,5.1451154 L7.426,6.07596094 L7.426,9.36896094 L5.131,11.0359609 L2.296,9.75196094 L1.8205205,8.28911387 C2.77654163,6.73513583 4.32266555,5.58306361 6.1466741,5.1451154 Z" fill="#000000" fillRule="nonzero"/>
					</g>
				</g>
			</g>
		</svg>
	)

export default IcCampoJogos
