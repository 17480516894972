import { kea } from 'kea'
import { put, delay } from 'redux-saga/effects'
import PropTypes from 'prop-types'

export default kea({
	path: () => ['scenes', 'containers', 'toaster'],

	actions: () => ({
		show: (type, title, message) => ({
			type, title, message
		}),
		close: () => ({})
	}),

	reducers: ({ actions }) => ({
		toast: [{}, PropTypes.object, {
			[actions.show]: (state, payload) => payload
		}],

		showing: [false, PropTypes.bool, {
			[actions.show]: () => true,
			[actions.close]: () => false
		}]
	}),

	takeLatest: ({ actions, workers }) => ({
		[actions.show]: workers.timeout
	}),

	workers: {
		* timeout () {
			const { close } = this.actions

			let showing = yield this.get('showing')
			while (showing) {
				yield delay(5000)
				yield put(close())
				showing = yield this.get('showing')
			}
		}
	}

})
