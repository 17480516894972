import { kea } from 'kea'
import BookingManagementLogic from 'screens/BookingManagement/logic'
import BookingDetails from 'screens/BookingDetails/logic'
import ToasterLogic from 'containers/Toaster/logic'
import { put, call } from 'redux-saga/effects'
import API from 'api'
import { fix2DigitHours } from 'utils'
import { handleApiErrorMessage } from '../../../../utils'

export default kea({
	path: () => ['scenes', 'containers', 'BookDetailsCheckInOut'],

	connect: {
		props: [
			BookingManagementLogic, [
				'parkingPlaces'
			],
			BookingDetails, [
				'form',
				'reservationStatus',
				'loading'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			BookingDetails, [
				'changeForm'
			]
		]
	},
	actions: () => ({
		downloadFile: url => url,
		checkIn: () => true,
		checkOut: () => true
	}),

	takeLatest: ({ actions, workers }) => ({
		[actions.downloadFile]: workers.downloadFile,
		[actions.checkIn]: workers.checkIn,
		[actions.checkOut]: workers.checkOut
	}),

	workers: {
		* downloadFile () {
			const { showToaster } = this.actions
			const { match, t } = this.props
			const id = match.params.id
			
			try {
				const response = yield call(API.Reservations.downloadQrCode, id)
				const link = document.createElement('a')
				const blob = new Blob([response], { type: 'image/png' })
				link.download = 'qrcode.png'
				link.target = '_blank'
				link.href = URL.createObjectURL(blob)
				link.click()
				URL.revokeObjectURL(link.href)
			} catch (e) {
				console.log(e)
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(e, t) || t('error.error_fetch')
				))
				
			}
					
		},
		* checkIn () {
			const { showToaster, changeForm } = this.actions
			const { match, t } = this.props
			const id = match.params.id
			const form = yield this.get('form')
			
			try {
				const response = yield call(API.Reservations.checkIn, id)
				if (!response.allowed) throw 'Not allowed'
				
				const { checkInOutInformations } = form
				const newCheckInOutInformations = [...checkInOutInformations.value]

				const currDate = new Date()

				newCheckInOutInformations.push({
					date: `${currDate.getDate()}-${currDate.getMonth() + 1}-${currDate.getFullYear()}`,
					hour: `${fix2DigitHours(currDate.getHours())}H${fix2DigitHours(currDate.getMinutes())}`,
					status: 'In'
				})

				yield put(changeForm({checkInOutInformations: newCheckInOutInformations}))
			} catch (e) {
				console.log(e)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('booking_details.error_manual_check_in_out')
				))
				
			}
					
		},
		* checkOut () {
			const { showToaster, changeForm } = this.actions
			const { match, t } = this.props
			const id = match.params.id
			const form = yield this.get('form')

			try {
				const response = yield call(API.Reservations.checkOut, id)
				if (!response.allowed) throw 'Not allowed'

				const { checkInOutInformations } = form
				const newCheckInOutInformations = [...checkInOutInformations.value]

				const currDate = new Date()
				
				newCheckInOutInformations.push({
					date: `${currDate.getDate()}-${currDate.getMonth() + 1}-${currDate.getFullYear()}`,
					hour: `${fix2DigitHours(currDate.getHours())}H${fix2DigitHours(currDate.getMinutes())}`,
					status: 'Out'
				})

				yield put(changeForm({checkInOutInformations: newCheckInOutInformations}))
				
			} catch (e) {
				console.log(e)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('booking_details.error_manual_check_in_out')
				))
				
			}
					
		}
	}
})
