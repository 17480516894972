/**
 * SuccessMessage Component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 */

import React, { Component } from 'react'
import styled from 'styled-components'

import { SecondTitle } from 'components/SubTitle'
import EmailSentIcon from 'components/EmailSent'

export default class SuccessMessage extends Component {
	render () {
		const { heading, secondTitle } = this.props
		return (
			<Wrapper>
				<Heading>{heading}</Heading>
				<SecondTitleWrapper>
					<SecondTitle>{secondTitle}</SecondTitle>
				</SecondTitleWrapper>
				<EmailSent />
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmailSent = styled(EmailSentIcon)`
  margin-top: 20px;
`

const SecondTitleWrapper = styled.div`
  margin-bottom: 57px;
`

const Heading = styled.span`
  font-size: 24px;
  color: #102526;
  margin-bottom: 16px;
`
