import React, { Component } from 'react'
import styled, { withTheme } from 'styled-components'
import { Button as bt, Loader } from 'semantic-ui-react'

class Button extends Component {

	onClick = () => {
		const {loading, onClick} = this.props

		if (!loading && onClick) onClick()
	}

	render () {

		const {loading, children, content, ...rest} = this.props

		const newContent = (
			<>
				{loading && <Loader active size='small' />}
				<InvisibleData visible={!loading}>
					{content}
					{children}
				</InvisibleData>
			</>
		)

		return (
			<StyledButton disabled={loading} content={newContent} onClick={() => this.onClick()} {...rest} />
		)
	}
}

const InvisibleData = styled.span`
	display: flex;
	align-items: center;
	${({visible}) => !visible && 'visibility: hidden;'}
`

const StyledButton = withTheme(styled(bt).attrs({
	type: 'button'
})`
  &&&& {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${({ primary, theme }) => primary && `
      border-radius: 100px;
      background: ${theme.primaryButtonColor}!important;
      box-shadow: ${theme.primaryButtonShadow};
      transition: all 0.2s ease-in;
      position: relative;

      a {
        color: ${theme.anchorColor};
      }

      &:hover {
        //transform: translateY(-1px);
        background: ${theme.primaryButtonColor} !important;
        box-shadow: ${theme.primaryButtonHoverShadow};
      }
    `}

    ${({ secondary, theme, img }) => secondary && `
      color: #616161;
      border: 1px solid #DDDDDD;
      border-radius: 100px;
      background-color: ${theme.secondaryButtonColor};
      box-shadow: ${theme.secondaryButtonShadow};
      transition: all 0.2s ease-in;

      a {
        color: ${theme.anchorColor};
      }

      i { 
        color: ${theme.iconColor};
      }

      ${img && `
        border-radius: 50%;
        max-height: none;
      `}

      &:hover{
        //transform: translateY(-1px);
        box-shadow: ${theme.secondaryButtonHoverShadow};
      }
    `}

    ${({ disabled }) => disabled && `
      background: linear-gradient(180deg, #E5E5E5 0%, #ADADAD 100%)!important;
      box-shadow: 0 4px 2px 0 rgba(155,155,155,0.15)!important;
      pointer-events: none;

      &:hover {
        transform: none;
      }
    `}

    ${({ isDelete }) => isDelete && `
      color: #c23434!important;
    `}
  }
`)

export default Button
