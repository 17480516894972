import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Grid, Message } from 'semantic-ui-react'
import Logic from './logic'

import Title from 'components/Title'
import Button from 'components/Button'
import StatusLabel from 'components/BookingStatus'
import BookDetailsCharacteristics from './BookDetailsCharacteristics'
import BookDetailsGuestInfo from './BookDetailsGuestInfo'
import BookDetailsMotorhome from './BookDetailsMotorhome'
import BookDetailsCheckInOut from './BookDetailsCheckInOut'
import BookDetailsPayment from './BookDetailsPayment'

class BookingDetails extends Component {

	render () {
		const {
			t,
			dirty,
			loading,
			errors,
			isNewReservation,
			prices,
			reservationStatus,
			isSubmitted,
		} = this.props
		
		const {
			changeEditForm,
			changeForm,
			fetchForm,
			saveForm,
			createReservation,
			simulateReservation,
			navigate
		} = this.actions

		const hasSimulation = prices && Object.keys(prices).length > 0
		
		return (
			<Wrapper>
				<Grid>
					<Grid.Row>
						<TopWrapper>
							<Title>
								{t('booking_details.title')}
							</Title>
							<StatusLabel status={reservationStatus}>
								{t(`booking_details.${reservationStatus}`)}
							</StatusLabel>
						</TopWrapper>
					</Grid.Row>

					{errors && !!errors.length &&
					<Message
						name={'errorMessage'}
						error
						header={'Corrija os seus erros antes de prosseguir'}
						list={errors}
					/>
					}
					<Grid.Row>
						<BookDetailsCharacteristics
							changeEditForm={changeEditForm}
							changeForm={changeForm}
						/>
					</Grid.Row>
					<Grid.Row>
						<BookDetailsGuestInfo
							changeEditForm={changeEditForm}
							changeForm={changeForm}
						/>
					</Grid.Row>
					<Grid.Row>
						<BookDetailsMotorhome
							changeEditForm={changeEditForm}
							changeForm={changeForm}
						/>
					</Grid.Row>
					{!isNewReservation &&
						<Grid.Row>
							<BookDetailsCheckInOut
								changeEditForm={changeEditForm}
								changeForm={changeForm}
							/>
						</Grid.Row>
					}
					<Grid.Row>
						{(reservationStatus === 'simulated' || reservationStatus === 'edit' || reservationStatus === 'confirmed') &&
							<BookDetailsPayment reservationStatus={reservationStatus} />
						}
					</Grid.Row>
					{(isSubmitted && !hasSimulation) &&
						<>
							<InfoTitle>
								{t('booking_details.no_simulation')}
							</InfoTitle>
							<Link
								onClick={() => navigate('/definicoes-reservas')}
							>
								{t('booking_details.booking_settings')}
							</Link>
						</>
					}
					<Grid.Row columns={2}>
						<Grid.Column>
							{/* eslint-disable-next-line max-len */}
							{!isNewReservation &&
								<Button
									secondary
									onClick={fetchForm}
									disabled={!dirty || loading}
								>
									{t('booking_details.cancel')}
								</Button>
							}
						</Grid.Column>
						<Grid.Column textAlign={'right'}>
							{/* eslint-disable-next-line max-len */}
							{(hasSimulation || (!hasSimulation && !isNewReservation)) &&
								<Button
									primary
									disabled={!dirty || loading}
									style={{ display: 'inline-block' }}
									onClick={isNewReservation
										? createReservation
										: saveForm
									}
								>
									{isNewReservation
										? t('booking_details.book')
										: t('booking_details.save')
									}
								</Button>
							}
							{(!hasSimulation && isNewReservation) &&
								<Button
									primary
									disabled={!dirty || loading}
									style={{ display: 'inline-block' }}
									onClick={simulateReservation}
								>
									{t('booking_details.simulate_reservation')}
								</Button>
							
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(BookingDetails))

const Wrapper = styled.div``

const TopWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 32px;
	
	> h1 {
		margin: 0;
	}
`

const InfoTitle = styled.span`
	color: #616161;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 600;
	-webkit-letter-spacing: 0;
	-moz-letter-spacing: 0;
	-ms-letter-spacing: 0;
	letter-spacing: 0;
	line-height: 14px;
	padding: 12px 0!important;
`

const Link = styled(InfoTitle)`
	text-decoration: underline;
	cursor: pointer;
`