import { getStore } from 'kea'
import 'kea-saga/install'

import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory({
	basename: ''
})

export const Store = getStore({
	middleware: [
		routerMiddleware(history)
	],
	reducers: {
		router: connectRouter(history)
	}
})