// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
import { Grid, Message } from 'semantic-ui-react'
/** Components */
import Title from 'components/Title'
import Button from 'components/Button'
/** Container */
import UsageDetailsCharacteristics from './UsageDetailsCharacteristics'
import UsageDetailsGuestInfo from './UsageDetailsGuestInfo'
import UsageDetailsMotorhome from './UsageDetailsMotorhome'
import UsagePayment from './UsagePayment'
import UsageDetailsCheckInOut from './UsageDetailsCheckInOut'
import StatusLabel from 'components/BookingStatus'
class UsageReservationDetails extends Component {

	constructor (props) {
		super(props)
	}

	render () {
		const {
			t,
			dirty,
			loading,
			status,
			form,
			errors
		} = this.props

		const {
			saveForm,
			createReservation,
			simulateReservation
		} = this.actions

		return (
			<Wrapper>
				<Grid>
					<Grid.Row>
						<TitleWrapper>
							<Title>
								{t('booking_details.title')}
							</Title>
							<StatusLabel status={form.status.value}>
								{t(`booking_details.${form.status.value}`)}
							</StatusLabel>
						</TitleWrapper>
					</Grid.Row>
					{errors && !!errors.length &&
					<Message
						name={'errorMessage'}
						error
						header={t('api_error.title')}
						list={errors}
					/>
					}
					<Grid.Row>
						<UsageDetailsCharacteristics />
						<UsageDetailsGuestInfo />
						<UsageDetailsMotorhome />
						{(status === 'simulated' || status === 'edit') &&
							<UsagePayment />
						}
						{status === 'edit' &&
							<UsageDetailsCheckInOut />
						}
					</Grid.Row>
					<Grid.Row>
						<Grid.Column textAlign={'right'}>
							{status !== 'new' &&
							<Button
								primary
								disabled={!dirty || loading}
								style={{ display: 'inline-block' }}
								onClick={status === 'simulated'
									? createReservation
									: saveForm
								}
							>
								{status === 'simulated' 
									? t('booking_details.book')
									: t('booking_details.save')
								}
							</Button>
							}
							{status === 'new' &&
							<Button
								primary
								disabled={!dirty || loading}
								style={{ display: 'inline-block' }}
								onClick={simulateReservation}
							>
								{t('booking_details.simulate_reservation')}
							</Button>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(UsageReservationDetails))

const Wrapper = styled.div``

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 32px;
	
	> h1 {
		margin: 0;
	}
`
