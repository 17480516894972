import React, { Component } from 'react'
import styled from 'styled-components'

import {
	Icon,
	Input as SemanticInput,
} from 'semantic-ui-react'

import Button from 'components/Button'
import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import { withTranslation } from 'react-i18next'

class ListAdderOptions extends Component {

	render () {

		const { data, options, add, remove, errors, index, valueChange, inlineAdd } = this.props

		// eslint-disable-next-line max-len
		const removeButton = remove ? <CircleButton onClick={() => remove(index)} secondary><Icon name={'minus'} /></CircleButton> : <WhiteSpaceButton />
		const addButton = !inlineAdd ? null
			: add ? <CircleButton onClick={add} primary><Icon name={'plus'} /></CircleButton> : <WhiteSpaceButton />
		
		return (
			<FormGroup widths='equal'>
				{options.map(({name, required, label, component}, i) => (
					<FormField
						key={`${name}-${i}`}
						required={required}
						label={label}
						error={errors && errors[name]}
						control={component || Input}
						value={data[name]}
						onChange={(e, { value }) => valueChange(name, value, index)}
					/>
				))}
				{removeButton}
				{addButton}
			</FormGroup>
		)
	}
}

class ListAdder extends Component {

	render () {

		const { data, errors, options, valueChange, add, remove, inlineAdd = false, t } = this.props
		//const canRemove = data.length !== 1

		return (
			<>
				{data.map((d, index) => (
					<ListAdderOptions
						key={index}
						data={d}
						options={options}
						add={inlineAdd && index === data.length - 1 && add}
						inlineAdd={inlineAdd}
						remove={remove}
						index={index}
						errors={errors && errors[index]}
						valueChange={valueChange}
					/>
				))}
				{!inlineAdd && (
					<AddButton onClick={add} secondary>
						<Icon name={'plus'} />
						{t('common.buttons_add')}
					</AddButton>
				)}
			</>
		)

	}
}

export default withTranslation()(ListAdder)

const Input = styled(SemanticInput)`
  width: 100%;
`

const WhiteSpaceButton = styled.div`
	width: 40px;
	min-width: 40px;
	margin: 15px 0 0 5px!important;
	height: 40px!important;
	padding: 0!important;
	position: relative;
`

const AddButton = styled(Button)`
	margin: 0 auto !important;
`

const CircleButton = styled(Button)`
	width: 40px;
	min-width: 40px;
	margin: 15px 0 0 5px!important;
	height: 40px!important;
	padding: 0!important;
	position: relative;

	&& i {
		margin: 0!important;
	}
`