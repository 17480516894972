import React from 'react'
import styled from 'styled-components'

export const SidebarItem = (props) => (
	<Item {...props}>{props.children}</Item>
)

export const SidebarSecondItem = (props) => (
	<SecondItem {...props}>{props.children}</SecondItem>
)

export const SidebarLastItem = (props) => (
	<LastItem {...props}>{props.children}</LastItem>
)

const Item = styled.div`
  height: 44px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  font-family: Open Sans;
  font-size: 14px;
  background-color: #FFFFFF;
  box-shadow: inset 0 1px 0 0 #EEEEEE, inset 0 -1px 0 0 #EEEEEE, inset -1px 0 0 0 #EEEEEE;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ selected }) => selected && `
    color: #102526;
    font-weight: 600;
    background-color: rgba(16,37,38,0.1);

    img {
      filter: invert(9%) sepia(54%) saturate(584%) hue-rotate(134deg) brightness(90%) contrast(90%);
      opacity: 1;
    }
  `}
  
  &:hover {
    color: #102526;
    background-color: rgba(16,37,38,0.1);

    img {
      filter: invert(9%) sepia(54%) saturate(584%) hue-rotate(134deg) brightness(90%) contrast(90%);
      opacity: 1;
    }
  }
`

const SecondItem = styled.div`
  height: 44px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  font-weight: 600;
  font-family: Open Sans;
  background-color: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  padding-left: 45px;
  display: flex;
  align-items: center;
  box-shadow: inset -1px 0 0 0 #EEEEEE;

  ${({ selected }) => selected && `
    color: #102526;
    font-weight: 600;

    img {
      filter: invert(9%) sepia(54%) saturate(584%) hue-rotate(134deg) brightness(90%) contrast(90%);
      opacity: 1;
    }
  `}

  &:hover {
    color: #102526;
    font-weight: 600;

    img {
      filter: invert(9%) sepia(54%) saturate(584%) hue-rotate(134deg) brightness(90%) contrast(90%);
      opacity: 1;
    }
  }
`

const LastItem = styled.div`
  height: 44px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: Open Sans;
  background-color: rgba(16, 37,38, 0.57);
  box-shadow: inset 0 1px 0 0 #EEEEEE, inset 0 -1px 0 0 #EEEEEE, inset -1px 0 0 0 #EEEEEE;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ selected }) => selected && `
    color: #102526;
    font-weight: 600;

    img {
      opacity: 1;
    }
  `}

  &:hover {
    background-color: rgba(16, 37,38, 0.57);
    font-weight: 600;

    img {
      opacity: 1;
    }
  }
`
