/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'

import Segment from 'components/Segment'
import Button from 'components/Button'

export default ({ error, t }) => (
	<div>
		{!error && <LoadingSegment loading />}

		{error &&
		<FatalWrapper>
			<Title>{t('error.something_went_wrong')}</Title>
			<Text>{t('error.fatal_error_on_load')}</Text>

			<Button 
				onClick={() => window.location.reload()}
				primary
			>
				{t('options.try_again')}
			</Button>
		</FatalWrapper>
		}
	</div>
)

const LoadingSegment = styled(Segment)`
  &&& {
    height: 100vh;
    background: transparent;
    border: 0;
    margin: 0;
  }
`

const Title = styled.h1`
  text-transform: uppercase;
  color: #ccc;
`

const Text = styled.p`
`

const FatalWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
