import { kea } from 'kea'

import ToasterLogic from 'containers/Toaster/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'

export default kea({
	path: () => ['scenes', 'containers', 'CampingManagementRequestForm', 'Equipments'],

	connect: {
		props: [
			CampsiteManagementRequestLogic, [
				'current',
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			CampsiteManagementRequestLogic, [
				'setCurrent',
			],
		]
	}
})
