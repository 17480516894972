import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
	Grid,
	Dimmer,
	Loader
} from 'semantic-ui-react'

import IcQrCode from 'assets/components/IcQrcode'
import Button from 'components/Button'
import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import Logic from './logic'

class UsageDetailsCheckInOut extends Component {

	render () {
		const {
			t,
			loading
		} = this.props

		const { downloadFile, newQrCode } = this.actions
		return (
			<Wrapper>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.checkin_and_checkout')}
				</SegmentTitle>
				{loading && 
					<Dimmer inverted active>
						<Loader inverted/>
					</Dimmer>
				}
				{!loading &&
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<InfoTitle>
								{t('booking_details.qrcode')}
							</InfoTitle>
						</Grid.Column>
						<Grid.Column>
							<Grid.Row>
								<Link 
									onClick={newQrCode}
								>
									{t('booking_details.new_qrcode')}
								</Link>
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<QrCodeWrapper>
							<Grid style={{ margin: 0 }}>
								<Grid.Row>
									<Grid.Column
										width={1}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<IcQrCode fillColor={'#9B9B9B'} />
									</Grid.Column>
									<Grid.Column width={7}>
										<CodeTitle>
											{t('booking_details.qrcode')}
										</CodeTitle>
										<CodeDescription>
											{t('booking_details.qrcode_automatic')}
										</CodeDescription>
									</Grid.Column>
									<Grid.Column
										width={8}
										style={{
											display: 'flex',
											justifyContent: 'flex-end'
										}}
									>
										<Button 
											secondary
											onClick={downloadFile}
										>
											<IcQrCode
												size={{width: 18, height: 18 }}
											/>
											<ButtonText>
												{t('booking_details.download_qrcode')}
											</ButtonText>
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</QrCodeWrapper>
					</Grid.Row>
				</Grid>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(withRouter(Logic(UsageDetailsCheckInOut)))

const Wrapper = styled(Segment)`
	padding: 32px 40px !important;
`

const InfoTitle = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
`

const Link = styled.p`
  opacity: 0.6;
  color: #102526;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
	text-align: right;
	text-decoration: underline;
	cursor: pointer;
	text-transform: uppercase;
`

const QrCodeWrapper = styled.div`
  background-color: #FFFFFF;
	box-shadow: inset 0 1px 0 0 rgba(155,155,155,0.15), inset 0 -1px 0 0 rgba(155,155,155,0.15);
	width: 100%;;
`

const CodeDescription = styled.p`
  color: #9B9B9B;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
	line-height: 18px;
	margin: 0;
`

const CodeTitle = styled.p`
  color: #3E3E3E;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
	line-height: 18px;
	margin: 0;
`

const ButtonText = styled.span`
	margin-left: 12px;
`