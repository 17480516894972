import * as React from 'react'

const IcLavaRoupa = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-lava-roupa</title>
			<g id="icons/servicos/ic-lava-roupa" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M3.8769375,12.777375 C3.41423437,12.546 2.88796875,12.3609375 2.33653125,12.2322656 L1.34765625,8.98279687 L0.00234375,9.39220312 L4.44778125,24 L19.5522656,24 L23.9977031,9.39220312 L22.6523906,8.98279687 L21.6666562,12.2219062 C21.1326563,12.3460312 20.6147344,12.5315156 20.1231094,12.7773281 C19.555125,13.0612969 18.9471094,13.241625 18.3281719,13.3193906 L18.3281719,5.625 L21.1406719,5.625 L21.1406719,1.77635684e-15 L14.109375,1.77635684e-15 L14.109375,0.703125 C14.109375,1.86623438 13.1631094,2.8125 12,2.8125 C10.8368906,2.8125 9.890625,1.86623438 9.890625,0.703125 L9.890625,1.77635684e-15 L2.859375,1.77635684e-15 L2.859375,5.625 L5.671875,5.625 L5.671875,13.3193906 C5.0529375,13.2416719 4.44492188,13.0613437 3.8769375,12.777375 Z M9.5019375,14.035125 C11.0662031,13.2530625 12.9338438,13.2530156 14.4980625,14.035125 C16.45575,15.0139687 18.7936875,15.01425 20.7519375,14.035125 C20.8894688,13.9663594 21.0297656,13.9041094 21.1720313,13.8470625 L20.7220781,15.3255937 C20.5184531,15.404625 20.3177344,15.4925156 20.1230156,15.589875 C18.5587969,16.3719375 16.6911563,16.3719844 15.1268906,15.589875 C13.1689219,14.6108906 10.8310313,14.6108906 8.87301563,15.589875 C7.30879688,16.3719844 5.44115625,16.3719844 3.87689063,15.589875 C3.68221875,15.4925156 3.48145313,15.404625 3.27782813,15.3255937 L2.82928125,13.8516094 C2.97590625,13.9085156 3.1168125,13.9695937 3.24801563,14.0351719 C5.20598438,15.0141094 7.543875,15.0141562 9.5019375,14.035125 L9.5019375,14.035125 Z" id="icon" fill="#000000"/>
			</g>
		</svg>
	)

export default IcLavaRoupa
