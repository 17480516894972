/* eslint-disable react/display-name */
import React from 'react'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

// https://github.com/styled-components/styled-components/issues/439#issuecomment-327251673
export default ({ ...props }) => (
	<FormField {...props} />
)

const FormField = styled(Form.Field)`
  &&&& {
    margin: ${({ margin }) => margin || '0 0 28px'};
    padding: ${({ padding }) => padding || '0 0.5em'};
    
    label {
      color: #616161;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      
      &:after {
        color: #616161;
      }
    }
    
    .ui.checkbox.radio input:checked~label{
      &:before{
        background: #102526 !important;
        border-color: #102526 !important;
      }
      &:after{
        background-color: #FFF;
      }
    }
    .ui.checkbox.radio.disabled input:checked~label{
      &:before{
        opacity: 0.4;
        background: #102526 !important;
        border-color: #102526 !important;
      }
      &:after{
        background-color: #FFF;
      }
    }
    .ui.checkbox.radio{
      margin-right: 15px;
      vertical-align: middle;
    }
  }
`