/* eslint-disable react/display-name */
/**
 * Go to button Component
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 */
import React from 'react'
import styled from 'styled-components'

import Button from 'components/Button'
import { ArrowFowardIcon } from 'components/Icons'

export default ({ children, ...props }) => (
	<ButtonGoTo
		secondary
		img={1}
		onClick={props.onClick}
		margin={props.margin || 'auto'}
	>
		{props.icon ? props.icon : <ArrowFowardIcon />}
		{children && children}
	</ButtonGoTo>

)

const ButtonGoTo = styled(Button)`
	&&&& {
		padding: 0px !important;
		display: flex;
		height: 35px;
		min-width: 35px;
		position: relative;
		align-items: center;
		justify-content: center;
		margin: ${({margin}) => margin};

		svg {
			margin-left: 2px;
		}

		&:hover {
			background-color: #102526;

			* {
				fill: #fff;
			}
		}

	}
`
