/**
 * StateLabel Component
 * Please write a description
 *
 */
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
const getLabel = (status, t) => {
	switch (status) {
		case 'registered': return t('labels.registered')
		case 'homologated': return t('labels.homologated')
		case 'homologation requested': return t('labels.homologation_requested')
		case 'registration requested': return t('labels.registration_requested')
		case 'draft': return t('labels.draft')
		case 'disabled': return t('labels.disabled')
		default: return ''
	}
}

class StateLabel extends Component {
	render () {
		const {
			state,
			style,
			t
		} = this.props

		if (state === 'canceled') return null

		return (
			<Label style={style && style} state={state}>
				{getLabel(state, t)}
			</Label>
		)
	}
}

export default withTranslation()(StateLabel)

const Label = styled.span`
  color: white;
  border-radius: 90px/90px;
  padding: 1px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90px;
  min-height: 24px;
  font-weight: 600;

  ${({ state }) => state === 'registered' && `
    background-color: #102526;
  `}

  ${({ state }) => state === 'homologated' && `
    background-color: #ABD037;
	`}
	

  ${({ state }) => (state === 'homologation requested' || state === 'registration requested') && `
    background-color: #d08b37;
  `}

  ${({ state }) => state === 'disabled' && `
    background-color: #D03737;
  `}

  ${({ state }) => state === 'draft' && `
    background-color: #C1C1C1;
  `}
`
