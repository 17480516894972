// @flow

import { kea } from 'kea'
import Proptypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import BookingManagementLogic from 'screens/BookingManagement/logic'

export default kea({
	path: () => ['scenes', 'containers', 'BookMngCalendarView'],

	connect: {
		props: [
			BookingManagementLogic, [
				'reservations',
				'parkingPlaces',
				'visibleTime'
			]
		],
		actions: [
			BookingManagementLogic, [
				'setSelectedServiceArea',
				'setReservations',
				'handleItemResize',
				'handleItemSelect',
				'handleItemMove'
			],
			AppLogic, [
				'navigate'
			],
		]
	},

	reducers: ({ actions }) => ({
		loading: [false, Proptypes.bool, {
			[actions.setSelectedServiceArea]: () => true,
			[actions.setReservations]: () => false
		}]
	})
})
