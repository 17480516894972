import * as React from 'react'

const IcLavandaria = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-lavandaria</title>
			<g id="icons/servicos/ic-lavandaria" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M3,23 L21.4570312,23 L21.4570312,8.15234375 L3,8.15234375 L3,23 Z M12.2285156,10.0390625 C15.2818418,10.0390625 17.765625,12.5228457 17.765625,15.5761719 C17.765625,18.629498 15.2818418,21.1132812 12.2285156,21.1132812 C9.17518945,21.1132812 6.69140625,18.629498 6.69140625,15.5761719 C6.69140625,12.5228457 9.17518945,10.0390625 12.2285156,10.0390625 Z M12.2285156,19.8828125 C13.3974199,19.8828125 14.4561973,19.4110918 15.2329512,18.6523437 L12.84375,18.6523437 C11.486502,18.6523437 10.3828125,17.5486543 10.3828125,16.1914062 C10.3828125,15.3760977 10.7757422,14.6467168 11.3963906,14.1973086 C10.4342461,13.59725 9.14332031,13.6109082 8.16558984,14.2078906 C8.01936914,14.6402363 7.921875,15.0951406 7.921875,15.5761719 C7.921875,17.9506074 9.85408008,19.8828125 12.2285156,19.8828125 Z M13.0029727,13.9641758 L13.7347734,14.7963008 L12.6412969,14.9771387 C12.045873,15.0756992 11.6132813,15.5863848 11.6132813,16.1914062 C11.6132813,16.8697227 12.1654336,17.421875 12.84375,17.421875 L16.1031797,17.421875 C16.3720371,16.860248 16.5351563,16.2391895 16.5351563,15.5761719 C16.5351563,13.2017363 14.6029512,11.2695312 12.2285156,11.2695312 C11.0148633,11.2695312 9.92155078,11.7786582 9.13786523,12.589291 C10.5457266,12.4011523 12.0427148,12.8716836 13.0029727,13.9641758 L13.0029727,13.9641758 Z M3,6.921875 L21.4570312,6.921875 L21.4570312,2 L3,2 L3,6.921875 Z M14.0742188,3.84570312 L18.9960938,3.84570312 L18.9960938,5.07617188 L14.0742188,5.07617188 L14.0742188,3.84570312 Z M7.921875,3.84570312 L9.15234375,3.84570312 L9.15234375,5.07617188 L7.921875,5.07617188 L7.921875,3.84570312 Z M5.4609375,3.84570312 L6.69140625,3.84570312 L6.69140625,5.07617188 L5.4609375,5.07617188 L5.4609375,3.84570312 Z" id="icon" fill="#000000" fillRule="nonzero"/>
			</g>
		</svg>
	)

export default IcLavandaria
