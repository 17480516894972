/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint react/prop-types: 0 */

import axios from 'axios'
import React, { Component } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import Button from 'components/Button'
import Remove from 'components/Remove'
import { Progress, Icon, Placeholder } from 'semantic-ui-react'
import { getFilenameOnFullPath } from 'utils'

import { ENDPOINTS } from 'api'
const MEDIA_BASE_URL = ENDPOINTS.BASE.substr(0, ENDPOINTS.BASE.length - 4) 

export default class Uploader extends Component {
	constructor () {
		super()
		this.state = {
			files: [],
			filesForUpload: []
		}
	}

	componentWillMount () {
		this.setState({ files: Array.isArray(this.props.value)
			? this.props.value.filter(f => f !== null)
			: []
		})
	}

	componentDidUpdate (prevProps) {
		if (prevProps.value !== this.props.value &&
      Array.isArray(this.props.value))
		{
			this.setState({ files: this.props.value })
		}
	}

	getFileIcon (mime) {
		if (mime.includes('image')) return <Icon name={'file image'} />
		if (mime.includes('pdf')) return <Icon name={'file pdf'} />
		else return <Icon name={'file'} />
	}

	getFileName (mime) {
		if (mime.includes('image')) return 'imagens'
		if (mime.includes('pdf')) return 'PDF\'s'
	}

	remove (index) {
		const onRemove = this.props.onRemove
		let files = [...this.state.files]
		files.splice(index, 1)
		this.setState({ files }, () => {
			onRemove && onRemove(index)
		})
	}

	upload () {
		const {
			uploadEndpoint,
			onChange
		} = this.props

		const {
			filesForUpload
		} = this.state

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				)
				
				this.setState(
					prevState => (
						{ files: prevState.files.map(
							file => file instanceof Blob
								? {
									name: file.name,
									progress: percentCompleted 
								}
								: file
						) }
					)
				)
			}
		}

		const data = new window.FormData()
		filesForUpload.forEach((file) => {
			if (file.preview) {
				data.append('files', file)
			}
		})

		axios.post(uploadEndpoint, data, config)
			.then((response) => {
				// eslint-disable-next-line max-len
				onChange && onChange(response.data.files.map(file => ({ image: { id: file.id, name: '', url: file.file }, isFavourite: false, progress: 100 })))

				this.setState({ error: false, errorMessage: null })
			})
			.catch((error) => {
				let detail = 'Alguma coisa correu mal no upload.'
				try {
					detail = error.response.data.detail
				} catch (e) {
					console.log(e)
				}

				this.setState({ error: true, errorMessage: detail })
			})
	}

	handleSetFavorite (index) {
		const newFiles = [...this.state.files]
		newFiles[index] = {
			...newFiles[index],
			isFavourite: !newFiles[index].isFavourite
		}
		// eslint-disable-next-line max-len
		this.setState({ files: newFiles }, () => this.props.onSetFavorite(index))
	}

	render () {
		const {
			single,
			accept,
			className,
			title
		} = this.props

		const {
			files,
			error,
			errorMessage
		} = this.state

		//const acceptMime = accept && accept.split('/')[1]

		// There's a time frame, between the file being dropped and the upload finishes
		// that the state.files holds a new object that is actually a Blob
		// after the file is uploaded, the structure of the object changes due to the props.value received
		// Flow:
		// 1. drop a file in the uploader
		// 2. the UI renders a placeholder and the name of the file (given by the Blob)
		// 3. upload the file
		// 4. the response from the POST/upload trigger a change() method on the component
		// 5. the method, binded to value prop, gives back that prop (value)
		// 6. this prop triggers a re-render (through 'component did update')

		let mimeTypesText = ''

		if (accept) {
			const mimeTypeMatches = accept.match(/\/([\w]+)/g)
			if (mimeTypeMatches && mimeTypeMatches.length) {
				for (let i = 0; i < mimeTypeMatches.length; i++) {
					const separator = i+1 == mimeTypeMatches.length && i != 0 ? ' e ' : i > 0 ? ', ' : ''
					mimeTypesText += separator + mimeTypeMatches[i].substr(1)					
				}
			}
		}

		return (
			<section className={className && className}>
				<div>
					{!files.length &&
					<DropArea
            	onDrop={(files) => {
            		this.setState(
            			{
            				files: [...this.state.files, ...files],
            				filesForUpload: files
            			}, () => this.upload())
            	}}
            	accept={accept}
					>
						<p style={{ 'fontSize': '14px' }}>
							Arraste um {`ou multiplos ficheiros${mimeTypesText ? `(${mimeTypesText})` : ''}`} para aqui ou
							<br />
							<Button secondary size='mini'>
								Selecione {!single ? 'ficheiros' : 'ficheiro'} do computador
							</Button>
						</p>
					</DropArea>
					}
				</div>
				<aside>
					<FileList>
						{!!files.length &&
						<MultipleFileContainer
              	onDrop={(files) => {
              		this.setState(
              			{
              				files: [...this.state.files, ...files],
              				filesForUpload: files
              			}, () => this.upload())
              	}}
              	accept={accept}
						>
							<MultipleFileTitle>
								{title}
							</MultipleFileTitle>
							<FileContainer>
								{files && files.map((f, index) =>
									<FileWrapper key={index}>
										{!f.image &&
										<FileImage>
											<PlaceholderImage />
										</FileImage>
              				}
										{f.image &&
										<FileImage favorite={this.state.files[index].isFavourite ? 1 : 0}>
											<img src={!f.image.url.includes('http') ? MEDIA_BASE_URL + f.image.url : f.image.url} alt={''} />
											{/**
                            <FavoriteIcon
                              name={this.state.files[index].isFavourite ? 'star' : 'star outline'}
                              size={'small'}
                              onClick={(e) => {
                                e.stopPropagation()
                                this.handleSetFavorite(index)
                              }}
                              favorite={this.state.files[index].isFavourite ? 1 : 0}
                            />
                          */}
										</FileImage>
              				}
										<File>
											{this.getFileIcon(accept)}
											{f.image ? getFilenameOnFullPath(f.image.url).toLowerCase() : f.name}
											<Remove
              						onClick={(event) => {
              							event.stopPropagation()
              							this.remove(index)
              						}}
											/>
											{f.progress &&
											<ProgressBar
                        	percent={f.progress}
                        	active={f.progress && f.progress !== 100}
                        	success={f.progress && f.progress === 100}
                        	error={error}
											/>
              					}
										</File>
									</FileWrapper>
              		)}
							</FileContainer>
							<UploadMoreFiles>
								<Button
              			secondary
              			floated={'right'}
								>
									<Icon
              				name={'plus'}
              				style={{ 'color': '#616161' }}
									/>
									{`Adicionar ${this.getFileName(accept)}`}
								</Button>
							</UploadMoreFiles>
						</MultipleFileContainer>
						}
					</FileList>
					<Error>{errorMessage}</Error>
				</aside>
			</section>
		)
	}
}

const DropArea = styled(Dropzone)`
  display: flex;
  padding: 60px 20px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin: 1rem 0;
  border-radius: .28571429rem;
  border: 1px dashed rgba(34,36,38,.15);
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    padding: 0;
    color: #9B9B9B;
    text-align: center;
  }
  button {
    margin-top: 10px!important;
  }
`

const File = styled.div`
  border-radius: 100px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 2px 0 rgba(97,97,97,0.2);
  border: 1px solid #DDDDDD;
  list-style-type: none;
  padding: 0 10px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  line-height: 32px;
  position: relative;
  padding-right: 40px;
  margin-bottom: 10px;
  overflow: hidden;
`

const FileList = styled.div`
  margin-top: 10px;
`

const MultipleFileContainer = styled(Dropzone)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  background-color: #FAFAFA;

  ${({ withBorder }) => withBorder && `
    border: 1px solid rgba(155,155,155,0.15);
  `}

`

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const FileWrapper = styled.div`

`

const FileImage = styled.div`
  width: 156px;
  height: 108px;
  margin: 15px 0;
  position: relative;


  img {
    width: 100%;
    height: 100%;
    border: 8px solid #fff;
    outline-style: dashed;
    outline-color: rgba(155,155,155,0.15);
    outline-width: .5px;

    ${({ favorite }) => favorite && `
      border: 8px solid rgba(171,208,55,0.2);
      outline-color: rgba(171,208,55,0.8);
    `}
  }
`

const PlaceholderImage = styled(Placeholder.Image)`
  width: 100%;
  height: 100%;
  border: 8px solid #fff;
  outline-style: dashed;
  outline-color: rgba(155,155,155,0.15);
  outline-width: .5px;
`
/*
const FavoriteIcon = styled(Icon)`
  color: rgba(155,155,155, 1);
  position: absolute;
  right: 10px;
  top: 15px;

  ${({ favorite }) => favorite && `
    color: #ABD037;
`}
`*/

const MultipleFileTitle = styled.span`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 14px;
  margin: 8px 0;
`

const UploadMoreFiles = styled.div`
  align-self: flex-end;
  color: #616161;
  position: absolute;
  bottom: -18px;
  right: -18px;
`

const ProgressBar = styled(Progress)`
  &&& {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    margin: 0;
    left: 0;
    z-index: 1;


    .bar {
      height: 2px;
      min-width: 0;
      &.success {
        background-color: #00A651!important;
      }
    }
  }
`

const Error = styled.span`
  display: block;
  color: #db2828;
  font-size: 12px;
  padding: 10px;
`
