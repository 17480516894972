/* eslint-disable react/display-name */
import React from 'react'

import SummaryDescription from './SummaryDescription'
//import Contacts from './Contacts'
import Datasheet from './Datasheet'
import Documents from './Documents'
import Photos from './Photos'

export default ({ current, ...props }) => {
	switch (current) {
		case 1: return <SummaryDescription {...props} />
		case 2: return <Datasheet {...props} />
		case 3: return <Documents {...props} />
		case 4: return <Photos {...props} />
		/*case 5: return <SummaryForm {...props} />*/
		default: return null
	}
}