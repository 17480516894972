import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import API from 'api'

import {
	Form,
	Radio,
	TextArea
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import ImageGrid from '../../ImageGrid'
import FormField from 'components/FormField'
import IcTrails from 'icSvg/IcTrails'
import IcCycling from 'icSvg/IcCycling'
import IcHorseRides from 'icSvg/IcHorseRides'
import IcJeepTours from 'icSvg/IcJeepTours'
import IcCanoeing from 'icSvg/IcCanoeing'
import IcCanyoning from 'icSvg/IcCanyoning'
import IcSailing from 'icSvg/IcSailing'
import IcDiving from 'icSvg/IcDiving'
import IcSurf from 'icSvg/IcSurf'
import IcGolf from 'icSvg/IcGolf'
import IcPadel from 'icSvg/IcPadel'
import IcBirdWatching from 'icSvg/IcBirdWatching'
import IcYoga from 'icSvg/IcYoga'

class SportAndLeisure extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	submitData = (callback) => {
		const {campsiteId, submit, current} = this.props
		const {updateStep} = this.actions
			
		submit(data => API.Campsites.steps.updateSportAndLeisure(campsiteId, data), {
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
				}
				callback(success)
			}
		})
	}

	render () {

		const { t, form, loading, changeForm } = this.props

		const {
			description,
			trails,
			cycling,
			horseRides,
			jeepTours,
			canoeing,
			climbingOrAbseiling,
			sailing,
			diving,
			surf,
			golf,
			padel,
			birdwatching,
			yoga
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('form.sport_and_leisure')}
						</SegmentTitle>

						<FormField
							id='description'
							required
							margin={'28px 0 28px 0'}
							name={'general_description'}
							label={t('form.general_description')}
							error={(description.dirty && !description.valid) && description.message}
							control={TextArea}
							value={description.value || ''}
							onChange={(e, { value }) => changeForm(
								{ description: value })
							}
						/>

						<ImageGrid data={[
							{
								image: <IcTrails />,
								component: <>
									<RadioLabel>
										{t('campsite_details.trails')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='trails_no'
										checked={trails.value === false}
										onChange={() => changeForm({
											trails: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='trails_yes'
										checked={trails.value === true}
										onChange={() => changeForm({
											trails: true
										})}
									/>
								</>
							},
							{
								image: <IcCycling />,
								component: <>
									<RadioLabel>
										{t('campsite_details.cycling')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='cycling_no'
										checked={cycling.value === false}
										onChange={() => changeForm({
											cycling: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='cycling_yes'
										checked={cycling.value === true}
										onChange={() => changeForm({
											cycling: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcHorseRides />,
								component: <>
									<RadioLabel>
										{t('campsite_details.horseback_riding')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='horseback_riding_no'
										checked={horseRides.value === false}
										onChange={() => changeForm({
											horseRides: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='horseback_riding_yes'
										checked={horseRides.value === true}
										onChange={() => changeForm({
											horseRides: true
										})}
									/>
								</>
							},
							{
								image: <IcJeepTours />,
								component: <>
									<RadioLabel>
										{t('campsite_details.jeep_tours')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='jeep_tours_no'
										checked={jeepTours.value === false}
										onChange={() => changeForm({
											jeepTours: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='jeep_tours_yes'
										checked={jeepTours.value === true}
										onChange={() => changeForm({
											jeepTours: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcCanoeing />,
								component: <>
									<RadioLabel>
										{t('campsite_details.canoeing')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='canoeing_no'
										checked={canoeing.value === false}
										onChange={() => changeForm({
											canoeing: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='canoeing_yes'
										checked={canoeing.value === true}
										onChange={() => changeForm({
											canoeing: true
										})}
									/>
								</>
							},
							{
								image: <IcCanyoning />,
								component: <>
									<RadioLabel>
										{t('campsite_details.climbing')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='climbing_no'
										checked={climbingOrAbseiling.value === false}
										onChange={() => changeForm({
											climbingOrAbseiling: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='climbing_yes'
										checked={climbingOrAbseiling.value === true}
										onChange={() => changeForm({
											climbingOrAbseiling: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcSailing />,
								component: <>
									<RadioLabel>
										{t('campsite_details.boat_trip')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='boat_trip_no'
										checked={sailing.value === false}
										onChange={() => changeForm({
											sailing: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='boat_trip_yes'
										checked={sailing.value === true}
										onChange={() => changeForm({
											sailing: true
										})}
									/>
								</>
							},
							{
								image: <IcDiving />,
								component: <>
									<RadioLabel>
										{t('campsite_details.diving')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='diving_no'
										checked={diving.value === false}
										onChange={() => changeForm({
											diving: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='diving_yes'
										checked={diving.value === true}
										onChange={() => changeForm({
											diving: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcSurf />,
								component: <>
									<RadioLabel>
										{t('campsite_details.surf')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='surf_no'
										checked={surf.value === false}
										onChange={() => changeForm({
											surf: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='surf_yes'
										checked={surf.value === true}
										onChange={() => changeForm({
											surf: true
										})}
									/>
								</>
							},
							{
								image: <IcGolf />,
								component: <>
									<RadioLabel>
										{t('campsite_details.golf')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='golf_no'
										checked={golf.value === false}
										onChange={() => changeForm({
											golf: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='golf_yes'
										checked={golf.value === true}
										onChange={() => changeForm({
											golf: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcPadel />,
								component: <>
									<RadioLabel>
										{t('campsite_details.padel')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='padel_no'
										checked={padel.value === false}
										onChange={() => changeForm({
											padel: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='padel_yes'
										checked={padel.value === true}
										onChange={() => changeForm({
											padel: true
										})}
									/>
								</>
							},
							{
								image: <IcBirdWatching />,
								component: <>
									<RadioLabel>
										{t('campsite_details.bird_watching')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='bird_watching_no'
										checked={birdwatching.value === false}
										onChange={() => changeForm({
											birdwatching: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='bird_watching_yes'
										checked={birdwatching.value === true}
										onChange={() => changeForm({
											birdwatching: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid columnWidth={50} data={[
							{
								image: <IcYoga />,
								component: <>
									<RadioLabel>
										{t('campsite_details.yoga')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='yoga_no'
										checked={yoga.value === false}
										onChange={() => changeForm({
											yoga: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='yoga_yes'
										checked={yoga.value === true}
										onChange={() => changeForm({
											yoga: true
										})}
									/>
								</>
							}
						]}
						/>
						
					</Form>
				</BoxSegment>
			</>
		)
	}
}

export default withTranslation()(Logic(SportAndLeisure))

const RadioLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`