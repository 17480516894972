/* eslint-disable react/display-name */
/**
 * Debouncer Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
import React, { Component } from 'react'
import debounce from 'lodash/debounce'

export default (WrappedComponent) => {
	return class extends Component {
		constructor (props) {
			super(props)
			this.state = {
				value: props.value
			}
			this.lastPropValue = props.value

			this.debouncedOnChange = debounce(event => {
				props.onChange(event.target.value)
			}, 150)
		}

		componentWillReceiveProps (props) {
			if (this.lastPropValue !== props.value) {
				this.setState({ value: props.value })
			}

			if (props.cancel === 'true') {
				if (this.debouncedOnChange) {
					this.debouncedOnChange.cancel()
				}
			}
		}

		handleChange (event) {
			event.persist()
			this.setState({ value: event.target.value })
			this.debouncedOnChange(event)
		}

		getValue () {
			const value = this.props.value !== this.lastPropValue
				? this.props.value
				: this.state.value

			this.lastPropValue = this.props.value

			return value
		}

		render () {
			return (
				<WrappedComponent
					{...this.props}
					value={this.getValue()}
					onChange={this.handleChange.bind(this)}
				/>
			)
		}
	}
}
