import { kea } from 'kea'
import UsageReservationDetailsLogic from 'screens/UsageReservationDetails/logic'

export default kea({
	path: () => ['scenes', 'containers', 'UsagePayment'],

	connect: {
		props: [
			UsageReservationDetailsLogic, [
				'form',
				'loading',
				'price',
				'paymentMethods',
				'status'
			]
		],
		actions: [
			UsageReservationDetailsLogic, [
				'changeForm',
				'cancelReservation'
			]
		]
	}
})
