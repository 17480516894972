/**
 * Sidebar Container Utils
 * Please write a description
 *
 * @author Mário Madeira <mmadeira@ubiwhere.com>
 */

// Open Children items
export const openChildren = (state, fatherKey, isChildrenFocused) => {
	const { SidebarItemOpen } = state.state
	const find = SidebarItemOpen.find(({ key }) => key === fatherKey)

	if (find) {
		if (!isChildrenFocused) return
		state.setState({ SidebarItemOpen: SidebarItemOpen.map(item => {
			if (item.key === find.key) {
				return {
					...item,
					open: !find.open
				}
			}
			return item
		})})
	}

	if (!find) {
		state.setState(
			{
				SidebarItemOpen: [
					...SidebarItemOpen,
					{ key: fatherKey, open: true }
				]
			}
		)
	}
}

// Check if father was clicked to open children
export const isOpen = (state, childrenKey) => {
	const { SidebarItemOpen } = state.state
	const find = SidebarItemOpen.find(({ key }) => key === childrenKey)

	if (find && find.open) {
		return true
	} else {
		return false
	}
}

export const checkSelected = (
	currentRoute,
	path,
	targetPath = [],
	key
) => {

	const { path: currentPath, key: currentKey, isChildrenOf: currentIsChildrenOf } = currentRoute

	if (key === currentIsChildrenOf || key === currentKey || currentPath === path) return true
	else if (targetPath.length) {
		const foundTargetPath = targetPath.some(target => new RegExp(target).test(currentPath))
		if (foundTargetPath) return true
	}
	return false
}
