import moment from 'moment'
import * as Check from 'validations'

const DEFAULT_VALUES = {
	name: {
		value: ''
	},
	timeInterval: {
		value: `${moment().format('DD-MM-YYYY')} - ${moment().add(1, 'day').format('DD-MM-YYYY')}`
	},
	spots: {
		value: []
	}
}

const VALIDATIONS = {
	name: [
		Check.isRequired
	],
	timeInterval: [
		Check.isRequired,
		Check.hasDateIntervalFilled
	],
	spots: [
		Check.isRequired
	]
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}