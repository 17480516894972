/**
 * TabFilter Component
 * Please write a description
 *
 * @author Mário Madeira <mmadeira@ubiwhere.com>
 */
import styled from 'styled-components'
import { Tab as SemanticTab } from 'semantic-ui-react'

export const Tab = styled(SemanticTab)`
  &&&& {

    ${({ background }) => background && `
      padding-top: 35px;
      background-color: #FAFAFA;
    `}


    ${({ tableless }) => tableless && `
        margin-top: 20px;
        //width: 100%;
        max-width: 100%;
    `}

    ${({ backgroundless }) => backgroundless && `
      div a.item {
        padding: 0 16px 15px 16px;
        font-family: "Open Sans";
        font-size: 16px;
        line-height: 16px;
      }

      div a.item:not(.active) {
        color: #9B9B9B;
      }

      div a.active.item {
        color: #ABD037 !important;
        background-color: transparent;
        padding-bottom: 12px;
        font-weight: 600;
        border: none;
      }
    `}

    .menu {
      border-bottom: 1px solid rgba(34,36,38,.15);
      margin-bottom: 0;
      border: none;

      ${({ tableless }) => tableless && `
        border-bottom: 1px solid rgba(34,36,38,.15);
      `}

      a {
        color: #5B5B5B;
        font-size: 16px;
        line-height: 16px;

        i {
          color: #CB8438;
        }

        ${({ tableless }) => tableless && `
          font-weight: normal;
          padding: 20px 30px;
        `}

        &.active {
          background-color: rgba(238,238,238,0.3);;
          border-bottom: 4px solid #102526 !important;
          border: 1px solid rgba(155, 155, 155, 0.1);
          box-shadow: inset 0 1px 15px 0 rgba(0,0,0,0.02);
          border-left: none;
          border-right: none;
          border-top: none;

          ${({ tableless }) => tableless && `
            border-bottom: 3px solid #ABD037 !important;
            box-shadow: none;
            background: rgba(155,155,155,0.05);
            border: 1px solid rgba(155,155,155,0.15);
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            color: #000 !important;
            border-radius: 3px;
          `}
        }

        &.active, &:hover, &:active, &:focus, &.active:hover{
          color: #102526;

          ${({ tableless }) => tableless && `
            color: #5B5B5B;
            cursor: pointer;
          `
}
      }
    }
  }
`

export const Pane = styled(SemanticTab.Pane)`
  &&&& {
    box-shadow: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 3px;
  }
`
export default {
	Tab,
	Pane
}
