import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Grid, Form, Input } from 'semantic-ui-react'

import FormField from 'components/FormField'
import FormError from 'components/FormError'
import MultipleSelector from 'components/MultipleSelector'
import PasswordMask from 'components/PasswordMask'

import { LOCATION_TYPES } from 'utils'

class EntityRegistrationForm extends Component {
	constructor (props) {
		super(props)

		// Form Passwords showing state
		this.state = {
			registerMask: false,
			registerConfirmationMask: false
		}
	}

getPasswordState = (name, state) => {
	this.setState({ [name]: state })
}

// Allow component to deselect options
// and to select both
handletypeSelection = (value) => {
	const { type } = this.props.form

	if (!type.value.includes(value)) {
		this.props.change({ type: [...type.value, value] })
	} else {
		this.props.change({ type: type.value.filter(item => item !== value) })
	}
}

render () {
	const {
		change,
		form,
		t,
		contacts
	} = this.props

	const {
		name,
		email,
		nif,
		password,
		passwordConfirmation,
		type
	} = form

	return (
		<GridWrapper>
			<Row style={{ paddingTop: '0px' }}>
				<Column width={16}>
					<Form>
						<FormField
							required
							name={'nif'}
							label={t('form.nif_company')}
							valid={nif.dirty && nif.valid}
							error={nif.dirty && !nif.valid}
							control={Input}
							value={nif.value}
							maxLength={9}
							onChange={(e, { value }) => change({ nif: value })}
						/>

						{nif.dirty && !nif.valid &&
							<FormError>{nif.message}</FormError>
						}
					</Form>
				</Column>
			</Row>
			<Row>
				<Column width={16}>
					<Form>
						<FormField
							required
							name={'name'}
							label={t('form.company_name')}
							valid={name.dirty && name.valid}
							error={name.dirty && !name.valid}
							control={Input}
							value={name.value}
							onChange={(e, { value }) => change({ name: value })}
						/>
						{name.dirty && !name.valid &&
							<FormError>{name.message}</FormError>
						}
					</Form>
				</Column>
			</Row>
			<Row>
				<Column width={16}>
					<Form>
						<FormField
							required
							name={'name'}
							label={t('form.email')}
							valid={email.dirty && email.valid}
							error={email.dirty && !email.valid}
							control={Input}
							value={email.value}
							onChange={(e, { value }) => change(
								{ email: value }
							)}
						/>
						{email.dirty && !email.valid &&
							<FormError>{email.message}</FormError>
						}
					</Form>
				</Column>
			</Row>
			<Row>
				<Column width={16}>
					<Form>
						<FormField
							required
							valid={password.dirty && password.valid}
							error={password.dirty && !password.valid}
						>
							<Label>Palavra-passe</Label>
							<Input
								type={this.state.registerMask
									? 'text'
									: 'password'
								}
								value={password.value}
								onChange={(e, { value }) => change(
									{ password: value })
								}
							/>
							{password.dirty && !password.valid &&
								<FormError>{password.message}</FormError>
							}
							<PasswordMask
								name={'registerMask'}
								getPasswordState={this.getPasswordState}
							/>
						</FormField>
					</Form>
				</Column>
			</Row>
			<Row>
				<Column width={16}>
					<Form>
						<FormField
							required
							valid={passwordConfirmation.dirty &&
                passwordConfirmation.valid}
							error={passwordConfirmation.dirty &&
                !passwordConfirmation.valid}
						>
							<Label>Confirmar palavra-passe</Label>
							<Input
								type={this.state.registerConfirmationMask
									? 'text'
									: 'password'
								}
								value={passwordConfirmation.value}
								onChange={(e, { value }) => change(
									{ passwordConfirmation: value }
								)}
							/>
							<PasswordMask
								name={'registerConfirmationMask'}
								getPasswordState={this.getPasswordState}
							/>
              
							{/* eslint-disable-next-line max-len */}
							{passwordConfirmation.dirty && !passwordConfirmation.valid &&
								<FormError>
									{passwordConfirmation.message}
								</FormError>
							}
						</FormField>
					</Form>
				</Column>
			</Row>
			<Row>
				<Column width={16}>
					<Form>
						<FormField required>
							<Label>{t('form.location_type_label')}</Label>
							<SmallLabel>
								{t('form.location_type_description')}
							</SmallLabel>
							<MultipleSelector
								style={{ marginLeft: 0 }}
								items={LOCATION_TYPES(t)}
								value={type.value}
								itemSize={'200px'}
								onSelectionChange={({ value, disabled }) =>
									!disabled && this.handletypeSelection(value)
								}
							/>
							{type.dirty && !type.valid &&
								<FormError>{type.message}</FormError>
							}
						</FormField>
					</Form>
				</Column>
			</Row>

			<Row>
				<Column width={16}>
					{contacts &&
					<Help>
						{/* eslint-disable-next-line max-len */}
						{t('common.contact_info')} <a href={`mailto:${contacts.CONTACT_US_EMAIL}`}>{contacts.CONTACT_US_EMAIL}</a>
					</Help>
					}
				</Column>
			</Row>
		</GridWrapper>
	)
}
}

export default withTranslation()(EntityRegistrationForm)

const GridWrapper = styled(Grid)`
  padding: 25px 0 !important;
`

const Row = styled(Grid.Row)``

const Column = styled(Grid.Column)``

const SmallLabel = styled.span`
  display: block;
  opacity: 0.5;
  color: #616161;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
`

const Label = styled.label`
  &&&& {
    color: #616161;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 14px;
    margin: 0 0 12px 0 !important;
  }
`

const Help = styled.div`
  margin-top: 40px;
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  text-align: center;
  font-family: Open sans;

  a {
    color: #000;
  }
`