export const findNationalityObject = (id, nationalities) => {
	if (!id || nationalities.length === 0) {
		return {}
	}
	
	const findNationality = nationalities.filter(item => item.id === id)

	if (findNationality.length > 0) {
		return findNationality[0]
	}
	
	return {}
}