import { kea } from 'kea'
import AppLogic from 'containers/App/logic'

export default kea({
	path: () => ['scenes', 'containers', 'header'],

	connect: {
		props: [
			AppLogic, [
				'currentRoute',
				'user'
			]
		],
		actions: [
			AppLogic, [
				'navigate',
				'logout',
			]
		]
	}

})
