/**
 * Sidebar Container Logic
 * Please write a description
 *
 */

import { kea } from 'kea'
// import { put } from 'redux-saga/effects'

import PropTypes from 'prop-types'
// import * as Check from 'validations'

/** logic */
import AppLogic from 'containers/App/logic'
import BookingManagementLogic from 'screens/BookingManagement/logic'

export default kea({
	path: () => ['scenes', 'containers', 'Sidebar'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
		],
		props: [
			AppLogic, [
				'currentRoute',
				'currentRoutes',
				'user'
			],
			BookingManagementLogic, [
				'selectedServiceArea'
			]
		]
	},

	selectors: ({ selectors }) => ({
		/** Get value from routes->showMenu to hide or show the Sidebar **/
		sidebarView: [
			() => [selectors.currentRoute],
			({ showSidebar }) => showSidebar,
			PropTypes.bool
		],

		/** Filter routes to show on Sidebar **/
		sidebarRoutes: [
			() => [selectors.currentRoutes],
			(routes) => {
				let arr = []
				Object.keys(routes).forEach((key) => {
					if (routes[key].showInMenu) {
						arr = [ ...arr, { ...routes[key] } ]
					}
				})

				return arr
			}, PropTypes.array
		]
	}),
})
