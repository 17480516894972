/**
 * Empty Scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 */
import React, { Component } from 'react'
import Logic from './logic'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Message as SemanticMessage } from 'semantic-ui-react'

class Empty extends Component {
	render () {
		const { currentRoute, t } = this.props

		return (
			<Wrapper>
				{t(`navigation.${currentRoute.name}`)}
				<Message
					header={'Disponível brevemente'}
					content={`${t('navigation.' + currentRoute.name)} estará disponível em breve`}
				/>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(Empty))

const Message = styled(SemanticMessage)`
  &&&& {
    margin-top: 25px;
    width: 60%;
    font-size: 17px;
    font-family: Open Sans;
  }
`

const Wrapper = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: Open Sans;
`
