import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { 
	Radio,
	Dropdown as SemanticDropdown, 
} from 'semantic-ui-react'

import Label from 'components/Label'
import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import FormError from 'components/FormError'

import { DEFAULT_RECEPTION_TYPES } from './utils'

class Installation extends Component  {
	render () {
		const { t, form, changeForm } = this.props
    
		const { 
			sanitaryFacilities,
			reception,
			receptionType,
			lobby,
			gate,
			fencing,
			surveillanceService,
		} = form

		return (
			<Wrapper>
				<FormField required>
					<Label>
						{t('form.sanitary_facilities')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='sanitaryFacilities_no'
							checked={!sanitaryFacilities.value}
							control={Radio}
							onChange={() => changeForm(
								{ sanitaryFacilities: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='sanitaryFacilities_yes'
							checked={sanitaryFacilities.value}
							control={Radio}
							onChange={() => changeForm(
								{ sanitaryFacilities: true })
							}
						/>
					</FormGroup>
				</FormField>
								
				<FormField required>
					<Label>
						{t('form.reception')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='reception_no'
							checked={!reception.value}
							control={Radio}
							onChange={() => changeForm(
								{ reception: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='reception_yes'
							checked={reception.value}
							control={Radio}
							onChange={() => changeForm(
								{ reception: true })
							}
						/>
						<FormGroup inline>
							<Label
								style={{
									borderLeft: '1px solid',
									paddingLeft: '6px',
									whiteSpace: 'nowrap'
								}}
							>
								{t('form.reception_type')}
							</Label>
							<Dropdown
								placeholder={
									t('form.reception_type_option')
								}
								disabled={!reception.value}
								onChange={(e, data) =>
									DEFAULT_RECEPTION_TYPES &&
                      changeForm({ receptionType: data.value })
								}
								options={DEFAULT_RECEPTION_TYPES}
								value={receptionType.value}
								selection
							/>
							<FormError>{reception.message}</FormError>
						</FormGroup>
					</FormGroup>
				</FormField>

				<FormField required>
					<Label>
						{t('form.lobby')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='lobby_no'
							checked={!lobby.value}
							control={Radio}
							onChange={() => changeForm(
								{ lobby: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='lobby_yes'
							checked={lobby.value}
							control={Radio}
							onChange={() => changeForm(
								{ lobby: true })
							}
						/>
					</FormGroup>
				</FormField>

				<FormField required>
					<Label>
						{t('form.gate')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='gate_no'
							checked={!gate.value}
							control={Radio}
							onChange={() => changeForm(
								{ gate: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='gate_yes'
							checked={gate.value}
							control={Radio}
							onChange={() => changeForm(
								{ gate: true })
							}
						/>
					</FormGroup>
				</FormField>

				<FormField required>
					<Label>
						{t('form.fencing')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='fencing_no'
							checked={!fencing.value}
							control={Radio}
							onChange={() => changeForm(
								{ fencing: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='fencing_yes'
							checked={fencing.value}
							control={Radio}
							onChange={() => changeForm(
								{ fencing: true })
							}
						/>
					</FormGroup>
				</FormField>

				<FormField required>
					<Label>
						{t('form.surveillance_service')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='surveillance_service_no'
							checked={!surveillanceService.value}
							control={Radio}
							onChange={() => changeForm(
								{ surveillanceService: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='surveillance_service_yes'
							checked={surveillanceService.value}
							control={Radio}
							onChange={() => changeForm(
								{ surveillanceService: true })
							}
						/>
					</FormGroup>
				</FormField>
			</Wrapper>
		)
	}
}

export default withTranslation()(Installation)

const Wrapper = styled.div`
  margin-top: 24px;
`

const Dropdown = styled(SemanticDropdown)`
  width: 100%;
`