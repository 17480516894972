import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import CampsiteLogic from '../../../ui/screens/CampsiteManagement/logic'
import ToasterLogic from 'containers/Toaster/logic'

import API from 'api'

export default kea({
	path: () => ['scenes', 'ServiceLocation'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
		],
		actions: [
			AppLogic, [
				'navigate',
			],
			CampsiteLogic, [
				'createCampsite',
				'getCampsites'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		createServiceArea: () => true,
		setServiceArea: process => ({ process }),
		setServiceAreaId: id => ({ id }),
		clearServiceArea: () => true,
		reset: () => true
	}),

	reducers: ({ actions }) => ({
    
		serviceAreaProcess: [null, Proptypes.any, {
			[actions.setServiceArea]: (_, payload) => payload.process,
			[actions.clearServiceArea]: () => null,
			[actions.reset]: () => null
		}],

		serviceAreaProcessId: [null, Proptypes.any, {
			[actions.setServiceArea]: (_, payload) => payload.process.id,
			[actions.setServiceAreaId]: (_, payload) => payload.id,
			[actions.clearServiceArea]: () => null,
			[actions.reset]: () => null
		}],

	}),

	start: function * () {
		const { getCampsites } = this.actions
		
		yield put(getCampsites())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.createServiceArea]: workers.createServiceArea,
	}),

	workers: {

		* createServiceArea () {
			const {
				setServiceArea,
				showToaster,
				navigate
			} = this.actions

			const { t } = this.props

			try {
				const request = yield call(API.Areas.create)
				const area = yield call(API.Areas.getById, request.id)
				yield put(setServiceArea(area))
				yield put(navigate(`/gestao-processos/pedido/${request.id}`))

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.process_start_error')
				))
			}
		}

	}

})
