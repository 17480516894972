import * as React from 'react'

const IcSurf = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/iic-surf</title>
			<g id="icons/servicos/iic-surf" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M9.44404687,6.59460937 L9.44404687,21.1089844 C7.0835625,22.6345312 5.1765,23.5537031 4.25414062,23.9642812 C3.9849375,24.0841406 3.686625,23.8685625 3.71517187,23.5752656 L3.71517187,23.5752656 L4.06996875,19.9280156 L0.422625,20.2828125 C0.12890625,20.3113594 -0.086015625,20.0125312 0.033984375,19.7429062 C0.828421875,17.958 3.52490625,12.4910156 8.83317187,7.18270312 C9.03464062,6.98123437 9.23840625,6.78525 9.44404687,6.59460937 L9.44404687,6.59460937 Z M14.8395938,2.833875 L14.8395938,17.0205938 C13.5042188,18.2000625 12.1892344,19.2189375 10.9562344,20.088 L10.9562344,20.088 L10.9562344,5.3105625 C12.2349844,4.3153125 13.5536719,3.50034375 14.8395938,2.833875 L14.8395938,2.833875 Z M23.4044531,0.006140625 C23.748375,-0.04659375 24.0445313,0.24984375 23.9917969,0.593765625 C23.6579063,2.77167187 22.20525,9.77479687 16.8152344,15.1648125 C16.660875,15.3191719 16.5063281,15.470625 16.3517813,15.620625 L16.3517813,15.620625 L16.3517813,2.10782812 C19.4609531,0.729140625 22.1870156,0.19275 23.4044531,0.006140625 Z" id="icon" fill="#102526"/>
			</g>
		</svg>
	)

export default IcSurf
