import * as React from 'react'

function IcEnergy (props) {
	return (
		<svg width={32} height={34} viewBox="0 0 24 24" {...props}>
			<title>{'icons/servicos/iic-energy'}</title>
			<path
				// eslint-disable-next-line max-len
				d="M9.616 3c.287 0 .547.121.736.316.188.196.304.467.304.765v4.277h.92c.208 0 .381.156.417.362l.007.079v1.232c0 .217-.15.396-.347.434l-.077.007h-.084v1.768c0 .076-.02.15-.054.215l-.04.062-2.996 3.842-.017-.001v1.56a.435.435 0 01-.373.438v1.562c0 .243-.19.44-.424.44H7.54v4.202c0 .243-.19.44-.423.44H5.884a.432.432 0 01-.424-.44l-.001-4.202h-.047a.432.432 0 01-.424-.44v-1.565l-.026-.001a.436.436 0 01-.348-.434v-1.56h-.016l-2.995-3.84a.449.449 0 01-.089-.203l-.006-.075v-1.768h-.084a.429.429 0 01-.417-.362L1 10.031V8.8c0-.216.15-.396.347-.433l.077-.008h.92V4.081C2.344 3.484 2.81 3 3.384 3c.287 0 .547.121.735.316.188.196.305.467.305.765l-.001 4.277h4.153V4.081c0-.597.466-1.081 1.04-1.081zm-.944 8.342H4.328a.405.405 0 00-.397.413c0 .2.136.366.317.404l.08.009h4.344c.22 0 .397-.185.397-.413a.405.405 0 00-.397-.413zM20.517.885a5.108 5.108 0 01-4.064 9.187l-.223-.059-2.158 1.009a.387.387 0 01-.546-.291l-.003-.077.108-2.38a5.11 5.11 0 01-.202-6.072C15.017-.103 18.206-.694 20.517.885zm-1.673.738h-1.408a.204.204 0 00-.145.06l-.032.044-1.74 3.076a.204.204 0 00.121.297l.056.008h1.55l-1.098 2.42c-.09.2.145.368.302.254l.04-.039 2.908-3.498a.204.204 0 00-.103-.327l-.054-.007h-1.713l1.479-1.961a.204.204 0 00-.163-.327z"
				fill="#102526"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default IcEnergy
