import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import SuccessMessage from 'components/SuccessMessage'
import { SecondTitle } from 'components/SubTitle'
import Title from 'components/Title'
import Segment from 'components/Segment'
import Button from 'components/Button'

import EntityRegistrationForm from 'containers/EntityRegistrationForm'
import Errors from 'containers/ErrorList'
import Loading from 'containers/Loading'

import Stamp from './clean.png'

class Register extends Component {
	render () {
		const { change, registerEntity, navigate } = this.actions
		const { 
			response,
			submiting,
			loading,
			form,
			error,
			t,
			contacts
		} = this.props

		if (loading) {
			return <Loading t={t} />
		}

		if (response) {
			return (
				<Wrapper>
					<SuccessMessage
						heading={t('success.register_submitted')}
						secondTitle={t('success.confirm_registry_email_sent')}
					/>
				</Wrapper>
			)
		}

		return (
			<Wrapper>
				<Title bolder uppercase centered>
					{t('register.screen_title')}
				</Title>
				<SecondTitle>
					{t('register.screen_subtitle')}
				</SecondTitle>
				<Image src={Stamp} />

				<BoxSegment>        
					<EntityRegistrationForm
						loading={submiting}
						change={change}
						form={form}
						contacts={contacts}
					/>
          
					{error && !!error.length && <Errors list={error} />}

					<FloatSubmit
						primary
						disabled={submiting}
						onClick={() => registerEntity()}
					>
						{t('options.register')}
					</FloatSubmit>
				</BoxSegment>

				<FloatLeft>
					<RegisterLink>
						<NoAccountLabel>
							{t('options.already_registered_login')}
              &nbsp;
						</NoAccountLabel>
            
						<span onClick={() => navigate('/entrar')}>
							{t('options.login')}
						</span>
					</RegisterLink>
				</FloatLeft>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(Register))

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;
`
const BoxSegment = styled(Segment)`
  &&&& {
    width: 544px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    margin-top: 40px;
    padding: 40px;
    position: relative;
    padding-bottom: 60px;
  }
`

const FloatSubmit = styled(Button)`
  &&&& {
    width: 50%;
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translate(-50%, 0)translateZ(1px);
    padding: 20px;
    box-sizing: content-box;

    &:hover {
      transform: translate(-50%, -1px)translateZ(1px);
    }
  }
`
const NoAccountLabel = styled.label`
  color: #000;
`

const RegisterLink = styled.a`
  color: #000000;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  text-transform: uppercase;

  span {
    border-bottom: 1px dashed #102526;
    color: #102526;
  }

  &:hover {
    color: #000;
  }
`

const FloatLeft = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  width: 545px;
`

const Image = styled.img`
  width: 75px;
  height: 75px;
  margin-top: 40px;
`