import moment from 'moment'
import * as Check from 'validations'

const DEFAULT_VALUES = {
	serviceArea: {
		value: null,
		edit: false
	},
	checkInOut: {
		value: `${moment().format('DD-MM-YYYY')} - ${moment().add(1, 'day').format('DD-MM-YYYY')}`,
		edit: false
	},
	checkIn: {
		value: ''
	},
	checkOut: {
		value: ''
	},
	checkInOutInformations: {
		value: []
	},
	nights: {
		value: 1,
		edit: false
	},
	guests: {
		value: 1,
		edit: false
	},
	spotName: {
		value: '',
		edit: false
	},
	needsElectricitySupply: {
		value: false,
		edit: false
	},
	needsWaterSupply: {
		value: false,
		edit: false
	},
	serviceAreaIsAdapted: {
		value: false,
		edit: false
	},
	guest1Name: {
		value: '',
		edit: false
	},
	guest1Birthdate: {
		value: moment().format('YYYY-MM-DD'),
		edit: false
	},
	guest1Birthplace: {
		value: '',
		edit: false
	},
	guest1Nationality: {
		value: '',
		edit: false
	},
	guest1Email: {
		value: '',
		edit: false
	},
	guest1Mobile: {
		value: '',
		edit: false
	},
	guest1IdentityDocumentType: {
		value: '',
		edit: false
	},
	guest1IdentityDocument: {
		value: '',
		edit: false
	},
	guest1IdentityDocumentCountry: {
		value: '',
		edit: false
	},
	guest1Nif: {
		value: '',
		edit: false
	},
	guest1ResidenceCountry: {
		value: '',
		edit: false
	},
	guest1ResidencePlace: {
		value: '',
		edit: false
	},
	otherGuests: {
		value: [],
		edit: false
	},
	motorhomeModel: {
		value: '',
		edit: false
	},
	motorhomeLicensePlate: {
		value: '',
		edit: false
	},
	motorhomeInfo: {
		value: '',
		edit: false
	},
	paymentMethod: {
		value: null,
		edit: false
	},
	paymentDetails: {
		value: null,
		edit: false
	}
}

const VALIDATIONS = {
	serviceArea: [
		Check.isRequired
	],
	checkInOut: [
		Check.isRequired,
		Check.hasDateIntervalFilled,
		Check.isValidDateInterval,
		Check.isDateIntervalEqualOrAfterToday
	],
	nights: [
		Check.isRequired
	],
	guests: [
		Check.isRequired
	],
	guest1Name: [
		Check.isRequired
	],
	guest1Birthdate: [
		Check.isRequired
	],
	guest1Birthplace: [
		Check.isRequired
	],
	guest1Nationality: [
		Check.isRequired
	],
	guest1Email: [
		Check.isRequired,
		Check.Email
	],
	guest1Nif: [
		Check.isRequired
	],
	guest1Mobile: [
		Check.isRequired,
		Check.isValidPhoneNumber
	],
	guest1IdentityDocument: [
		Check.isRequired
	],
	guest1ResidencePlace: [
		Check.isRequired
	],
	guest1ResidenceCountry: [
		Check.isRequired
	],
	guest1IdentityDocumentCountry: [
		Check.isRequired
	],
	guest1IdentityDocumentType: [
		Check.isRequired
	],
	motorhomeModel: [
		Check.isRequired
	],
	motorhomeLicensePlate: [
		Check.isRequired
	]
}

// Checks the validation of a field
export const changeEdit = (state, payload) => {
	let newStateChanges = {}
	Object.keys(payload.field).forEach((fieldName) => {
		const field = payload.field[fieldName]

		let settedField = {
			...state[fieldName],
			edit: field
		}

		newStateChanges = { ...newStateChanges, [fieldName]: settedField }
	})

	return { ...state, ...newStateChanges }
}

export const resetEdit = (state) => {
	for (var key in state) {
		if (Object.prototype.hasOwnProperty.call(state, key)) {
			state[key].edit = false
		}
	}
	
	return state
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}