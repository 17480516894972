import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import API from 'api'

export default kea({
	path: () => ['scenes', 'containers', 'CampingManagementRequestForm', 'OtherServices'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			CampsiteManagementLogic, [
				'campsiteId'
			]
		]
	},

	actions: () => ({
		fetchForm: () => true
	}),

	reducers: () => ({

	}),

	start: function * () {
		
	},

	takeLatest: ({actions, workers}) => ({
		[actions.fetchForm]: workers.fetchForm
	}),

	workers: {

		* fetchForm () {
			
			const { fetchData } = this.props
			const campsiteId = yield this.get('campsiteId')
			
			fetchData((data, config) => API.Campsites.steps.getOtherServices(campsiteId, config), {
				resolveData: data => {
					return {services: data.serviceName.map(service => ({name: service}))}
				}
			})

		},
	}
})
