// Theme variables
export default {
	/** * Colors */
	primaryColor: '#9B9B9B',
	secondaryColor: '#FFFFFF',
	anchorColor: '#ABD037',
	iconColor: '#616161',
	inputLabelColor: '#616161',
	primaryButtonColor: '#102526',
	secondaryButtonColor: '#FFFFFF',
	degreesColor: '#565656',
	degreesDescriptionColor: '#9B9B9B',

	/** * Shadows */
	primaryButtonShadow: '0 4px 2px 0 rgba(0, 107, 47, 0.3)',
	primaryButtonHoverShadow: '0 5px 2px 0 rgba(16,37,38,0.2)',
	secondaryButtonShadow: '0 4px 2px 0 rgba(97, 97, 97, 0.2)',
	secondaryButtonHoverShadow: '0 5px 2px 0 rgba(97, 97, 97, 0.2)',
	segmentShadow: '0 0 2px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05)',

	/** * Background */
	bodyBackground: '#FFFFFF',
	headerBackground: '#eef1f6',
	segmentBackground: '#FFFFFF',
	accordionBackground: 'rgba(255, 255, 255, 1)',

	/** * Font Families */
	defaultFontFamily: 'sans-serif',
	inputFontFamily: 'Open Sans',
	headerFontFamily: 'azo-sans-web, sans-serif'
}