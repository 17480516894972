import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import API from 'api'
/** Logic */
import UsageReservationDetailsLogic from 'screens/UsageReservationDetails/logic'

export default kea({
	path: () => ['scenes', 'containers', 'UsageDetailsGuestInfo'],

	connect: {
		props: [
			UsageReservationDetailsLogic, [
				'loading',
				'form'
			]
		],
		actions: [
			UsageReservationDetailsLogic, [
				'changeEditForm',
				'changeForm'
			]
		]
	},

	actions: () => ({
		getNationalities: params => params,
		setNationalities: country => country,
		reset: () => true
	}),
	
	reducers: ({ actions }) => ({
		nationalities: [[], Proptypes.array, {
			[actions.setNationalities]: (state, payload) => payload,
			[actions.reset]: () => ([])
		}],
				
		isFetching:[false, Proptypes.bool, {
			[actions.getNationalities]: () => true,
			[actions.setNationalities]: () => false,
			[actions.reset]: () => false
		}]
	}),
	
	selectors: ({ selectors }) => ({

		nationalitiesOptions: [ () => [ selectors.nationalities],
			nationalities => nationalities.map(item => ({
				key: item.id,
				text: item.designation,
				value: item.id
			}))
		]
	}),
	
	start: function* () {
		const { getNationalities } = this.actions

		yield put(getNationalities())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.getNationalities]: workers.getNationalities
	}),
	
	workers: {
		* getNationalities () {			
			let params = { limit: 'max' }
			
			const { setNationalities } = this.actions
			
			try {
				const response = yield call(
					API.Municipalities.getCountries,
					{ params }
				)
				const { results } = response
				yield put(setNationalities(results))
			} catch (e) {
				console.log(e)
			}
		},
	}
})
