/**
 * SubTitle Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import styled from 'styled-components'

export default styled.h2`
  color: #9B9B9B;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  margin-bottom: 32px;
  width: 538px;

  ${({ indoc }) => indoc && `
    margin-top: 50px;
  `}
`

export const SecondTitle = styled.h2`
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: normal;
  margin: 0;
`
