import axios from 'axios'
import ENDPOINTS from '../endpoints'

const getPaymentMethods = params => axios.get(ENDPOINTS.PAYMENTMETHODS, params)
	.then(res => res.data)
	.catch(err => { throw err })

const getTranslations = () => {
	
	const getLanguage = () => window.localStorage.i18nextLng

	return axios.get(ENDPOINTS.TRANSLATIONS, {
		headers: {
			'Accept-Language': `${getLanguage()};q=0.8`,
		},
		params: {
			'used_by': 'reserved',
			'limit': 'max'
		}
	}
	)
		.then(res => res.data.results)
		.catch(err => { throw err })
}

const getContacts = () => 
	axios.get(ENDPOINTS.CONTACTS)
		.then(res => res.data)
		.catch(err => { 
			throw err 
		})
	
export default {
	getPaymentMethods,
	getTranslations,
	getContacts
}