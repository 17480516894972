/**
 * FormError Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import styled from 'styled-components'

export default styled.span`
    color: rgba(237,28,36,0.8);
    position: relative;
    margin-bottom: 5px;
    font-size: 12px;
    margin-top: 5px;
    display: block;
`
