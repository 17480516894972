
import styled from 'styled-components'

export default styled.span`
color: white;
border-radius: 90px/90px;
padding: 16px;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
height: 24px;
font-weight: 600;

${({ status }) => status === 'confirmed' && `
	background-color: #ABD037;
`}

${({ status }) => status === 'pending' && `
	background-color: #C1C1C1;
`}

${({ status }) => status === 'expired' && `
	background-color: #D03737;
`}

${({ status }) => status === 'canceled' && `
	background-color: #D03737;
`}
`