import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'

import API from 'api'

export default kea({
	path: () => ['scenes', 'containers', 'ServiceLocationList'],

	connect: {
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			AppLogic, [
				'navigate'
			]
		],
		props: []
	},

	actions: () => ({

		fetchAreas: () => true,
		setAreas: areas => ({ areas }),

		changePage: (page) => ({ page }),
		changeLimit: (limit) => ({ limit }),
		setPagination: (pagination) => ({ ...pagination }),

		tableLoaded: () => true,
		reset: () => true

	}),

	reducers: ({ actions }) => ({

		tableLoading: [false, Proptypes.bool, {
			[actions.fetchAreas]: () => true,
			[actions.tableLoaded]: () => false,
			[actions.reset]: () => false
		}],

		areas: [[], Proptypes.array, {
			[actions.setAreas]: (_, payload) => payload.areas,
			[actions.reset]: () => []
		}],

		limit: [5, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.limit,
			[actions.changeLimit]: (state, payload) => payload.limit
		}],

		offset: [0, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.offset,
			[actions.changeLimit]: () => 0
		}],

		count: [5, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.count
		}],

		hasNext: [false, Proptypes.bool, {
			[actions.setPagination]: (state, payload) => payload.hasnext
		}],

		hasPrevious: [false, Proptypes.bool, {
			[actions.setPagination]: (state, payload) => payload.hasPrevious
		}],

		page: [0, Proptypes.number, {
			[actions.changePage]: (state, payload) =>
				payload.page === 'next' ? state + 1 : state - 1,
			[actions.changeLimit]: () => 0
		}],

	}),

	start: function * () {
		const { fetchAreas } = this.actions
		yield put(fetchAreas())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchAreas]: workers.fetchAreas,
		[actions.changeLimit]: workers.fetchAreas,
		[actions.changePage]: workers.fetchAreas
	}),

	workers: {

		* fetchAreas () {

			const {
				setAreas,
				showToaster,
				tableLoaded,
				setPagination
			} = this.actions

			const { t } = this.props
			const limit = yield this.get('limit')
			const page = yield this.get('page')
			const offset = limit * page
			const sort = '-created_at'

			let params = { limit, offset, sort }

			try {
 
				const {
					count,
					results,
					next,
					previous
				} = yield call(API.Areas.get, { params })
				
				yield put(setPagination({
					...params,
					hasnext: !!next,
					hasPrevious: !!previous,
					count
				}))

				yield put(setAreas(results))
				yield put(tableLoaded())

			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
				yield put(tableLoaded())
			}

		},

	}

})
