/* eslint-disable react/display-name */
import omit from 'lodash/omit'
import React from 'react'
import styled from 'styled-components'

import { Dropdown as SemanticDropdown, Image } from 'semantic-ui-react'

import ArrowDown from './images/ArrowDown.js'
import AvatarPlaceholder from './images/avatar-placeholder.png'
import Button from 'components/Button'

const trigger = ({ avatar, name, role }) => (
	<Wrapper>
		<Avatar circular src={avatar ? avatar.url : AvatarPlaceholder} />
		<Personal>
			<Name>{name}</Name>
			<SubName>{role}</SubName>
		</Personal>
		<RoundButton secondary>
			<ArrowDown />
		</RoundButton>
	</Wrapper>
)

export default ({ user, options }) => (
	<Dropdown
		simple
		trigger={trigger(user)}
		options={
			options
				.filter(({ visible }) => visible)
				.map(item => ({
					...omit(item, ['visible']),
					content: <Item onClick={() => item.onClick()}>
						{item.text}
					</Item>
				}))
		}
		icon={null}
	/>
)

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Avatar = styled(Image)`
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  object-fit: cover;
`

const Name = styled.span`
  color: #5B5B5B;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 8px;
`

const SubName = styled.span`
  color: #5B5B5B;
  font-family: "Open Sans";
  font-size: 10px;
  line-height: 10px;
  margin: 0 8px;
`

const Personal = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 45px;
`

const Dropdown = styled(SemanticDropdown)`
  &&&& {
    padding: 10px 20px 0 20px;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    &.active, &:hover{
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      box-shadow: 0px 0px 2px 0 rgba(0,0,0,0.1), 0px 0px 10px 0 rgba(0,0,0,0.05);
      border-top: 1px solid rgba(0,0,0,0.05);
      border-right: 1px solid rgba(0,0,0,0.05);
      border-left: 1px solid rgba(0,0,0,0.05);

      span .arrow {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
      }
    }

    > div{
      width: calc(100% + 2px)!important;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: none;
      left: -1px;
      padding-top: 10px;
      border-color: rgba(0,0,0,0.05);

      > div{
        text-align: center;
        border-top: 1px solid rgba(0,0,0,0.05);
        margin: 0 8px;

        &.item {
          color: #5B5B5B;
          font-family: "Open Sans";
          font-size: 14px;
          font-height: 600;
          line-height: 14px;
        }

        &:first-child{
          border-top: 1px solid rgba(0,0,0,0.05);
        }
        &:last-child{
          color: rgba(194,52,52,0.5);
        }

        &.selected, &:hover{
          background: transparent;
        }
        &.active{
          font-weight: normal;
        }
      }
    }
  }
`

const Item = styled.span``

const RoundButton = styled(Button)`
  padding: 10px !important;
  height: 38px;
  width: 38px;
  
  border-radius: 50%;
  max-height: none;
`
