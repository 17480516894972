import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import API from 'api'

/** Logic */
import ToasterLogic from 'containers/Toaster/logic'
import UsageReservationDetailsLogic from 'screens/UsageReservationDetails/logic'
import { handleApiErrorMessage } from '../../../../utils'

export default kea({
	path: () => ['scenes', 'containers', 'UsageDetailsCheckInOut'],

	connect: {
		props: [
			UsageReservationDetailsLogic, [
				'form',
				'loading',
				'selectedServiceArea'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},
	actions: () => ({
		downloadFile: url => url,
		newQrCode: () => true,
		test : () => true
	}),

	takeLatest: ({ actions, workers }) => ({
		[actions.downloadFile]: workers.downloadFile,
		[actions.newQrCode]: workers.newQrCode,
		[actions.test]: workers.test
	}),

	workers: {

		* downloadFile () {
			const { showToaster } = this.actions
			const { match, t } = this.props
			const id = match.params.id
			try {
				const response = yield call(API.Reservations.downloadQrCodeCleaning, id)
				const link = document.createElement('a')
				const blob = new Blob([response], { type: 'image/png' })
				link.download = 'qrcode.png'
				link.target = '_blank'
				link.href = URL.createObjectURL(blob)
				link.click()
				URL.revokeObjectURL(link.href)
			} catch (e) {
				console.log(e)
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(e, t) || t('error.error_fetch')
				))
				
			}
					
		},

		* newQrCode () {
			const {	showToaster } = this.actions
		
			const { t, match } = this.props
			const id = match.params.id
			try {
				yield call(
					API.Reservations.generateQrCodeCleaning, id)
				yield put(showToaster(
					'success',
					t('common.success'),
					//tradução
					t('New QrCode')
				))
			} catch (err) {
				console.log(err)
				if (err.response && err.response.data) {
					console.log(err)
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		}
	}
})
