import { kea } from 'kea'
import BookingManagementLogic from 'screens/BookingManagement/logic'
import BookingDetails from 'screens/BookingDetails/logic'

export default kea({
	path: () => ['scenes', 'containers', 'BookDetailsCharacteristics'],

	connect: {
		props: [
			BookingManagementLogic, [
				'parkingPlaces',
				'serviceAreas'
			],
			BookingDetails, [
				'form',
				'loading',
				'isNewReservation'
			]
		]
	}
})
