/* eslint-disable max-len */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint react/prop-types: 0 */

import axios from 'axios'
import React, { Component } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import Button from 'components/Button'
//import Remove from 'components/Remove'
import { 
	//Progress,
	Icon,
	Image,
	//Placeholder
} from 'semantic-ui-react'
//import { getFilenameOnFullPath } from 'utils'

import AvatarPlaceholder from 'components/UserMenu/images/avatar-placeholder.png'

import { ENDPOINTS } from 'api'
const MEDIA_BASE_URL = ENDPOINTS.BASE.substr(0, ENDPOINTS.BASE.length - 4)

export default class Uploader extends Component {
	constructor () {
		super()
		this.state = {
			file: null,
			fileForUpload: []
		}
	}

	componentWillMount () {
		this.setState({
			file: this.props.value
				? this.props.value
				: null
		})
	}

	componentDidUpdate (prevProps) {
		if (prevProps.value !== this.props.value &&
			Array.isArray(this.props.value)) {
			this.setState({ file: this.props.value })
		}
	}

	getFileIcon (mime) {
		if (mime.includes('image')) return <Icon name={'file image'} />
		if (mime.includes('pdf')) return <Icon name={'file pdf'} />
		else return <Icon name={'file'} />
	}

	getFileName (mime) {
		if (mime.includes('image')) return 'imagens'
		if (mime.includes('pdf')) return 'PDF\'s'
	}

	remove () {
		const onRemove = this.props.onRemove
		this.setState({ file: null }, () => {
			onRemove && onRemove()
		})
	}

	upload () {
		const {
			uploadEndpoint,
			onChange
		} = this.props

		const {
			fileForUpload
		} = this.state

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				)

				this.setState(
					{
						file: fileForUpload instanceof Blob
							? {
								name: fileForUpload.name,
								progress: percentCompleted
							}
							: fileForUpload
					}
				)
			}
		}

		const data = new window.FormData()
		if (fileForUpload.preview) {
			data.append('files', fileForUpload)
		}

		axios.post(uploadEndpoint, data, config)
			.then((response) => {
				// eslint-disable-next-line max-len
				onChange && onChange(response.data.files.map(file => ({ image: { id: file.id, name: '', url: file.file }, isFavourite: false, progress: 100 })))

				this.setState({ error: false, errorMessage: null })
			})
			.catch((error) => {
				let detail = 'Alguma coisa correu mal no upload.'
				try {
					detail = error.response.data.detail
				} catch (e) {
					console.log(e)
				}

				this.setState({ error: true, errorMessage: detail })
			})
	}

	renderImagePreview = () => {
		const {
			file,
			fileForUpload
		} = this.state

		let src = AvatarPlaceholder

		if (file && file.value) {
			src = file.value.url.includes('http') ? file.value.url : MEDIA_BASE_URL + file.value.url
		} else if ( fileForUpload && fileForUpload.preview ) {
			src = fileForUpload.preview
		}

		return <Avatar circular src={src} alt='' />

	}

	render () {
		const {
			//accept,
			className,
			t,
			//title,
		} = this.props

		const {
			//error,
			errorMessage
		} = this.state

		//const acceptMime = accept && accept.split('/')[1]

		// There's a time frame, between the file being dropped and the upload finishes
		// that the state.files holds a new object that is actually a Blob
		// after the file is uploaded, the structure of the object changes due to the props.value received
		// Flow:
		// 1. drop a file in the uploader
		// 2. the UI renders a placeholder and the name of the file (given by the Blob)
		// 3. upload the file
		// 4. the response from the POST/upload trigger a change() method on the component
		// 5. the method, binded to value prop, gives back that prop (value)
		// 6. this prop triggers a re-render (through 'component did update')

		return (
			<section className={className && className}>
				<div>

					<DropArea
						onDrop={(file) => {
							this.setState(
								{
									file: file[0],
									fileForUpload: file[0]
								}, () => this.upload())
						}}
						accept={'image/jpeg, image/png'}
					>
						<aside>
							{this.renderImagePreview()}
						</aside>
						<p style={{ 'fontSize': '14px' }}>
							{t('edit_profile.upload_prompt_1')}
							<br />
							<Button secondary size='mini'>
								{t('edit_profile.upload_prompt_2')}
							</Button>
						</p>
					</DropArea>
				</div>
				<aside>
					<Error>{errorMessage}</Error>
				</aside>
			</section>
		)
	}
}

const DropArea = styled(Dropzone)`
  display: flex;
  padding: 60px 20px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin: 1rem 0;
  border-radius: .28571429rem;
  border: 1px dashed rgba(34,36,38,.15);
  align-items: center;
  justify-content: space-around;

  p {
    margin: 0;
    padding: 0;
    color: #9B9B9B;
    text-align: center;
  }
  button {
    margin-top: 10px!important;
  }
`

const Avatar = styled(Image)`
  width: 200px;
  height: 200px;
  border: 1px solid #eee;
  object-fit: cover;
`

const Error = styled.span`
  display: block;
  color: #db2828;
  font-size: 12px;
  padding: 10px;
`
