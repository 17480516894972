import React from 'react'

export const CalendarRemove = ({width, height, ...props}) => (
	<svg width={width} height={height} viewBox="0 0 24 24" {...props}>
		<path
			// eslint-disable-next-line max-len
			d="M19 19H5V8h14m0-5h-1V1h-2v2H8V1H6v2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2M9.31 17l2.44-2.44L14.19 17l1.06-1.06-2.44-2.44 2.44-2.44L14.19 10l-2.44 2.44L9.31 10l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 17z"
		/>
	</svg>
)

export const CalendarSync = ({width, height, fill, ...props}) => (
	<svg width={width} height={height} viewBox="0 0 24 24" {...props}>
		<path
			fill={fill||'#9B9B9B'}
			// eslint-disable-next-line max-len
			d="M18 11v1.5c3.19 0 5.09 3.55 3.33 6.21l-1.09-1.09C21.06 15.96 19.85 14 18 14v1.5l-2.25-2.25L18 11m0 11v-1.5c-3.19 0-5.09-3.55-3.33-6.21l1.09 1.09C14.94 17.04 16.15 19 18 19v-1.5l2.25 2.25L18 22m1-19h-1V1h-2v2H8V1H6v2H5a2 2 0 00-2 2v14a2 2 0 002 2h9a5.919 5.919 0 01-1.5-2H5V8h14v2.59c.71.11 1.39.35 2 .72V5a2 2 0 00-2-2z"
		/>
	</svg>
)

export const CalendarPerson = ({width, height, ...props}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<defs>
			<path
				d="M12 10c2 0 3 2.42 1.59 3.84-1.42 1.42-3.84.41-3.84-1.59C9.75 11 10.75 10 12 10m4.5 9h-9v-1.12c0-1.25 2-2.25 4.5-2.25s4.5 1 4.5 2.25M19 20H5V9h14m-3-7v2H8V2H6v2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2h-1V2h-2z"
				id="prefix__a"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="prefix__b" fill="#fff">
				<use xlinkHref="#prefix__a" />
			</mask>
			<use fill="#000" fillRule="nonzero" xlinkHref="#prefix__a" />
			<g mask="url(#prefix__b)" fill="#000">
				<path d="M0 0h24v24H0z" />
			</g>
		</g>
	</svg>
)