/**
 * ListDonwloads Container Logic
 * Please write a description
 *
 */

import { kea } from 'kea'
import Proptypes from 'prop-types'
import { put, call } from 'redux-saga/effects'

import API from 'api'
/** logic */
import ToasterLogic from 'containers/Toaster/logic'

export default kea({
	path: () => ['scenes', 'containers', 'ListDownloadsServicesAreas'],

	connect: {
		actions: [
			ToasterLogic, [
				'show as showToaster'
			]
		],
		props: []
	},

	actions: () => ({
		fetchDownloads: () => true,
		setDownloads: (downloads) => ({ downloads }),

		changePage: (page) => ({ page }),
		changeLimit: (limit) => ({ limit }),
		setPagination: (pagination) => ({ ...pagination }),

		tableLoaded: () => true,
		reset: () => true

	}),

	reducers: ({ actions }) => ({

		downloads: [[], Proptypes.array, {
			[actions.setDownloads]: (state, payload) => payload.downloads,
			[actions.reset]: () => []
		}],

		tableLoading: [false, Proptypes.bool, {
			[actions.fetchDownloads]: () => true,
			[actions.tableLoaded]: () => false,
			[actions.reset]: () => false
		}],

		limit: [5, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.limit,
			[actions.changeLimit]: (state, payload) => payload.limit
		}],

		offset: [0, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.offset,
			[actions.changeLimit]: () => 0
		}],

		count: [5, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.count
		}],

		hasNext: [false, Proptypes.bool, {
			[actions.setPagination]: (state, payload) => payload.hasnext
		}],

		hasPrevious: [false, Proptypes.bool, {
			[actions.setPagination]: (state, payload) => payload.hasPrevious
		}],

		page: [0, Proptypes.number, {
			[actions.changePage]: (state, payload) =>
				payload.page === 'next' ? state + 1 : state - 1,
			[actions.changeLimit]: () => 0
		}],
	}),

	start: function * () {
		const { fetchDownloads } = this.actions

		yield put(fetchDownloads())
	},
	
	stop : function * () {
		const { reset } = this.actions

		yield put(reset())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchDownloads]: workers.fetchDownloads,
		[actions.changeLimit]: workers.fetchDownloads,
		[actions.changePage]: workers.fetchDownloads
		
	}),

	workers: {

		* fetchDownloads () {
			const {
				setDownloads,
				showToaster,
				tableLoaded,
				setPagination,
			} = this.actions

			const { t } = this.props
			const limit = yield this.get('limit')
			const page = yield this.get('page')
			const offset = limit * page

			let params = { limit, offset }
			try {

				const {
					count,
					results,
					next,
					previous
				} = yield call(API.Downloads.getServiceAreas, { params })

				yield put(setPagination({
					...params,
					hasnext: !!next,
					hasPrevious: !!previous,
					count
				}))
				
				yield put(setDownloads(results))
				yield put(tableLoaded())

			} catch (err) {
				console.log(err)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.process_start_error')
				))
			}
		}

	}
})
