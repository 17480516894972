// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
import moment from 'moment'

import {
	Grid,
	Loader,
	Dimmer as SegmentDimmer,
	Tab as SemanticTab
} from 'semantic-ui-react'
/** Components */
import Title from 'components/Title'
import Button from 'components/Button'
/** Container */
import BookUsageResCalendarView from 'containers/BookUsageResCalendarView'
import BookUsageResListView from 'containers/BookUsageResListView'
import BookUsageResFilters from 'containers/BookUsageResFilters'

class UsageReservation extends Component {

	constructor (props) {
		super(props)

		this.state = {
			timeFilter: 'week'
		}
	}

	onPrevClick = () => {
		const { setVisibleTime } = this.actions
		const { visibleTime } = this.props
		const { visibleTimeStart, visibleTimeEnd } = visibleTime

		const zoom = (visibleTimeEnd + 1) - visibleTimeStart
		const newVisibleTimeStart = visibleTimeStart - zoom
		const newVisibleTimeEnd = visibleTimeEnd - zoom

		setVisibleTime({
			visibleTimeStart: newVisibleTimeStart,
			visibleTimeEnd: newVisibleTimeEnd
		})
	}

	onNextClick = () => {
		const { setVisibleTime } = this.actions
		const { visibleTime } = this.props
		const { visibleTimeStart, visibleTimeEnd } = visibleTime
		// zoom should be calculated with the visibleTimeEnd on the next day at 00:00:00, +1 fixes that (moment endOf sets the date to the last millisecond)
		const zoom = (visibleTimeEnd + 1) - visibleTimeStart
		const newVisibleTimeStart = visibleTimeStart + zoom
		const newVisibleTimeEnd = visibleTimeEnd + zoom
	
		setVisibleTime({
			visibleTimeStart: newVisibleTimeStart,
			visibleTimeEnd: newVisibleTimeEnd
		})
	}
	
	setTimeFilter = (value) => {
		const { setVisibleTime } = this.actions
		
		const visibleTimeStart = moment().startOf(value).valueOf()	
		const visibleTimeEnd = moment().endOf(value).valueOf()
		
		this.setState({
			timeFilter: value
		})

		setVisibleTime({
			visibleTimeEnd,
			visibleTimeStart
		})
	}

	render () {
		const {
			timeFilter
		} = this.state
		
		const {
			t,
			loading,
			serviceAreaOptions,
			selectedServiceArea,
			visibleTime,
			reservations
		} = this.props
		
		const {
			visibleTimeStart,
			visibleTimeEnd
		} = visibleTime

		const { setSelectedServiceArea, navigate } = this.actions

		const panes = [
			{ menuItem: t('booking_management.calendar_view'), render: () => (
				<Tab.Pane>
					<BookUsageResFilters
						timeFilter={timeFilter}
						serviceAreaOptions={serviceAreaOptions}
						selectedServiceArea={selectedServiceArea}
						setSelectedServiceArea={setSelectedServiceArea}
					>
						<BookUsageResCalendarView
							reservations={reservations}
							timeFilter={timeFilter}
							prevClick={this.onPrevClick}
							nextClick={this.onNextClick}
							visibleTimeStart={visibleTimeStart}
							visibleTimeEnd={visibleTimeEnd}
							setTimeFilter={this.setTimeFilter}
						/>
					</BookUsageResFilters>
				</Tab.Pane>
			)},
			{ menuItem: t('booking_management.list_view'), render: () => (
				<Tab.Pane>
					<BookUsageResFilters
						serviceAreaOptions={serviceAreaOptions}
						selectedServiceArea={selectedServiceArea}
						setSelectedServiceArea={setSelectedServiceArea}
					>
						<BookUsageResListView />
					</BookUsageResFilters>
				</Tab.Pane>
			)}
		]

		return (
			<Wrapper>
				<Grid>
					<Grid.Row columns={2} stretched>
						<Grid.Column>
							<Title>
								{t('navigation.usage_reservations')}
							</Title>
						</Grid.Column>
						<Grid.Column floated={'right'} width={2}>
							<Button
								primary
								onClick={() => navigate('/reserva-utilizacao/nova-reserva')}
							>
								Criar Reserva
								{/*{t('booking_management.add_new')}*/}
							</Button>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						{loading && 
							<Dimmer inverted active>
								<Loader inverted/>
							</Dimmer>
						}
						{!loading &&
							<Tab
								panes={panes}
								onTabChange={(event, data) => {
									const { activeIndex } = data
									
									if (activeIndex === 0) {
										this.setTimeFilter('week')
									} else {
										this.setTimeFilter('month')
									}
								}}
							/>
						}
					</Grid.Row>
				</Grid>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(UsageReservation))

const Wrapper = styled.div``

const Dimmer = styled(SegmentDimmer)`
	&&&& {
		background-color: transparent;
		height: 40vh;
	}
`

const Tab = styled(SemanticTab)`
	width: 100%;
	
	.ui.attached.tabular.menu {
		border: 0;
		margin-left: 15px;
	}
	
	.ui.bottom.attached.segment {
		background: transparent;
		padding: 0;
		border: 0;
	}
	
	.ui.tabular.menu .active.item {
		border: 1px solid rgb(244, 244, 244);
		background-color: rgb(244, 244, 244);
		color: #102526;
		font-family: "Open Sans";
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 16px;
		
		&:after {
			position: absolute;
			content: '';
			border-bottom: 3px solid #102526;
			width: 70%;
			transform: translateX(-50%);
			bottom: 0;
			left: 50%;
		}
	}
	
	.ui.tabular.menu .item {
		color: #9B9B9B;
		font-family: "Open Sans";
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 16px;
		text-align: center;
	}
`
