import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import API from 'api'

import {
	Form,
	Radio,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import ImageGrid from '../../ImageGrid'
import IcRestaurante from 'icSvg/IcRestaurante'
import IcMinimercado from 'icSvg/IcMinimercado'
import IcSnackbar from 'icSvg/IcSnackbar'
import IcBar from 'icSvg/IcBar'
import IcLavandaria from 'icSvg/IcLavandaria'
import IcGym from 'icSvg/IcGym'
import IcSpa from 'icSvg/IcSpa'
import IcJacuzzi from 'icSvg/IcJacuzzi'
import IcLimpeza from 'icSvg/IcLimpeza'
import IcBicicletas from 'icSvg/IcBicicletas'
import IcEntretenimento from 'icSvg/IcEntretenimento'
import IcServiçoCriança from 'icSvg/IcServiçoCriança'
import IcMotorhome from 'icSvg/IcMotorhome'
import IcWifi from 'icSvg/IcWifi'

class Services extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	submitData = (callback) => {
		const {campsiteId, submit, current} = this.props
		const {updateStep} = this.actions
		
		submit(data => API.Campsites.steps.updateServices(campsiteId, data), {
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
				}
				callback(success)
			}
		})
	}

	render () {

		const { t, form, loading, changeForm } = this.props

		const {
			restaurant,
			miniMarket,
			snackBar,
			bar,
			laundry,
			gym,
			spa,
			jacuzzi,
			cleaning,
			bicycleRental,
			entertainment,
			childrenServices,
			motorhomeServiceStation,
			wifiAccess
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('form.services')}
						</SegmentTitle>

						<ImageGrid data={[
							{
								image: <IcRestaurante />,
								component: <>
									<RadioLabel>
										{t('campsite_details.restaurant')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='restaurant_no'
										checked={restaurant.value === false}
										onChange={() => changeForm({
											restaurant: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='restaurant_yes'
										checked={restaurant.value === true}
										onChange={() => changeForm({
											restaurant: true
										})}
									/>
								</>
							},
							{
								image: <IcMinimercado />,
								component: <>
									<RadioLabel>
										{t('campsite_details.mini_market')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='mini_market_no'
										checked={miniMarket.value === false}
										onChange={() => changeForm({
											miniMarket: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='mini_market_yes'
										checked={miniMarket.value === true}
										onChange={() => changeForm({
											miniMarket: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcSnackbar />,
								component: <>
									<RadioLabel>
										{t('campsite_details.snackbar')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='snackbar_no'
										checked={snackBar.value === false}
										onChange={() => changeForm({
											snackBar: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='snackbar_yes'
										checked={snackBar.value === true}
										onChange={() => changeForm({
											snackBar: true
										})}
									/>
								</>
							},
							{
								image: <IcBar />,
								component: <>
									<RadioLabel>
										{t('campsite_details.bar')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='bar_no'
										checked={bar.value === false}
										onChange={() => changeForm({
											bar: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='bar_yes'
										checked={bar.value === true}
										onChange={() => changeForm({
											bar: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcLavandaria />,
								component: <>
									<RadioLabel>
										{/* eslint-disable-next-line max-len */}
										{t('campsite_details.laundry_selfservice')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='laundry_selfservice_no'
										checked={laundry.value === false}
										onChange={() => changeForm({
											laundry: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='laundry_selfservice_yes'
										checked={laundry.value === true}
										onChange={() => changeForm({
											laundry: true
										})}
									/>
								</>
							},
							{
								image: <IcGym />,
								component: <>
									<RadioLabel>
										{t('campsite_details.gym')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='gym_no'
										checked={gym.value === false}
										onChange={() => changeForm({
											gym: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='gym_yes'
										checked={gym.value === true}
										onChange={() => changeForm({
											gym: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcSpa />,
								component: <>
									<RadioLabel>
										{t('campsite_details.spa')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='spa_no'
										checked={spa.value === false}
										onChange={() => changeForm({
											spa: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='spa_yes'
										checked={spa.value === true}
										onChange={() => changeForm({
											spa: true
										})}
									/>
								</>
							},
							{
								image: <IcJacuzzi />,
								component: <>
									<RadioLabel>
										{t('campsite_details.jacuzzi')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='jacuzzi_no'
										checked={jacuzzi.value === false}
										onChange={() => changeForm({
											jacuzzi: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='jacuzzi_yes'
										checked={jacuzzi.value === true}
										onChange={() => changeForm({
											jacuzzi: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcLimpeza />,
								component: <>
									<RadioLabel>
										{t('campsite_details.cleaning_and_changing')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='cleaning_and_changing_no'
										checked={cleaning.value === false}
										onChange={() => changeForm({
											cleaning: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='cleaning_and_changing_yes'
										checked={cleaning.value === true}
										onChange={() => changeForm({
											cleaning: true
										})}
									/>
								</>
							},
							{
								image: <IcBicicletas />,
								component: <>
									<RadioLabel>
										{t('campsite_details.bicycle_rent')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='bicycle_rent_no'
										checked={bicycleRental.value === false}
										onChange={() => changeForm({
											bicycleRental: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='bicycle_rent_yes'
										checked={bicycleRental.value === true}
										onChange={() => changeForm({
											bicycleRental: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcEntretenimento />,
								component: <>
									<RadioLabel>
										{t('campsite_details.entertainment')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='entertainment_no'
										checked={entertainment.value === false}
										onChange={() => changeForm({
											entertainment: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='entertainment_yes'
										checked={entertainment.value === true}
										onChange={() => changeForm({
											entertainment: true
										})}
									/>
								</>
							},
							{
								image: <IcServiçoCriança />,
								component: <>
									<RadioLabel>
										{t('campsite_details.child_services')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='child_services_no'
										checked={childrenServices.value === false}
										onChange={() => changeForm({
											childrenServices: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='child_services_yes'
										checked={childrenServices.value === true}
										onChange={() => changeForm({
											childrenServices: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcMotorhome />,
								component: <>
									<RadioLabel>
										{t('campsite_details.motorhome_service_station')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='motorhome_service_station_no'
										checked={motorhomeServiceStation.value === false}
										onChange={() => changeForm({
											motorhomeServiceStation: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='motorhome_service_station_yes'
										checked={motorhomeServiceStation.value === true}
										onChange={() => changeForm({
											motorhomeServiceStation: true
										})}
									/>
								</>
							},
							{
								image: <IcWifi />,
								component: <>
									<RadioLabel>
										{t('campsite_details.wifi_access')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='wifi_access_no'
										checked={wifiAccess.value === false}
										onChange={() => changeForm({
											wifiAccess: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='wifi_access_yes'
										checked={wifiAccess.value === true}
										onChange={() => changeForm({
											wifiAccess: true
										})}
									/>
								</>
							}
						]}
						/>
						
					</Form>
				</BoxSegment>

			</>
		)
	}
}

export default withTranslation()(Logic(Services))

const RadioLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`