/* eslint-disable max-len */
import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import { withTranslation } from 'react-i18next'

class Campsite extends Component {
	render () {
		const { open, onClose, onTryAgain, t, success } = this.props

		const type = success ? 'success' : 'error'

		return (
			<Modal.Modal
				open={open}
				closeIcon
				onClose={onClose}
				size={'small'}
			>
				<Modal.Content>
					<Title success={success}>
						{t(`campsite_details.submit_modal_title_${type}`)}
					</Title>
					<Description>
						{t(`campsite_details.submit_modal_description_${type}`)}
					</Description>
				</Modal.Content>

				<Modal.Actions>
					{!success &&
						<Button
							secondary
							onClick={onTryAgain}
						>
							{t('common.buttons_try_again')}
						</Button>
					}
					<Button
						primary
						onClick={onClose}
					>
						{t('common.buttons_close')}
					</Button>
				</Modal.Actions>
			</Modal.Modal>
		)
	}
}

export default withTranslation()(Campsite)

const Title = styled.p`
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
	margin-top: 105px !important;
	font-weight: bold;
	color: ${({success}) => success ? '#1a531b' : '#912d2b'};
`
const Description = styled.p`
  color: #000000;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
	margin: 0 26px 70px 26px !important;
`