import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import moment from 'moment'
import Proptypes from 'prop-types'
import BookingManagementLogic from 'screens/BookingManagement/logic'
import BookingDetailsLogic from 'screens/BookingDetails/logic'
import API from 'api'

const OTHER_GUESTS = {
	name: { value: '', edit: false },
	nationality: { value: '', edit: false },
	birthdate: { value: moment().format('YYYY-MM-DD'), edit: false }
}

export default kea({
	path: () => ['scenes', 'containers', 'BookDetailsGuestInfo'],

	connect: {
		props: [
			BookingManagementLogic, [
				'parkingPlaces'
			],
			BookingDetailsLogic, [
				'loading',
				'form'
			]
		],
		actions: [
			BookingDetailsLogic, [
				'changeArray',
				'changeForm'
			]
		]
	},

	actions: () => ({
		getNationalities: params => params,
		setNationalities: country => country,
		reset: () => true
	}),
	
	reducers: ({ actions }) => ({
		nationalities: [[], Proptypes.array, {
			[actions.setNationalities]: (state, payload) => payload,
			[actions.reset]: () => ([])
		}],
				
		isFetching:[false, Proptypes.bool, {
			[actions.getNationalities]: () => true,
			[actions.setNationalities]: () => false,
			[actions.reset]: () => false
		}]
	}),
	
	selectors: ({ selectors }) => ({

		nationalitiesOptions: [ () => [ selectors.nationalities],
			nationalities => nationalities.map(item => ({
				key: item.id,
				text: item.designation,
				value: item.id
			}))
		]
	}),
	
	start: function* () {
		const form = yield this.get('form')
		const { changeArray, getNationalities } = this.actions
		const { guests } = form

		if (!isNaN(guests.value - 1) && guests.value > 0) {
			const newArray = [...Array(guests.value - 1)].fill(OTHER_GUESTS)
			yield put(changeArray({ otherGuests: { value: newArray } }))
		}
		
		yield put(getNationalities())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.getNationalities]: workers.getNationalities,
		[actions.changeForm]: workers.changeOtherGuests,
	}),
	
	workers: {
		* getNationalities () {
			//const { payload } = actionPayload
			
			let params = { limit: 'max' }
			//if (isNaN(payload)) {
			//	params = { designation: payload }
			//}
			
			const { setNationalities } = this.actions
			
			try {
				const response = yield call(
					API.Municipalities.getCountries,
					{ params }
				)
				const { results } = response
				yield put(setNationalities(results))
			} catch (e) {
				console.log(e)
			}
		},
		
		* changeOtherGuests (payload) {
			const { field } = payload.payload
			const { changeForm } = this.actions
			const form = yield this.get('form')
			const { otherGuests, guests } = form
			
			if (field['guests']) {
				const diffGuests = guests.value - 1 - otherGuests.value.length
				
				if (diffGuests > 0) {
					const newArray = [...Array(diffGuests)]
						.fill(OTHER_GUESTS)
					const newGuests = otherGuests.value.concat(newArray)
					yield put(changeForm({ otherGuests: newGuests }))
				} else if (diffGuests  < 0) {
					const newGuests = otherGuests.value.slice(
						0,
						guests.value - 1
					)
					yield put(changeForm({ otherGuests: newGuests }))
				}
			}
		}
	}
})
