import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import Logic from './logic'

import ContentBox from 'components/ContentBox'
//import GoToButton from 'components/GoToButton'
//import StateLabel from 'components/StateLabel'
import Table from 'components/Table'
import { EditIcon } from 'components/Icons'
import StateLabel from 'components/StateLabel'
import GoToButton from 'components/GoToButton'
import { formatDatePt } from 'utils'
import { Image } from 'semantic-ui-react'
import {Camping as CampingIcon} from 'components/SidebarIcons'

class List extends Component {

	render () {
		const {
			t,
			isFetchingData,
			limit,
			offset, 
			count,
			hasNext,
			hasPrevious,
			campsites
		} = this.props

		const {
			changeLimit,
			changePage,
			navigate
		} = this.actions
		
		return (
			<Wrapper>
				<ContentBox
					loading={isFetchingData}
					//icon={<CampingIcon width={40} height={40} fill="#9B9B9B" />}
					icon={<Image style={{margin: 0}} src={CampingIcon}/>}
					title={t('navigation.campsite_management')}
					noMarginBottom
				>
					<Table.Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{t('campsite_management.campsite_name')}</Table.HeaderCell>
								<Table.HeaderCell>{t('form.district')}</Table.HeaderCell>
								<Table.HeaderCell>{t('form.locality')}</Table.HeaderCell>
								<Table.HeaderCell>{t('form.created_date')}</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{campsites.map((campsite, i) => (
								<React.Fragment key={`areas-${i}`}>
									<Row>
										<Cell>
											{campsite.name || '-'}
										</Cell>
										<Cell>
											{campsite.district || '-'}
										</Cell>
										<Cell>
											{campsite.locality || '-'}
										</Cell>
										<Cell>
											{formatDatePt(campsite.createdAt)}
										</Cell>
										<Cell textAlign={'center'}>
											<StateLabel 
												state={campsite.status}
											/>                
										</Cell>
										<Cell>
											<GoToButton 
												icon={
													<EditIcon
														width={18}
														height={18}
													/>
												}
												onClick={() => 
													navigate(`/parques-campismo/${campsite.id}`)
												} 
											/>
										</Cell>
									</Row>
								</React.Fragment>
							))}

							{!campsites.length && (
								<Table.Row>
									<Cell>{t('error.noResults')}</Cell>
								</Table.Row>
							)}
						</Table.Body>

						{!!campsites.length && (
							<Table.Footer>
								<Table.Row>
									<PagContainer colSpan={9}>
										<Table.Pagination
											style={{ width: 'auto' }}
											resultsPerPage={[
												{ text: '5', value: 5 },
												{ text: '10', value: 10 },
												{ text: '20', value: 20 }
											]}
											limit={limit}
											offset={offset}
											total={count}
											hasNext={hasNext}
											hasPrevious={hasPrevious}
											onChangeResults={changeLimit}
											onChangePage={payload => {
												// eslint-disable-next-line max-len
												if (payload === 'next' && hasNext) changePage(payload)
												// eslint-disable-next-line max-len
												if (payload === 'prev' && hasPrevious) changePage(payload)
												else return
											}}
										/>
									</PagContainer>
								</Table.Row>
							</Table.Footer>
						)}
					</Table.Table>
				</ContentBox>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(List))

const Wrapper = styled.div``

const Row = styled(Table.Row)`
  &&&& {
    &:hover {
      background-color: #f9f9f9;
    }
  }
`

const Cell = styled(Table.Cell)`
  &&&&{
    color: #3E3E3E;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 14px;
  }
`

const PagContainer = styled.th`
  div.menu {
    width: auto !important;
    align-items: center;
  }

  div.ui.right {
    background: none;
  }
`