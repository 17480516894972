import { kea } from 'kea'
/** Logic */
import ServiceAreaRequestLogic from 'screens/ServiceAreaRequest/logic'
import ServiceAreaLogic from 'screens/ServiceArea/logic'
import ModalServiceAreaLogic from 'containers/Modals/ModalServiceArea/logic'

export default kea({
	path: () => ['scenes', 'containers', 'FormNavigation', 'ServiceArea'],

	connect: {
		props: [
			ServiceAreaRequestLogic, [
				'current',
				'steps',
				'lastPatchDate',
				'canSubmit',
				'contacts'
			],
			ServiceAreaLogic, [
				'serviceAreaProcess'
			],
		],
		actions: [
			ServiceAreaRequestLogic, [
				'submit',
				'cancelStamp',
				'archive',
				'unarchive',
				'setCurrent',
				'toggleCleanAndSafe',
				'toggleHomologation'
			],
			ModalServiceAreaLogic, [
				'open as openModal'
			]
		]
	}
})
