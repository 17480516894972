import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import API from 'api'

import {
	Form,
	Grid,
	Radio,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import IcSanitariosAdaptativos from 'assets/components/IcSanitariosAdaptativos'
import ImageGrid from '../../ImageGrid'
import IcPostoSocorro from 'icSvg/IcPostoSocorro'
import IcDuche from 'icSvg/IcDuche'
import IcFraldario from 'icSvg/IcFraldario'
import IcSalaTv from 'icSvg/IcSalaTv'
import IcMicroondas from 'icSvg/IcMicroondas'
import IcGrelhador from 'icSvg/IcGrelhador'
import IcPiscina from 'icSvg/IcPiscina'
import IcCampoJogos from 'icSvg/IcCampoJogos'
import IcParqueInfantil from 'icSvg/IcParqueInfantil'
import IcResiduosQuimicos from 'icSvg/IcResiduosQuimicos'
import IcAreaLavagem from 'icSvg/IcAreaLavagem'
import IcLavatorios from 'icSvg/IcLavatorios'
import IcLavaLouça from 'icSvg/IcLavaLouça'
import IcLavaRoupa from 'icSvg/IcLavaRoupa'

class Equipments extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	submitData = (callback) => {
		const {campsiteId, submit, current} = this.props
		const {updateStep} = this.actions
			
		submit(data => API.Campsites.steps.updateEquipments(campsiteId, data), {
			callback: (data, success) => {
				if (success) {
					const {status, updatedAt} = data
					updateStep({current, data: {status, updatedAt}})
				}
				callback(success)
			}
		})
	}

	render () {

		const { t, form, loading, changeForm } = this.props

		const {
			firstAidPost,
			hotShower,
			adaptedToilets,
			babyChangingStation,
			socialRoom,
			communityMicrowave,
			grills,
			pool,
			playground,
			childrenPlayground,
			chemicalToiletsDumpingPoint,
			vehicleWashArea,
			washbasins,
			washbasinsHaveHotWater,
			commonDishwasher,
			commonDishwasherHasHotWater,
			commonLaundry,
			commonLaundryHasHotWater
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('form.equipments')}
						</SegmentTitle>

						<ImageGrid data={[
							{
								image: <IcPostoSocorro />,
								component: <>
									<RadioLabel>
										{t('campsite_details.first_aid_station')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='first_aid_station_no'
										checked={firstAidPost.value === false}
										onChange={() => changeForm({
											firstAidPost: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='first_aid_station_yes'
										checked={firstAidPost.value === true}
										onChange={() => changeForm({
											firstAidPost: true
										})}
									/>
								</>
							},
							{
								image: <IcDuche />,
								component: <>
									<RadioLabel>
										{t('campsite_details.hot_shower')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='hot_shower_no'
										checked={hotShower.value === false}
										onChange={() => changeForm({
											hotShower: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='hot_shower_yes'
										checked={hotShower.value === true}
										onChange={() => changeForm({
											hotShower: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcSanitariosAdaptativos />,
								component: <>
									<RadioLabel>
										{t('campsite_details.adapted_toilets')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='adapted_toilets_no'
										checked={adaptedToilets.value === false}
										onChange={() => changeForm({
											adaptedToilets: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='adapted_toilets_yes'
										checked={adaptedToilets.value === true}
										onChange={() => changeForm({
											adaptedToilets: true
										})}
									/>
								</>
							},
							{
								image: <IcFraldario />,
								component: <>
									<RadioLabel>
										{t('campsite_details.baby_changing_station')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='baby_changing_station_no'
										checked={babyChangingStation.value === false}
										onChange={() => changeForm({
											babyChangingStation: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='baby_changing_station_yes'
										checked={babyChangingStation.value === true}
										onChange={() => changeForm({
											babyChangingStation: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcSalaTv />,
								component: <>
									<RadioLabel>
										{t('campsite_details.living_room_with_tv')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='living_room_with_tv_no'
										checked={socialRoom.value === false}
										onChange={() => changeForm({
											socialRoom: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='living_room_with_tv_yes'
										checked={socialRoom.value === true}
										onChange={() => changeForm({
											socialRoom: true
										})}
									/>
								</>
							},
							{
								image: <IcMicroondas />,
								component: <>
									<RadioLabel>
										{t('campsite_details.community_microwave')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='community_microwave_no'
										checked={communityMicrowave.value === false}
										onChange={() => changeForm({
											communityMicrowave: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='community_microwave_yes'
										checked={communityMicrowave.value === true}
										onChange={() => changeForm({
											communityMicrowave: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcGrelhador />,
								component: <>
									<RadioLabel>
										{t('campsite_details.grills')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='grills_no'
										checked={grills.value === false}
										onChange={() => changeForm({
											grills: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='grills_yes'
										checked={grills.value === true}
										onChange={() => changeForm({
											grills: true
										})}
									/>
								</>
							},
							{
								image: <IcPiscina />,
								component: <>
									<RadioLabel>
										{t('campsite_details.pool')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='pool_no'
										checked={pool.value === false}
										onChange={() => changeForm({
											pool: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='pool_yes'
										checked={pool.value === true}
										onChange={() => changeForm({
											pool: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcCampoJogos />,
								component: <>
									<RadioLabel>
										{t('campsite_details.playground')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='playground_no'
										checked={playground.value === false}
										onChange={() => changeForm({
											playground: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='playground_yes'
										checked={playground.value === true}
										onChange={() => changeForm({
											playground: true
										})}
									/>
								</>
							},
							{
								image: <IcParqueInfantil />,
								component: <>
									<RadioLabel>
										{t('campsite_details.child_playground')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='child_playground_no'
										checked={childrenPlayground.value === false}
										onChange={() => changeForm({
											childrenPlayground: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='child_playground_yes'
										checked={childrenPlayground.value === true}
										onChange={() => changeForm({
											childrenPlayground: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcResiduosQuimicos />,
								component: <>
									<RadioLabel>
										{t('campsite_details.chemical_toilet_disposal_ready')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='chemical_toilet_disposal_ready_no'
										checked={chemicalToiletsDumpingPoint.value === false}
										onChange={() => changeForm({
											chemicalToiletsDumpingPoint: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='chemical_toilet_disposal_ready_yes'
										checked={chemicalToiletsDumpingPoint.value === true}
										onChange={() => changeForm({
											chemicalToiletsDumpingPoint: true
										})}
									/>
								</>
							},
							{
								image: <IcAreaLavagem />,
								component: <>
									<RadioLabel>
										{t('campsite_details.vehicle_washing')}
									</RadioLabel>
									<Radio
										label={t('options.no')}
										name='vehicle_washing_no'
										checked={vehicleWashArea.value === false}
										onChange={() => changeForm({
											vehicleWashArea: false
										})}
									/>
									<Radio
										style={{ paddingLeft: '16px' }}
										label={t('options.yes')}
										name='vehicle_washing_yes'
										checked={vehicleWashArea.value === true}
										onChange={() => changeForm({
											vehicleWashArea: true
										})}
									/>
								</>
							}
						]}
						/>

						<ImageGrid data={[
							{
								image: <IcLavatorios />,
								component: <>
									<RadioLabel>
										{t('campsite_details.washbasins')}
									</RadioLabel>
									<Grid.Row>
										<Radio
											label={t('options.no')}
											name='washbasins_no'
											checked={washbasins.value === false && !washbasinsHaveHotWater.value}
											onChange={() => changeForm({
												washbasins: false,
												washbasinsHaveHotWater: false
											})}
										/>
									</Grid.Row>
									<Grid.Row>
										<Radio
											style={{marginTop: 5}}
											label={t('options.yes_without_hotwater')}
											name='washbasins_yes_without_hotwater'
											checked={washbasins.value === true && !washbasinsHaveHotWater.value}
											onChange={() => changeForm({
												washbasins: true,
												washbasinsHaveHotWater: false
											})}
										/>
									</Grid.Row>
									<Grid.Row>
										<Radio
											style={{marginTop: 5}}
											label={t('options.yes_with_hotwater')}
											name='washbasins_yes_with_hotwater'
											checked={washbasinsHaveHotWater.value === true}
											onChange={() => changeForm({
												washbasins: true,
												washbasinsHaveHotWater: true
											})}
										/>
									</Grid.Row>
								</>
							},
							{
								image: <IcLavaLouça />,
								component: <>
									<RadioLabel>
										{/* eslint-disable-next-line max-len */}
										{t('campsite_details.shared_dishwasher')}
									</RadioLabel>
									<Grid.Row>
										<Radio
											label={t('options.no')}
											name='shared_dishwasher_no'
											checked={commonDishwasher.value === false && !commonDishwasherHasHotWater.value}
											onChange={() => changeForm({
												commonDishwasher: false,
												commonDishwasherHasHotWater: false
											})}
										/>
									</Grid.Row>
									<Grid.Row>
										<Radio
											style={{marginTop: 5}}
											label={t('options.yes_without_hotwater')}
											name='shared_dishwasher_yes_without_hotwater'
											checked={commonDishwasher.value === true && !commonDishwasherHasHotWater.value}
											onChange={() => changeForm({
												commonDishwasher: true,
												commonDishwasherHasHotWater: false
											})}
										/>
									</Grid.Row>
									<Grid.Row>
										<Radio
											style={{marginTop: 5}}
											label={t('options.yes_with_hotwater')}
											name='shared_dishwasher_yes_with_hotwater'
											checked={commonDishwasherHasHotWater.value === true}
											onChange={() => changeForm({
												commonDishwasher: true,
												commonDishwasherHasHotWater: true
											})}
										/>
									</Grid.Row>
								</>
							}
						]}
						/>

						<ImageGrid columnWidth={50} data={[
							{
								image: <IcLavaRoupa />,
								component: <>
									<RadioLabel>
										{t('campsite_details.shared_washing_machine')}
									</RadioLabel>
									<Grid.Row>
										<Radio
											label={t('options.no')}
											name='shared_washing_machine_no'
											checked={commonLaundry.value === false && !commonLaundryHasHotWater.value}
											onChange={() => changeForm({
												commonLaundry: false,
												commonLaundryHasHotWater: false
											})}
										/>
									</Grid.Row>
									<Grid.Row>
										<Radio
											style={{marginTop: 5}}
											label={t('options.yes_without_hotwater')}
											name='shared_washing_machine_yes_without_hotwater'
											checked={commonLaundry.value === true && !commonLaundryHasHotWater.value}
											onChange={() => changeForm({
												commonLaundry: true,
												commonLaundryHasHotWater: false
											})}
										/>
									</Grid.Row>
									<Grid.Row>
										<Radio
											style={{marginTop: 5}}
											label={t('options.yes_with_hotwater')}
											name='shared_washing_machine_yes_with_hotwater'
											checked={commonLaundryHasHotWater.value === true}
											onChange={() => changeForm({
												commonLaundry: true,
												commonLaundryHasHotWater: true
											})}
										/>
									</Grid.Row>
								</>
							}
						]}
						/>
						
					</Form>
				</BoxSegment>

			</>
		)
	}
}

export default withTranslation()(Logic(Equipments))

const RadioLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`
