import * as Check from 'validations'

const DEFAULT_VALUES = {
	accommodationType: {
		value: []
	},
	terrainType: {
		value: []
	},
	area: {
		value: ''
	},
	capacity: {
		value: ''
	},
	openingYear: {
		value: ''
	},
	operatingPeriodBegin: {
		value: ''
	},
	operatingPeriodEnd: {
		value: ''
	},
	regularReceptionHoursBegin: {
		value: ''
	},
	regularReceptionHoursEnd: {
		value: ''
	},
	highSeasonReceptionHoursBegin: {
		value: ''
	},
	highSeasonReceptionHoursEnd: {
		value: ''
	},
	petsAllowed: {
		value: ''
	},
	languages: {
		value: []
	}
}

const VALIDATIONS = {
	accommodationType: [
		Check.isRequired
	],
	terrainType: [
		Check.isRequired
	],
	area: [
		Check.isRequired,
		(value) => Check.maxNumber(value, 999999),
		(value) => Check.minNumber(value, 1)
	],
	capacity: [
		Check.isRequired
	],
	openingYear: [
		Check.isRequired
	],
	operatingPeriod: [
		Check.isRequired
	],
	regularReceptionHours: [
		Check.isRequired
	],
	highSeasonReceptionHours: [
		Check.isRequired
	],
	operatingPeriodBegin: [
		Check.isRequired,
		Check.validHHMMFormat
	],
	operatingPeriodEnd: [
		Check.isRequired,
		Check.validHHMMFormat
	],
	regularReceptionHoursBegin: [
		Check.isRequired,
		Check.validHHMMFormat
	],
	regularReceptionHoursEnd: [
		Check.isRequired,
		Check.validHHMMFormat
	],
	highSeasonReceptionHoursBegin: [
		Check.isRequired,
		Check.validHHMMFormat
	],
	highSeasonReceptionHoursEnd: [
		Check.isRequired,
		Check.validHHMMFormat
	],
	petsAllowed: [
		Check.isRequired
	],
	languages: [
		Check.isRequired
	],
}

const VALIDATIONS_NO_REQUIRED = {
	operatingPeriodBegin: [
		Check.validHHMMFormat
	],
	area: [
		(value) => Check.maxNumber(value, 999999),
		(value) => Check.minNumber(value, 1)
	],
	operatingPeriodEnd: [
		Check.validHHMMFormat
	],
	regularReceptionHoursBegin: [
		Check.validHHMMFormat
	],
	regularReceptionHoursEnd: [
		Check.validHHMMFormat
	],
	highSeasonReceptionHoursBegin: [
		Check.validHHMMFormat
	],
	highSeasonReceptionHoursEnd: [
		Check.validHHMMFormat
	],
}

const resolveAccommodationTypeOptions = t => ([
	{
		text: t('campsite_details.traditional'),
		value: 'traditional',
	},
	{
		text: t('campsite_details.motorhomes'),
		value: 'motorhomes'
	},
	{
		text: t('campsite_details.bungalows'),
		value: 'bungalows'
	},
	{
		text: t('campsite_details.mobile'),
		value: 'mobile'
	},
	{
		text: t('campsite_details.teepees'),
		value: 'teepees'
	},
	{
		text: t('campsite_details.rooms'),
		value: 'rooms'
	},
	{
		text: t('campsite_details.glamping'),
		value: 'glamping'
	}
])

const resolveLanguageOptions = t => ([
	{
		text: t('form.portuguese'),
		value: 'portuguese',
	},
	{
		text: t('form.english'),
		value: 'english'
	},
	{
		text: t('form.spanish'),
		value: 'spanish'
	},
	{
		text: t('form.french'),
		value: 'french'
	},
	{
		text: t('form.german'),
		value: 'german'
	},
	{
		text: t('form.dutch'),
		value: 'dutch'
	},
	{
		text: t('form.hungarian'),
		value: 'hungarian'
	},
	{
		text: t('form.polish'),
		value: 'polish'
	},
	{
		text: t('form.italian'),
		value: 'italian'
	}
])

const resolveTerrainTypeOptions = t => ([
	{
		text: t('campsite_details.flat'),
		value: 'flat',
	},
	{
		text: t('campsite_details.undulated'),
		value: 'undulated',
	},
	{
		text: t('campsite_details.terraced'),
		value: 'terraced',
	},
	{
		text: t('campsite_details.hard'),
		value: 'hard',
	},
	{
		text: t('campsite_details.sandy'),
		value: 'sandy',
	},
	{
		text: t('campsite_details.turfy'),
		value: 'turfy',
	}
])

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED,
	resolveAccommodationTypeOptions,
	resolveTerrainTypeOptions,
	resolveLanguageOptions
}
