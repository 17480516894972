import * as Check from 'validations'

export const DEFAULT_VALUES = {
	password: {
		value: ''
	},
	emailNif: {
		value: ''
	}
}

export const VALIDATIONS = {
	password: [
		Check.isRequired
	],
	emailNif: [
		Check.isNifOrEmail
	]
}

export const LOGIN_TYPES = [
	{
		slug: 'entity',
		name: 'Entity'
	},
	{
		slug: 'manager',
		name: 'Manager'
	}
]

export const DEFAULT_LOGIN_TYPE = 'entity'