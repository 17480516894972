import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'
import { MENU_OPTIONS, ROLES } from './utils'

import Brand from 'components/Brand'
import Divider from 'components/Divider'
import UserMenu from 'components/UserMenu'
import { UserIcon } from 'components/Icons'
import Container from 'components/ContainerWrapper'

class Header extends PureComponent {

handleClickLogo = (e) => {
	const { user } = this.props
	const { navigate } = this.actions

	if (user) {
		e.preventDefault()
		!user.registrationComplete && navigate('/finalizar-registo')
		user.registrationComplete && navigate('/gestao-processos')
	}     
}

render () {
	const {
		currentRoute,
		user,
		t
	} = this.props

	const {
		navigate,
		logout
	} = this.actions

	const options = MENU_OPTIONS(navigate, logout)
	const getRoles = user && ROLES.find(role => role.key === user.type)
	const getRoleText = () => getRoles && getRoles.text

	return (
		<Wrapper>
			<WrapperContainer flex>
				<Brand onClick={this.handleClickLogo} />

				{user && currentRoute.key !== 'finalize_register' ? (
					<ActionsWrapper>
						<StyledDivider />
						{!currentRoute.disableUserMenu &&
						<UserMenu
							user={{
								name: user.name,
								role: getRoleText(),
								avatar: user.avatar
							}}
							options={options}
						/>
						}
					</ActionsWrapper>
				) : (
					<UnknownActionsWrapper>
						<Triangle />
						<Avatar>
							<UserIcon />
						</Avatar>
						<Actions>
							<UserArea>{t('header.reserved_area')}</UserArea>
							{currentRoute.key !== 'finalize_register' && (
								<ButtonArea>
									<Login
										onClick={() => navigate('/entrar')}
									>{t('header.login')}
									</Login>
									<Register
										onClick={() => navigate('/registar')}
									>{t('header.register')}
									</Register>
								</ButtonArea>
							)}
						</Actions>
					</UnknownActionsWrapper>
				)}
			</WrapperContainer>
		</Wrapper>
	)
}
}

export default withTranslation()(Logic(Header))

// Header
const Wrapper = styled.div`
  background-color: #FFFFFF;
  position: fixed;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.08);
  width: 100%;
  height: 96px;
  z-index: 99999999;
`

const WrapperContainer = styled(Container)`
	height: 100%;
	justify-content: space-between;
`

const StyledDivider = styled(Divider)`
  margin: 0 0 0 20px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  ${({ marginAuto }) => marginAuto && `
    margin-left: auto;
  `}
`
const UnknownActionsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 96px;
  background: #102526;
  color: #fff;
`

const Triangle = styled.div`
  position: absolute;
  width: 60px;
  height: 96px;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: 96px 0 0 60px;
  border-color: transparent transparent transparent #fff;
`

const Avatar = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px 5px 5px 25px;
`

const Actions = styled.div`
  margin: 5px;
`

const UserArea = styled.div`
  font-weight: bold;
`

const ButtonArea = styled.div``

const Login = styled.span`
  cursor: pointer;
	padding-right: 5px;
  
  border-right: 1px solid;
`

const Register = styled.span`
	cursor: pointer;
	padding-left: 5px;
`
