export const MENU_OPTIONS = (navigate, logout) => [
	{
		key: 'user',
		text: 'Editar Perfil',
		visible: true,
		onClick: () => navigate('/editar-perfil')
	},
	{
		key: 'sign-out',
		text: 'Terminar Sessão',
		visible: true,
		onClick: () => logout()
	}
]

export const ROLES = [
	{
		key: 'manager',
		text: 'Administrador'
	},
	{
		key: 'entity',
		text: 'Entidade'
	}
]