import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import { Segment, Loader } from 'semantic-ui-react'
/** Components */
import Title from 'components/Title'
/** Container */
import ModalServiceArea from 'containers/Modals/ModalServiceArea'
import ModalCleanAndSafe from 'containers/Modals/CleanAndSafe'
import ModalHomologation from 'containers/Modals/ModalHomologation'
import FormNavigation from 'containers/FormNavigation/ServiceArea'
import ServiceAreaRequestForm from 'containers/ServiceAreaRequestForm'

class ServiceAreaRequest extends Component {
  
	componentDidMount () {
		this.actions.start()
	}

	componentWillUnmount () {
		this.actions.clearServiceArea()
	}

	render () {
		const { 
			t, 
			current,
			loading,
			areaName,
			serviceAreaProcessId,
			showCleanAndSafe,
			showHomologation
		} = this.props

		const { 
			toggleCleanAndSafe,
			toggleHomologation,
			requestHomologation,
			requestCleanAndSafe
		} = this.actions

		return (
			<Wrapper>

				<FormContainer>
					<FormNavigation />
				</FormContainer>

				<FormWrapper>
					{!loading && 
					<Title>
						{areaName || t('navigation.service_area_request')}
					</Title>
					}
					<Loader active={loading} size='large' />
          
					{serviceAreaProcessId && !loading && (
						<ServiceAreaRequestForm
							current={current}
							loading={loading}
						/>
					)}
				</FormWrapper>

				<ModalServiceArea />

				<ModalCleanAndSafe 
					open={showCleanAndSafe}
					onClose={toggleCleanAndSafe}
					onSubmit={requestCleanAndSafe}
				/>

				<ModalHomologation
					open={showHomologation}
					onClose={toggleHomologation}
					onSubmit={requestHomologation}
				/>

			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(ServiceAreaRequest))

const Wrapper = styled(Segment)`
	&&&& {
	  display: flex;
	  border: none !important;
		box-shadow: none !important;
		background: transparent;
	}
`

const FormContainer = styled.div`
  flex: 0.3;
  min-width: 30%;
  @media screen and (max-width: 1240px){
    flex: 0.45;
  }
`

const FormWrapper = styled.div`
  flex: 0.7;
  min-width: 70%;
  position: relative;
  @media screen and (max-width: 1240px){
    flex: 0.55;
  }
`