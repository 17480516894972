/**
 * ContentBox Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva <nmsilva@ubiwhere.com>
 */
import React, { Component } from 'react'
import styled from 'styled-components'
import {
	Header as SemanticHeader,
	Segment as SemanticSegment
} from 'semantic-ui-react'

export default class ContentBox extends Component {
	render () {
		const {
			title,
			icon,
			help,
			noPadding,
			hidden,
			button,
			loading,
			noMarginBottom = false
		} = this.props

		return (
			<Wrapper style={hidden && {'display': 'none'}}>
				{title &&
				<Header as='h2' attached='top' noMarginBottom={noMarginBottom}>
					<Left left icon={icon && icon}>
						{icon && icon}
						<span 
							style={icon && { marginLeft: 14 }}
						>
							{title}
						</span>
						{help}
					</Left>
					<Right>
						{button}
					</Right>
				</Header>
				}
				<SegmentStyled
					style={noPadding && {'padding': 0}}
					attached={title && true}
					loading={loading && loading}
				>
					{this.props.children}
				</SegmentStyled>
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	margin-bottom: 32px;
	width: 100%;
`

const Header = styled(SemanticHeader)`
	&&&& {
		position:relative;
		background-color: #F8F8F8;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		border-bottom: none;
		font-size: 20px;
		color: #000000;
		padding: 16px 16px !important;
		font-weight: normal;
		border: 1px solid rgba(196,196,196,0.4);
		box-shadow: 0px 0px 7px rgba(204, 204, 204, 0.39);

		@media screen and (max-width: 722px){
			font-size: 16px;
		}

		i, img{
			display: inline-block;
			margin-right: 16px;
			max-width: 40px!important;
			vertical-align: middle;
		}

		${({ noMarginBottom }) => noMarginBottom && `
			margin-bottom: 0 !important;
		`}
	}
`

const Left = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		font-family: "Open Sans";
	}

	img {
		width: 32px;
		heigth: 32px;
	}
`

const Right = styled.div`
	float: right;
	display: flex;

	i {
		display: initial !important;
		max-width: initial !important;
		vertical-align: top !important;
	}
`

const SegmentStyled = styled(SemanticSegment)`
	&&&& {
		padding: 0 0;
		${({attached}) => attached && `
			border-bottom: 1px solid rgba(196,196,196,0.4);
			border-left: 1px solid rgba(196,196,196,0.4);
			border-right: 1px solid rgba(196,196,196,0.4);
			box-shadow: 0 5px 7px rgba(204,204,204,0.39);
		`}
	}
`
