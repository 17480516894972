import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	Grid,
	Input,
	Dimmer,
	Loader
} from 'semantic-ui-react'

import Logic from './logic'
import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import FormError from 'components/FormError'

class UsageDetailsMotorhome extends Component {

	render () {
		const {
			t,
			form,
			loading
		} = this.props

		const { 
			changeEditForm,
			changeForm
		} = this.actions

		const {
			motorhomeModel,
			motorhomeLicensePlate,
			motorhomeInfo
		} = form

		return (
			<Wrapper>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.motorhome_info')}
				</SegmentTitle>
				{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
				}
				{!loading &&
				<Grid>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.motorhome_model')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!motorhomeModel.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												motorhomeModel: !motorhomeModel.edit
											})
									}}
									value={motorhomeModel.value}
									disabled={!motorhomeModel.edit}
									onChange={(e, {value}) => changeForm({
										motorhomeModel: value
									})}
								/>
								{!motorhomeModel.valid &&
									<FormError>
										{motorhomeModel.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.motorhome_licenseplate')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!motorhomeLicensePlate.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												motorhomeLicensePlate: !motorhomeLicensePlate.edit
											})
									}}
									value={motorhomeLicensePlate.value}
									disabled={!motorhomeLicensePlate.edit}
									onChange={(e, {value}) => changeForm({
										motorhomeLicensePlate: value
									})}
								/>
								{!motorhomeLicensePlate.valid &&
									<FormError>
										{motorhomeLicensePlate.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{t('booking_details.motorhome_otherinfos')}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!motorhomeInfo.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												motorhomeInfo: !motorhomeInfo.edit
											})
									}}
									value={motorhomeInfo.value}
									disabled={!motorhomeInfo.edit}
									onChange={(e, {value}) => changeForm({
										motorhomeInfo: value
									})}
								/>
								{!motorhomeInfo.valid &&
									<FormError>
										{motorhomeInfo.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(UsageDetailsMotorhome))

const Wrapper = styled(Segment)`
	padding: 32px 40px !important;
`

const InputLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
	line-height: 14px;
	padding: 8px 0;
`

