import React, { Component } from 'react'
import styled from 'styled-components'
import { Confirm as SemanticConfirm } from 'semantic-ui-react'

import Button from 'components/Button'

export default class ConfirmButton extends Component {
	constructor () {
		super()
		this.state = { open: false }
	}

getContent = () => {
	const { content } = this.props

	if (typeof content === 'string') {
		return <Content>{content}</Content>
	} else {
		return content
	}
}

closeModal = () => {
	this.setState({ open: false })
}

render () {
	const {
		width,
		primary,
		secondary,
		header,
		label,
		icon = null,
		content,
		onClick,
		onCancel,
		disabled,
		style = {},
		secondaryBtnTxt = 'Cancelar',
		primaryBtnTxt = 'Continuar',
		size = 'small',
		padding
	} = this.props

	const { open } = this.state

	const confirm = () => {
		const { notSafeToClose } = this.props

		// notSafeToClose prop allow the modal
		// not to be closed immediately after confirm triggered
		// to give room for some logic
		// after that 'logic', the modal could be closed by 'closeModal()'
		// with a ref
		if (!notSafeToClose) {
			this.setState({ open: false })
		}

		onClick()
	}

	return (
		<Wrapper>
			<Button
				disabled={disabled}
				onClick={() => this.setState({ open: true })}
				primary={primary}
				secondary={secondary}
				style={style}
			>
				{icon}
				{label}
			</Button>

			<ConfirmModal
				width={width}
				open={open}
				header={header}
				content={<Content padding={padding}>{content}</Content>}
				cancelButton={
					<Button
						secondary
					>
						{secondaryBtnTxt}
					</Button>
				}
				confirmButton={
					<Button
						primary
					>
						{primaryBtnTxt}
					</Button>
				}
				onCancel={() => {
					if (onCancel) {
						onCancel()
					}
					this.setState({ open: false })
				}}
				onConfirm={confirm}
				size={size}
			/>
		</Wrapper>
	)
}
}

const Wrapper = styled.div``

const ConfirmModal = styled(SemanticConfirm)`
	${({ width }) => width && `
		width: ${width} !important;
	`}
	
	.actions {
		display: flex;
		justify-content: flex-end;
	}
`

const Content = styled.div`
	padding: ${({ padding }) => padding || '40px'};
`