import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Logic from './logic'

import { Form, Input } from 'semantic-ui-react'

import * as Check from 'validations'

import Title from 'components/Title'
import Segment from 'components/Segment'
import FormField from 'components/FormField'
import Button from 'components/Button'
import { SecondTitle } from 'components/SubTitle'
import PasswordMask from 'components/PasswordMask'

import Loading from 'containers/Loading'
import RecoverPassword from 'containers/RecoverPassword'
import Toaster from 'containers/Toaster'
import Errors from 'containers/ErrorList'

import Stamp from './clean.png'

class Login extends Component {
	constructor (props) {
		super(props)

		// Login Password showing state
		this.state = {
			loginPassword: false
		}
	}

handleKeyPress = (e) => {
	const { submit } = this.actions
	if (e.charCode === 13) {
		submit()
	}
}

getPasswordState = (name, state) => {
	this.setState({ [name]: state })
}

render () {

	const {
		change,
		submit,
		toggleRecoverPassword,
		recoverPassword,
		setRecoverEmail,
		navigate
	} = this.actions

	const {
		t,
		form,
		error,
		loading,
		submiting,
		showingRecover,
		contacts
	} = this.props

	const {
		password,
		emailNif
	} = form

	if (loading) {
		return <Loading t={t} />
	}

	// Shows form for password recovery
	if (showingRecover) {
		return (
			<RecoverPassword
				submit={recoverPassword}
				back={toggleRecoverPassword}
				onChange={setRecoverEmail}
				email={!Check.isNif(emailNif.value).result ? emailNif : ''}
				contacts={contacts}
				{...this.props}
			/>
		)
	}

	return (
		<Wrapper>
			<Title bolder uppercase centered>{t('login.screen_title')}</Title>
			<SecondTitle>{t('login.screen_subtitle')}</SecondTitle>
			<Image src={Stamp} />

			<BoxSegment>
				<Form loading={submiting} onKeyPress={this.handleKeyPress}>

					{/* Email */}
					<FormField required>
						<Label>{t('form.email_or_nif')}</Label>
						<Input
							value={emailNif.value}
							error={!emailNif.valid}
							onChange={e => change({ emailNif: e.target.value })}
						/>
					</FormField>

					{/* Password */}
					<FormField
						required
						style={{'position': 'relative'}}
					>
						<Label>{t('form.password')}</Label>
						<Input
							type={this.state.loginPassword
								? 'text'
								: 'password'
							}
							value={password && password.value}
							error={!password.valid}
							onChange={e => change({ password: e.target.value })}
						/>
						<PasswordMask
							name={'loginPassword'}
							getPasswordState={this.getPasswordState}
						/>
					</FormField>

					{error && !!error.length && <Errors list={error} />}

					<SubmitButton 
						primary 
						onClick={() => submit()} 
						disabled={!emailNif.valid || !password.valid}
					>
						{t('options.login')}
					</SubmitButton>
					{contacts &&
					<Help>
						{/* eslint-disable-next-line max-len */}
						{t('common.contact_info')} <a href={`mailto:${contacts.CONTACT_US_EMAIL}`}>{contacts.CONTACT_US_EMAIL}</a>
					</Help>
					}
				</Form>
			</BoxSegment>

			<FloatLeft>
				<RegisterLink>
					<NoAccountLabel>
						{t('login.not_register')}
            &nbsp;
					</NoAccountLabel>
					<span
						onClick={() => navigate('/registar')}
					>
						{t('options.register_now')}
					</span>
					<span 
						onClick={() => toggleRecoverPassword()}
					>
						{t('options.password_recover')}
					</span>
				</RegisterLink>
			</FloatLeft>
			<Toaster />
		</Wrapper>
	)
}
}

export default withTranslation()(Logic(Login))

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const BoxSegment = styled(Segment)`
  &&&& {
    width: 544px;
    background-color: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    margin-top: 40px;
    padding: 40px;
    position: relative;
  }
`

const Label = styled.label`
  &&&& {
    color: #616161;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 14px;
    margin: 0 0 8 0 !important;
  }
`

const SubmitButton = styled(Button)`
  &&&& {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    max-height: 48px;
    padding: 18px 0;
  }
`
const NoAccountLabel = styled.label`
  color: #000;
`

const RegisterLink = styled.a`
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  width: 100%;
  display: block;
  text-transform: uppercase;

  span {
    border-bottom: 1px dashed #102526;
    color: #102526;
    text-transform: uppercase;
  }

  span:last-child {
    float: right;
  }
`

const FloatLeft = styled.div`
  display: flex;
  align-items: flex-start;
  width: 544px;
`

const Help = styled.div`
  margin-top: 40px;
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  text-align: center;
  font-family: Open sans;

  a {
    color: #000;
  }
`

const Image = styled.img`
  width: 75px;
  height: 75px;
  margin-top: 40px;
`