import * as Check from 'validations'

const DEFAULT_VALUES = {
	images: {
		value: []
	}
}

const VALIDATIONS = {
	images: [
		Check.isRequired
	]
}

const VALIDATIONS_NO_REQUIRED = {
}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}