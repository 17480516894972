/**
 * Divider Component
 *
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 * @author João Guiomar <jguiomar@ubiwhere.com>
 */
import styled from 'styled-components'

export default styled.div`
  width: 1px;
  height: 48px;
  background-color: rgba(0,0,0,0.05);
  margin: 0 20px;
`
