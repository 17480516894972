import React, { Component } from 'react'
import styled from 'styled-components'
import Logic from './logic'

import Sidebar from 'containers/Sidebar'
import Footer from 'containers/Footer'

class Master extends Component {
	render () {
		const { currentRoute, t } = this.props

		return (
			<Wrapper>
				<Sidebar t={t} />
				<Main asSideBar={currentRoute.showSidebar}>
					<InnerMain>
						{this.props.children}
					</InnerMain>
					<Footer hide={currentRoute.noFooter} />
				</Main>
			</Wrapper>
		)
	}
}

export default Logic(Master)

const Wrapper = styled.div`
  display: flex;
	flex-direction: row;
	background-color: #FAFAFA;
`

const InnerMain = styled.div`
  margin: 0 auto;
`

const Main = styled.div`
	${({ asSideBar }) => asSideBar ? 'width: calc(100% - 250px);': 'width: 100%;' }
  margin-top: 96px;
  transition: margin 0.2s linear;
`
