import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icon,
	Loader,
	Dimmer as SegmentDimmer
} from 'semantic-ui-react'
import moment from 'moment'
import Timeline, {
	TimelineHeaders,
	CustomHeader
} from 'react-calendar-timeline'

import ComponentDropdown from 'components/Dropdown'
import { getCalendarMonths } from './utils'
import Logic from './logic'

var keys = {
	groupIdKey: 'id',
	groupTitleKey: 'title',
	groupRightTitleKey: 'rightTitle',
	itemIdKey: 'id',
	itemTitleKey: 'title',    // key for item div content
	itemDivTitleKey: 'tip', // key for item div title (<div title="text"/>)
	itemGroupKey: 'group',
	itemTimeStartKey: 'start_time',
	itemTimeEndKey: 'end_time',
}

class BookMngCalendarView extends Component {

	render () {
		
		const {
			t,
			reservations,
			timeFilter,
			parkingPlaces,
			visibleTime,
			setTimeFilter,
			prevClick,
			nextClick,
			loading
		} = this.props
		
		const { visibleTimeStart, visibleTimeEnd } = visibleTime
		
		const {
			handleItemResize,
			handleItemMove,
			handleItemSelect
		} = this.actions
		
		const timeFilterOptions = [{
			text: t('booking_management.week'),
			value: 'week'
		}, {
			text: t('booking_management.month'),
			value: 'month'
			
		}]

		var resolvedReservations = reservations.map(e => {
			e.itemProps.onDoubleClick = () => this.actions.navigate(`/gestao-reservas/${e.id}`)
			return e
		})

		return (
			<Wrapper>
				<FiltersWrapper>
					<CalendarHeader>
						<IconButton onClick={prevClick}>
							<Icon name={'angle left'} />
						</IconButton>
						<IconButton
							onClick={nextClick}
							margin={'0 0 0 16px'}
						>
							<Icon name={'angle right'} />
						</IconButton>
						<CalendarTitle>{getCalendarMonths(
							visibleTimeStart,
							visibleTimeEnd
						)}</CalendarTitle>
					</CalendarHeader>
					<FiltersRight>
						<DropdownBordered
							options={timeFilterOptions}
							defaultValue={timeFilter}
							// eslint-disable-next-line max-len
							onChange={(e, { value }) => setTimeFilter(value)}
						/>
					</FiltersRight>
				</FiltersWrapper>
				{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
				}
				{!loading &&
				<CalendarWrapper>
					<Timeline
						groups={parkingPlaces || []}
						items={resolvedReservations || []}
						keys={keys}
						visibleTimeStart={visibleTimeStart}
						visibleTimeEnd={visibleTimeEnd}
						onItemMove={handleItemMove}
						onItemResize={handleItemResize}
						lineHeight={48}
						itemHeightRatio={0.75}
						sidebarWidth={90}
						canResize
						canMove
						onItemSelect={handleItemSelect}
					>
						<TimelineHeaders>
							<CustomHeader style={{height: 70}}>
								{({
									headerContext: { intervals },
									getRootProps,
									getIntervalProps
								}) => {
									return (
										<div {...getRootProps()}>
											{intervals.map(interval => {
												return (
													<HeaderWrapper
														// eslint-disable-next-line max-len
														className={'rct-dateHeader'}
														// eslint-disable-next-line max-len
														key={interval.startTime}
														// eslint-disable-next-line max-len
														{...getIntervalProps({
															interval
														})}
													>
														{/* eslint-disable-next-line max-len*/}
														{timeFilter === 'week' && 
														// eslint-disable-next-line max-len
														<p>{moment(interval.startTime).format('dddd')}</p>
														}
														{/* eslint-disable-next-line max-len*/}
														<span>{moment(interval.startTime).format('DD')}</span>
													</HeaderWrapper>
												)
											})}
										</div>
									)
								}}
							</CustomHeader>
						</TimelineHeaders>
					</Timeline>
				</CalendarWrapper>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(BookMngCalendarView))

const Wrapper = styled.div``

const CalendarWrapper = styled.div`	
	.rct-calendar-header {
		border: 0;
		border-bottom: 1px solid #F3F3F3;
		background: #FFF;
		height: 70px;
		
		> div {
			height: 70px;
		}
	}
	
	.rct-header-root {
		height: 70px;
		
		> div {
			border-right: 1px solid #F3F3F3!important;
		}
	}
	
	.react-calendar-timeline .rct-header-root,
	.react-calendar-timeline .rct-vertical-lines .rct-vl {
		background: transparent;
	}
	
	.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even,
	.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd{
		background: #FFF;
	}
	
	.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
	.react-calendar-timeline .rct-horizontal-lines .rct-hl-even  {
		background: #FAFAFA;
	}
	
	.react-calendar-timeline .rct-dateHeader {
		border-bottom: 0;
		background: #FFF;
		height: 70px;
		border-left: 1px solid #F3F3F3!important;
	}

	.react-calendar-timeline .rct-vertical-lines .rct-vl {
		border-left: 1px solid #F3F3F3!important;
	}
	
	.react-calendar-timeline .rct-header-root,
	.react-calendar-timeline .rct-sidebar .rct-sidebar-row,
	.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
	.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
		border-bottom: 1px solid #F3F3F3!important;
	}
	
	.react-calendar-timeline .rct-sidebar {
		border-right: 1px solid #F3F3F3!important;
		
		color: #102526;
		font-family: "Open Sans";
		font-size: 10px;
		font-style: italic;
		letter-spacing: 0;
		line-height: 14px;
		text-transform: uppercase;
	}
`

const HeaderWrapper = styled.div`
	background: #FFF;
	height: 70px;
	border-left: 1px solid #F3F3F3;
	
	justify-content: center;
  display: flex;
  align-items: center;
	flex-direction: column;
	
	p {
		color: #6F7C7C;
		font-family: "Open Sans";
		font-size: 10px;
		letter-spacing: 0;
		line-height: 19px;
		text-align: center;
		text-transform: uppercase;
		margin: 0;
	}
	
	span {
		font-family: "Open Sans";
		color: #102526;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 19px;
		text-align: center;
	}
`

const DropdownBordered = styled(ComponentDropdown)`
	&&&& {
		box-sizing: border-box;
		border: 1px solid #D0D0D0!important;
		border-radius: 4px;
		background-color: #FFFFFF;
		padding: 6px 16px;
		
		.text {
			font-family: "Open Sans";
			letter-spacing: 0;
			line-height: 20px;
			font-size: 14px;
			color: #102526;
		}
		
		.dropdown.icon {
			height: 5px;
		}
		
		.item .text {
			line-height: 20px;
			font-size: 14px;
		}
	}
`

const IconButton = styled.button`
  height: 32px;
  width: 32px;
  border-radius: 2px;
	background-color: #F4F4F4;
	border: 0;
	cursor: pointer;
	
	margin: ${({ margin }) => margin};
	
	> i {
		margin: 0;
	}
`

const CalendarHeader = styled.div`
	margin-bottom: 34px;
	display: flex;
	align-items: center;
`

const CalendarTitle = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
	text-align: center;
	margin-left: 32px;
`

const FiltersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const FiltersRight = styled.div`
	display: flex;
	
	> div {
		padding:0 18px;
	}
`

const Dimmer = styled(SegmentDimmer)`
	&&&& {
		background-color: transparent;
		height: 40vh;
	}
`