/* eslint-disable no-debugger */
import moment from 'moment'

export const getPeriod = (startDay, endDay) => {
	const period = []

	let incrementDay = moment(startDay)
	const lastDay = moment(endDay)
	while (lastDay.isAfter(incrementDay)) {
		incrementDay.format('YYYY-MM-DD')
		period.push({ number: incrementDay.format('D'), name: incrementDay.format('dddd'), date: incrementDay.format('YYYY-MM-DD') })
		incrementDay = incrementDay.add(1, 'days')
	}
	return period
}

export const getTimeSchedule = (schedule) => {
	const hours = []
	const defaultHours = [9,18]
	let intervalStart = Infinity
	let intervalEnd = 0
	schedule.forEach(element => {
		const startHour = parseInt(element.startingTime)
		const endHour = parseInt(element.endingTime)
		
		if (intervalStart > startHour) {
			intervalStart = startHour
		}

		if (intervalEnd < endHour) {
			intervalEnd = endHour
		}

	})

	for (let hour = 0; hour < 24; hour++) {
		if ((hour >= defaultHours[0] && hour < defaultHours[1]) || (hour <= intervalEnd && hour >= intervalStart)) {
			hours.push(`${moment({ hour }).format('HH[h]mm')} - ${moment({ hour }).add(1, 'hours').format('HH[h]mm')}`)	
		}
	}

	return hours
}

export const getSchedule = (data) => {
	const newData = data.map(seat => {
		const newObj = seat.schedule.reduce((acc, element)=> {
			if (!acc[element.date]) {
				acc[element.date] = []
			}
			const item = verifyTime(element)
			if (Array.isArray(item)) {
				item.forEach(item =>
					acc[element.date].push({...item})
				)
			} else {
				acc[element.date].push({...item}) 
			}
			return acc
		},{})
		return  { ...seat, schedules: newObj, hours: getTimeSchedule(seat.schedule) }
	})
	return newData
}

const verifyTime = element => {
	const hours = []
	const start = moment(element.startingTime, 'HH:mm')
	const diferenceTime = moment(element.endingTime, 'HH:mm').diff(start, 'h')
	if (diferenceTime > 1) {
		const startHour = parseInt(element.startingTime)
		const endHour = parseInt(element.endingTime)
		for (let hour = 0; hour < 24; hour++) {
			if (hour < endHour && hour >= startHour) {
				hours.push({
					...element,
					startingTime: `${moment({ hour }).format('HH:mm:ss')}`,
					endingTime: `${moment({ hour }).add(1, 'hours').format('HH:mm:ss')}`
				})
			}
		}
		return hours
	} else {
		return element
	}
}