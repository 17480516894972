import { kea } from 'kea'
import BookingDetails from 'screens/BookingDetails/logic'
import BookingManagementLogic from 'screens/BookingManagement/logic'

export default kea({
	path: () => ['scenes', 'containers', 'BookDetailsPayment'],

	connect: {
		props: [
			BookingDetails, [
				'form',
				'prices',
				'paymentMethods',
				'loading',
				'datasheet'
			],
			BookingManagementLogic, [
				'serviceAreas'
			]
		],
		actions: [
			BookingDetails, [
				'changeForm'
			]
		]
	}
})
