/**
 * Main Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import styled from 'styled-components'

export default styled.div`
  width: 100%;
`
