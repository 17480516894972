import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import Logic from './logic'
import LogoImage from './image/logo.png'

import { DownloadIcon, CaravanIcon, EditIcon } from 'components/Icons'
import ContentBox from 'components/ContentBox'
import GoToButton from 'components/GoToButton'
import StateLabel from 'components/StateLabel'
import Table from 'components/Table'

import { formatDatePt, downloadFile } from 'utils'

class ServiceLocationList extends Component {
	render () {

		const {
			t,
			areas,
			limit,
			offset, 
			count,
			hasNext,
			hasPrevious,
			tableLoading
		} = this.props

		const {
			navigate,
			changeLimit,
			changePage
		} = this.actions

		return (
			<Wrapper>
				<ContentBox 
					loading={tableLoading}
					icon={<CaravanIcon width={40} height={40} fill="#9B9B9B" />}
					title={t('navigation.autocaravan_service_areas')}
					noMarginBottom
				>
					<Table.Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{'Nº'}</Table.HeaderCell>
								<Table.HeaderCell>
									{t('form.service_area_name')}
								</Table.HeaderCell>
								<Table.HeaderCell>
									{t('form.district')}
								</Table.HeaderCell>
								<Table.HeaderCell>
									{t('form.locality')}
								</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>
									Clean & Safe
								</Table.HeaderCell>
								<Table.HeaderCell>
									{t('form.created_date')}
								</Table.HeaderCell>
								<Table.HeaderCell/>
								<Table.HeaderCell />
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{areas.map((area, i) => (
								<React.Fragment key={`areas-${i}`}>
									<Row>
										<Cell>
											{area.registerNumber || '-'}
										</Cell>
										<Cell>{area.name || '-'}</Cell>
										<Cell>{area.district || '-'}</Cell>
										<Cell>{area.locality || '-'}</Cell>
										<Cell 
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											{(area.hasCleanAndSafeStamp && 
											area.cleanAndSafeMaterials) &&
												<Logo src={LogoImage} />
											}
											{area.hasCleanAndSafeStamp && 
											area.cleanAndSafeMaterials && (
												<GoToButton
													margin={'0 8px'}
													icon={
														<DownloadIcon
															width={18}
															height={18}
														/>
													}
													onClick={() =>
														/* eslint-disable-next-line */
														area.cleanAndSafeMaterials && 
														/* eslint-disable-next-line */
														downloadFile(area.cleanAndSafeMaterials.url)
													}
												/>
											)}
										</Cell>
										<Cell>
											{formatDatePt(area.createdAt)}
										</Cell>
										<Cell textAlign={'center'}>
											<StateLabel 
												state={area.status}
											/>                
										</Cell>
										<Table.Cell width={1}>
											<GoToButton 
												icon={
													<EditIcon
														width={18}
														height={18}
													/>
												}
												onClick={() => 
													navigate(`/gestao-processos/pedido/${area.id}`)
												} 
											/>
										</Table.Cell>
									</Row>
								</React.Fragment>
							))}

							{!areas.length && (
								<Table.Row>
									<Cell>{t('error.noResults')}</Cell>
								</Table.Row>
							)}
						</Table.Body>

						{!!areas.length && (
							<Table.Footer>
								<Table.Row>
									<PagContainer colSpan={9}>
										<Table.Pagination
											style={{ width: 'auto' }}
											resultsPerPage={[
												{ text: '5', value: 5 },
												{ text: '10', value: 10 },
												{ text: '20', value: 20 }
											]}
											limit={limit}
											offset={offset}
											total={count}
											hasNext={hasNext}
											hasPrevious={hasPrevious}
											onChangeResults={changeLimit}
											onChangePage={payload => {
												// eslint-disable-next-line max-len
												if (payload === 'next' && hasNext) changePage(payload)
												// eslint-disable-next-line max-len
												if (payload === 'prev' && hasPrevious) changePage(payload)
												else return
											}}
										/>
									</PagContainer>
								</Table.Row>
							</Table.Footer>
						)}
					</Table.Table>
				</ContentBox>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(ServiceLocationList))

const Wrapper = styled.div``

const Row = styled(Table.Row)`
  &&&& {
    &:hover {
      background-color: #f9f9f9;
    }
  }
`

const Cell = styled(Table.Cell)`
  &&&&{
    color: #3E3E3E;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 14px;
  }
`

const PagContainer = styled.th`
  div.menu {
    width: auto !important;
    align-items: center;
  }

  div.ui.right {
    background: none;
  }
`

const Logo = styled.img`
  width: 30px;
  height: 30px;
`