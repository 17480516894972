/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const Check = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M20.6495758,8.17409424 C20.6495758,8.45311243 20.5379685,8.73213062 20.3370754,8.93302371 L10.7388497,18.5312494 C10.5379566,18.7321425 10.2589384,18.8437498 9.97992023,18.8437498 C9.70090204,18.8437498 9.42188385,18.7321425 9.22099075,18.5312494 L3.66294841,12.9732071 C3.46205531,12.772314 3.35044804,12.4932958 3.35044804,12.2142776 C3.35044804,11.9352594 3.46205531,11.6562412 3.66294841,11.4553481 L5.18080736,9.9374892 C5.38170046,9.7365961 5.66071865,9.62498882 5.93973684,9.62498882 C6.21875503,9.62498882 6.49777322,9.7365961 6.69866632,9.9374892 L9.97992023,13.2299038 L17.3013575,5.89730581 C17.5022506,5.69641271 17.7812688,5.58480544 18.060287,5.58480544 C18.3393052,5.58480544 18.6183234,5.69641271 18.8192165,5.89730581 L20.3370754,7.41516476 C20.5379685,7.61605786 20.6495758,7.89507605 20.6495758,8.17409424 Z" />
		</g>
	</svg>
)

Check.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

Check.defaultProps = {
	width: 24,
	height: 24,
	fill: '#fff'
}

export default Check
