export const STEPS = [
	{
		id: 1,
		title: 'summary_description',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 2,
		title: 'features',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 3,
		title: 'equipments',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 4,
		title: 'services',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 5,
		title: 'sport_and_leisure',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 6,
		title: 'useful_contacts',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 7,
		title: 'documents',
		linked: false,
		hide: false,
		status: null
	},
	{
		id: 8,
		title: 'photos',
		linked: false,
		hide: false,
		status: null
	},
]