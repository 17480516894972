export const STEPS = [
	{
		step: 1,
		progress: 34,
		canIgnore: false,
		isSelect: false,
		value: '',
		checked: 0
	},
	{
		step: 2,
		progress: 67,
		canIgnore: true,
		isSelect: false,
		value: ''
	},
	{
		step: 3,
		progress: 100,
		canIgnore: false,
		isSelect: true,
		value: 'yes'
	}
]