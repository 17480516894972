/**
 * RemoveButton Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import styled from 'styled-components'

export default styled.button`
  border-radius: 50%;
  box-shadow: 0 4px 2px 0 rgba(97,97,97,0.2);
  width: 32px;
  height: 32px;
  border: 1px solid #DDDDDD;
  border-radius: 100px;
  background-color: #FFFFFF;
  outline: none;
  position: absolute;
  right: 0;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 4;

  &:after {
    content: "✕";
    font-family: "Open Sans";
    font-size: 16px;
    display: block;
  }
`
