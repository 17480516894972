import * as React from 'react'

const IcMinimercado = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-minimercado</title>
			<g id="icons/servicos/ic-minimercado" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M7.89221918,18.8368219 C9.31514679,18.8368219 10.4686575,19.9903327 10.4686575,21.4132603 C10.4686575,22.8361879 9.31514679,23.9896986 7.89221918,23.9896986 C6.46929157,23.9896986 5.31578082,22.8361879 5.31578082,21.4132603 C5.31578082,19.9903327 6.46929157,18.8368219 7.89221918,18.8368219 Z M18.7705753,18.8368219 C20.193503,18.8368219 21.3470137,19.9903327 21.3470137,21.4132603 C21.3470137,22.8361879 20.193503,23.9896986 18.7705753,23.9896986 C17.3476477,23.9896986 16.194137,22.8361879 16.194137,21.4132603 C16.194137,19.9903327 17.3476477,18.8368219 18.7705753,18.8368219 Z M2.51024658,8.73967565e-13 C3.63106849,0.016 4.6189589,0.739671233 4.97221918,1.80350685 L4.97221918,1.80350685 L5.85967123,4.49446575 L23.0933151,4.75205479 L21.8909589,9.81906849 C21.6968767,10.6484384 20.9930411,11.26 20.1447123,11.3363288 L20.1447123,11.3363288 L8.49336986,12.5386849 L8.66515068,12.9681096 C8.69813699,13.1109589 8.68816438,13.2603288 8.63654795,13.3975342 L8.63654795,13.3975342 L8.2930411,14.2563836 C8.11243836,14.7101918 8.16591781,15.223726 8.43616438,15.6305205 C8.68663014,16.0426849 9.12783562,16.3009315 9.60986301,16.317589 L9.60986301,16.317589 L20.7745753,16.317589 C21.0907945,16.317589 21.3471233,16.5739178 21.3471233,16.890137 C21.3471233,17.2063562 21.0907945,17.4626849 20.7745753,17.4626849 L20.7745753,17.4626849 L9.60991781,17.4626849 C8.74082192,17.4365479 7.93928767,16.9876712 7.46284932,16.2603288 C7.00449315,15.5105205 6.91989041,14.5905753 7.23380822,13.7697534 L7.23380822,13.7697534 L7.49145205,13.1113425 L3.88438356,2.14706849 C3.68093151,1.55857534 3.13276712,1.15884932 2.51024658,1.14509589 L2.51024658,1.14509589 L0.592219178,1.14509589 C0.276,1.14509589 0.0196712329,0.888767123 0.0196712329,0.572547945 C0.0196712329,0.256328767 0.276,8.73967565e-13 0.592219178,8.73967565e-13 L0.592219178,8.73967565e-13 Z" id="icon" fill="#000000"/>
			</g>
		</svg>
	)

export default IcMinimercado
