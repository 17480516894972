import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
import API from 'api'

import {
	Form,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
//import FormField from 'components/FormField'
import ListAdder from 'components/ListAdder'

class OtherEquipments extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	valueChangeEquipment = (prop, value, index) => {

		const { changeForm, form } = this.props
		const { equipments } = form

		const newOtherEquipments = [...equipments.value]
		newOtherEquipments[index][prop] = value

		changeForm({equipments: newOtherEquipments})

	}

	addEquipment = () => {

		const { form, changeForm } = this.props
		const { equipments } = form

		const newOtherEquipments = [...equipments.value]
		newOtherEquipments.push({name: ''})
		changeForm({
			equipments: newOtherEquipments
		})
	}

	removeEquipment = index => {

		const { form, changeForm } = this.props
		const { equipments } = form

		const newOtherEquipments = [...equipments.value]
		newOtherEquipments.splice(index, 1)
		changeForm({
			equipments: newOtherEquipments
		})
	}

	submitData = (callback) => {
		const {campsiteId, submit} = this.props
	
		submit(data => API.Campsites.steps.updateOtherEquipments(campsiteId, data), {
			resolveData: data => {
				return {equipmentName: data.equipments.map(equipment => equipment.name)}
			},
			callback: (data, success) => {
				callback(success)
			}
		})
	}

	render () {

		const { t, form, loading } = this.props

		const {
			equipments
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('navigation.other_equipments')}
						</SegmentTitle>

						<ListAdder
							data={equipments.value}
							options={[{
								name: 'name',
								label: t('form.equipment_name')
							}]}
							add={this.addEquipment}
							remove={this.removeEquipment}
							valueChange={this.valueChangeEquipment}
						/>

					</Form>
				</BoxSegment>

			</>
		)
	}
}

export default withTranslation()(Logic(OtherEquipments))
