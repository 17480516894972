import { kea } from 'kea'
import BookingDetails from 'screens/BookingDetails/logic'

export default kea({
	path: () => ['scenes', 'containers', 'BookDetailsMotorhome'],

	connect: {
		props: [
			BookingDetails, [
				'form',
				'loading'
			]
		]
	}
})
