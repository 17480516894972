import { handleApiErrorMessage } from "../utils"

//const API_ERRORS = {
//	'Ensure this value is less than or equal to 100.': 'less_100',
//	'Este campo é obrigatório.': 'mandatory',
//	'Introduza um endereço de e-mail válido.': 'invalid_email',
//	'Nenhum lugar disponível para as datas selecionadas. Por favor tente outras datas.': 'no_spots_dates',
//	'Não é possível criar esta regra uma vez que colide com outra.': 'matching_rules',
//	'Reservations can only be canceled 24 hours in advance.': 'cannot_cancel_reservation',
//	'As reservas só podem ser canceladas com 24 horas de antecedência.': 'cannot_cancel_reservation',
//	'Ensure this field has no more than 16 characters.': 'identity_document',
//	'Uma reserva \"expired\" não pode ser cancelada': 'expired_reservation_cannot_be_canceled',
//	'Já existe uma reserva pendente para este utilizador ou matrícula.': 'reservation_with_same_registration_already_exists'
//}

export const mapApiErrors = (errors, t) => {
	
	if (errors.nonFieldErrors) {
		return errors.nonFieldErrors.map(error => {
			return handleApiErrorMessage(error[Object.keys(error)[0]], t)
		})
	}
	
	if (errors.status) {
		return [handleApiErrorMessage(errors.status, t)]
	}

	if (Array.isArray(errors)) {
		return errors?.map(error => {
			const errorValue = error[Object.keys(error)[0]]
			if (Array.isArray(errorValue)) {
				return errorValue.map(item => {

					if (typeof item === 'string') return handleApiErrorMessage(item, t)
					
					return handleApiErrorMessage(Object.keys(item)[0], t) + ' - ' + handleApiErrorMessage(item[Object.keys(item)[0]], t)
				})
			} else {
				return handleApiErrorMessage(Object.keys(error)[0], t) + ' - ' + handleApiErrorMessage(error[Object.keys(error)[0]], t)
			}
		})
	} else {

		if (errors.error && typeof errors.error === 'string') return [handleApiErrorMessage(errors.error, t)]

		const firstKey = Object.keys(errors)[0]
		const secondKey = Object.keys(errors[Object.keys(errors)])[0]

		return [handleApiErrorMessage(firstKey, t) + ' - ' + handleApiErrorMessage(errors[firstKey][secondKey], t)]
	}
}