import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Radio } from 'semantic-ui-react'

import Label from 'components/Label'
import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import InputUnity from 'components/InputUnity'

class Equipments extends Component {
	render () {
		const { t, form, changeForm } = this.props
		
		const { 
			firstAidEquipment,
			laundryPlaces,
			laundryPlacesQuantity,
			publicLightingSystem,
			emergencySystem,
			garbageContainers,
			garbageContainersQuantity,
			cafeRestaurant,
			vendingMachines,
			tablesChairs,
			wifi
		} = form

		return (
			<Wrapper>
				<FormField required>
					<Label>
						{t('form.first_aid_equipment')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='firstAidEquipment_no'
							checked={!firstAidEquipment.value}
							control={Radio}
							onChange={() => changeForm(
								{ firstAidEquipment: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='firstAidEquipment_yes'
							checked={firstAidEquipment.value}
							control={Radio}
							onChange={() => changeForm(
								{ firstAidEquipment: true })
							}
						/>
					</FormGroup>
				</FormField>
								
				<FormField required>
					<Label>
						{t('form.local_laundry')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='laundryPlaces_no'
							checked={!laundryPlaces.value}
							control={Radio}
							onChange={() => changeForm(
								{ laundryPlaces: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='laundryPlaces_yes'
							checked={laundryPlaces.value}
							control={Radio}
							onChange={() => changeForm(
								{ laundryPlaces: true })
							}
						/>
						<FormGroup inline>
							<Label
								style={{
									borderLeft: '1px solid',
									paddingLeft: '6px'
								}}
							>
								{t('form.quantity')}
							</Label>
							<InputUnity 
								unity='uni.'
								name="quantity"
								// eslint-disable-next-line max-len
								disabled={!laundryPlaces.value}
								// eslint-disable-next-line max-len
								value={laundryPlacesQuantity.value || ''}
								// eslint-disable-next-line max-len
								placeholder={t('form.quantity_placeholder')}
								// eslint-disable-next-line max-len
								onChange={(e, { value }) => changeForm({ laundryPlacesQuantity: value })}
								unityWidth={'60px'}
								// eslint-disable-next-line max-len
								error={laundryPlacesQuantity.dirty && !laundryPlacesQuantity.valid && laundryPlacesQuantity.message }
							/>
						</FormGroup>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.public_lighting_system')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='publicLightingSystem_no'
							checked={!publicLightingSystem.value}
							control={Radio}
							onChange={() => changeForm(
								{ publicLightingSystem: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='publicLightingSystem_yes'
							checked={publicLightingSystem.value}
							control={Radio}
							onChange={() => changeForm(
								{ publicLightingSystem: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.emergency_system')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='emergencySystem_no'
							checked={!emergencySystem.value}
							control={Radio}
							onChange={() => changeForm(
								{ emergencySystem: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='emergencySystem_yes'
							checked={emergencySystem.value}
							control={Radio}
							onChange={() => changeForm(
								{ emergencySystem: true })
							}
						/>
					</FormGroup>
				</FormField>
								
				<FormField required>
					<Label>
						{t('form.garbage_containers')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='garbageContainers_no'
							checked={!garbageContainers.value}
							control={Radio}
							onChange={() => changeForm(
								{ garbageContainers: false,
										garbageContainersQuantity: ''
								}
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='garbageContainers_yes'
							checked={garbageContainers.value}
							control={Radio}
							onChange={() => changeForm(
								{ garbageContainers: true })
							}
						/>
						<FormGroup inline>
							<Label
								style={{
									borderLeft: '1px solid',
									paddingLeft: '6px'
								}}
							>
								{t('form.quantity')}
							</Label>
							<InputUnity 
								unity='uni.'
								name="quantity"
								// eslint-disable-next-line max-len
								disabled={!garbageContainers.value}
								// eslint-disable-next-line max-len
								value={garbageContainersQuantity.value || ''}
								// eslint-disable-next-line max-len
								placeholder={t('form.quantity_placeholder')}
								// eslint-disable-next-line max-len
								onChange={(e, { value }) => changeForm({ garbageContainersQuantity: value })}
								unityWidth={'60px'}
								// eslint-disable-next-line max-len
								error={garbageContainersQuantity.dirty && !garbageContainersQuantity.valid && garbageContainersQuantity.message }
							/>
						</FormGroup>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.cafe_restaurant')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='cafeRestaurant_no'
							checked={!cafeRestaurant.value}
							control={Radio}
							onChange={() => changeForm(
								{ cafeRestaurant: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='cafeRestaurant_yes'
							checked={cafeRestaurant.value}
							control={Radio}
							onChange={() => changeForm(
								{ cafeRestaurant: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.vending_machine')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='vendingMachines_no'
							checked={!vendingMachines.value}
							control={Radio}
							onChange={() => changeForm(
								{ vendingMachines: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='vendingMachines_yes'
							checked={vendingMachines.value}
							control={Radio}
							onChange={() => changeForm(
								{ vendingMachines: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.table_chairs')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='tablesChairs_no'
							checked={!tablesChairs.value}
							control={Radio}
							onChange={() => changeForm(
								{ tablesChairs: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='tablesChairs_yes'
							checked={tablesChairs.value}
							control={Radio}
							onChange={() => changeForm(
								{ tablesChairs: true })
							}
						/>
					</FormGroup>
				</FormField>
				
				<FormField required>
					<Label>
						{t('form.wifi')}
					</Label>
					<FormGroup inline>
						<FormField
							label={t('options.no')}
							name='wifi_no'
							checked={!wifi.value}
							control={Radio}
							onChange={() => changeForm(
								{ wifi: false }
							)}
						/>
						<FormField
							label={t('options.yes')}
							name='wifi_yes'
							checked={wifi.value}
							control={Radio}
							onChange={() => changeForm(
								{ wifi: true })
							}
						/>
					</FormGroup>
				</FormField>
			</Wrapper>
		)
	}
}

export default withTranslation()(Equipments)

const Wrapper = styled.div`
  margin-top: 24px;
`
