/*
 * Copyright (C) Ubiwhere, LDA
 * 2012-2017
 * Authors:
 * Hugo Fonseca   hfonseca@ubiwhere.com
 *
 */

import axios from 'axios'
import React, { Component } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import Button from 'components/Button'
import Remove from 'components/Remove'
import { Progress } from 'semantic-ui-react'

export default class FileUploader extends Component {
	constructor () {
		super()
		this.state = {
			files: [],
			progress: null
		}
	}

	componentWillMount () {
		this.setState({ files: this.props.value || [] })
	}

	componentDidUpdate (prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({ files: this.props.value || [] })
		}
	}

	remove (index) {
		const onRemove = this.props.onRemove
		let files = [...this.state.files]
		files.splice(index, 1)
		this.setState({ files }, () => {
			onRemove && onRemove()
		})
	}

	upload () {
		const {
			uploadEndpoint,
			onChange
		} = this.props

		const {
			files
		} = this.state

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				)
				this.setState({ progress: percentCompleted })
			}
		}

		const data = new window.FormData()

		if (uploadEndpoint.includes('/files/geojson/')) {
			files.forEach((file) => data.append('file', file))
		} else {
			files.forEach((file) => data.append('file', file))
		}

		axios.post(uploadEndpoint, data, config)
			.then((response) => {
				// There's a specific scenario where there's a need for the file uploader
				// to answer with a geojson property
				if (response.data.geojson) {
					onChange([{
						id: response.data.file.id,
						url: response.data.file.url,
						geojson: response.data.geojson.url
					}])
				} else {
					onChange([{
						id: response.data.id,
						url: response.data.file
					}])
				}
			})
			.catch((error) => {
				console.log('Error ---> ', error)
				let detail = 'Alguma coisa correu mal no upload.'
				try {
					detail = error.response.data.detail
				} catch (e) {
					console.log(e)
				}

				this.setState({ error: true, errorMessage: detail })
			})
	}

	render () {
		const {
			single,
			accept,
			className
		} = this.props

		const {
			files,
			progress,
			error,
			errorMessage
		} = this.state

		let acceptMime = ''

		if (accept) {
			if (typeof accept === 'string') {
				acceptMime = accept.split('/')[1]
			} else {
				acceptMime = accept.map(mime => mime.split('/')[1])
				acceptMime = acceptMime.join(', ')
			}
		}

		return (
			<section className={className && className}>
				<div>
					{!files.length &&
					<DropArea
						onDrop={files => this.setState(
							{ files }, () => this.upload()
						)}
						multiple={!single}
						accept={accept}
					>
						<p style={{ 'fontSize': '14px' }} >
							Arraste um {!single ? `ou multiplos ficheiros (${acceptMime})` : `ficheiro (${acceptMime})`} para aqui ou
							<br />
							<Button secondary size='mini'>
								{/* eslint-disable-next-line max-len*/}
								Selecione {!single ? 'ficheiros' : 'ficheiro'} do computador
							</Button>
						</p>
					</DropArea>
					}
				</div>
				<aside>
					<FileList>
						{
							files && files.map((f, index) =>
								<File key={f.name} >
									{f.name}
									<Remove
										onClick={() => this.remove(index)}
									/>
									<ProgressBar
										percent={progress}
										active={progress && progress !== 100}
										success={progress && progress === 100}
										error={error}
									/>
								</File>
							)
						}
					</FileList>

					<Error>{errorMessage}</Error>
				</aside>
			</section>
		)
	}
}

const DropArea = styled(Dropzone)`
  display: flex;
  padding: 60px 20px;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin: 1rem 0;
  border-radius: .28571429rem;
  border: 1px dashed rgba(34,36,38,.15);
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    padding: 0;
    color: #9B9B9B;
    text-align: center;
  }
  button {
    margin-top: 10px!important;
  }
`

const File = styled.div`
  border-radius: 100px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 2px 0 rgba(97,97,97,0.2);
  border: 1px solid #DDDDDD;
  list-style-type: none;
  padding: 0 10px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  line-height: 32px;
  position: relative;
  padding-right: 40px;
  margin-bottom: 10px;
  overflow: hidden;
`

const FileList = styled.div`
  margin-top: 10px;
`

const ProgressBar = styled(Progress)`
  &&& {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    margin: 0;
    left: 0;
    z-index: 1;


    .bar {
      height: 2px;
      min-width: 0;
      &.success {
        background-color: #00A651!important;
      }
    }
  }
`

const Error = styled.span`
  display: block;
  color: indianred;
`
