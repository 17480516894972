import { kea } from 'kea'
/** Logic */
import ToasterLogic from 'containers/Toaster/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import AppLogic from 'containers/App/logic'
import { call, put } from 'redux-saga/effects'
import API from 'api'
import Proptypes from 'prop-types'

export default kea({
	path: () => ['scenes', 'containers', 'FormNavigation', 'CampsiteManagement'],

	connect: {
		props: [
			CampsiteManagementRequestLogic, [
				'steps',
				'current',
				'canSubmitRegistration'
			],
			CampsiteManagementLogic, [
				'campsite',
				'campsiteId'
			]
		],
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			],
			CampsiteManagementRequestLogic, [
				'setCurrent',
			],
			CampsiteManagementLogic, [
				'updateCampsite'
			]
		]
	},

	actions: () => ({
		submitRegistration: () => true,
		setSubmitPopupOpen: toggle => toggle,
		setSubmitResult: toggle => toggle,
		archive: () => true,
		unarchive: () => true
	}),

	reducers: ({actions}) => ({
		submitPopupOpen: [false, Proptypes.boolean, {
			[actions.setSubmitPopupOpen]: (state, toggle) => toggle
		}],
		submitResult: [null, Proptypes.boolean, {
			[actions.setSubmitResult]: (state, toggle) => toggle
		}]
	}),

	start: function * () {
		
	},

	takeLatest: ({actions, workers}) => ({
		[actions.submitRegistration]: workers.submitRegistration,
		[actions.archive]: workers.archive,
		[actions.unarchive]: workers.unarchive
	}),

	workers: {

		* archive () {
			const { navigate, showToaster } = this.actions
			const { t } = this.props
			const campsiteId = yield this.get('campsiteId')

			try {
				yield call(API.Campsites.archive, campsiteId)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.archive_success')
				))
				yield put(navigate('/parques-campismo'))

			} catch (err) {
				console.log(err)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.archive_error')
				))
			}
		},

		* unarchive () {
			const { navigate, showToaster } = this.actions
			const { t } = this.props
			const campsiteId = yield this.get('campsiteId')

			try {
				yield call(API.Campsites.unarchive, campsiteId)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.unarchive_success')
				))
				yield put(navigate('/parques-campismo'))

			} catch (err) {
				console.log(err)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.unarchive_error')
				))
			}
		},

		* submitRegistration () {

			const {updateCampsite, setSubmitResult, setSubmitPopupOpen} = this.actions
			
			const campsiteId = yield this.get('campsiteId')
			
			try {
				
				const response = yield call(
					API.Campsites.submitRegistration,
					campsiteId
				)

				const {status, updatedAt} = response

				yield put(updateCampsite({status, updatedAt}))

				yield put(setSubmitPopupOpen(false))
				yield put(setSubmitResult(true))
								
			} catch (err) {
				yield put(setSubmitPopupOpen(false))
				yield put(setSubmitResult(false))
				console.log(err)
			}

		},
	}

})
