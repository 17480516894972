import { camelizeKeys } from 'humps'
import moment from 'moment'
import 'moment/locale/pt'

import * as pathToRegexp from 'path-to-regexp'
import { scroller, animateScroll as scroll } from 'react-scroll'
import { isObject, omit, toArray, mapValues } from 'lodash'

export const getRoute = (routesList, path) => {
	for (var key in routesList) {
		if (Object.prototype.hasOwnProperty.call(routesList, key)) {
			const route = routesList[key]
			const regex = pathToRegexp.pathToRegexp(route.path)
			let result = regex.exec(path)
			if (result) {
				let keys = pathToRegexp.parse(route.path)
				let params = {}
				keys = keys.filter((value) => isObject(value))
				result = toArray(omit(result, ['index', 'input', 'groups']))
				result.shift()

				if (keys.length) {
					result.map((value, key) => {
						params[keys[key].name] = value
						return value
					})
				}

				route.params = params
				route.createUrl = pathToRegexp.compile(route.path)
				return route
			}
		}
	}

	return routesList.notfound
}

export const log = (message, color) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('%c' + message, `color:${color}`)
	}
}

export const isNifValid = (nif) => {
	if (
		!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
		// eslint-disable-next-line max-len
		!['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
	) return false

	// eslint-disable-next-line max-len
	let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2

	let modulo11 = total - parseInt(total / 11) * 11
	// eslint-disable-next-line eqeqeq
	let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11

	// eslint-disable-next-line eqeqeq
	return nif[8] == comparador
}

// Maps onChange from inputs and other DOM elements to state
export const mapOnChangeToState = (state, payload) => {
	const key = Object.keys(payload.field)[0]
	const value = payload.field[key]
	const oldState = state[key] || {}
	return { ...state, ...{ [key]: { ...oldState, value } } }
}

/**
 * Transforms url with {} tags based on object
 * @param {*} url
 * @param {*} obj
 */

export const generateUrl = (url, obj) => {
	let newUrl = url

	for (var key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key]
			newUrl = newUrl.replace(`{${key}}`, value)
		}
	}

	return newUrl
}

export const LOCATION_TYPES = t => [
	{
		id: 1,
		name: 'camping_park',
		text: t('form.location_type_camping_park_label'),
		description: t('form.location_type_camping_park_description'),
		value: 1
	},
	{
		id: 2,
		name: 'service_area',
		text: t('form.location_type_service_area_label'),
		description: t('form.location_type_service_area_description'),
		value: 2
	}
]

// Formats dates to PT format
export const formatDatePt = (date) => {
	return date && moment(date).format('DD/MM/YYYY')
}

// Formats numbers to PT format
export const formatNumbersPt = (number) =>
	number && String(number).replace(',', '.')

// get just the filename on fullpath string
// /full-path/file.ext -> file.ext
export const getFilenameOnFullPath = (fullPath) =>
	fullPath.replace(/^.*(\\|\/|:)/, '')

// helper function to load errors returned by WS and put them in form format
// check each field retunerd by WS, put as invalid and put the message returned
export const mapWSErrorsToForm = (errors, currentForm) => {
	errors = camelizeKeys(errors)
	let newForm = { ...currentForm }
	mapValues(errors, (value, key) => {
		if (value.length && currentForm[key]) {
			newForm[key].valid = false
			newForm[key].message = value[0]
		}
	})

	return newForm
}

// helper function to find the first invalid field in a form
// and scroll the page to field element by name
// to work is required add prop name on each field
export const scrollToFirstFormError = (form) => {
	let scrolled = false
	mapValues(form, (value, key) => {
		if (!value.valid && !scrolled) {
			scroller.scrollTo(key, { offset: -140, smooth: true })
			scrolled = true
		}
	})
}
export const sortedAlphabetically = (arrayOfObjects, key) => {
	return arrayOfObjects.sort((a, b) => {
		if (a[key] < b[key]) return -1
		if (a[key] > b[key]) return 1
		return 0
	})
}

export const parseJwt = (token) => {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	return JSON.parse(window.atob(base64))
}

export const mapAPIFormStateToForm = (apiFormState, currentForm) => {
	let newForm = { ...currentForm }
	mapValues(apiFormState, (value, key) => {
		// eslint-disable-next-line max-len
		if ((value === null || value === undefined || value.length || typeof value === 'boolean' || 'number') && currentForm[key]) {
			newForm[key].value = value
		}
	})
	return newForm
}

export const getErrorMessagesAndScroll = (errors, form) => {
	// Get an array of errors messages
	// these errors came, from API, as an array of objects ({field: error message})
	// and we should extract only the messages (object.values() + turn into a 1D array)
	let arrayOfErrors = []
	if (Array.isArray(errors)) {
		arrayOfErrors = Array.isArray(errors) && errors
			.map(error => Object.values(error))
			.reduce((a, b) => a.concat(b), [])
	} else {
		return arrayOfErrors
	}

	//...and the same for the fields with the errors
	const fieldsWithErrors = errors.map(error =>
		Object.keys(error)).reduce((a, b) => a.concat(b), []
	)
	// remove duplicates (for scenarios where there's more than 1 error for the same field)
	const noDuplicateFieldsWithErrors = Array.from(new Set(fieldsWithErrors))

	const fieldsOfForm = Object.keys(form)

	// Get the first field of the form that has an error
	// by first we mean the field that is closer to the top of the page
	// (the form has the key - values order from top to bottom according to the UI)
	let firstFieldWithError = ''
	fieldsOfForm.every(field => {
		if (noDuplicateFieldsWithErrors.find(f => f === field)) {
			firstFieldWithError = field
			return false
		} else {
			return true
		}
	})

	// Scroll to the first field if we have found it
	if (firstFieldWithError) {
		scroll.scrollToTop()
		scroller.scrollTo(firstFieldWithError, { offset: -150, smooth: true })
	}

	// https://gitlab.ubiwhere.com/fcmp/area-reservada/issues/37
	// the page should scroll bottom after showing the errors
	scroll.scrollToBottom()
	// Lastly, return the array of error messages
	return arrayOfErrors
}

export const downloadFile = (uri) => {
	let link = document.createElement('a')
	link.href = uri
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export const downloadFileBlob = (uri, name) => {
	if (uri) {
		
		fetch(uri)
			.then(response => response.blob())
			.then(blob => {
				const blobURL = URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = blobURL
				//a.style = 'display: none'

				if (name && name.length) a.download = name
				document.body.appendChild(a)
				a.click()
			})
			.catch(() => {})
	}
}

export const resolveDecimalString = (value) => {
	if (typeof value !== 'string') return value

	return value.includes(',', '.') ? value.replace(',', '.') : value
}

export const fix2DigitHours = (value) => {
	if (value !== 0 && !value) return ''
	return ('0'+value).substr(-2)
}

export const handleApiErrorMessage = (err, t) => {
  
	let originalMessage
	
	if (typeof err === 'string') originalMessage = err
	else if (err.response) {

		if (!t || err.response.status !== 400 || !err.response.data) return null

		if (err.response.data.error && err.response.data.error.length) originalMessage = err.response.data.error[0]
		else if (err.response.data.error && typeof err.response.data.error === 'string') originalMessage = err.response.data.error
		else if (err.response.data.length && err.response.data[0].error) {
			const errData = err.response.data[0].error
      
			if (errData.length) originalMessage = errData[0]
			else if (typeof errData === 'string') originalMessage = errData
		}
	}

	if (!originalMessage) return null

	const resolvedKey = resolveApiErrorKey(originalMessage)

	let message = t(`api_error.${resolvedKey}`)

	if (message.substring(0, 10) === 'api_error.') {
		console.error(`"${originalMessage}" is not a valid translation. The expected key is "api_error.${resolvedKey}"`)
		message = originalMessage
	}

	return message
}

const resolveApiErrorKey = str => {
	const map = {
		'a': 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
		'e': 'é|è|ê|ë|É|È|Ê|Ë',
		'i': 'í|ì|î|ï|Í|Ì|Î|Ï',
		'o': 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
		'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
		'c': 'ç|Ç',
		'n': 'ñ|Ñ',
		'_': '(?![\\w])([\\S ])',
		'': '_$|^_'
	}
  
	for (var pattern in map) {
		str = str.replace(new RegExp(map[pattern], 'g'), pattern)
	}
  
	return str.replaceAll('__', '_').toLowerCase()
}

export const isValidIBANNumber = (input) => {
	const CODE_LENGTHS = {
		AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
		CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
		FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
		HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
		LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
		MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
		RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
		AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25, SV: 28,
		TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
	}
	const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
	const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
	let digits
	// check syntax and length
	if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
		return false
	}
	// rearrange country code and check digits, and convert chars to ints
	digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
		return letter.charCodeAt(0) - 55
	})
	// final check
	return mod97(digits)
}

const mod97 = (string) => {
	var checksum = string.slice(0, 2), fragment
	for (var offset = 2; offset < string.length; offset += 7) {
		fragment = String(checksum) + string.substring(offset, offset + 7)
		checksum = parseInt(fragment, 10) % 97
	}
	return checksum
}