import * as React from 'react'

const IcBirdWatching = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/iic-birdwatching</title>
			<g id="icons/servicos/iic-birdwatching" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M18.96075,11.8525 L23.34375,12.05275 C23.34375,12.05275 19.2915,12.5635 18.80175,12.709 C17.8725,15.19225 15.1275,14.5615 14.238,15.7525 C12.8385,17.62825 10.26525,18.265 6.2565,17.17375 C5.85075,17.7625 1.80825,20.869 0.657,21.244 C-0.49425,21.619 3.03825,18.84475 3.03825,18.84475 C3.03825,18.84475 1.344,20.02 0.1965,20.32525 C-0.9495,20.6275 3.28575,17.43475 3.28575,17.43475 C3.28575,17.43475 1.44525,18.2005 0.44925,18.2635 C-0.54825,18.3265 2.3745,16.46425 2.3745,16.46425 C2.3745,16.46425 1.47375,16.53325 1.1325,16.53325 C0.79125,16.53325 0.64125,16.255 1.86225,15.703 C3.0825,15.15175 5.664,13.8835 8.046,13.42825 C8.2065,12.60025 3.89625,6.14725 4.137,5.6125 C4.37775,5.07625 6.573,5.26375 9.70575,8.878 C10.0005,8.34325 9.114,0.39175 9.8115,1.114 C10.50825,1.83775 13.482,7.78075 13.80225,12.33175 C14.3115,12.43975 15.25725,11.63425 16.2915,11.15425 C18.1515,10.28425 18.96075,11.8525 18.96075,11.8525 Z" id="icon" fill="#102526"/>
			</g>
		</svg>
	)

export default IcBirdWatching
