import * as Check from 'validations'

export const DEFAULT_VALUES = {
	equipmentName: {
		value: []
	}
}

export const DEFAULT_EQUIPMENT_VALUES = {
	name: {
		value: ''
	}
}

export const CONTACTS_VALIDATIONS = {
	name: [
		Check.isRequired
	]
}

// helper function to change values of specific 'Equipment'
export const changeEquipment = (state, payload) => {
	const stateClone = { ...state }
	const { index } = payload
	const currentEquipments = stateClone.equipmentName.value[index]
	stateClone.equipmentName.value[index] = Check.setAndCheckValidation(
		currentEquipments, payload, CONTACTS_VALIDATIONS
	)
	return stateClone
}

// helper function to remove a specific 'Equipment'
export const removeEquipment = (state, payload) => {
	const stateClone = { ...state }
	const { index } = payload
	stateClone.equipmentName.value = stateClone.equipmentName.value.filter(
		(v, i) => i !== index
	)

	return stateClone
}

// helper function to append new 'Equipment'
export const addEquipment = (state) => {
	const stateClone = { ...state }
	const EquipmentsClone = { ...state.equipmentName }
	EquipmentsClone.value = [ ...EquipmentsClone.value, DEFAULT_EQUIPMENT_VALUES ]
	stateClone.equipmentName = EquipmentsClone

	return stateClone
}

// helper function to handle data in order to match the API expectation
export const convertEquipment = (form) => {
	const newEquipmentsArray = []
	form.equipmentName.value.forEach(element => 
		Boolean(element.name.value) && 
		newEquipmentsArray.push(element.name.value)
	)
	return { equipmentName:newEquipmentsArray }
}
