import axios from 'axios'
import ENDPOINTS from '../endpoints'
import { generateUrl } from 'utils'

const get = params => axios.get(ENDPOINTS.CAMPSITE.LIST + 'my/', params)
	.then(res => res.data)
	.catch(err => { throw err })

const getById = (id, params) => axios.get(ENDPOINTS.CAMPSITE.LIST + `${id}/`, params) 
	.then(res => res.data)
	.catch(err => { throw err })

const getAllStepsById = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.ALL, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

const create = () => axios.post(ENDPOINTS.CAMPSITE.LIST)
	.then(res => res.data)
	.catch(err => { throw err })

const getSummary = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.SUMMARY, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

const updateSummary = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.SUMMARY, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

const getEquipments = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.EQUIPMENTS, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

const updateEquipments = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.EQUIPMENTS, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

const getServices = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.SERVICES, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

const updateServices = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.SERVICES, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const getCharacteristics = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.CHARACTERISTICS, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateCharacteristics = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.CHARACTERISTICS, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const getSportAndLeisure = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.SPORT_AND_LEISURE, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateSportAndLeisure = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.SPORT_AND_LEISURE, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

const getContacts = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.CONTACTS, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateContacts = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.CONTACTS, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

const getDocuments = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.DOCUMENTS, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateDocuments = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.DOCUMENTS, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })
	
const getPhotos = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.PHOTOS, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updatePhotos = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.PHOTOS, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const getOtherEquipments = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.OTHER_EQUIPMENTS, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateOtherEquipments = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.OTHER_EQUIPMENTS, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

const getOtherServices = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.OTHER_SERVICES, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateOtherServices = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.OTHER_SERVICES, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const getOtherSportAndLeisure = (id, params) => axios.get(generateUrl(ENDPOINTS.CAMPSITE.STEPS.OTHER_SPORT_AND_LEISURE, {id}), params)
	.then(res => res.data)
	.catch(err => { throw err })

// eslint-disable-next-line max-len
const updateOtherSportAndLeisure = (id, body) => axios.patch(generateUrl(ENDPOINTS.CAMPSITE.STEPS.OTHER_SPORT_AND_LEISURE, {id}), body)
	.then(res => res.data)
	.catch(err => { throw err })

const submitRegistration = id => axios.post(generateUrl(ENDPOINTS.CAMPSITE.SUBMIT_REGISTRATION, {id}), {register: true})
	.then(res => res.data)
	.catch(err => { throw err })

const archive = id => axios.post(generateUrl(ENDPOINTS.CAMPSITE.ARCHIVE, {id}))
	.then(res => res.data)
	.catch(err => { throw err })

const unarchive = id => axios.post(generateUrl(ENDPOINTS.CAMPSITE.UNARCHIVE, {id}))
	.then(res => res.data)
	.catch(err => { throw err })

export default {
	get,
	getById,
	getAllStepsById,
	create,
	submitRegistration,
	archive,
	unarchive,
	steps: {
		getSummary,
		updateSummary,
		getEquipments,
		updateEquipments,
		getServices,
		updateServices,
		getCharacteristics,
		updateCharacteristics,
		getSportAndLeisure,
		updateSportAndLeisure,
		getContacts,
		updateContacts,
		getDocuments,
		updateDocuments,
		getPhotos,
		updatePhotos,
		getOtherEquipments,
		updateOtherEquipments,
		getOtherServices,
		updateOtherServices,
		getOtherSportAndLeisure,
		updateOtherSportAndLeisure
	}
}