/* eslint-disable react/display-name */
/* eslint-disable max-len */
import React from 'react'

export default () => (
	<svg width='210' height='166' viewBox='0 0 210 166' xmlns='http://www.w3.org/2000/svg'>
		<defs>
			<linearGradient x1='50%' y1='5.847%' x2='50%' y2='76.626%' id='linearGradient-1'>
				<stop stopColor='#B4EC51' offset='0%' />
				<stop stopColor='#429321' offset='100%' />
			</linearGradient>
			<ellipse id='path-2' cx='170.176' cy='76.271' rx='33.866' ry='33.898'/>
			<linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-3'>
				<stop stopColor='#D5EA68' offset='0%' />
				<stop stopColor='#ABD037' offset='100%' />
			</linearGradient>
		</defs>
		<g id='[D]-Entidades-Certificadas' fill='none' fillRule='evenodd'>
			<g id='[D]-Entidade-Gestora-Submissao-de-pedido-de-acesso' transform='translate(-535 -347)'>
				<g id='info' transform='translate(272 223)'>
					<g id='elementos/mail-submetido' transform='translate(243 108)'>
						<path d='M168.425985,68.6440678 L35.6719915,68.6440678 C27.0926518,68.6440678 20.3194888,75.4237288 20.3194888,84.0112994 L20.3194888,165.819209 C20.3194888,174.40678 27.0926518,181.186441 35.6719915,181.186441 L167.974441,181.186441 C176.553781,181.186441 183.326944,174.40678 183.326944,165.819209 L183.326944,84.0112994 C183.778488,75.4237288 176.553781,68.6440678 168.425985,68.6440678 Z'
							id='Path' fill='#FFF'
						/>
						<path d='M168.425985,68.6440678 L35.6719915,68.6440678 C27.0926518,68.6440678 20.3194888,75.4237288 20.3194888,84.0112994 L20.3194888,165.819209 C20.3194888,174.40678 27.0926518,181.186441 35.6719915,181.186441 L167.974441,181.186441 C176.553781,181.186441 183.326944,174.40678 183.326944,165.819209 L183.326944,84.0112994 C183.778488,75.4237288 176.553781,68.6440678 168.425985,68.6440678 Z M102.952077,127.853107 C102.500532,128.305085 101.597444,128.305085 101.1459,127.853107 L38.8328009,79.039548 L164.813632,79.039548 L102.952077,127.853107 Z M30.7050053,85.3672316 L74.5047923,119.717514 L30.7050053,163.559322 L30.7050053,85.3672316 Z M82.6325879,126.045198 L94.8242812,135.988701 C97.0820021,137.79661 99.3397231,138.248588 102.048988,138.248588 C104.758253,138.248588 107.015974,137.344633 109.273695,135.988701 L121.465389,126.045198 L166.168264,170.79096 L37.9297125,170.79096 L82.6325879,126.045198 Z M129.593184,119.717514 L173.392971,85.3672316 L173.392971,163.559322 L129.593184,119.717514 Z'
							id='ic-email' fill='#3E3E3E' fillRule='nonzero'
						/>
						<g id='Oval'>
							<ellipse stroke='#3E3E3E' strokeWidth='8.475' strokeLinejoin='square'
								fill='url(#linearGradient-1)' cx='170.176' cy='76.271' rx='29.629' ry='29.661'
							/>
							<ellipse stroke='#FFF' strokeWidth='6.78' cx='170.176' cy='76.271' rx='37.256'
								ry='37.288'
							/>
						</g>
						<path d='M183.722045,67.9033009 C183.722045,68.4861438 183.430903,68.9718462 183.042713,69.4575485 C177.802155,74.7031344 172.464549,80.0458608 167.223991,85.2914466 C166.253518,86.2628514 165.185996,86.2628514 164.215523,85.2914466 C161.886386,83.0572156 159.654296,80.7258441 157.422207,78.4916131 C156.451733,77.5202083 156.354685,76.3545226 157.228112,75.5773987 C158.004491,74.8002749 159.072012,74.8974154 160.042486,75.7716797 C161.401149,77.0345059 162.662765,78.3944726 164.021428,79.7544393 C165.768281,81.502968 165.768281,81.502968 167.515133,79.7544393 C171.68817,75.5773987 175.861208,71.4003581 180.034245,67.2233175 C180.325387,66.9318961 180.616529,66.6404747 180.907671,66.4461937 C181.489955,66.0576318 182.169287,65.9604913 182.751571,66.3490532 C183.333855,66.6404747 183.624997,67.2233175 183.722045,67.9033009 Z'
							id='ic-aceitar' stroke='#FFF' strokeWidth='1.695' fill='#FFF' fillRule='nonzero'
						/>
						<path d='M224.466367,37.3935814 L220.868486,37.3935814 L220.868486,33.7922489 C220.868486,32.9151108 220.157448,32.2033898 219.28115,32.2033898 C218.404243,32.2033898 217.693204,32.9151108 217.693204,33.7922489 L217.693204,37.3935814 L214.095323,37.3935814 C213.218416,37.3935814 212.507987,38.1053024 212.507987,38.9824405 C212.507987,39.8601889 213.218416,40.5719099 214.095323,40.5719099 L217.693204,40.5719099 L217.693204,44.1732424 C217.693204,45.0509909 218.404243,45.7627119 219.28115,45.7627119 C220.157448,45.7627119 220.868486,45.0509909 220.868486,44.1732424 L220.868486,40.5719099 L224.466367,40.5719099 C225.343274,40.5719099 226.054313,39.8601889 226.054313,38.9830508 C226.054313,38.1053024 225.343274,37.3935814 224.466367,37.3935814'
							id='Fill-141' fill='url(#linearGradient-3)'
						/>
						<path d='M194.38412,20.8417962 L191.68571,20.8417962 L191.68571,18.1407968 C191.68571,17.4829433 191.152431,16.9491525 190.495208,16.9491525 C189.837527,16.9491525 189.304248,17.4829433 189.304248,18.1407968 L189.304248,20.8417962 L186.605838,20.8417962 C185.948157,20.8417962 185.415335,21.3755869 185.415335,22.0334405 C185.415335,22.6917519 185.948157,23.2255426 186.605838,23.2255426 L189.304248,23.2255426 L189.304248,25.926542 C189.304248,26.5848533 189.837527,27.1186441 190.495208,27.1186441 C191.152431,27.1186441 191.68571,26.5848533 191.68571,25.926542 L191.68571,23.2255426 L194.38412,23.2255426 C195.041801,23.2255426 195.57508,22.6917519 195.57508,22.0338983 C195.57508,21.3755869 195.041801,20.8417962 194.38412,20.8417962'
							id='Fill-141' fill='url(#linearGradient-3)'
						/>
						<path d='M228.646922,67.8493331 L226.847981,67.8493331 L226.847981,66.0486668 C226.847981,65.6100978 226.492462,65.2542373 226.054313,65.2542373 C225.615859,65.2542373 225.26034,65.6100978 225.26034,66.0486668 L225.26034,67.8493331 L223.4614,67.8493331 C223.022946,67.8493331 222.667732,68.2051935 222.667732,68.6437626 C222.667732,69.0826368 223.022946,69.4384973 223.4614,69.4384973 L225.26034,69.4384973 L225.26034,71.2391636 C225.26034,71.6780378 225.615859,72.0338983 226.054313,72.0338983 C226.492462,72.0338983 226.847981,71.6780378 226.847981,71.2391636 L226.847981,69.4384973 L228.646922,69.4384973 C229.085375,69.4384973 229.440895,69.0826368 229.440895,68.6440678 C229.440895,68.2051935 229.085375,67.8493331 228.646922,67.8493331'
							id='Fill-141' fill='url(#linearGradient-3)'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)
