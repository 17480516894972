/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const Eye = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M3,17.2525 L3,21.0025 L6.75,21.0025 L17.81,9.9425 L14.06,6.1925 L3,17.2525 Z M20.71,7.0425 C21.1,6.6525 21.1,6.0225 20.71,5.6325 L18.37,3.2925 C17.98,2.9025 17.35,2.9025 16.96,3.2925 L15.13,5.1225 L18.88,8.8725 L20.71,7.0425 Z" />
		</g>
	</svg>
)

Eye.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

Eye.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

export default Eye
