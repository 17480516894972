//import * as Check from 'validations'

const DEFAULT_VALUES = {
	restaurant: {
		value: null
	},
	miniMarket: {
		value: null
	},
	snackBar: {
		value: null
	},
	bar: {
		value: null
	},
	laundry: {
		value: null
	},
	gym: {
		value: null
	},
	spa: {
		value: null
	},
	jacuzzi: {
		value: null
	},
	cleaning: {
		value: null
	},
	bicycleRental: {
		value: null
	},
	entertainment: {
		value: null
	},
	childrenServices: {
		value: null
	},
	motorhomeServiceStation: {
		value: null
	},
	wifiAccess: {
		value: null
	}
}

const VALIDATIONS = {
}

const VALIDATIONS_NO_REQUIRED = {
}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}