import axios from 'axios'
import moment from 'moment'
import { parseJwt } from 'utils'
import Cookies from 'js-cookie'

import ENDPOINTS from './endpoints'

export default () => axios.interceptors.request.use(async (config) => {
	let access = {}
	let refresh = {}

	const BASE_DOMAIN  = process.env.REACT_APP_BASE_DOMAIN

	// Save both decoded tokens (acccess and refresh)

	if (Cookies.get('access') && Cookies.get('refresh')) {
		access = parseJwt(Cookies.get('access'))
		refresh = parseJwt(Cookies.get('refresh'))
	}

	// Request new access token if the current one is dead
	// and if there's a refresh token alive
	if (access.exp < moment().unix() && refresh.exp > moment().unix()) {
		const instance = axios.create()
		const params = { refresh: Cookies.get('refresh') }
		const response = await instance.post(ENDPOINTS.AUTH.REFRESH, params)
		access = parseJwt(response.data.access)
		Cookies.set('access', response.data.access, { domain: BASE_DOMAIN })
	}

	// Save the Authorization header
	if (access.exp > moment().unix()) {
		config.headers['Authorization'] = `Bearer ${Cookies.get('access')}`
	}

	// If both tokens are dead: redirect to login
	if (access.exp < moment().unix() && refresh.exp < moment().unix()) {
		Cookies.remove('access')
		Cookies.remove('refresh')
		window.location.href = '/entrar'
	}

	config.headers['Accept-Language'] = 'pt-PT'

	return config
}, function (error) {
	return Promise.reject(error)
})