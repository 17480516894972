// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
	Grid,
	Message,
	Dimmer,
	Loader,
	Form,
	FormGroup,
	Input,
	Radio
} from 'semantic-ui-react'
import { DatesRangeInput } from 'semantic-ui-calendar-react'

import Button from 'components/Button'
import InputUnity from 'components/InputUnity'
import FormField from 'components/FormField'
import Segment from 'components/Segment'
import Logic from './logic'
import Title from 'components/Title'
import SegmentTitle from 'components/SegmentTitle'

type Props = {

}

class BookingRule extends Component<Props> {

static defaultProps = {}

render () {
	const {
		errors,
		t,
		loading,
		form,
		isNewRule,
		dirty
	} = this.props
	const { changeForm, fetchForm, createRule, saveForm } = this.actions
	const {
		name,
		timeInterval,
		spotPrice,
		//guestPrice,
		electricitySupply,
		potableWaterSupply,
		cleaningPrice,
		//hasElectricityPrice,
		electricityPrice,
		//hasWaterPrice,
		waterPrice
	} = form || {}

	return (
		<Wrapper>
			<Grid>
				<Grid.Row>
					<Title>
						{t('booking_rule.title')}
					</Title>
				</Grid.Row>

				{errors && !!errors.length &&
					<Message
						name={'errorMessage'}
						error
						header={'Corrija os seus erros antes de prosseguir'}
						list={errors}
					/>
				}
				<Grid.Row>
					<Segment>
						<SegmentTitle
							margin={'0 0 26px 0'}
						>
							{t('booking_rule.booking_settings')}
						</SegmentTitle>
						{loading && 
							<Dimmer inverted active>
								<Loader inverted/>
							</Dimmer>
						}
						{!loading &&
							<Form>
								<FormGroup widths='equal'>
									<FormField
										style={{ width: '100%' }}
										value={name.value}
										required
										label={t('booking_rule.name')}
										control={Input}
										onChange={(e, {value}) => 
											changeForm({ name: value })
										}
										// eslint-disable-next-line max-len
										error={name.dirty && !name.valid && name.message }
									/>
									<FormField
										style={{ width: '100%' }}
										value={timeInterval.value}
										required
										placeholder="De - a"
										iconPosition="left"
										label={t('booking_rule.time_interval')}
										control={DatesRangeInput}
										animation='none'
										onChange={(e, {value}) => 
											changeForm({ timeInterval: value })
										}
										// eslint-disable-next-line max-len
										error={timeInterval.dirty && !timeInterval.valid && timeInterval.message }
									/>
                  
								</FormGroup>

								{/*<FormField
									required
									width={4}
									// eslint-disable-next-line max-len
									error={guestPrice.dirty && !guestPrice.valid && guestPrice.message }
								>
									<InputLabel>
										{t('booking_rule.guest_price')} *
									</InputLabel>
									<Input
										label={'€'}
										labelPosition='right'
										value={guestPrice.value}
										onChange={(e, {value}) => 
											changeForm({ guestPrice: value })
										}
									/>
								</FormField>*/}
                
								<FormField required>
									<InputLabel>
										{t('form.electricity_supply')}
										{/*{t('booking_rule.electricity_price')}*/}
									</InputLabel>
									<FormGroup inline>
										<FormField
											control={Radio}
											label={t('options.no')}
											// eslint-disable-next-line max-len
											name='electricity_supply_unavailable'
											// eslint-disable-next-line max-len
											checked={electricitySupply.value === 'unavailable'}
											value='unavailable'
											// eslint-disable-next-line max-len
											onChange={() => changeForm({ electricitySupply: 'unavailable', electricityPrice: null })}
										/>
										<FormField
											control={Radio}
											label={t('options.included_price')}
											//name='electricity_supply_included'
											name='electricity_supply_included'
											value='included'
											checked={electricitySupply.value === 'included'}
											// eslint-disable-next-line max-len
											onChange={() => changeForm({ electricitySupply: 'included', electricityPrice: null })}
										/>
										<FormField
											control={Radio}
											label='Paga no local'
											//label={t('booking_rule.paid')}
											// eslint-disable-next-line max-len
											name='electricity_supply_paid'
											// eslint-disable-next-line max-len
											checked={electricitySupply.value === 'paid locally'}
											value='paid locally'
											// eslint-disable-next-line max-len
											onChange={() => changeForm({ electricitySupply: 'paid locally', electricityPrice: 0 })}
										/>
										{electricitySupply.value === 'paid locally' &&
										<FormGroup inline style={{ margin: 0 }}>
											<InputLabel
												style={{
													borderLeft: '1px solid',
													paddingLeft: '6px',
													width: '100%'
												}}
											>
												{t('booking_rule.price')}
											</InputLabel>
											<FormField
												//width={6}
												// eslint-disable-next-line max-len
												error={electricityPrice.dirty && !electricityPrice.valid && electricityPrice.message }
											>
												<InputUnity 
													unity='€'
													name="price"
													onBlur={() => electricityPrice.value === '' && changeForm( { electricityPrice: 0 } )}
													// eslint-disable-next-line max-len
													//disabled={!hasElectricityPrice.value}
													// eslint-disable-next-line max-len
													value={electricityPrice.value}
													// eslint-disable-next-line max-len
													onChange={(e, { value }) => changeForm( { electricityPrice: value } )}
												/>
											</FormField>	
										</FormGroup>
										}
									</FormGroup>
								</FormField>
                
								<FormField required>
									<InputLabel>
										{t('form.water_supply')}
										{/*{t('booking_rule.water_price')}*/}
									</InputLabel>
									<FormGroup inline>
										<FormField
											control={Radio}
											label={t('options.no')}
											// eslint-disable-next-line max-len
											name='water_supply_unavailable'
											// eslint-disable-next-line max-len
											checked={potableWaterSupply.value === 'unavailable'}
											value='unavailable'
											// eslint-disable-next-line max-len
											onChange={() => changeForm({ potableWaterSupply: 'unavailable', waterPrice: null })}
										/>
										<FormField
											control={Radio}
											label={t('options.included_price')}
											name='water_supply_included'
											// eslint-disable-next-line max-len
											value='included'
											checked={potableWaterSupply.value === 'included'}
											// eslint-disable-next-line max-len
											onChange={() => changeForm( { potableWaterSupply: 'included', potableWaterPrice: null })}
										/>
										<FormField
											control={Radio}
											//label={t('booking_rule.paid')}
											label='Paga no local'
											// eslint-disable-next-line max-len
											name='water_supply_paid'
											// eslint-disable-next-line max-len
											checked={potableWaterSupply.value === 'paid locally'}
											value='paid locally'
											// eslint-disable-next-line max-len
											onChange={() => changeForm({ potableWaterSupply: 'paid locally', waterPrice: 0 })}
										/>
										{potableWaterSupply.value === 'paid locally' &&
										<FormGroup inline style={{ margin: 0 }}>
											<InputLabel
												style={{
													borderLeft: '1px solid',
													paddingLeft: '6px',
													width: '100%'
												}}
											>
												{t('booking_rule.price')}
											</InputLabel>
											<FormField
												//width={6}
												// eslint-disable-next-line max-len
												error={waterPrice.dirty && !waterPrice.valid && waterPrice.message }
											>
												<InputUnity 
													unity='€'
													name="price"
													onBlur={() => waterPrice.value === '' && changeForm( { waterPrice: 0 } )}
													// eslint-disable-next-line max-len
													//disabled={!hasWaterPrice.value}
													// eslint-disable-next-line max-len
													value={waterPrice.value}
													// eslint-disable-next-line max-len
													onChange={(e, { value }) => changeForm( { waterPrice: value } )}
												/>
											</FormField>
										</FormGroup>
										}
									</FormGroup>
								</FormField>
								<Label>
									{t('booking_rule.prices_night')}
								</Label>
								<FormField
									required
									width={6}
									// eslint-disable-next-line max-len
									error={spotPrice.dirty && !spotPrice.valid && spotPrice.message }
								>
									<InputLabel>
										{t('booking_rule.spot_price')} *
									</InputLabel>
									<Input
										label={'€'}
										value={spotPrice.value}
										labelPosition='right'
										onChange={(e, {value}) =>
											changeForm({ spotPrice: value })
										}
									/>
								</FormField>

								<Label>
									{t('form.usage_reservation')}
								</Label>
								<FormField
									required
									width={6}
									// eslint-disable-next-line max-len
									error={cleaningPrice.dirty && !cleaningPrice.valid && cleaningPrice.message }
								>
									<InputLabel>
										{t('form.price_for_service')} *
									</InputLabel>
									<Input
										label={'€'}
										value={cleaningPrice.value}
										labelPosition='right'
										onChange={(e, {value}) =>
											changeForm({ cleaningPrice: value })
										}
									/>
								</FormField>

							</Form>
						}
					</Segment>
				</Grid.Row>
				<Grid.Row columns={2}>
					<Grid.Column>
						{/* eslint-disable-next-line max-len */}
						{!isNewRule &&
						<Button
							secondary
							onClick={fetchForm}
							disabled={!dirty || loading}
						>
							{t('booking_rule.cancel')}
						</Button>
						}
					</Grid.Column>
					<Grid.Column textAlign={'right'}>
						<Button
							primary
							disabled={!dirty || loading}
							style={{ display: 'inline-block' }}
							onClick={isNewRule
								? createRule
								: saveForm
							}
						>
							{t('booking_rule.save')}
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Wrapper>
	)
}
}

export default withTranslation()(Logic(BookingRule))

const Wrapper = styled.div`
`

const Label = styled.p`
	color: #102526;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18px;
  padding: 0 8px;
`
const InputLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
	line-height: 14px;
  padding: 8px 0;
  margin: 0;
`