/**
 * Register Logic 
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 * 
 */

import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'

import Proptypes from 'prop-types'
import * as Check from 'validations'
import mapValues from 'lodash/mapValues'
import toArray from 'lodash/toArray'
import omit from 'lodash/omit'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'

import API from 'api'
import { ENTITY_DEFAULT_VALUES, USER_VALIDATIONS } from './utils'

export default kea({
	path: () => ['scenes', 'Register'],

	connect: {
		props: [
			AppLogic, [
				'contacts'
			]
		],
		actions: [
			AppLogic, [
				'navigate',
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		registerEntity: () => true,
		change: (field) => ({ field }),
		changeWithValidations: (field, form) => ({ field, form }),
		response: (response) => ({ response }),
		setForm: (form) => ({ form }),
		error: (error) => ({ error }),
	}),

	reducers: ({ actions }) => ({

		form: [ENTITY_DEFAULT_VALUES, Proptypes.object, {
			[actions.changeWithValidations]: (state, payload) => payload.form,
			[actions.setForm]: (state, payload) => payload.form
		}],

		dirty: [false, Proptypes.bool, {
			[actions.change]: () => true,
			[actions.response]: () => false,
			[actions.error]: () => true,
		}],

		submiting: [false, Proptypes.bool, {
			[actions.registerEntity]: () => true,
			[actions.error]: () => false,
			[actions.response]: () => false
		}],

		response: [null, Proptypes.any, {
			[actions.response]: (state, payload) => payload.response
		}],

		error: [null, Proptypes.any, {
			[actions.error]: (state, payload) => payload.error,
		}],

		loading: [false, Proptypes.any, {}]

	}),

	start: function * () {

	},

	takeLatest: ({ actions, workers }) => ({
		[actions.change]: workers.changeWithValidations,
		[actions.registerEntity]: workers.registerEntity,
	}),

	workers: {

		* registerEntity () {
			const {
				error,
				setForm,
				response
			} = this.actions

			const form = yield this.get('form')
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, USER_VALIDATIONS)

			if (dirty && validation.invalid) {
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			try {
				let params = mapValues(form, ({ value }) => value)
				params.isCampsiteManager = params.type.includes(1)
				params.isMotorhomeServiceAreaManager = params.type.includes(2)
				params.termsAccepted = true

				params = omit(params, ['type', 'passwordConfirmation'])
				const res = yield call(API.Auth.Register, params)
				yield put(response(res))

			} catch (er) {
				let errors = er
				if (er.response && er.response.data) {
					errors = toArray(er.response.data)
				}
				yield put(error(errors))
			}
		},

		/**
     * Validates field with the correct validations of the active type
     * @param {*} action
     */
    
		* changeWithValidations (action) {
			const { changeWithValidations } = this.actions
			const form = yield this.get('form')
			const payload = action.payload

			let validated = Check.setAndCheckValidation(
				form,
				payload,
				USER_VALIDATIONS
			)
			yield put(changeWithValidations(payload.field, validated))
		},

	}

})
