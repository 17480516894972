import React, { Component } from 'react'
import styled from 'styled-components'

import {
	Icon,
	Menu,
	Label as SemanticLabel,
	Table as SemanticTable,
	Dropdown as SemanticDropdown
} from 'semantic-ui-react'

const Table = (props) => (
	<TableContainer>
		<TableStyle {...props}>
			{props.children}
		</TableStyle>
	</TableContainer>
)

class Pagination extends Component {
	render () {
		const {
			offset,
			limit,
			resultsPerPage,
			hasPrevious,
			hasNext,
			total,
			onChangeResults,
			onChangePage
		} = this.props
    
		const start = offset || 0
		let end = start + limit
		if (end > total) {
			end = total
		}

		return (
			<PaginationMenu floated='right'>
				<PaginationMenuItem as='div' icon>
					<Label floated='right'>Resultados por página:</Label>
					<Dropdown
						inline
						options={resultsPerPage}
						value={limit}
						onChange={(e, { value }) => onChangeResults(value)}
					/>
					<Label
						floated='right'
					>
						{start || 1} a {end} de {total}
					</Label>
				</PaginationMenuItem>
				<PaginationMenuItemClickable
					as='a'
					icon
					disabled={!hasPrevious}
					onClick={() => onChangePage('prev')}
				>
					<Icon name='left chevron' />
				</PaginationMenuItemClickable>
				<PaginationMenuItemClickable
					as='a'
					icon
					disabled={!hasNext}
					onClick={() => onChangePage('next')}
				>
					<Icon name='right chevron' />
				</PaginationMenuItemClickable>
			</PaginationMenu>
		)
	}
}

const Filters = (props) => (
	<FiltersContainer>
		{props.children}
	</FiltersContainer>
)

const Title = (props) => (
	<TableTitle>
		{props.children}
	</TableTitle>
)

const PaginationMenu = styled(Menu)`
  &&&& {
    border: none;
    box-shadow: none;
  }
`

const PaginationMenuItem = styled(Menu.Item)`
  &&&& {
    &:before{
      display:none;
    }

    i {
      color: rgba(0,0,0,0.50) !important;
    }
  }
`

const PaginationMenuItemClickable = styled(PaginationMenuItem)`
	cursor: pointer;
`

const Label = styled(SemanticLabel)`
  &&&& {
    background: transparent;
    color: #616161;
    font-size: 12px;
    line-height: 12px;
  }
`

const Dropdown = styled(SemanticDropdown)`
  &&&& {
    color: #616161;
    font-size: 12px;

    i{
      margin: 1px .5em 0 8px;
      font-size: 17px;
      float: right;
    }
  }
`

const TableContainer = styled.div`
`

const TableStyle = styled(SemanticTable)`
  &&&& {
    border: none;

    ${({ sortable }) => sortable && `
      thead th{
        background: transparent;
        border-left: none;
      }
    `}
  }
`

const Header = styled(SemanticTable.Header)`
`

const Body = styled(SemanticTable.Body)`
`

const Footer = styled(SemanticTable.Footer)`
  &&&& {
    th{
      border-top: 1px solid rgba(155, 155, 155, 0.15);
      border-bottom: none;
      padding: 5px;
    }
  }
`

const Row = styled(SemanticTable.Row)`
  &&&& {
    height: 60px;
    &:first-child td {
      border-top: none;
    }
  }
`

const Cell = styled(SemanticTable.Cell)`
  &&&& {
    border-top: 1px solid rgba(155, 155, 155, 0.15);
    color: rgba(0,0,0,0.87);
    font-size: 14px;
    line-height: 16px;
    padding: 15px 10px;

    &:first-child{
      padding-left: 25px;
    }
  }
`

const HeaderCell = styled(SemanticTable.HeaderCell)`
  &&&& {
    background: transparent;
    border-bottom: 1px solid rgba(155, 155, 155, 0.15);
    color: #9B9B9B;
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
    padding: 20px 10px;
    font-family: "Open Sans";

    &:first-child{
      padding-left: 25px;
    }

    &.sorted{
      color: #000;

      &:hover{
        background: transparent;
      }
    }
  }
`

const HeaderCellInfo = styled.label`
  font-size: 10px;
`

const FiltersContainer = styled.div`
  padding: 20px 20px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(155,155,155,0.15);

  > form{
    width: 100%;
  }
`

const TableTitle = styled.div`
  color: #000000;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 15px;
`

export default {
	Table,
	Header,
	Body,
	Footer,
	Row,
	Cell,
	HeaderCell,
	HeaderCellInfo,
	Pagination,
	Filters,
	Title
}
