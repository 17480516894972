import Empty from 'screens/Empty'
import ServiceArea from 'screens/ServiceArea'
import ServiceAreaRequest from 'screens/ServiceAreaRequest'
import BookingManagement from 'screens/BookingManagement'
import BookingDetails from 'screens/BookingDetails'
import BookingSettings from 'screens/BookingSettings'
import BookingRule from 'screens/BookingRule'
import BookingUnavailabilityRule from 'screens/BookingUnavailabilityRule'
import BookingUnavailability from 'screens/BookingUnavailability'
import Downloads from 'screens/Downloads'
import UsageReservation from '../screens/UsageReservation'
import UsageReservationDetails from '../screens/UsageReservationDetails'
import CampsiteManagement from '../screens/CampsiteManagement'
import CampsiteManagementRequest from '../screens/CampsiteManagementRequest'
import EditProfile from '../screens/EditProfile'

export default {
	editProfile: {
		key: 'editProfile',
		name: 'editProfile',
		path: '/editar-perfil',
		component: EditProfile,
		hideBreadCrumb: true,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'Edit Profile'
	},
	faq: {
		key: 'faqs',
		name: 'faqs',
		defaultName: 'FAQs',
		path: '/faqs',
		component: Empty,
		hideBreadCrumb: true,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'FAQ'
	},

	contacts: {
		key: 'contacts',
		name: 'contacts',
		path: '/contactos',
		component: Empty,
		hideBreadCrumb: true,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'Contacts'
	},

	serviceArea: {
		key: 'service_areas',
		name: 'service_areas',
		path: '/gestao-processos',
		targetPath: ['/gestao-processos/pedido/[\\S]*'],
		component: null,
		crumb: ['processManagement'],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		keepChildrenPath: true,
		isFather: true,
		//isChildrenOf: 'processManagement',
		iconName: 'CARAVAN'
	},

	serviceAreaManagement: {
		key: 'service_areas_management',
		name: 'service_areas_management',
		path: '/gestao-processos',
		targetPath: ['/gestao-processos/pedido/[\\S]*'],
		component: ServiceArea,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isChildrenOf: 'service_areas',
		iconName: 'CARAVAN',
	},

	serviceAreaRequest: {
		key: 'service_area_request',
		name: 'service_area_request',
		path: '/gestao-processos/pedido/:id',
		component: ServiceAreaRequest,
		crumb: ['processManagement', 'serviceArea'],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'PENGRET'
	},

	bookingManagement: {
		key: 'booking_management',
		name: 'booking_management',
		path: '/gestao-reservas',
		component: null,
		targetPath: ['/gestao-reservas/[\\S]*', '/definicoes-reservas/[\\S]*', '/indisponibilidade-reservas/[\\S]*', '/reserva-utilizacao/[\\S]*'],
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		iconName: 'Reservas',
		isFather: true,
		keepChildrenPath: true,
		needServicesArea: true
	},
	
	overnightBooking: {
		key: 'overnight_booking',
		name: 'overnight_booking',
		path: '/gestao-reservas',
		targetPath: ['/gestao-reservas/[\\S]*'],
		component: BookingManagement,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isChildrenOf: 'booking_management',
		iconName: 'CalendarSync'
	},

	bookingDetails: {
		key: 'booking_details',
		name: 'booking_details',
		path: '/gestao-reservas/:id',
		component: BookingDetails,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false
	},

	usageReservation: {
		key: 'usage_reservations',
		name: 'usage_reservations',
		path: '/reserva-utilizacao',
		component: UsageReservation,
		targetPath: ['/reserva-utilizacao/[\\S]*'],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isChildrenOf: 'booking_management',
		iconName: 'UsageReservation'
	},

	bookingSettings: {
		key: 'booking_settings',
		name: 'booking_settings',
		path: '/definicoes-reservas',
		targetPath: ['/definicoes-reservas/[\\S]*'],
		component: BookingSettings,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isChildrenOf: 'booking_management',
		iconName: 'CalendarSync'
	},

	bookingRule: {
		key: 'booking_rule',
		name: 'booking_rule',
		path: '/definicoes-reservas/:areaid/:id',
		component: BookingRule,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false
	},

	bookingUnavailabilityRule: {
		key: 'booking_unavailabilityrule',
		name: 'booking_unavailabilityrule',
		path: '/indisponibilidade-reservas/:areaid/:id',
		component: BookingUnavailabilityRule,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false
	},

	bookingUnavailability: {
		key: 'booking_unavailability',
		name: 'booking_unavailability',
		path: '/indisponibilidade-reservas',
		targetPath: ['/indisponibilidade-reservas/[\\S]*'],
		component: BookingUnavailability,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isChildrenOf: 'booking_management',
		iconName: 'CalendarRemove'
	},

	usageReservationDetails: {
		key: 'usage_details',
		name: 'usage_details',
		path: '/reserva-utilizacao/:id',
		component: UsageReservationDetails,
		exact: true,
		showInMenu: false,
		showSidebar: false
	},

	campsites: {
		key: 'campsites',
		name: 'campsites',
		path: '/parques-campismo',
		targetPath: ['/parques-campismo/[\\S]*'],
		component: null,
		//crumb: ['processManagement'],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		keepChildrenPath: true,
		isFather: true,
		//isChildrenOf: 'processManagement',
		iconName: 'Camping'
	},

	campsiteManagement: {
		key: 'campsite_management',
		name: 'campsite_management',
		path: '/parques-campismo',
		targetPath: ['/parques-campismo/[\\S]*'],
		component: CampsiteManagement,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		isChildrenOf: 'campsites',
		iconName: 'Camping',
	},

	campSiteManagementRequest: {
		key: 'campsite_management_request',
		name: 'campsite_management_request',
		path: '/parques-campismo/:id',
		component: CampsiteManagementRequest,
		crumb: [],
		exact: true,
		showInMenu: false,
		showSidebar: false,
		iconName: 'Camping'
	},

	downloads: {
		key: 'downloads',
		name: 'downloads',
		path: '/downloads',
		component: Downloads,
		crumb: [],
		exact: true,
		showInMenu: true,
		showSidebar: true,
		iconName: 'Downloads'
	},
}