// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
	Grid,
	Loader,
	Dimmer as SegmentDimmer,
	Icon
} from 'semantic-ui-react'
import moment from 'moment'

import Logic from './logic'
import Table from 'components/Table'
import Title from 'components/Title'
import Button from 'components/Button'
import ContentBox from 'components/ContentBox'
import { CalendarSync, EditIcon } from 'components/Icons'
import ComponentDropdown from 'components/Dropdown'
import GoToButton from 'components/GoToButton'

type Props = {

}

class BookingSettings extends Component<Props> {

static defaultProps = {

}

render () {
	const {
		t,
		loading,
		serviceAreaOptions,
		selectedServiceArea,
		rules
	} = this.props
	const { setSelectedServiceArea, navigate, deleteRule } = this.actions

	return (
		<Wrapper>
			<Grid>
				<Grid.Row>
					<Title>
						{t('navigation.booking_settings')}
					</Title>
				</Grid.Row>
				<Grid.Row>
					{loading && 
					<Dimmer inverted active>
						<Loader inverted/>
					</Dimmer>
					}
					{!loading && selectedServiceArea &&
						<Grid style={{ width: '100%', margin: '0' }}>
							<Grid.Row style={{ width: '100%' }}>
								<Dropdown
									options={serviceAreaOptions}
									defaultValue={selectedServiceArea}
									// eslint-disable-next-line max-len
									onChange={(e, { value }) => setSelectedServiceArea(value)}
								/>
								<HorizontalLine />
							</Grid.Row>
							<Grid.Row style={{ width: '100%' }}>
								<ContentBox
									icon={
										<CalendarSync
											width={40}
											height={40}
											fill="#9B9B9B"
										/>
									}
									title={t('booking_settings.created_rules')}
									noMarginBottom
									button={
										<Button
											primary
											// eslint-disable-next-line max-len
											onClick={() => navigate(`/definicoes-reservas/${selectedServiceArea}/nova-regra`)}
										>
											{t('booking_settings.new_rule_set')}
										</Button>
									}
								>
									<Table.Table>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>
													{/* eslint-disable-next-line max-len */}
													{t('booking_settings.creation_date')}
												</Table.HeaderCell>
												<Table.HeaderCell>
													{/* eslint-disable-next-line max-len */}
													{t('booking_settings.set_name')}
												</Table.HeaderCell>
												<Table.HeaderCell>
													{/* eslint-disable-next-line max-len */}
													{t('booking_settings.time_interval')}
												</Table.HeaderCell>
												<Table.HeaderCell>
													{/* eslint-disable-next-line max-len */}
													{t('booking_settings.prices')}
												</Table.HeaderCell>
												<Table.HeaderCell>
												</Table.HeaderCell>
												<Table.HeaderCell>
												</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
			
										<Table.Body>
											{rules && rules.map((rule) => (
												<Table.Row key={rule.id}>
													<Table.Cell>
														{/* eslint-disable-next-line max-len */}
														{moment(rule.createdAt).format('DD/MM/YYYY')}
													</Table.Cell>
													<Table.Cell>
														{rule.name}
													</Table.Cell>
													<Table.Cell>
														{/* eslint-disable-next-line max-len */}
														{moment(rule.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')} - {moment(rule.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
													</Table.Cell>
													<Table.Cell>
														{/* eslint-disable-next-line max-len */}
														{rule.spotPrice}€/{t('booking_settings.night')}
														{/*{rule.spotPrice}€/{t('booking_settings.night')} • {rule.guestPrice}€/{t('booking_settings.person')}*/}
													</Table.Cell>
													<Table.Cell width={1}>
														<GoToButton 
															icon={
																<EditIcon
																	width={18}
																	height={18}
																/>
															}
															// eslint-disable-next-line max-len
															onClick={() => navigate(`/definicoes-reservas/${selectedServiceArea}/${rule.id}`)}
														/>
													</Table.Cell>
													<Table.Cell width={1}>
														<GoToButton
															icon={
																<Icon
																	// eslint-disable-next-line max-len
																	style={{ margin: 0 }}
																	// eslint-disable-next-line max-len
																	name={'trash'}
																/>
															}
															// eslint-disable-next-line max-len
															onClick={() => deleteRule(rule.id)}
														/>
													</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
									</Table.Table>
								</ContentBox>
							</Grid.Row>
						</Grid>
					}
				</Grid.Row>
			</Grid>
		</Wrapper>
	)
}
}

export default withTranslation()(Logic(BookingSettings))

const Wrapper = styled.div``

const Dimmer = styled(SegmentDimmer)`
	&&&& {
		background-color: transparent;
		height: 40vh;
	}
`

const Dropdown = styled(ComponentDropdown)`
	&&&& {
		.text {
			color: #000000;
			font-family: "Open Sans";
			font-size: 24px;
			letter-spacing: 0;
			line-height: 30px;
		}
		
		.dropdown.icon {
			height: 5px;
		}
		
		.item .text {
			font-size: 16px;
			line-height: 20px;
		}
	}
`

const HorizontalLine = styled.hr`
	height: 1px;
	background: #F3F3F3;
	border: 0;
	margin: 14px 0 24px 0;
`