import * as Check from 'validations'

const DEFAULT_VALUES = {
	description: {
		value: ''
	},
	slogan: {
		value: ''
	},
	email: {
		value: ''
	},
	fullAddress: {
		value: ''
	},
	gpsCoordinates: {
		value: ''
	},
	locality: {
		value: ''
	},
	mobile: {
		value: ''
	},
	municipality: {
		value: ''
	},
	name: {
		value: ''
	},
	postalCode: {
		value: ''
	},
	telephone: {
		value: ''
	},
	website: {
		value: ''
	},
	district: {
		value: ''
	},
	iban: {
		value: ''
	},
	ibanAccountName: {
		value: ''
	}
}

const VALIDATIONS = {
	description: [
		Check.isRequired
	],
	email: [
		Check.Email,
		Check.isRequired
	],
	fullAddress: [
		Check.isRequired
	],
	postalCode: [
		Check.isValidPostalCode,
		Check.isRequired
	],
	locality: [
		Check.isRequired
	],
	municipality: [
		Check.isRequired
	],
	district: [
		Check.isRequired
	],
	telephone: [
		Check.isValidPhoneNumber
	],
	website: [
		Check.isValidWebsite
	],
	mobile: [
		Check.isValidPhoneNumber,
		Check.isRequired
	],
	gpsCoordinates: [
		Check.isRequired,
		Check.isGpsCoordinates
	],
	name: [
		Check.isRequired
	],
	iban: [
		Check.isValidIBAN
	]
	//slogan: [
	//	Check.isRequired
	//]
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}