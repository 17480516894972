import React from 'react'
import styled from 'styled-components'
import { getPeriod, getSchedule } from './utils'
import { withTranslation } from 'react-i18next'
class CalendarUsageReservation extends React.Component  {

	constructor (props) {
		super(props)
		this.state = {
			itemHeight: this.maxHeight
		}
	}

	getDays = (period) => (
		<>
			{period.map((day, index) =>
				<DayItem
					index={index} 
					key={day.number} 
					length={period.length}
				>
					<div>
						{period.length < 8 &&
						<DayTitle>{day.name}</DayTitle>
						}
						<DayNumber>{day.number}</DayNumber>
					</div>
				</DayItem>
			)}
		</>
	)

	getHours = (hours, period) => {
		return ( 
			<>
				{hours.map((item, indexParent) =>{
					return (
						<>
							<Schedule row={indexParent + 1} key={indexParent}>
								<ScheduleItem >{item}</ScheduleItem>
							</Schedule>
							{[...Array(period)].map((item,index) => 
								<ScheduleDay key={index} index={index} indexParent={indexParent}/>
							)}
						</>
					)
				})}
			</>
		)
	}

	setSchedule = (schedule, period, hours, navigate) => {

		const findDate = period.findIndex(item => item.date === schedule[0].date)

		const column = findDate + 3

		const getCurrentRow = (item) => {
			const startHour = parseInt(item.startingTime)
			const endHour = parseInt(item.endingTime)
			const intervalIndex = hours.findIndex(hour => {
				const interval = hour.split(' - ')
				const intervalStart = parseInt(interval[0])
				const intervalEnd = parseInt(interval[1])
				
				return endHour <= intervalEnd && startHour >= intervalStart
			})
			
			return intervalIndex + 1
		}

		return schedule.map((item, index) => 
			<ScheduleRow key={index} row={getCurrentRow(item)} column={column}>
				<ScheduleContainer onClick={() => navigate(`/reserva-utilizacao/${item.id}`)}>
					<ScheduleInfoContent>
						<div style={{ width: '100%' }}>
							<ScheduleTitle>{item.name}</ScheduleTitle>
							<ScheduleLicensePlate>{item.licensePlateNumber}</ScheduleLicensePlate>
						</div>
					</ScheduleInfoContent>
				</ScheduleContainer>
			</ScheduleRow>
		)
	}
	
	render () {
		const { startDay, endDay, data, navigate, t } = this.props
		const period = getPeriod(startDay, endDay)
		const seats = getSchedule(data, period)
		if (data.length > 0) {
			return (
				<Wrapper columns={period.length}>
					{this.getDays(period)}
					{seats.map((item, index) =>
						<SteaContainer rows={item.hours.length} key={index} columns={period.length}>
							<Seats 
								span={item.hours.length + 1}
								index={index + 1}
							>
								{item.name}
							</Seats>
							{this.getHours(item.hours, period.length)}
							{Object.values(item.schedules).map(schedule =>
								this.setSchedule(schedule, period, item.hours, navigate)
							)}
						</SteaContainer>
					)}
				</Wrapper>	
			)
		} else {
			return <NoResults>{t('error.noResults')}</NoResults>
		}
	}
}

export default withTranslation()(CalendarUsageReservation)

const Wrapper = styled.div`
	width: 100%;
	height: auto;
	display: grid;
	grid-template-columns: ${({ columns }) => `minmax(50px, 100px) [col-seat] minmax(100px, 210px) [col-schedule] repeat(${columns}, minmax(50px, 1fr)) [col-calendar]`};
	grid-template-rows: auto;
`

const Seats = styled.div`
	font-family: 'Open Sans';
	font-style: italic;
	font-size: 10px;
	line-height: 14px;
	color: ${({theme}) => theme.primaryButtonColor};
	align-self: stretch;
	grid-column-start: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid #F3F3F3;
	border-bottom: 1px solid #F3F3F3;
	grid-column: 1;
	grid-row : 1 / -1;
	height: 100%;
	width: 100%;
	grid-gap: 1px;
	text-transform: uppercase;
`

const SteaContainer = styled.div`
	display: grid;
	grid-template-columns: ${({ columns }) => `minmax(50px, 100px) [col-seat] minmax(100px, 210px) [col-schedule] repeat(${columns}, minmax(50px, 1fr)) [col-calendar]`};
	grid-column: 1 / -1;
	grid-template-rows: ${({ rows }) => `repeat(${rows}, 44px)` };
	grid-gap: 1px;
	height: 100%;
	width: 100%;
	box-sizing: content-box;
	border-top: 1px solid #F3F3F3;
	border-bottom: 1px solid #F3F3F3;
`

const Schedule = styled.div`
	grid-column-start: 2;
	grid-row: ${({ row }) => row};
	border: 1px solid #F3F3F3;
	box-sizing: content-box;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ScheduleItem = styled.div`
	font-size: 10px;
	line-height: 10px;
	color: #6F7C7C;
	font-family: 'Open Sans';
	font-weight: 600;
	text-align: center;
	font-weight: bold;
`

const DayItem = styled.div`
	box-sizing: content-box;
	border: 1px solid #f3f3f3;
	grid-column-start: ${({index}) => index + 3};
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ScheduleRow = styled.div`
	grid-row: ${({ row }) => row};
	grid-column: ${({ column }) => column};
	border: 1px solid #f3f3f3;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
`

const ScheduleContainer = styled.div`
	display: flex;
	background-color: #E6E6EE;
	height: 32px;
	width: 100%;
	margin: 6px auto;
	cursor: pointer;
`

const ScheduleInfoContent = styled.div`
	border-left: 3px solid #647899;
	display: flex;
	align-items: center;
	justify-content:center;
	width: 100%;
`

const ScheduleTitle = styled.p`
	font-family: 'Open Sans';
	font-weight: bold;
	font-style: italic;
	font-size: 10px;
	color: #71819A;
	margin: 0;
	padding-left: 8px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ScheduleLicensePlate = styled.p`
	font-family: 'Open Sans';
	font-size: 10px;
	color: #71819A;
	margin: 0;
	padding-left: 8px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DayNumber = styled.p`
	font-family: 'Open Sans';
	font-weight: 600; 	
	font-size: 14px;
	text-align: center;
	line-height: 19px;
	color: ${({theme}) => theme.primaryButtonColor};
`

const DayTitle = styled.p`
	font-family: 'Open Sans';
	font-size: 10px;
	color: #6F7C7C;
	text-align: center;
	margin: 0;
	text-transform: uppercase;
`

const ScheduleDay = styled.div`
	border: 1px solid #f3f3f3;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	grid-column: ${({index}) => `${index + 3} / span 1`};
	grid-row:  ${({indexParent}) => indexParent  + 1};
	background-color: #FAFAFA;
`

const NoResults = styled.p`
	color: #3E3E3E;
	font-family: 'Open Sans';
	font-size: 14px;
	line-height: 14px;
`