import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import { 
	Form,
	Input as SemanticInput,
	Dropdown as SemanticDropdown,
	TextArea as SemanticTextArea
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import FormField from 'components/FormField'
import Label from 'components/Label'
import FormGroup from 'components/FormGroup'
import Buttons from 'containers/ServiceAreaRequestForm/Buttons'
import Map from 'components/Map'
import { isGpsCoordinates } from 'validations'

class SummaryDescription extends Component {

onChangePostalCode = (field) => {
	const { changeForm, getDataToPrefillFields } = this.actions
	changeForm(field)
	getDataToPrefillFields()
}

onGetCoordinates = (coordinates) => {
	const { changeForm } = this.actions
	const { lat, lng } = coordinates

	if (lat && lng) {
		const location = coordinates.lat + ',' + coordinates.lng
		changeForm({ gpsCoordinates: location })
	}
}

render () {
	const {
		loading,
		t,
		form,
		districts,
		municipalities,
		dirty
	} = this.props

	const { changeForm, patchForm } = this.actions

	const { 
		name,
		slogan,
		description,
		fullAddress,
		gpsCoordinates,
		postalCode,
		municipality,
		locality,
		district,
		website,
		email,
		mobile,
		telephone,
		iban,
		ibanAccountName,
	} = form

	const coordinates = gpsCoordinates.value.split(',')
	let position = [0,0]
	let markers = []

	if (coordinates.length === 2 && isGpsCoordinates(coordinates).result) {
		position = [coordinates[0], coordinates[1]]
		markers = [{ position: coordinates }]
	}

	return (
		<>
			<BoxSegment loading= {loading}>
				<Form>
					<SegmentTitle
						margin={'8px 0 30px 0'}
					>
						{t('form.summary_description')}
					</SegmentTitle>

					<FormField
						required
						name={'service_area_name'}
						label={t('form.service_area_name')}
						error={name.dirty && !name.valid && name.message}
						control={Input}
						value={name.value}
						onChange={(e, { value }) => changeForm(
							{ name: value })
						}
					/>

					<FormField
						//required
						name={'service_area_slogan'}
						label={'Slogan'}
						//error={slogan.dirty && !slogan.valid && slogan.message}
						control={Input}
						value={slogan.value}
						onChange={(e, { value }) => changeForm(
							{ slogan: value })
						}
					/>

					<FormField
						required
						margin={'28px 0 0 0'}
						name={'general_description'}
						label={t('form.general_description')}
						// eslint-disable-next-line max-len
						error={(description.dirty && !description.valid) && description.message}
						control={TextArea}
						maxLength={600}
						value={description.value}
						onChange={(e, { value }) => changeForm(
							{ description: value })
						}
					/>
					<SmallLabel style={{ paddingTop: 8, paddingLeft: '0.5em' }}>
						{t('form.characters')} {description.value.length} / 600
					</SmallLabel>
					<SegmentTitle
						margin={'8px 0 30px 0'}
					>
						{t('form.address_and_contacts')}
					</SegmentTitle>

					<FormField
						required
						name={'address'}
						control={Input}
						label={
							<>
								<Label color={'#616161'}>
									{t('form.full_address')}
								</Label>
								<SmallLabel>
									{t('form.full_address_description')}
								</SmallLabel>
							</>
						}
						value={fullAddress.value}
						onChange={(e, { value }) => changeForm(
							{ fullAddress: value }
						)}
						// eslint-disable-next-line max-len
						error={fullAddress.dirty && !fullAddress.valid && fullAddress.message }
					/>

					<FormField
						required
						name={'gpsCoordinates'}
						label={t('form.gps_coordinates')}
						control={Input}
						value={gpsCoordinates.value}
						onChange={(e, { value }) => {
							//const coordinates = value.split(',')
							// eslint-disable-next-line max-len
							//if (!(isNaN(coordinates[0]) || isNaN(coordinates[1]))) {
							changeForm({ gpsCoordinates: value })
							//}
						}}
						// eslint-disable-next-line max-len
						error={(gpsCoordinates.dirty && !gpsCoordinates.valid) && gpsCoordinates.message}
					/>

					<MapWrapper>
						<Map
							centerPosition={position}
							markers={markers}
							getCoordinates={this.onGetCoordinates}
							withSearch
						/>
					</MapWrapper>
					<FormGroup widths='equal'>
						<FormField
							required
							name={'postal_code'}
							label={t('form.postal_code')}
							// eslint-disable-next-line max-len
							error={(postalCode.dirty && !postalCode.valid) && postalCode.message}
							control={Input}
							value={postalCode.value}
							onChange={(e, { value }) =>
								this.onChangePostalCode(
									{ postalCode: value }
								)
							}
						/>

						<FormField
							required
							name={'locality'}
							label={t('form.locality')}
							// eslint-disable-next-line max-len
							error={(locality.dirty && !locality.valid) && locality.message}
							control={Input}
							value={locality.value || ''}
							onChange={(e, { value }) => changeForm(
								{ locality: value }
							)}
						/>
					</FormGroup>

					<FormGroup widths='equal'>
						<FormField
							required
							name={'district'}
							// eslint-disable-next-line max-len
							error={(district.dirty && !district.valid) && district.message}
							label={t('form.district')}
							control={Dropdown}
							placeholder={t('form.district_option')}
							options={districts && districts.map(
								({ id, name }) =>
									({ text: name, value: id })
							)}
							onChange={(e, data) =>
								districts && changeForm(
									{ district: data.value }
								)}
							value={district.value}
							selection
						/>

						<FormField
							required
							name={'municipality'}
							control={Dropdown}
							// eslint-disable-next-line max-len
							error={(municipality.dirty && !municipality.valid) && municipality.message}
							label={t('form.municipality')}
							placeholder={t('form.municipality_option')}
							options={municipalities &&
municipalities
// eslint-disable-next-line max-len
	.filter(municipality => municipality.district.id === district.value)
// eslint-disable-next-line max-len
	.map(({ id, name }) => ({ text: name, value: id }))
							}
							value={municipality.value}
							onChange={(e, data) =>
								municipalities &&
changeForm({ municipality: data.value })
							}
							selection
						/>
					</FormGroup>

					<FormGroup widths='equal'>
						<FormField
							name={'website'}
							label={t('form.website')}
							error={(website.dirty && !website.valid) &&
website.message}
							control={Input}
							value={website.value}
							onChange={(e, { value }) => changeForm(
								{ website: value })
							}
						/>

						<FormField
							required
							name={'email_address'}
							label={t('form.email_address')}
							// eslint-disable-next-line max-len
							error={(email.dirty && !email.valid) && email.message}
							control={Input}
							value={email.value || ''}
							onChange={(e, { value }) => changeForm(
								{ email: value })
							}
						/>
					</FormGroup>

					<FormGroup widths='equal'>
						<FormField
							name={'phone'}
							label={t('form.phone')}
							// eslint-disable-next-line max-len
							error={(telephone.dirty && !telephone.valid) && telephone.message}
							control={Input}
							value={telephone.value}
							onChange={(e, { value }) => changeForm(
								{ telephone: value })
							}
						/>

						<FormField
							required
							name={'mobile'}
							label={t('form.mobile')}
							// eslint-disable-next-line max-len
							error={(mobile.dirty && !mobile.valid) && mobile.message}
							control={Input}
							value={mobile.value}
							onChange={(e, { value }) => changeForm(
								{ mobile: value })
							}
						/>

					</FormGroup>
					<SegmentTitle
						margin={'8px 0 30px 0'}
					>
						{t('form.payment')}
					</SegmentTitle>
					<FormGroup widths='equal'>
						<FormField
							name={'ibanAccountName'}
							control={Input}
							label={
								<>
									<Label color={'#616161'}>
										Titular IBAN
									</Label>
									<SmallLabel>
										Nome do Titular da Conta Bancária
									</SmallLabel>
								</>
							}
							value={ibanAccountName.value}
							onChange={(e, { value }) => changeForm(
								{ ibanAccountName: value }
							)}
							// eslint-disable-next-line max-len
							error={ibanAccountName.dirty && !ibanAccountName.valid && ibanAccountName.message }
						/>
						<FormField
							name={'iban'}
							control={Input}
							label={
								<>
									<Label color={'#616161'}>
										{t('form.iban')}
									</Label>
									<SmallLabel>
										{t('form.iban_description')}
									</SmallLabel>
								</>
							}
							value={iban.value}
							onChange={(e, { value }) => changeForm(
								{ iban: value }
							)}
							// eslint-disable-next-line max-len
							error={iban.dirty && !iban.valid && iban.message }
						/>
					</FormGroup>
				</Form>
			</BoxSegment>

			<Buttons
				nextButtonClick={() => patchForm('next')}
				saveButtonClick={() => patchForm()}
				dirty={dirty}
			/>
		</>
	)
}
}

export default withTranslation()(Logic(SummaryDescription))

const Input = styled(SemanticInput)`
  width: 100%;
`

const TextArea = styled(SemanticTextArea)`
  width: 100%;
  border: 1px solid #DDDDDD;
`

const Dropdown = styled(SemanticDropdown)`
  width: 100%;
`

const SmallLabel = styled.span`
  display: block;
  opacity: 0.5;
  color: #616161;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 14px;
  padding: 5px 0 10px 0;
`

const MapWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  margin: 15px 0;

  .leaflet-container {
    height: 430px;
    width: 100%;
  }

  .leaflet-div-icon {
    background: #ACCF38;
  }
`