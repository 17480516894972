import React from 'react'
import styled from 'styled-components'
import { compose, onlyUpdateForKeys } from 'recompose'
import LogoImage from './images/logo.svg'

const Brand = ({ onClick }) => (
	<a href='/'>
		<Logo onClick={onClick}>
			<img src={LogoImage} alt='FCMP' />
		</Logo>
	</a>
)

export default compose(
	onlyUpdateForKeys([])
)(Brand)

const Logo = styled.div`
  position: relative;
  margin-right: 5px;
  width: 140px;
  height: 100%;

  img {
    width: 155px;
    z-index: 9;
    padding: 20px;
    padding-left: 46px !important;
    box-sizing: initial;
  }
`
