import * as React from 'react'

function IcWater (props) {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>{'icons/servicos/iic-water'}</title>
			<path
				// eslint-disable-next-line max-len
				d="M18.968 20.492c.252 0 .485.135.61.354l.688 1.204c.087.153.109.368.109.544 0 .775-.631 1.406-1.406 1.406a1.408 1.408 0 01-1.3-1.947l.689-1.208a.703.703 0 01.61-.353zm2.813-3.523v1.406a.703.703 0 01-.703.703H16.86a.703.703 0 01-.703-.703v-1.406h5.625zM9.781 0a1.41 1.41 0 011.327.938l.166.468h1.845c.141-.092.619-.703 1.584-.703 1.163 0 2.11.946 2.11 2.11 0 1.163-.947 2.109-2.11 2.109-.964 0-1.443-.612-1.584-.703h-1.931v3.867a4.197 4.197 0 011.717 1.148h3.251a5.625 5.625 0 015.625 5.625v.704h-5.84c-.245-.413-.676-.704-1.191-.704h-1.845a4.192 4.192 0 01-3.124 1.407 4.192 4.192 0 01-3.124-1.407H2V9.234h4.657a4.197 4.197 0 011.718-1.148V4.22H6.443c-.14.092-.618.703-1.584.703a2.112 2.112 0 01-2.109-2.11c0-1.163.946-2.109 2.11-2.109.963 0 1.443.612 1.583.703h1.845l.167-.468A1.41 1.41 0 019.78 0z"
				fill="#102526"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default IcWater
