//import * as Check from 'validations'

const DEFAULT_VALUES = {
	firstAidPost: {
		value: null
	},
	hotShower: {
		value: null
	},
	adaptedToilets: {
		value: null
	},
	babyChangingStation: {
		value: null
	},
	socialRoom: {
		value: null
	},
	communityMicrowave: {
		value: null
	},
	grills: {
		value: null
	},
	pool: {
		value: null
	},
	playground: {
		value: null
	},
	childrenPlayground: {
		value: null
	},
	chemicalToiletsDumpingPoint: {
		value: null
	},
	vehicleWashArea: {
		value: null
	},
	washbasins: {
		value: null
	},
	washbasinsHaveHotWater: {
		value: null
	},	
	commonDishwasher: {
		value: null
	},
	commonDishwasherHasHotWater: {
		value: null
	},
	commonLaundry: {
		value: null
	},
	commonLaundryHasHotWater: {
		value: null
	}
}

const VALIDATIONS = {
	
}

const VALIDATIONS_NO_REQUIRED = {

}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}