/* eslint-disable max-len */
import { isValidNumber } from 'libphonenumber-js'
import { isNifValid, isValidIBANNumber } from 'utils'

// Checks the validation of the whole form
export const checkValidation = (form, validations) => {
	let clone = { ...form }
	let invalid = false
	let message = ''
	for (const key in clone) {
		if (Object.prototype.hasOwnProperty.call(clone, key)) {
			const element = clone[key]
			const validationList = validations[key]
			element.valid = true
			element.dirty = true

			if (validationList && validationList.length) {
				for (let index = 0; index < validationList.length; index++) {
					const check = validationList[index]
					const response = check(element.value, form)
					if (response.result === 'end') {
						// for unrequired fields: don't set as valid
						// for scenarios where this method is called when
						// form is loaded (retrieved from API), the field would turn green
						// when empty - and this is weird
						if (check.name === 'hasValue') {
							element.dirty = false
							element.valid = null
						} else {
							// put valid true and empty message like default value
							element.valid = true
							element.message = ''
						}
						break
					}

					if (!response.result && element.valid) {
						element.valid = false
						element.message = response.message
						message = response.message
						invalid = true
					} else if (element.valid === null) {
						element.valid = true
					}
				}
			}
		}
	}

	return { invalid, form: clone, message }
}

export const checkBidimensionalValidation = (form, validations) => {
	let clone = { ...form }
	let invalid = false
	let message = ''

	for (const key in Object.values(clone)[0].value) {
		if (Object.prototype.hasOwnProperty.call(
			Object.values(clone)[0].value,
			key
		)) {
			const element = Object.values(clone)[0].value[key]
			for (const key in element) {
				if (Object.prototype.hasOwnProperty.call(element, key)) {
					const validationList = validations[key]

					if (validationList && validationList.length) {
						for (
							let index = 0;
							index < validationList.length;
							index++
						) {
							element[key].valid = true
							element[key].dirty = true
							const check = validationList[index]
							const response = check(element[key].value, form)
							if (response.result === 'end') {
								// for unrequired fields: don't set as valid
								// for scenarios where this method is called when
								// form is loaded (retrieved from API), the field would turn green
								// when empty - and this is weird
								if (check.name === 'hasValue') {
									element[key].dirty = false
									element[key].valid = null
								} else {
									// put valid true and empty message like default value
									element[key].valid = true
									element[key].message = ''
								}
								break
							}

							if (!response.result) {
								element[key].valid = false
								element[key].message = response.message
								message = response.message
								invalid = true
							} else {
								element[key].valid = true
							}
						}
					}
				}
			}
		}
	}

	return { invalid, form: clone, message }
}

export const checkTridimensionalValidations = (form, validations, nestedValidations) => {
	let clone = { ...form }
	let invalid = false
	let message = ''

	for (const key in Object.values(clone)[0].value) {
		if (Object.prototype.hasOwnProperty.call(
			Object.values(clone)[0].value,
			key
		)) {
			let element = Object.values(clone)[0].value[key]

			for (const masterKey in element) {
				if (Object.prototype.hasOwnProperty.call(
					element,
					masterKey
				) && !Array.isArray(element[masterKey].value)) {
					const validationList = validations[masterKey]

					if (validationList && validationList.length) {
						for (
							let index = 0;
							index < validationList.length;
							index++
						) {
							element[masterKey].valid = true
							element[masterKey].dirty = true
							const check = validationList[index]
							const response = check(
								element[masterKey].value,
								form
							)
							if (response.result === 'end') {
								if (check.name === 'hasValue') {
									element[masterKey].dirty = false
									element[masterKey].valid = null
								} else {
									element[masterKey].valid = true
									element[masterKey].message = ''
								}
								break
							}

							if (!response.result) {
								element[masterKey].valid = false
								element[masterKey].message = response.message
								message = response.message
								invalid = true
							} else {
								element[masterKey].valid = true
							}
						}
					}
				} else {
					for (const key in element[masterKey].value) {
						if (Object.prototype.hasOwnProperty.call(
							element[masterKey].value,
							key
						)) {
							const newElement = element[masterKey].value

							for (const key in newElement) {
								if (Object.prototype.hasOwnProperty.call(
									newElement,
									key
								)) {
									const validationList =
										nestedValidations[masterKey]
									if (validationList &&
										validationList.length) {
										for (
											let index = 0;
											index < validationList.length;
											index++
										) {
											Object.values(newElement[key])[0].valid = true
											Object.values(newElement[key])[0].dirty = true
											const check = validationList[index]
											const response = check(Object.values(newElement[key])[0].value, form)
											if (response.result === 'end') {
												if (check.name === 'hasValue') {
													Object.values(newElement[key])[0].dirty = false
													Object.values(newElement[key])[0].valid = null
												} else {
													Object.values(newElement[key])[0].valid = true
													Object.values(newElement[key])[0].message = ''
												}
												break
											}

											if (!response.result) {
												Object.values(newElement[key])[0].valid = false
												Object.values(newElement[key])[0].message = response.message
												message = response.message
												invalid = true
											} else {
												Object.values(newElement[key])[0].valid = true
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	return { invalid, form: clone, message }
}

// Checks the validation of the whole form
export const checkArrayValidation = (arrForm, validations) => {
	let clone
	let invalid = false
	let message = ''
	arrForm.forEach(form => {
		clone = { ...form }
		for (const key in clone) {
			if (Object.prototype.hasOwnProperty.call(clone, key)) {
				const element = clone[key]
				const validationList = validations[key]
				element.valid = true
				element.dirty = true

				if (validationList && validationList.length) {
					for (let index = 0; index < validationList.length; index++) {
						const check = validationList[index]
						const response = check(element.value, form)
						if (response.result === 'end') {
							// put valid true and empty message like default value
							element.valid = true
							element.message = ''
							break
						}

						if (!response.result) {
							element.valid = false
							element.message = response.message
							message = response.message
							invalid = true
						} else {
							element.valid = true
						}
					}
				}
			}
		}
	})
	return { invalid, form: clone, message }
}

export const setArrayAndCheckValidation = (state, payload, validations) => {
	let newStateChanges = {}

	state.forEach((fieldState, index) => {
		Object.keys(payload.field).forEach(fieldName => {
			if (index === payload.index) {
				const field = payload.field[fieldName]
				const validationList = validations[fieldName]
				let settedField = {
					value: field,
					valid: true,
					dirty: true
				}

				if (validationList) {
					for (var i = 0; i < validationList.length; i++) {
						const check = validationList[i]
						const response = check(field, state)

						if (response.result === 'end') {
							break
						}
						if (!response.result) {
							settedField.valid = false
							settedField.message = response.message
							break
						}
					}
				}

				newStateChanges = { ...newStateChanges, [fieldName]: settedField }
			}
		})
	})
	return state.map((val, index) => {
		if (index === payload.index) return { ...val, ...newStateChanges }
		return val
	})
}

// Checks the validation of a field
export const setAndCheckValidation = (state, payload, validations) => {
	let newStateChanges = {}
	
	Object.keys(payload.field).forEach((fieldName) => {
		const field = payload.field[fieldName]
		const validationList = validations[fieldName]

		let settedField = {
			...state[fieldName],
			value: field,
			valid: true,
			dirty: true,
			message: undefined
		}

		if (validationList) {
			for (var index = 0; index < validationList.length; index++) {
				const check = validationList[index]
				const response = check(field, state)

				if (!response.result) {
					settedField.valid = false
					settedField.message = response.message
					break
				}

				if (response.result === 'end') {
					if (check.name === 'hasValue') {
						settedField.dirty = false
						settedField.valid = null
					}
				}

			}
		}

		newStateChanges = { ...newStateChanges, [fieldName]: settedField }
	})

	return { ...state, ...newStateChanges }
}

// Validates and email
export const Email = (value) => {

	if (!value || value === '') return {result: true}

	const message = 'O email não está no formato correcto'
	const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (!regex.test(value)) {
		return { result: false, message }
	}
	return { result: true }
}

// Validates nif or email
export const isNifOrEmail = (value) => {

	if (!value || value === '') return {result: true}

	const message = 'O email/nif não está no formato correcto'

	const email = Email(value)
	const nif = isNif(value)

	if (!nif.result && !email.result)
		return { result: false, message }

	return { result: true }
}

// Validates IBAN
export const isValidIBAN = (value) => {

	if (!value || value === '') return {result: true}

	const message = 'O IBAN não está no formato correcto'

	if (!isValidIBANNumber(value))
		return { result: false, message }

	return { result: true }
}

// Validates if a field is set
export const isRequired = (value) => {
	const message = 'Campo de preenchimento obrigatório'
	if (value === undefined || value === null || value.length === 0) {
		return { result: false, message }
	}

	return { result: true }
}

export const isValidCheckInCheckOutHour = (start, finish) => {
	const messageStart = 'A hora de Check-in tem de ser anteior à hora de Check-out.'

	if (start.value > finish.value) {
		return { result: false, message: messageStart }
	}

	return { result: true }

}

export const isValidDateInterval = (value) => {
	const message = 'A data final tem de ser superior à data inicial'

	const dates = value.split(' - ')

	const date1Numbers = dates[0].match(/[\d]+/g)
	const date2Numbers = dates[1].match(/[\d]+/g)

	const date1 = new Date(`${date1Numbers[1]}-${date1Numbers[0]}-${date1Numbers[2]}`).getTime()
	const date2 = new Date(`${date2Numbers[1]}-${date2Numbers[0]}-${date2Numbers[2]}`).getTime()

	if (date1 > date2) {
		return { result: false, message }
	}

	return { result: true }
}

export const isDateIntervalEqualOrAfterToday = (value) => {
	const message = 'As datas não podem ser inferiores à data atual'

	const dates = value.split(' - ')

	const date1Numbers = dates[0].match(/[\d]+/g)
	const date2Numbers = dates[1].match(/[\d]+/g)

	const date1 = new Date(`${date1Numbers[1]}-${date1Numbers[0]}-${date1Numbers[2]}`).getTime()
	const date2 = new Date(`${date2Numbers[1]}-${date2Numbers[0]}-${date2Numbers[2]}`).getTime()

	const today = new Date()
	today.setHours(0)
	today.setMinutes(0)
	today.setSeconds(0)
	today.setMilliseconds(0)
	
	if (date1 < today || date2 < today) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if gps coordinates is valid
export const isGpsCoordinates = (value) => {

	const resolvedValue = value.length === 2 ? `${value[0]}, ${value[1]}` : value

	if (!resolvedValue || resolvedValue === '') return {result: true}

	let message = 'As coordenadas não estão num formato correcto'

	if (!/^-?[\d]+[.]*[\d]+[ ]*,[ ]*-?[\d]+[.]*[\d]+$/.test(resolvedValue)) {
		return { result: false, message }
	}

	const latLng = resolvedValue.match(/[\w.-]+/g)

	const lat = parseFloat(latLng[0])
	const lng = parseFloat(latLng[1])

	if (lat > 90 || lat < -90 || lng > 180 || lng < -180) {
		message = 'A latitude só pode variar entre -90 e 90 e a longitude entre -180 e 180'
		return { result: false, message }
	}

	return { result: true }
}

// Validates if a field exceeds max size
export const hasMaxSize = (value, max) => {
	const message = `Limite máximo de ${max} caracteres.`
	if (value && value.length > max) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if a field exceeds max size
export const maxNumber = (value, max) => {
	const message = `O limite máximo é ${max}.`
	if (value && parseFloat(value) > max) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if a field subceeds min size
export const minNumber = (value, min) => {
	const message = `O limite mínimo é ${min}.`
	if (value && parseFloat(value) < min) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if a radio field is set
// Radio has usually a boolean value
// In this case a 'false' value must be taken as { result: true }
export const isRequiredRadio = (value) => {
	const message = 'Campo de preenchimento obrigatório'

	if (value === null) {
		return { result: false, message }
	}

	return { result: true }
}

export const hasDateIntervalFilled = value => {
	
	if (!value) return {result: true}

	const message = 'Ambas as datas têm de estar preenchidas'
	const dates = value.split(' - ')

	if (!dates.length || dates.length !== 2 || dates[0] === '' || dates[1] === '')
		return {result: false, message}

	return {result: true}
}

// Used to validate unrequired fields
export const hasValue = (value) => {
	if (!value || (Array.isArray(value) && !value.length)) {
		return { result: 'end' }
	}

	return { result: 'continue' }
}

// Validates if user has checked that it has a Certificate
// If yes proceeds to the next validation if no it stops and marks as valid
export const hasCertificate = (value, state) => {
	if (state.hasCertificate && !state.hasCertificate.value) {
		return { result: 'end' }
	}

	return { result: 'continue' }
}

// Validates if user has checked that it has a PEDataSource
// If yes proceeds to the next validation if no it stops and marks as valid
export const hasPEDataSource = (value, state) => {
	if (state.dataSource && state.dataSource.value !== 'pe') {
		return { result: 'end' }
	}

	return { result: 'continue' }
}

// Validates if its a valid postal code
export const isValidPostalCode = (value) => {

	if (!value || value === '') return {result: true}

	const message = 'O código postal não está no formato correto'
	const regex = /^\d{4}-\d{3}$/
	if (!regex.test(value)) {
		return { result: false, message }
	}

	return { result: true }
}

export const isValidPhoneNumber = value => {

	if (!value || value === '') return {result: true}

	const message = 'Número está incorreto. Para números estrangeiros coloque o indicativo'
	const regex = /^[0][0]|[+]/
	if (!regex.test(value)) {
		value = '+351' + value
	}
	const valid = isValidNumber(value)
	return { result: valid, message }
}

export const isTermsChecked = value => {
	const message = 'Aceite os termos e condições para poder continuar'

	if (!value) {
		return { result: false, message }
	}

	return { result: true }
}

export const isValidWebsite = value => {

	if (!value || value === '') return {result: true}

	const message = 'Website invalido'
	const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
	if (!regex.test(value)) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if user has checked Expert Support
export const hasExpertSupport = (value, state) => {
	if (state.expertSupport && !state.expertSupport.value) {
		return { result: 'end' }
	}
	return { result: 'continue' }
}

// Validates if user has checked availability for a visit
export const isAvailable = (value, state) => {
	if (state.availability && !state.availability.value) {
		return { result: 'end' }
	}
	return { result: 'continue' }
}

// Validates if user has inserted a new password
export const hasNewPassword = (value, state) => {
	if (state.newPassword && !state.newPassword.value) {
		return { result: 'end' }
	}

	return { result: 'continue' }
}

export const isTheSamePassword = (value, state) => {
	if (state.passwordConfirmation && state.password.value === value) {
		return { result: true }
	}

	if (state.newPassword && state.newPassword.value === value) {
		return { result: true }
	}

	return { result: false, message: 'Passwords não são as mesmas.' }
}

export const isStrongPassword = (value) => {
	if (value.length >= 8) {
		return { result: true }
	}

	return { result: false, message: 'Password necessita de pelo menos 8 caracteres.' }
}

export const pCode = (value) => {
	if (value.length === 4) {
		return { result: true }
	}

	return { result: false, message: 'Código postal inválido.' }
}

export const pCodeExtension = (value) => {
	if (value.length === 3) {
		return { result: true }
	}

	return { result: false, message: 'Código postal inválido.' }
}

export const isViaPortal = (value, state) => {
	if (state.viaPortal && state.viaPortal.value) {
		return { result: 'continue' }
	}

	return { result: 'end' }
}

export const isCPECUIValid = (value) => {
	let message = 'Formato inválido (PT + 16 algarismos + 2 letras)'
	const regex = /^PT\d{16}[a-zA-Z]{2}$/g
	if (!value || !regex.test(value)) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if Rich Text Editor has description
export const hasDescription = (value) => {
	const message = 'Campo de preenchimento obrigatório'
	if (value === '<p><br></p>') {
		return { result: false, message }
	}

	return { result: true }
}

// Validates input is a valid number
export const isNumber = (value) => {

	if (!value || value === '') return {result: true}

	const message = 'O valor introduzido deverá ser um número'
	if (!(/^\d+$/.test(value))) {
		return { result: false, message }
	}

	return { result: true }
}

export const isNumberDecimal = (value,) => {

	if (!value || value === '') return {result: true}

	const message = 'O valor introduzido deverá ser um número'
	if (!(/^[-]?[\d]+(?:(?:\.|,)[\d]+)?$/.test(value))) {
		return { result: false, message }
	}

	return { result: true }
}

export const isMonetaryNumber = (value,) => {

	if (!value || value === '') return {result: true}

	const message = 'O valor introduzido deverá ser um valor monetário válido'
	if (!(/^[\d]+(?:(?:\.|,)[\d]{1,2})?$/.test(value))) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates input is a valid number
export const isFloat = (value) => {
	const message = 'O valor introduzido deverá ser um número'
	if (!(/[+-]?([0-9]*[.])?[0-9]+/.test(value))) {
		return { result: false, message }
	}

	return { result: true }
}

// Validates if match the 'YYYY-MM-DD' format
export const validYYYYMMDDFormat = (value) => {
	const message = 'A data deve ter o formato \'AAAA-MM-DD'
	if (!(/^\d{4}-\d{2}-\d{2}$/.test(value))) {
		return { result: false, message }
	}

	return { result: true }
}

export const validHHMMFormat = value => {
	const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/
	const message = 'Inserir hora valida'
	if (!(regex.test(value))) {
		return { result: false, message }
	}

	return { result: true }

}

export const checked5Hours = value => {
	const hours = parseInt(value.split(':')[0])
	const message = 'Inserir uma hora inferior a 5 horas'
	if (hours > 5) {
		return { result: false, message }
	}

	return { result: true }

}

export const isNif = (value, state) => {
	const hasValue = isRequired(value, state)
	if (!hasValue.result) return hasValue

	const message = 'Número de identificação fiscal inválido'
	if (!isNifValid(value) || value.length > 9) return { result: false, message }

	return { result: true }
}

export const isValidDecimalCoordinate = (value) => {
	const message = 'Insira uma coordenada válida'
	const regex = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/
	if (!regex.test(value)) {
		return { result: false, message }
	}

	return { result: true }
}

export const hasEletricity = (value, state) => {
	if (state.electricitySupply.value === 'paid locally') {
		return { result: 'continue' }
	}
	
	return { result: 'end' }
}

export const hasWaterSypply = (value, state) => {
	if (state.potableWaterSupply.value === 'paid locally') {
		return { result: 'continue' }
	}
	
	return { result: 'end' }
}

export const hasLaundryPlaces = (value, state) => {
	if (state.laundryPlaces.value ) {
		return { result: 'continue' }
	}
	
	return { result: 'end' }
}

export const hasGarbageContainers = (value, state) => {
	if (state.garbageContainers.value ) {
		return { result: 'continue' }
	}
	
	return { result: 'end' }
}

export const hasGrids = (value, state) => {
	if (state.grids.value ) {
		return { result: 'continue' }
	}
	
	return { result: 'end' }
}

export const hasPriceMenu = (value, state) => {
	if (state.hasPriceMenu.value ) {
		return { result: 'continue' }
	}
	
	return { result: 'end' }
}
