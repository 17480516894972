// @flow

import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import moment from 'moment'

import BookingManagementLogic from 'screens/BookingManagement/logic'
import AppLogic from 'containers/App/logic'

export default kea({
	path: () => ['scenes', 'containers', 'BookMngFilters'],

	connect: {
		actions: [
			BookingManagementLogic, [
				'setVisibleTime'
			],
			AppLogic, [
				'navigate'
			]
		]
	},
	
	start: function* () {
		const { timeFilter } = this.props
		const { setVisibleTime } = this.actions
		
		if (timeFilter === 'week') {
			yield put(setVisibleTime({
				visibleTimeStart: moment().startOf('week').valueOf(),
				visibleTimeEnd: moment().endOf('week').valueOf()
			}))
		} else {
			yield put(setVisibleTime({
				visibleTimeStart: moment().startOf('month').valueOf(),
				visibleTimeEnd: moment().endOf('month').valueOf()
			}))
		}
	}
})
