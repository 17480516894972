import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import { call, put } from 'redux-saga/effects'
import API from 'api'
import { sortedAlphabetically } from 'utils'
import Proptypes from 'prop-types'

export default kea({
	path: () => ['scenes', 'containers', 'CampingManagementRequestForm', 'SummaryDescription'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			CampsiteManagementRequestLogic, [
				'current',
			],
			CampsiteManagementLogic, [
				'campsiteId',
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			CampsiteManagementLogic, [
				'updateCampsite'
			],
			CampsiteManagementRequestLogic, [
				'setCurrent',
				'updateStep'
			],
		]
	},

	actions: () => ({
		setDistricts: districts => districts,
		setMunicipalities: municipalities => municipalities,
		fetchDistrictsAndMunicipalities: () => true,
		getDataToPrefillFields: () => true,
		reset: () => true,
		fetchForm: () => true,
	}),

	reducers: ({actions}) => ({

		districts: [[], Proptypes.array, {
			[actions.setDistricts]: (state, districts) => districts,
			[actions.reset]: () => []
		}],
		
		municipalities: [[], Proptypes.array, {
			[actions.setMunicipalities]: (state, municipalities) => municipalities,
			[actions.reset]: () => []
		}]
	}),

	start: function * () {
		const { fetchDistrictsAndMunicipalities } = this.actions
		
		yield put(fetchDistrictsAndMunicipalities())
	},

	takeLatest: ({actions, workers}) => ({
		[actions.fetchDistrictsAndMunicipalities]: workers.fetchDistrictsAndMunicipalities,
		[actions.getDataToPrefillFields]: workers.getDataToPrefillFields,
		[actions.fetchForm]: workers.fetchForm,
	}),

	workers: {

		* fetchForm () {

			const { fetchData } = this.props
			const campsiteId = yield this.get('campsiteId')

			fetchData((data, config) => API.Campsites.steps.getSummary(campsiteId, config), {
				resolveData: (data) => {
					
					if (data.municipality) {
						
						if (data.municipality.district) {
							data.district = data.municipality.district.id
						}

						data.municipality = data.municipality.id

					}

					//if (!data.district) data.district = { value: '' }

					if (data.gpsCoordinates) data.gpsCoordinates = data.gpsCoordinates.latitude + ',' + data.gpsCoordinates.longitude
					else data.gpsCoordinates = ''
					
					return data
				}
			})

		},

		* fetchDistrictsAndMunicipalities () {
			const {
				setDistricts,
				setMunicipalities,
				showToaster
			} = this.actions
			const { t } = this.props

			try {
				const params = { limit: 'max' }
				const { results } = yield call(
					API.Municipalities.get,
					{ params }
				)
  
				const districts = []
				const map = new Map()
				let bulkedDistricts = results.map((result) => result.district)
  
				for (const item of bulkedDistricts) {
					if (!map.has(item.id)) {
						map.set(item.id, true)
						districts.push({
							id: item.id,
							name: item.name
						})
					}
				}
				
				yield put(setDistricts(districts))
				yield put(setMunicipalities(sortedAlphabetically(
					results,
					'name'
				)))
      
			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}
		},

		* getDataToPrefillFields () {
			const {
				changeForm,
				showToaster
			} = this.actions

			const { t, form } = this.props
			
			const postalCodeValid = form.postalCode.valid
			const input = form.postalCode.value

			if (postalCodeValid) {
				try {
					const params = { code: input }
					const { results } = yield call(
						API.Municipalities.byPostalCode,
						{ params }
					) 
          
					// Set the district, municipality and locality
					const district = !!results.length &&
            results[0].municipality.district.id
					const municipality = !!results.length &&
            results[0].municipality.id
					const locality = !!results.length &&
            results[0].localities[0].name

					if (district) yield put(changeForm({ district }))
					if (municipality) yield put(changeForm({ municipality }))
					if (locality) yield put(changeForm({ locality }))

				} catch (er) {
					console.log(er)
					yield put(showToaster(
						'error',
						t('common.error'),
						t('error.error_fetch')
					))
				}
			}
		},
	}
})
