import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { 
	Icon,
	Input as SemanticInput
} from 'semantic-ui-react'

import DeleteButton from 'components/DeleteButton'
import Button from 'components/Button'
import FormField from 'components/FormField'

class OtherEquipments extends Component {
	render () {
		const { t, form, actions } = this.props
		const { addEquipment, removeEquipment, changeEquipment } = actions

		return (
			<Wrapper>
				{form.equipmentName.value.map((c, i) => (
					<Container key={`other-equipment-${i}`}>
						<FormField
							name={'equipment_name'}
							label={t('form.equipment_name')}
							error={c.name.dirty && !c.name.valid}
							control={Input}
							value={c.name.value || ''}
							onChange={(e, { value }) =>
								changeEquipment(i, { name: value })
							}
						/>
          
						{i > 0 && (
							<div style={{
								position: 'relative',
								width: 32,
								height: 32,
								marginLeft: 24
							}}
							>
								<RemoveContact
									onClick={() => removeEquipment(i)}
								/>
							</div>
						)}
					</Container>
				))}
				<AddMoreContainer>
					<AddMore
						secondary
						floated={'right'}
						onClick={() => addEquipment()}
					>
						<IconAddMore name={'plus'} />
						{t('common.buttons_add')}
					</AddMore>
				</AddMoreContainer>
			</Wrapper>
		)
	}
}

export default withTranslation()(OtherEquipments)

const Wrapper = styled.div`
  margin-top: 24px;

  button {
    margin: 0 0 0 8px !important;
  }
` 

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`

const Input = styled(SemanticInput)`
  width: 443px;
`

const RemoveContact = styled(DeleteButton)`
  &&&{
    position: absolute;
  }

  &:focus {
    outline: none;
  }
`

const AddMore = styled(Button)`
  margin-right: 13px !important;
`

const IconAddMore = styled(Icon)`
  color: #616161 !important;
`

const AddMoreContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
` 