import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const createHelpers = (width, height, css) => {
// somehow sizes is ending up in markup, even if it is not a valid svg attribute
// until we have a better solution, just render it empty, instead to '[Object object]'
	const sanitizeSizes = sizes =>
		Object.defineProperty(sizes, 'toString', {
			value: () => '',
			enumerable: false
		})

	const getDimensions = (size, sizes) => {
		if (
			size &&
typeof size.width === 'number' &&
typeof size.height === 'number'
		) {
			return size
		}

		return size && sizes[size] ? sizes[size] : { width, height }
	}

	const getCss = (size, sizes, fillColor, fillColorRule, noStyles) => {
		if (noStyles) {
			return ''
		}

		const dimensions = getDimensions(size, sizes)
		const fillRule =
fillColor && fillColorRule
	? `${fillColorRule}{ fill: ${fillColor}; }`
	: ''

		return css`
width: ${dimensions.width}px;
height: ${dimensions.height}px;
${fillRule}
`
	}

	const propsToCss = ({ size, sizes, fillColor, fillColorRule, noStyles }) =>
		getCss(size, sizes, fillColor, fillColorRule, noStyles)

	return {
		getCss,
		getDimensions,
		propsToCss,
		sanitizeSizes
	}
}

const width = '24'
const height = '24'
const viewBox = '0 0 24 24'

const { getDimensions, getCss, propsToCss, sanitizeSizes } = createHelpers(
	width,
	height,
	css
)

const sizes = sanitizeSizes({})

const Image = styled.svg`
${propsToCss}
`

const children = (
	<Fragment>
		<defs key="key-0">
			<path
				id="s-b1da5811fd-a"
				// eslint-disable-next-line max-len
				d="M4 4h6v6H4V4m16 0v6h-6V4h6m-6 11h2v-2h-2v-2h2v2h2v-2h2v2h-2v2h2v3h-2v2h-2v-2h-3v2h-2v-4h3v-1m2 0v3h2v-3h-2M4 20v-6h6v6H4M6 6v2h2V6H6m10 0v2h2V6h-2M6 16v2h2v-2H6m-2-5h2v2H4v-2m5 0h4v4h-2v-2H9v-2m2-5h2v4h-2V6M2 2v4H0V2a2 2 0 012-2h4v2H2m20-2a2 2 0 012 2v4h-2V2h-4V0h4M2 18v4h4v2H2a2 2 0 01-2-2v-4h2m20 4v-4h2v4a2 2 0 01-2 2h-4v-2h4z"
			/>
		</defs>
		<g fill="none" fillRule="evenodd" key="key-1">
			<mask id="s-b1da5811fd-b" fill="#fff">
				<use xlinkHref="#s-b1da5811fd-a" />
			</mask>
			<use fill="#000" fillRule="nonzero" xlinkHref="#s-b1da5811fd-a" />
			<g fill="#000" mask="url(#s-b1da5811fd-b)">
				<path d="M0 0h24v24H0z" />
			</g>
		</g>
	</Fragment>
)

const defaultProps = {
	children,
	viewBox,
	fillColor: null,
	fillColorRule: '&&& path, &&& use, &&& g',
	sizes,
	size: null
}

Image.propTypes /* remove-proptypes */ = {
	fillColor: PropTypes.string,
	fillColorRule: PropTypes.string,
	viewBox: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	size: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			height: PropTypes.number.isRequired,
			width: PropTypes.number.isRequired
		})
	]),
	sizes: PropTypes.shape({
		height: PropTypes.number,
		width: PropTypes.number
	})
}

export default Object.assign(Image, {
	getDimensions,
	getCss,
	defaultProps,
	displayName: 'IcQrcode'
})
