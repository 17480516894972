import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import _, { cloneDeep } from 'lodash'

import API from 'api'
import * as Check from 'validations'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import ServiceAreaLogic from 'screens/ServiceArea/logic'
import ServiceAreaRequestLogic from 'screens/ServiceAreaRequest/logic'

import { DEFAULT_VALUES, VALIDATIONS } from './utils'
import {
	mapAPIFormStateToForm,
	getErrorMessagesAndScroll,
	scrollToFirstFormError
} from 'utils'

export default kea({
	path: () => ['scenes', 'containers', 'SAR_Documents'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			ServiceAreaLogic, [
				'serviceAreaProcessId'
			],
			ServiceAreaRequestLogic, [
				'current'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			ServiceAreaRequestLogic, [
				'setStatus',
				'setCurrent'
			]
		]
	},

	actions: () => ({
		patchForm: action => action,
		fetchForm: () => true,
		setForm: form => ({ form }),
		changeForm: field => ({ field }),

		unsetDirty: () => true,

		error: errors => ({ errors }),
		loading: () => true,
		loaded: () => true,
		reset: () => true
	}),

	reducers: ({ actions }) => ({

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.setForm]: (_, payload) => payload.form,
			[actions.changeForm]: (state, payload) =>
				Check.setAndCheckValidation(state, payload, VALIDATIONS),
			[actions.reset]: () => cloneDeep(DEFAULT_VALUES)
		}],

		dirty: [false, Proptypes.bool, {
			[actions.changeForm]: () => true,
			[actions.reset]: () => false,
			[actions.unsetDirty]: () => false,
		}],

		loading: [true, Proptypes.bool, {
			[actions.patchForm]: () => true,
			[actions.loading]: () => true,
			[actions.setForm]: () => false,
			[actions.loaded]: () => false, 
			[actions.error]: () => false,
			[actions.reset]: () => false
		}],

		errors: [[], Proptypes.array, {
			[actions.error]: (state, payload) => payload.errors,
			[actions.patchForm]: () => [],
			[actions.reset]: () => []
		}]

	}),

	start: function * () {
		const { fetchForm } = this.actions
		yield put(fetchForm())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.patchForm]: workers.patchForm,
		[actions.fetchForm]: workers.fetchForm,
	}),

	workers: {

		* fetchForm () { 
			const { setForm, showToaster, loaded } = this.actions
			const { t } = this.props
			const form = yield this.get('form')
			const id = yield this.get('serviceAreaProcessId')

			try {
				let data = yield call(API.Areas.steps.getDocument, id)

				if (typeof data.hasFacilitiesRegulationDocument !== 'boolean') {
					data.hasFacilitiesRegulationDocument = false
				}

				// eslint-disable-next-line max-len
				if (typeof data.hasCityCouncilPermissionDocument !== 'boolean') {
					data.hasCityCouncilPermissionDocument = false
				}

				// eslint-disable-next-line max-len
				if (typeof data.hasMotorhomeServiceAreaBlueprint !== 'boolean') {
					data.hasMotorhomeServiceAreaBlueprint = false
				}

				if (typeof data.hasPriceMenu !== 'boolean') {
					data.hasPriceMenu = false
				}

				const newForm = yield call(
					mapAPIFormStateToForm,
					data,
					_.cloneDeep(form)
				)
				yield put(setForm(newForm))

			} catch (err) {
				yield put(loaded())
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}

		},

		* patchForm ({ payload }) {
			const {
				showToaster,
				setStatus,
				loaded,
				error,
				setCurrent,
				setForm,
				unsetDirty,
			} = this.actions

			const { t } = this.props
			const form = yield this.get('form')
			let params = _.mapValues(form, ({ value }) => value)
      
			if (params.facilitiesRegulationDocument) {
				params.facilitiesRegulationDocument =
          params.facilitiesRegulationDocument.id
			}    

			if (params.cityCouncilPermissionDocument) {
				params.cityCouncilPermissionDocument =
          params.cityCouncilPermissionDocument.id
			}    

			if (params.motorhomeServiceAreaBlueprint) {
				params.motorhomeServiceAreaBlueprint =
          params.motorhomeServiceAreaBlueprint.id
			}  

			if (params.priceMenu) {
				params.priceMenu = params.priceMenu.id
			}  

			if (params.otherDocument) {
				params.otherDocument = params.otherDocument.id
			}  

			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			const dirty = yield this.get('dirty')
			const current = yield this.get('current')

			const validation = Check.checkValidation(form, VALIDATIONS)
			
			if (dirty && validation.invalid) {
				// try to scroll to first form field error
				scrollToFirstFormError(validation.form)
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				// try to scroll to first form field error
				scrollToFirstFormError(validation.form)
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}
			
			if (!dirty) {
				yield put(loaded())
				
				switch (payload) {
					case 'next':
						yield put(setCurrent(current + 1))
						break
					case 'previous':
						yield put(setCurrent(current - 1))
						break
					default:
						break
				}
        
				return
			} 

			try {
				const response = yield call(
					API.Areas.steps.updateDocument,
					serviceAreaProcessId,
					params
				)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.success_save_data')
				))
				yield put(setStatus('documents', response.status))
				yield put(loaded())
				yield put(unsetDirty())
				
				switch (payload) {
					case 'next':
						yield put(setCurrent(current + 1))
						break
					case 'previous':
						yield put(setCurrent(current - 1))
						break
					default:
						break
				}

			} catch (err) {
				if (err.response) {
					const errors = getErrorMessagesAndScroll(
						err.response.data,
						form
					)
					yield put(error(errors))
				}

				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}

		},

	}

})
