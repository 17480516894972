/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'

import { Segment, Transition } from 'semantic-ui-react'

export default (props) => (
	<StyledSegment {...props}>
		<TransitionWrapper>
			<Transition
				visible={!props.loading}
				animation='fade'
				duration={1150}
				mountOnShow={false}
			>
				{props.children}
			</Transition>
		</TransitionWrapper>
	</StyledSegment>
)

const StyledSegment = styled(Segment)`
  &&&& {
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    padding: 56px;
  }
`

const TransitionWrapper = styled.div`
  .hidden.transition {
    display: block !important;
  }
`
