/**
 * Segment Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import styled, { withTheme } from 'styled-components'
import { Segment as sg } from 'semantic-ui-react'

const Segment = styled(sg)`
  &&&& {
    width: 100%;
    border-color: transparent;
    background-color: ${({ theme }) => theme.segmentBackground};
    box-shadow: ${({ theme }) => theme.segmentShadow};
    border-radius: 4px;
    color: #000000;
    font-family: "Open Sans";
    font-size: 20px;
    //border: 1px solid rgba(196,196,196,0.5);
    border-color: transparent;

    ${({ nopadding }) => nopadding && `
      padding: 0;
    `}

    ${({ bordered }) => bordered && `
      border-radius: 4px;
    `}

  }
`

export default withTheme(Segment)
