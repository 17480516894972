import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'
import { DatesRangeInput } from 'semantic-ui-calendar-react'
import { Loader, Dimmer as SegmentDimmer, Icon } from 'semantic-ui-react'

import StatusLabel from 'components/BookingStatus'
import Table from 'components/Table'
import SearchInput from 'components/SearchInput'

import Logic from './logic'

class BookMngListView extends Component {
	state = {
		dateRange: ''
	}
	
	componentDidMount () {
		const { visibleTime } = this.props
		const { visibleTimeStart, visibleTimeEnd } = visibleTime
		
		const start = moment(visibleTimeStart).format('DD-MM-YYYY')
		const end = moment(visibleTimeEnd).format('DD-MM-YYYY')
		
		this.setState({
			dateRange: `${start} - ${end}`
		})
	}

	handleChange = (event, {name, value}) => {
		if (Object.prototype.hasOwnProperty.call(this.state, name)) {
			this.setState({ [name]: value })
		}
		this.callAPI(value)
	}
	
	callAPI = (dateRange, params) => {
		const { getReservations } = this.actions
		const [ startDate, endDate ] = dateRange.split(' - ')
		if (startDate && endDate) {
			getReservations({
				//or_has_check_in: moment(startDate, 'DD-MM-YYYY')
				//	.format('YYYY-MM-DD'),
				//or_has_check_out: moment(endDate, 'DD-MM-YYYY')
				//	.format('YYYY-MM-DD'),
				check_in__gte: moment(startDate, 'DD-MM-YYYY')
					.format('YYYY-MM-DD'),
				check_out__lte: moment(endDate, 'DD-MM-YYYY')
					.format('YYYY-MM-DD'),
				//date__gte: moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
				//date__lte: moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
				...params
			})
		}
	}

	render () {
		const {
			t,
			reservations,
			loading
		} = this.props
		
		const { getReservations, navigate } = this.actions

		const { dateRange } = this.state
		return (
			<Wrapper>
				<FiltersWrapper>
					<SearchInput
						getReservations={getReservations}
						onSubmit={(result) => this.callAPI(dateRange, result)}
					/>

					<DatesRangeInput
						name={'dateRange'}
						placeholder="De - a"
						value={dateRange}
						iconPosition="left"
						onChange={this.handleChange}
					/>
				</FiltersWrapper>
				{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
				}
				{!loading &&
				<Table.Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								{t('booking_management.name')}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t('booking_management.license_plate')}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t('booking_management.check_in')}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t('booking_management.check_out')}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t('booking_management.spot')}
							</Table.HeaderCell>
							<Table.HeaderCell>
								{t('booking_management.status')}
							</Table.HeaderCell>
							<Table.HeaderCell>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{reservations.map(reservation => (
							<Table.Row key={reservation.id}>
								<Table.Cell>
									{reservation.title}
								</Table.Cell>
								<Table.Cell>
									{reservation.tip}
								</Table.Cell>
								<Table.Cell>
									{moment(reservation.start_time)
										.format('DD-MM-YYYY')}
								</Table.Cell>
								<Table.Cell>
									{moment(reservation.end_time)
										.format('DD-MM-YYYY')}
								</Table.Cell>
								<Table.Cell>
									{reservation.spot}
								</Table.Cell>
								<Table.Cell>
									<StatusLabel>
										{t(`booking_details.${reservation.status}`)}
									</StatusLabel>
								</Table.Cell>
								<Table.Cell>
									<Arrow onClick={() => navigate(`/gestao-reservas/${reservation.id}`)}>
										<Icon name={'angle right'} />
									</Arrow>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Table>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(BookMngListView))

const Wrapper = styled.div``

const FiltersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;

	.field {
		> div {
		  min-width: 215px;
		}
	}
`

const Dimmer = styled(SegmentDimmer)`
	&&&& {
		background-color: transparent;
		height: 40vh;
	}
`

const Arrow = styled.div`
  height: 32px;
  width: 32px;
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF;
	box-shadow: 0 4px 2px 0 rgba(97,97,97,0.2);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	
	> i {
		font-size: 16px;
		margin: 0;
	}
`