import * as React from 'react'

const IcBar = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-bar</title>
			<g id="icons/servicos/ic-bar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M17.5353437,0.938484375 C15.1057187,0.938484375 13.0887813,2.73839063 12.74725,5.07478125 L6.86153125,5.07478125 L2.0824375,0 L1.05882813,0.9639375 L4.93009375,5.07478125 L1,5.07478125 L9.34942188,14.4315938 L9.34942188,22.5939844 L5.43184375,22.5939844 L5.43184375,24 L14.8053437,24 L14.8053437,22.5939844 L10.7554844,22.5939844 L10.7554844,14.4315938 L14.872375,9.81801563 C15.6582812,10.3365469 16.5818125,10.6170938 17.5353437,10.6170938 C20.20375,10.6170938 22.374625,8.44617188 22.374625,5.7778125 C22.374625,3.10945313 20.2037031,0.938484375 17.5353437,0.938484375 Z M14.0838437,8.58984375 L6.02101562,8.58984375 L4.13903125,6.48079688 L15.9657812,6.48079688 L14.0838437,8.58984375 Z" id="icon" fill="#000000"/>
			</g>
		</svg>
	)

export default IcBar
