import React from 'react'
import Proptypes from 'prop-types'

export const OthersFile = ({ width, height, fill, ...props}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<defs>
			<path
				d="M20.8 5.25H12L9.8 3H3.2C1.99 3 1 4.013 1 5.25v13.5C1 19.988 1.99 21 3.2 21h17.6c1.21 0 2.2-1.012 2.2-2.25V7.5c0-1.237-.99-2.25-2.2-2.25M18.6 12h-2.2v2.25h2.2v2.25h-2.2v2.25h-2.2V16.5h2.2v-2.25h-2.2V12h2.2V9.75h-2.2V7.5h2.2v2.25h2.2V12z"
				id="prefix__a_1"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="prefix__b_1" fill="#fff">
				<use xlinkHref="#prefix__a_1" />
			</mask>
			<use fill={fill} fillRule="nonzero" xlinkHref="#prefix__a_1" />
			<g mask="url(#prefix__b_1)" fill={fill}>
				<path d="M0 0h24v24H0z" />
			</g>
		</g>
	</svg>
)

export const PDFFile = ({ width, height, fill, ...props}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<defs>
			<path
				d="M20.365 5.717l-4.582-4.582A.457.457 0 0015.458 1H5.833C4.823 1 4 1.822 4 2.833v18.334C4 22.177 4.823 23 5.833 23h12.834c1.01 0 1.833-.822 1.833-1.833V6.042a.457.457 0 00-.135-.325zm-3.99 10.866c-.41 0-1.258-.564-2.025-1.154-.957.179-2.17.464-3.146.849-.94 1.917-2.227 3.055-3.079 3.055-.661 0-1.375-.407-1.375-1.064 0-.905.967-1.363 2.43-2.056.321-.152.667-.316 1.032-.498.1-.05.206-.1.313-.147.133-.297.258-.617.374-.963.24-.721.453-1.36.635-1.915-.743-.965-1.117-1.795-1.117-2.48 0-1.55.748-1.877 1.375-1.877.825 0 1.375.312 1.375 1.834 0 .358-.185 1.03-.607 2.333.27.322.582.66.93 1.01.27.268.673.611 1.097.945.649-.109 1.145-.163 1.33-.163.595 0 1.833 0 1.833 1.083 0 .551-.239 1.208-1.375 1.208zm-6.62.373c-.69.998-1.37 1.46-1.63 1.46a.724.724 0 01-.458-.147c0-.325.969-.784 1.906-1.228l.181-.085zm6.162-1.748c.677 0 .869.067.916.167 0 .206 0 .292-.425.294-.125-.026-.413-.197-.77-.44.13-.013.225-.02.279-.02zm-3.69-1.694c.19.21.396.424.616.643.085.086.299.28.582.522-.565.123-1.166.276-1.74.458.028-.08.056-.16.083-.242l.124-.373.23-.69zm-.38-4.264c.284.003.403.054.403.917 0 .193-.129.676-.365 1.431-.36-.554-.552-1.029-.552-1.387 0-.961.298-.961.459-.961zm4.07-6.685l3.018 3.018h-2.102a.918.918 0 01-.916-.916V2.565z"
				id="prefix__a_2"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="prefix__b_2" fill="#fff">
				<use xlinkHref="#prefix__a_2" />
			</mask>
			<use fill={fill} fillRule="nonzero" xlinkHref="#prefix__a_2" />
			<g mask="url(#prefix__b_2)" fill={fill}>
				<path d="M0 0h24v24H0z" />
			</g>
		</g>
	</svg>
)

export const ImageFile = ({ width, height, fill, ...props}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<defs>
			<path
				d="M20.365 5.717l-4.582-4.582A.457.457 0 0015.458 1H5.833C4.823 1 4 1.822 4 2.833v18.334C4 22.177 4.823 23 5.833 23h12.834c1.01 0 1.833-.822 1.833-1.833V6.042a.457.457 0 00-.135-.325zM14.857 12l3.572 3.571v3.572H7V17l2.143-2.143 1.428 1.429L14.857 12zm1.06-9.435l3.018 3.018h-2.102a.918.918 0 01-.916-.916V2.565zm-6.774 10.72A2.143 2.143 0 119.144 9a2.143 2.143 0 01-.001 4.287z"
				id="prefix__a_3"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="prefix__b_3" fill="#fff">
				<use xlinkHref="#prefix__a_3" />
			</mask>
			<use fill={fill} xlinkHref="#prefix__a_3" />
			<g mask="url(#prefix__b_3)" fill={fill}>
				<path d="M0 0h24v24H0z" />
			</g>
		</g>
	</svg>
)

OthersFile.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

OthersFile.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

PDFFile.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

PDFFile.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

ImageFile.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

ImageFile.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}