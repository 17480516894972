import * as Check from 'validations'

export const DEFAULT_VALUES = {
	sanitaryFacilities: {
		value: false
	},
	reception: {
		value: false
	},
	receptionType: {
		value: ''
	},
	lobby: {
		value: false
	},
	gate: {
		value: false
	},
	fencing: {
		value: false
	},
	surveillanceService: {
		value: false
	}
}

export const VALIDATIONS = {
	sanitaryFacilities: [
		Check.isRequired,
	],
	reception: [
		Check.isRequired,
	],
	receptionType: [
		Check.isRequired,
	],
	lobby: [
		Check.isRequired
	],
	gate: [
		Check.isRequired
	],
	fencing: [
		Check.isRequired
	],
	surveillanceService: [
		Check.isRequired
	]
}

export const DEFAULT_RECEPTION_TYPES = [
	{
		value: 1,
		text: 'Presencial',
		key: 'presential'
	},
	{
		value: 2,
		text: 'Automática',
		key: 'automatic'
	}
]