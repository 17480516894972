import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'
import theme from 'src/globalStyles'
import Logic from './logic'
import { downloadFileBlob } from 'utils'

import {  Icon as SemanticIcon, Segment } from 'semantic-ui-react'
/** components */
import Button from 'components/Button'
import ConfirmButton from 'containers/ConfirmButton'
import { CheckIcon } from 'components/Icons'
import StateLabel from 'components/StateLabel'

class FormNavigation extends Component {
	render () {
		const {
			t,
			steps,
			current,
			canSubmit,
			lastPatchDate,
			serviceAreaProcess,
			contacts
		} = this.props

		const {
			openModal,
			submit,
			cancelStamp,
			archive,
			unarchive,
			setCurrent,
			toggleCleanAndSafe,
			toggleHomologation
		} = this.actions
		
		return (
			<Wrapper hide={0}>
				{serviceAreaProcess && serviceAreaProcess.status !== 'draft' && (
					<>
						<Title>
							{t('sidebar_form.available_actions')}
						</Title>
						<LineActions />
						<SubTitle>
							Selo Clean & Safe
						</SubTitle>
						<Descrption>
							{t('sidebar_form.description_clean_safe')}
						</Descrption>
						<Bottom>            
							{/* eslint-disable-next-line max-len */}
							{serviceAreaProcess.status !== 'disabled' &&
							!serviceAreaProcess.hasCleanAndSafeStamp &&
							submit && (
								<SubmitButton
									onClick={toggleCleanAndSafe}
									style={{ margin: '12px 0 16px 0' }}
									disabled={!canSubmit}
									primary
								>
									{t('form.stamp')}
								</SubmitButton>
							)}
						</Bottom>

						{serviceAreaProcess && 
						(serviceAreaProcess.status === 'registered'
						|| serviceAreaProcess.status === 'homologation requested'
						|| serviceAreaProcess.status === 'homologated') &&   
						serviceAreaProcess.hasCleanAndSafeStamp &&
							<ConfirmButton
								secondary
								label={t('form.cancel_stamp')}
								icon={<Icon color='red' name='remove' />}
								content={t('form.cancel_stamp_info')}
								style={{ margin: '12px 0 16px 0' }}
								onClick={cancelStamp}
							/>            
						}
						<LineActions />
						{serviceAreaProcess && (
							(serviceAreaProcess.status === 'homologated' && serviceAreaProcess.homologationCertificate && submit)
							|| serviceAreaProcess.status === 'homologation requested') &&
							<SubTitle>
								{t('sidebar_form.homologation_service_area')}
							</SubTitle>
						}
						{serviceAreaProcess && 
						serviceAreaProcess.status === 'homologated' &&
						serviceAreaProcess.homologationCertificate &&
						submit && (
							<>
								<Descrption>
									{t('sidebar_form.homologation_download_info')}
								</Descrption>
								<SubmitButton
									onClick={() => downloadFileBlob(serviceAreaProcess.homologationCertificate.url, `${serviceAreaProcess.registerNumber}.pdf`)}
									style={{ margin: '12px 0 16px 0' }}
									primary
								>
									{t('sidebar_form.homologation_download')}
								</SubmitButton>
							</>
						)}
						{serviceAreaProcess && 
						serviceAreaProcess.status === 'homologation requested' &&
						submit && (
							<>
								<Descrption>
									{t('sidebar_form.homologation_description')}
								</Descrption>
								<SubmitButton
									onClick={toggleHomologation}
									style={{ margin: '12px 0 16px 0' }}
									disabled={serviceAreaProcess.status !== 'registered' || serviceAreaProcess.status === 'disabled'}
									primary
								>
									{t('sidebar_form.homologated_button')}
								</SubmitButton>
							</>
						)}
						{serviceAreaProcess && 
						serviceAreaProcess.status === 'homologation requested' &&
						<>
							<OrderDateTitle>{t('sidebar_form.order_date')}</OrderDateTitle>
							<OrderDate>
								{serviceAreaProcess.homologationRequestedAt 
									? moment(serviceAreaProcess.homologationRequestedAt).format('YYYY-MM-DD')
									: t('form.no_date')
								}
							</OrderDate>
							<StateLabel
								style={{ width: '140px', margin: '0 0 12px 0' }}
								state={serviceAreaProcess.status}
							/>
						</>  
						}
						<LineActions />
					</>
				)}
				<ContainerStep>
					{steps.map(({ id, hide, linked, status, title }, index) => {
						if (hide) return null
						
						if (linked) return (
							<Linked
								key={`step-${id}`}
								selected={id === current}
								status={status}
							>
								<Line 
									numb={index}
									selected={id === current}
									status={status}
								/>
								<Container key={`step-${id}`}
									selected={id === current}
									onClick={() => setCurrent(id)}
									status={status}
								>
									<Bullet 
										selected={id === current}
										status={status}
									/>

									{t(`form.${title}`)}
								</Container>
							</Linked>
						)

						if (!linked) return (
							<Container key={`step-${id}`}
								onClick={() => setCurrent(id)}
								selected={id === current}
								status={status}
							>
								<Bullet 
									selected={id === current}
									status={status}
								>
									{status === 'complete' && (
										<CheckIcon width={12} height={12} />
									)}

									{status === 'pending' && (
										'i'
									)}

								</Bullet>

								{t(`form.${title}`)}
							</Container>
						)
					})}
				</ContainerStep>
				<LastChanged>
					<strong>{t('form.last_changed')}: </strong>
					{moment(lastPatchDate).format('YYYY-MM-DD')}
				</LastChanged>
				{serviceAreaProcess &&
				serviceAreaProcess.status !== 'draft' &&
				serviceAreaProcess.status !== 'disabled' && (        
					<ConfirmButton
						secondary
						label={t('form.to_file')}
						icon={<Icon color='grey' name='archive' />}
						content={t('form.to_file_info')}
						style={{ 
							width: 250,
							height: 40, 
							background: '#cc0000',
							color: 'white'
						}}
						onClick={archive}
					/>            
				)}
				{serviceAreaProcess &&
				serviceAreaProcess.status === 'disabled' && (
					<SubmitButton
						style={{ marginTop: 20 }}
						onClick={unarchive}
						primary
					>
						{t('form.unarchive')}
					</SubmitButton>
				)}
				{serviceAreaProcess &&
				serviceAreaProcess.status === 'draft' && (
					<SubmitButton
						style={{ marginTop: 20 }}
						onClick={openModal}
						primary
						disabled={!canSubmit}
					>
						{t('common.submit')}
					</SubmitButton>
				)}
				{contacts &&
				<Help>
					{/* eslint-disable-next-line max-len */}
					{t('common.contact_info')} <a href={`mailto:${contacts.CONTACT_US_EMAIL}`}>{contacts.CONTACT_US_EMAIL}</a>
				</Help>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(FormNavigation))

const Wrapper = styled(Segment)`
  &&&& {
    border: none;
    box-shadow: none;
    margin-top: 72px;
		padding: 0;
		max-width: 250px;
		background-color: #FAFAFA;

    ${({ hide }) => hide && `
      display: none;
    `}
  }
`

const Linked = styled.div`
  display: flex;
  flex-direction: column;
	position: relative;
	height: 45px;
`

const Line = styled.div`
	width: 2px;
	${({ numb }) => numb && console.log('numb', numb) }
  height: calc(${({ numb }) => numb ? 45 : 0}px);
  position: absolute;
  top: 10px;
  left: 6px;

  ${({ selected }) => selected && `
    background-color: #000;
	`}
	
	${({ status }) => status  === 'pending' && `
		background-color: transparent;
		border-right: 2px dotted #000;
  `}
`

const ContainerStep = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	margin-bottom: 48px;
	align-items: center;
`

const Container = styled.div`
	width: 180px;
	border-radius: 25px;
	padding: 0 15px;
	background-color: none;
	display: flex;
	height: 45px;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
	font-size: 12.8px;
	color: #102526;
	font-family: ${theme.inputFontFamily};
	transition: background-color ease-in-out 0.3s;

	${({ selected }) => selected && `
		background-color: #eaeaea;
	`}
`

const Bullet = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 15px;
  border: .5px solid #9B9B9B;
  background-color: #fff;  

  ${({ selected }) => selected && `
    border: 4px solid #102526;
  `}

  ${({ status }) => {
		if (status === 'pending') return `
      border: none;
      background-color: #102526;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 600;
    `
    
		if (status === 'complete') return `
      border: none;
      background-color: #102526;
      display: flex;
      align-items: center;
      justify-content: center;
    `
	}}
` 

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const LastChanged = styled.p`
  margin-bottom: 16px;
  font-size: 12px;
  color: #5B5B5B;
  font-weight: 400;
	font-family: ${theme.inputFontFamily};
	text-align: center;
`

const SubmitButton = styled(Button)`
  &&&& {
    width: 100%;
    max-width: 250px;
    margin-top: 8px;
    text-align: center;
    max-height: 30px;
    padding: 18px 0;
  }
`

const Help = styled.div`
  margin-top: 40px;
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  text-align: left;
  font-family: ${theme.inputFontFamily};

  a {
    color: #000;
  }
`

const Icon = styled(SemanticIcon)`
  &&&& {
    color: white !important;
  }  
`
const Title = styled.p`
	font-family: ${theme.inputFontFamily};
	font-size: 18px;
	line-height: 18px;
	color: #000;
`
const LineActions = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgba(155,155,155,0.15);
`
const SubTitle = styled.p`
	color: #3E3E3E;
  font-family: ${theme.inputFontFamily};
  font-size: 14px;
	font-weight: 600;
	padding-top: 16px;
  line-height: 14px;
`
const Descrption = styled.p`
	color: #9B9B9B;
	font-family: ${theme.inputFontFamily};
	font-size: 12px;
	line-height: 16px;
	margin: 0;
`
const OrderDateTitle = styled.p`
color: #616161;
font-family: ${theme.inputFontFamily};
font-size: 14px;
line-height: 14px;
margin: 0;
` 
const OrderDate = styled.p`
color: #3E3E3E;
font-family: ${theme.inputFontFamily};
font-size: 14px;
font-weight: 600;
letter-spacing: 0;
line-height: 14px;
margin-top: 4px;
`