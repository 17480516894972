import axios from 'axios'
import ENDPOINTS from '../endpoints'
import { reservationInfo } from '../schemas'
import { generateUrl } from 'utils'

const getById = id => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATIONID, { id })
	return axios.get(endpoint)
		.then(res => reservationInfo(res.data))
		.catch(err => { throw err })
}

const patchById = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATIONID, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const createReservation = params => {
	return axios.post(ENDPOINTS.RESERVATIONS.RESERVATIONS, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const downloadQrCode = id => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.RESERVATIONID_QRCODE,
		{ id }
	)
	return axios.get(endpoint, { responseType: 'blob' })
		.then(res => res.data)
		.catch(err => { throw err })
}

const checkIn = id => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.CHECK_IN,
		{ id }
	)
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const checkOut = id => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.CHECK_OUT,
		{ id }
	)
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const simulateReservation = (id, params) => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.RESERVATION_SIMULATE,
		{ id }
	)
	return axios.post(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })	
}

const getRules = id  => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.RESERVATION_RULES,
		{ id }
	)
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })	
}

const getRuleById = (id, ruleid)  => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.RESERVATION_RULEID,
		{ id, ruleid }
	)
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })	
}

const patchRuleById = (id, ruleid, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_RULEID, { id, ruleid })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const createRule = (id, ruleid, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_RULES, { id })
	return axios.post(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const deleteRule = (id, ruleid) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_RULEID, { id, ruleid })
	return axios.delete(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getUnavailRules = id  => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.RESERVATION_UNAVAILRULES,
		{ id }
	)
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })	
}

const getUnavailRuleById = (id, ruleid)  => {
	const endpoint = generateUrl(
		ENDPOINTS.RESERVATIONS.RESERVATION_UNAVAILRULEID,
		{ id, ruleid }
	)
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })	
}

const patchUnavailRuleById = (id, ruleid, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_UNAVAILRULEID, { id, ruleid })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const createUnavailRule = (id, ruleid, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_UNAVAILRULES, { id })
	return axios.post(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const deleteUnavailRule = (id, ruleid) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_UNAVAILRULEID, { id, ruleid })
	return axios.delete(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

/*
	CLEANING ENDPOINTS
*/
const getAreaCleaningReservation = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.AREA_RESERVATIONS, { id })
	return axios.get(endpoint, { params })
		.then(res => res.data)
		.catch(err => { throw err })
}

const getCleaningReservationById = id => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATION_BY_ID, {id})
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const createCleaningReservation = params => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATIONS)
	return axios.post(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateCleaningReservation = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATION_BY_ID, {id})
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getAreaUnavailableDates = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.RESERVATION_UNAVAILABLEDATES, { id })
	return axios.get(endpoint, { params })
		.then(res => res.data)
		.catch(err => { throw err })
}

const getAreaUnavailCleaning = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATION_UNAVAILABILITY, { id })
	return axios.get(endpoint, { params })
		.then(res => res.data)
		.catch(err => { throw err })
}

const getAreaAvailCleaningSchedule = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATION_AVAILABILITY_SCHEDULE, { id })
	return axios.get(endpoint, { params })
		.then(res => res.data)
		.catch(err => { throw err })
}

const simulateAreaCleaningReservation = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATION_SIMULATION, { id })
	return axios.post(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const cancelAreaCleaningReservation = id => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.CANCEL, { id })
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const downloadQrCodeCleaning = id => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATIONS_DOWN_QRCODE, { id })
	return axios.get(endpoint, { responseType: 'blob' })
		.then(res => res.data)
		.catch(err => { throw err })
}

const generateQrCodeCleaning = id => {
	const endpoint = generateUrl(ENDPOINTS.RESERVATIONS.CLEANING.RESERVATIONS_NEW_QRCODE, { id })
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

export default {
	getById,
	patchById,
	downloadQrCode,
	createReservation,
	simulateReservation,
	getRules,
	getRuleById,
	patchRuleById,
	createRule,
	deleteRule,
	getUnavailRules,
	getUnavailRuleById,
	patchUnavailRuleById,
	createUnavailRule,
	deleteUnavailRule,
	getAreaUnavailableDates,
	// CLEANING
	getAreaCleaningReservation,
	getCleaningReservationById,
	getAreaUnavailCleaning,
	getAreaAvailCleaningSchedule,
	createCleaningReservation,
	updateCleaningReservation,
	simulateAreaCleaningReservation,
	cancelAreaCleaningReservation,
	downloadQrCodeCleaning,
	generateQrCodeCleaning,
	checkIn,
	checkOut
}