import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	Grid,
	Input,
	Dropdown,
	Loader,
	Dimmer
} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import moment from 'moment'

import Segment from 'components/Segment'
import FormError from 'components/FormError'
import SegmentTitle from 'components/SegmentTitle'

import { accompanyingGuestsNumberOptions, findNationalityObject } from './utils'
import Logic from './logic'

class UsageDetailsGuestInfo extends Component {

	render () {
		const {
			t,
			form,
			nationalities,
			nationalitiesOptions,
			isFetching,
			loading
		} = this.props
		
		const { changeEditForm, changeForm } = this.actions

		const {
			guestName,
			guestBirthdate,
			guestNationality,
			guestEmail,
			guestMobile,
			guestIdentityDocument,
			accompanyingGuestsNumber,
			guestIdentityDocumentType,
			guestNif
		} = form
			
		const docTypeOptions = [{
			key: 'bi',
			text: t('booking_details.cc'),
			value: 'bi'
		},
		{
			key: 'passport',
			text: t('booking_details.passport'),
			value: 'passport'
		},
		{
			key: 'other',
			text: t('booking_details.other'),
			value: 'other'
		}]
		
		const docTypeValue = docTypeOptions.find(type =>
			type.key === guestIdentityDocumentType.value
		)
		
		return (
			<Wrapper>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.guest_info')}
				</SegmentTitle>
				{loading && 
					<Dimmer inverted active>
						<Loader inverted/>
					</Dimmer>
				}
				{!loading &&
				<Grid>
					<Grid.Row>
						<Grid.Column computer={8} tablet={16}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.full_name')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guestName.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												guestName: !guestName.edit
											})
									}}
									value={guestName.value}
									disabled={!guestName.edit}
									onChange={(e, {value}) => changeForm({
										guestName: value
									})}
								/>
								{!guestName.valid &&
									<FormError>{guestName.message}</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={4} tablet={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.birthdate')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guestBirthdate.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												// eslint-disable-next-line max-len
												guestBirthdate: !guestBirthdate.edit
											})
									}}
									// eslint-disable-next-line max-len
									value={moment(guestBirthdate.value, 'YYYY-MM-DD').format('DD-MM-YYYY')}
									disabled={!guestBirthdate.edit}
								/>
								}
								{guestBirthdate.edit &&
								<DateInput
									style={{ width: '100%' }}
									name={'guest1birthdate'}
									placeholder={'DD-MM-YYYY'}
									animation='none'
									// eslint-disable-next-line max-len
									value={moment(guestBirthdate.value, 'YYYY-MM-DD').format('DD-MM-YYYY')}
									iconPosition={'left'}
									maxDate={moment().subtract(18, 'Y')}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guestBirthdate: moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
									})}
								/>
								}
								{!guestBirthdate.valid &&
								<FormError>
									{guestBirthdate.message}
								</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={4} tablet={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.nationality')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guestNationality.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
												// eslint-disable-next-line max-len
												guestNationality: !guestNationality.edit
											})
										}
									}}
									// eslint-disable-next-line max-len
									value={guestNationality.value.designation || ''}
									disabled={!guestNationality.edit}
								/>
								}
								{guestNationality.edit &&
								<Dropdown
									style={{ width: '100%' }}
									selection
									search
									options={nationalitiesOptions}
									value={guestNationality.value.id}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guestNationality: findNationalityObject(
											value, nationalities
										)})}
									disabled={isFetching}
									loading={isFetching}
								/>
								}
								{!guestNationality.valid &&
									<FormError>
										{guestNationality.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.email')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guestEmail.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guestEmail: !guestEmail.edit
											})
									}}
									value={guestEmail.value}
									disabled={!guestEmail.edit}
									onChange={(e, {value}) => changeForm({
										guestEmail: value
									})}
								/>
								{!guestEmail.valid &&
									<FormError>
										{guestEmail.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>						
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.mobile')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guestMobile.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guestMobile: !guestMobile.edit
											})
									}}
									value={guestMobile.value}
									disabled={!guestMobile.edit}
									onChange={(e, {value}) => 
										changeForm({ guestMobile: value	})
									}
								/>
								{!guestMobile.valid &&
									<FormError>
										{guestMobile.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.doc_type')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!guestIdentityDocumentType.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
											// eslint-disable-next-line max-len
												guestIdentityDocumentType: !guestIdentityDocumentType.edit
											})
										}}}
									// eslint-disable-next-line max-len
									value={(docTypeValue && docTypeValue.text)|| ''}
									disabled={!guestIdentityDocumentType.edit}
								/>
								}
								{guestIdentityDocumentType.edit &&
								<Dropdown
									style={{ width: '100%' }}
									selection
									options={docTypeOptions}
									value={guestIdentityDocumentType.value}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										guestIdentityDocumentType: value
									})}
								/>
								}
								{!guestIdentityDocumentType.valid &&
									<FormError>
										{guestIdentityDocumentType.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{/* eslint-disable-next-line max-len */}
									{`${t('booking_details.doc_number')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guestIdentityDocument.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guestIdentityDocument: !guestIdentityDocument.edit
											})
									}}
									value={guestIdentityDocument.value}
									disabled={!guestIdentityDocument.edit}
									onChange={(e, {value}) => changeForm({
										guestIdentityDocument: value
									})}
								/>
								{!guestIdentityDocument.valid &&
									<FormError>
										{guestIdentityDocument.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>						
					</Grid.Row>
					<Grid.Row>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.nif')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<Input
									style={{ width: '100%' }}
									icon={!guestNif.edit && {
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
											// eslint-disable-next-line max-len
												guestNif: !guestNif.edit
											})
									}}
									value={guestNif.value}
									disabled={!guestNif.edit}
									onChange={(e, {value}) => changeForm({
										guestNif: value
									})}
								/>
								{!guestNif.valid &&
									<FormError>{guestNif.message}</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column computer={8}>
							<Grid.Row>
								<InputLabel>
									{t('booking_details.accompanying_guests_number')}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!accompanyingGuestsNumber.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () => {
											changeEditForm({
												// eslint-disable-next-line max-len
												accompanyingGuestsNumber: !accompanyingGuestsNumber.edit
											})
										}
									}}
									// eslint-disable-next-line max-len
									value={accompanyingGuestsNumber.value || ''}
									disabled={!accompanyingGuestsNumber.edit}
								/>
								}
								{accompanyingGuestsNumber.edit &&
								<Dropdown
									style={{ width: '100%' }}
									selection
									search
									options={accompanyingGuestsNumberOptions}
									value={accompanyingGuestsNumber.value}
									onChange={(e, { value }) => changeForm({
										// eslint-disable-next-line max-len
										accompanyingGuestsNumber: value})}
									disabled={isFetching}
									loading={isFetching}
								/>
								}
								{!accompanyingGuestsNumber.valid &&
									<FormError>
										{accompanyingGuestsNumber.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(UsageDetailsGuestInfo))

const Wrapper = styled(Segment)`
	padding: 32px 40px !important;
`

const InputLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
	line-height: 14px;
	padding: 8px 0;
`