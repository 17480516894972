// @flow

import { kea } from 'kea'
import { put } from 'redux-saga/effects'
import moment from 'moment'

/** Logic */
import UsageReservationLogic from 'screens/UsageReservation/logic'

export default kea({
	path: () => ['scenes', 'containers', 'BookOvernightFilters'],

	connect: {
		actions: [
			UsageReservationLogic, [
				'setVisibleTime'
			],
		]
	},
	
	start: function* () {
		const { timeFilter } = this.props
		const { setVisibleTime } = this.actions
		
		if (timeFilter === 'week') {
			yield put(setVisibleTime({
				visibleTimeStart: moment().startOf('week').valueOf(),
				visibleTimeEnd: moment().endOf('week').valueOf()
			}))
		} else {
			yield put(setVisibleTime({
				visibleTimeStart: moment().startOf('month').valueOf(),
				visibleTimeEnd: moment().endOf('month').valueOf()
			}))
		}
	}
})
