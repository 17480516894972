const DEFAULT_VALUES = {
	images: {
		value: []
	}
}

const VALIDATIONS = {
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}