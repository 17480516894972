import axios from 'axios'
import ENDPOINTS from '../endpoints'
import { generateUrl } from 'utils'

const get = params => axios.get(ENDPOINTS.MYAREAS, params)
	.then(res => res.data)
	.catch(err => { throw err })

const getById = id => axios.get(ENDPOINTS.AREAS + `${id}/`) 
	.then(res => res.data)
	.catch(err => { throw err })

const create = () => axios.post(ENDPOINTS.AREAS)
	.then(res => res.data)
	.catch(err => { throw err })

const getSummary = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.SUMMARY, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateSummary = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.SUMMARY, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getDatasheet = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.DATASHEET, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateDatasheet = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.DATASHEET, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getContatcs = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.CONTACTS, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateContatcs = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.CONTACTS, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getDocument = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.DOCUMENTS, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateDocument = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.DOCUMENTS, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getPhotos = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.PHOTOS, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updatePhotos = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.PHOTOS, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getFacilities = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.INSTALLATION, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateFacilities = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.INSTALLATION, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getServiceStation = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.SERVICEAREA, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateServiceStation = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.SERVICEAREA, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getEquipments = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.EQUIPMENTS, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateEquipments = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.EQUIPMENTS, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getOtherEquipments = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.OTHEREQUIPMENTS, { id })
	return axios.get(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateOtherEquipments = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.OTHEREQUIPMENTS, { id })
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getCleaningReservationsRules = (id, params) => {
	const endpoint = 
	generateUrl(ENDPOINTS.AREAS_STEPS.CLEANRESERVATIONRULES, { 
		id 
	})
	return axios.get(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const updateCleaningReservationsRules = (id, params) => {
	const endpoint = 
	generateUrl(ENDPOINTS.AREAS_STEPS.CLEANRESERVATIONRULES, { 
		id 
	})
	return axios.patch(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const submit = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.SUBMIT, { id })
	return axios.post(endpoint, params)
		.then(res => res.data)
		.catch(err => { throw err })
}

const cancel = (id) => {
	const endpoint = generateUrl(ENDPOINTS.CANCEL_STAMP, { id })
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const archive = (id) => {
	const endpoint = generateUrl(ENDPOINTS.ARCHIVE, { id })
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const unarchive = (id) => {
	const endpoint = generateUrl(ENDPOINTS.UNARQUIVE, { id })
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

const getSpots = id => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.SPOTS, { id })
	return axios.get(endpoint, {
		params: {
			limit: 100
		}
	})
		.then(res => res.data)
		.catch(err => { throw err })
}

const getReservations = (id, params) => {
	const endpoint = generateUrl(ENDPOINTS.AREAS_STEPS.RESERVATIONS, { id })
	return axios.get(endpoint, { params })
		.then(res => res.data)
		.catch(err => { throw err })
}

const requestHomologation = id => {
	const endpoint = generateUrl(ENDPOINTS.HOMOLOGATION, { id })
	return axios.post(endpoint)
		.then(res => res.data)
		.catch(err => { throw err })
}

export default {
	get,
	getById,
	create,
	steps: {
		getSummary,
		updateSummary,
		getDatasheet,
		updateDatasheet,
		getContatcs,
		updateContatcs,
		getDocument,
		updateDocument,
		getPhotos,
		updatePhotos,
		getFacilities,
		updateFacilities,
		getServiceStation,
		updateServiceStation,
		getEquipments,
		updateEquipments,
		getOtherEquipments,
		updateOtherEquipments,
		getCleaningReservationsRules,
		updateCleaningReservationsRules
	},
	submit,
	cancel,
	archive,
	unarchive,
	getSpots,
	getReservations,
	requestHomologation
}