/* eslint-disable react/display-name */
import React from 'react'
import { Message } from 'semantic-ui-react'

export default ({ list }) => (
	<Message
		visible
		error
		header='Verifique os seus dados'
		content={list && list.map((v, index) =>
			<li key={index}>{v}</li>
		)}
	/>
)
