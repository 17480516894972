import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
import API from 'api'

import {
	Form,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
//import FormField from 'components/FormField'
import ListAdder from 'components/ListAdder'

class OtherSportAndLeisure extends Component {

	componentDidMount () {

		const {fetchForm} = this.actions

		fetchForm()
	}

	valueChangeSportAndLeisure = (prop, value, index) => {

		const { changeForm, form } = this.props
		const { sportAndLeisures } = form

		const newOtherSportAndLeisures = [...sportAndLeisures.value]
		newOtherSportAndLeisures[index][prop] = value

		changeForm({sportAndLeisures: newOtherSportAndLeisures})

	}

	addSportAndLeisure = () => {

		const { form, changeForm } = this.props
		const { sportAndLeisures } = form

		const newOtherSportAndLeisure = [...sportAndLeisures.value]
		newOtherSportAndLeisure.push({name: ''})
		changeForm({
			sportAndLeisures: newOtherSportAndLeisure
		})
	}

	removeSportAndLeisure = index => {

		const { form, changeForm } = this.props
		const { sportAndLeisures } = form

		const newOtherSportAndLeisure = [...sportAndLeisures.value]
		newOtherSportAndLeisure.splice(index, 1)
		changeForm({
			sportAndLeisures: newOtherSportAndLeisure
		})
	}

	submitData = (callback) => {
		const {campsiteId, submit} = this.props
	
		submit(data => API.Campsites.steps.updateOtherSportAndLeisure(campsiteId, data), {
			resolveData: data => {
				return {leisureName: data.sportAndLeisures.map(sportandleisure => sportandleisure.name)}
			},
			callback: (data, success) => {
				callback(success)
			}
		})
	}

	render () {

		const { t, form, loading } = this.props

		const {
			sportAndLeisures
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('campsite_details.other_sport_and_leisure')}
						</SegmentTitle>

						<ListAdder
							data={sportAndLeisures.value}
							options={[{
								name: 'name',
								label: t('campsite_details.sport_and_leisure_name')
							}]}
							add={this.addSportAndLeisure}
							remove={this.removeSportAndLeisure}
							valueChange={this.valueChangeSportAndLeisure}
						/>

					</Form>
				</BoxSegment>

			</>
		)
	}
}

export default withTranslation()(Logic(OtherSportAndLeisure))
