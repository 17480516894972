import React, { Component } from 'react'
import styled from 'styled-components'
import Logic from './logic'

import { Message, Transition, Icon } from 'semantic-ui-react'

class Toaster extends Component {
	render () {
		const { title, message, type } = this.props.toast
		const { showing } = this.props
		const { close } = this.actions

		let toastProps = {}
		let icon = ''

		switch (type) {
			case 'error':
				toastProps = {
					negative: true
				}
				icon = 'warning circle'
				break
			case 'success':
				toastProps = {
					positive: true
				}
				icon = 'checkmark'
				break
			case 'alert':
				toastProps = {
					warning: true
				}
				icon = 'warning'
				break
			case 'info':
				toastProps = {
					info: true
				}
				icon = 'info'
				break
			default:
				break
		}

		return (
			<Transition visible={showing} animation='scale' duration={500}>
				<Toast {...toastProps} onClick={() => close()}>
					<MessageWrapper>
						{icon && <Icon name={icon} />}
						<Info>
							<Message.Header>{title}</Message.Header>
							<p>{message}</p>
						</Info>
					</MessageWrapper>
				</Toast>
			</Transition>
		)
	}
}

export default Logic(Toaster)

const Toast = styled(Message)`
  &&&& {
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    width: 300px;
    right: 20px;   
  }
`
const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  
  i {
    font-size: 25px;
    margin-right: 20px;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
`
