/**
 * Title Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import styled from 'styled-components'

export default styled.h1`
  color: #102526;
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  font-weight: normal;
  margin: 0;
  margin-bottom: 20px;

  ${({ bolder }) => bolder && `
    font-weight: 600;
  `}

  ${({ uppercase }) => uppercase && `
    text-transform: uppercase;
  `}

  ${({ centered }) => centered && `
    text-align: center !important;
  `}
`
