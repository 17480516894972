import Login from 'screens/Login'
import Register from 'screens/Register'
import FinalizeRegister from 'screens/FinalizeRegister'

export default {

	login: {
		key: 'login',
		name: 'login',
		path: '/entrar',
		component: Login,
		exact: true,
		crumb: [],
		showSidebar: false,
	},

	register: {
		key: 'register',
		name: 'register',
		path: '/registar',
		component: Register,
		exact: true,
		crumb: [],
		showSidebar: false,
	},

	finalize: {
		key: 'finalize_register',
		name: 'finalize_register',
		path: '/finalizar-registo',
		component: FinalizeRegister,
		exact: true,
		crumb: [],
		showSidebar: false,
	}

}
