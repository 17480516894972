/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const noEye = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<g fill={fill}>
			<path d="M12.06,9 L12.18,9 C12.6200022,9 13.0333314,9.1133322 13.42,9.34 C13.8066686,9.5666678 14.1133322,9.8733314 14.34,10.26 C14.5666678,10.6466686 14.68,11.0599978 14.68,11.5 L14.68,11.66 L12.06,9 Z M8.48,9.66 C8.1599984,10.2733364 8,10.8866636 8,11.5 C8,12.2600038 8.1866648,12.9599968 8.56,13.6 C8.9333352,14.2400032 9.4399968,14.7466648 10.08,15.12 C10.7200032,15.4933352 11.4199962,15.68 12.18,15.68 C12.7933364,15.68 13.4066636,15.5200016 14.02,15.2 L12.72,13.92 C12.5333324,13.9733336 12.3533342,14 12.18,14 C11.7399978,14 11.3266686,13.8866678 10.94,13.66 C10.5533314,13.4333322 10.2466678,13.1266686 10.02,12.74 C9.7933322,12.3533314 9.68,11.9400022 9.68,11.5 C9.68,11.3266658 9.7066664,11.1466676 9.76,10.96 L8.48,9.66 Z M3.86,5.06 L4.92,4 L19.68,18.76 L18.62,19.82 C17.3666604,18.5933272 16.4333364,17.6666698 15.82,17.04 C14.7133278,17.506669 13.5000066,17.74 12.18,17.74 C10.8066598,17.74 9.5000062,17.473336 8.26,16.94 C7.059994,16.4199974 6.0033379,15.6900047 5.09,14.75 C4.1766621,13.8099953 3.4800024,12.7266728 3,11.5 C3.6400032,9.899992 4.6799928,8.5200058 6.12,7.36 L3.86,5.06 Z M12.18,7.32 C11.6599974,7.32 11.1533358,7.4266656 10.66,7.64 L8.86,5.84 C9.8866718,5.439998 10.9933274,5.24 12.18,5.24 C13.5533402,5.24 14.8533272,5.506664 16.08,6.04 C17.280006,6.5600026 18.3333288,7.2933286 19.24,8.24 C20.1466712,9.1866714 20.8399976,10.2733272 21.32,11.5 C20.7066636,13.033341 19.7600064,14.3466612 18.48,15.44 L16.04,13.02 C16.2533344,12.5266642 16.36,12.0200026 16.36,11.5 C16.36,10.7399962 16.1733352,10.0400032 15.8,9.4 C15.4266648,8.7599968 14.9200032,8.2533352 14.28,7.88 C13.6399968,7.5066648 12.9400038,7.32 12.18,7.32 Z" />
		</g>
	</svg>
)

noEye.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

noEye.defaultProps = {
	width: 24,
	height: 24,
	fill: '#000'
}

export default noEye
