import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Dimmer, Loader } from 'semantic-ui-react'
import moment from 'moment'

import Table from 'components/Table'

import IcQrCode from 'assets/components/IcQrcode'
import Button from 'components/Button'
import ConfirmButton from 'containers/ConfirmButton'
import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import Logic from './logic'

class BookDetailsCheckInOut extends Component {
	render () {
		const { t, loading } = this.props

		const { form, reservationStatus } = this.props
		const { downloadFile, checkIn, checkOut } = this.actions

		const isCheckIn = form.checkInOutInformations.value.length === 0
							|| form.checkInOutInformations.value[form.checkInOutInformations.value.length - 1].status !== 'In'
		
		const paymentIsValid = reservationStatus === 'confirmed'

		const checkOutDateLimitIsValid = form.checkOut.value && moment(form.checkOut.value, 'DD-MM-YYYY').add(1, 'days').isAfter(moment(new Date(), 'DD-MM-YYYY'))
		const checkInDateIsValid = form.checkIn.value && moment(form.checkIn.value, 'DD-MM-YYYY').isSameOrBefore(moment(new Date(), 'DD-MM-YYYY'))
		
		const checkInOutClick = () => {

			if (!paymentIsValid) return

			if (isCheckIn) {
				if (!checkOutDateLimitIsValid || !checkInDateIsValid) return
				checkIn()
			}
			else {
				checkOut()
			}
		}

		return (
			<Segment>
				<SegmentTitle margin={'0 0 26px 0'}>
					{t('booking_details.checkin_and_checkout')}
				</SegmentTitle>
				{loading && (
					<Dimmer inverted active>
						<Loader inverted />
					</Dimmer>
				)}
				{!loading && (
					<>
						<Grid>
							<Grid.Row columns={2}>
								<Grid.Column>
									<InfoTitle>{t('booking_details.qrcode')}</InfoTitle>
								</Grid.Column>
								<Grid.Column>
									<Grid.Row>
										<Link>{t('booking_details.new_qrcode')}</Link>
									</Grid.Row>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<QrCodeWrapper>
									<Grid style={{ margin: 0 }}>
										<Grid.Row>
											<Grid.Column
												width={1}
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<IcQrCode fillColor={'#9B9B9B'} />
											</Grid.Column>
											<Grid.Column width={7}>
												<CodeTitle>{t('booking_details.qrcode')}</CodeTitle>
												<CodeDescription>
													{/* eslint-disable-next-line max-len */}
													{t('booking_details.qrcode_automatic')}
												</CodeDescription>
											</Grid.Column>
											<Grid.Column
												width={8}
												style={{
													display: 'flex',
													justifyContent: 'flex-end',
												}}
											>
												<Button secondary onClick={downloadFile}>
													<IcQrCode size={{ width: 18, height: 18 }} />
													<ButtonText>
														{/* eslint-disable-next-line max-len */}
														{t('booking_details.download_qrcode')}
													</ButtonText>
												</Button>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</QrCodeWrapper>
							</Grid.Row>
						</Grid>
						<CheckInTitle>{t('booking_details.recent_activity')}</CheckInTitle>
						<ConfirmButton
							primary
							label={!isCheckIn || !paymentIsValid ? t('booking_details.check_out') : t('booking_details.check_in')}
							content={!isCheckIn || !paymentIsValid ? t('booking_details.check_out_modal') : t('booking_details.check_in_modal')}
							disabled={!paymentIsValid || (isCheckIn && (!checkOutDateLimitIsValid || !checkInDateIsValid))}
							style={{ marginBottom: 16, float: 'right' }}
							onClick={checkInOutClick}
						/>
						<Table.Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('booking_details.checkinout_date')}</Table.HeaderCell>
									<Table.HeaderCell>{t('booking_details.checkinout_hour')}</Table.HeaderCell>
									<Table.HeaderCell>{t('booking_details.checkinout_type')}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								<>
									{form.checkInOutInformations.value.map((info, i) => (
										<Table.Row key={i}>
											<Table.Cell>
												{info.date}
											</Table.Cell>
											<Table.Cell>
												{info.hour.replace('H', ':')}
											</Table.Cell>
											<Table.Cell>
												<TypeBadge type={info.status}>Check-{info.status}</TypeBadge>
											</Table.Cell>
										</Table.Row>
									))}
									{!form.checkInOutInformations.value.length && (
										<Table.Row>
											<Table.Cell>{t('error.noResults')}</Table.Cell>
										</Table.Row>
									)}
								</>
							</Table.Body>
						</Table.Table>
					</>
				)}
			</Segment>
		)
	}
}

export default withTranslation()(withRouter(Logic(BookDetailsCheckInOut)))

const CheckInTitle = styled.div`
	margin: 50px 0 30px;
`

const TypeBadge = styled.span`
	padding: 5px 11px;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 50px;
	font-weight: bold;
	color: #fff;
	background-color: ${({type}) => type === 'In' ? '#4ada4a' : '#ff7272'};
`

const InfoTitle = styled.p`
	color: #102526;
	font-family: 'Open Sans';
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18px;
`

const Link = styled.p`
	opacity: 0.6;
	color: #102526;
	font-family: 'Open Sans';
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 12px;
	text-align: right;
	text-decoration: underline;
	cursor: pointer;
	text-transform: uppercase;
`

const QrCodeWrapper = styled.div`
	background-color: #ffffff;
	box-shadow: inset 0 1px 0 0 rgba(155, 155, 155, 0.15),
		inset 0 -1px 0 0 rgba(155, 155, 155, 0.15);
	width: 100%;
	margin: 0 20px;
`

const CodeDescription = styled.p`
	color: #9b9b9b;
	font-family: 'Open Sans';
	font-size: 12px;
	letter-spacing: 0;
	line-height: 18px;
	margin: 0;
`

const CodeTitle = styled.p`
	color: #3e3e3e;
	font-family: 'Open Sans';
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18px;
	margin: 0;
`

const ButtonText = styled.span`
	margin-left: 12px;
`
