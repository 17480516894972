/* eslint-disable react/display-name */
import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

export default ({ valid, error, fullwidth, ...props }) => (
	<Wrapper valid={valid}>
		<StyledDropdown {...props} error={error} fullwidth={fullwidth} />
	</Wrapper>
)

const Wrapper = styled.div`
  &&& {
    .ui.dropdown>.menu .active.item{
      background-color:transparent;
    }

    .ui.dropdown.error>.menu .active.item{
      background-color:transparent;
    }

    .ui.dropdown.error>.menu>.item {
      color: #000;
    }

    .ui.dropdown.error>.menu>.item:hover {
      background-color: transparent;
    }

    ${({ valid }) => valid && `
      .ui.selection.dropdown {
        border: 1px solid rgba(0, 166, 81, 0.4) !important;
      }
    `}

    .ui.selection.dropdown.error{
      background-color: #FFF;
      border-color: rgba(237, 28, 36, 0.4);
    }
  };
`

const StyledDropdown = styled(Dropdown)`
  &&&&{
    background: linear-gradient(180deg, #FFFFFF 0 %, #F9F9F9 100 %);

    ${({ fullwidth }) => fullwidth && 'width: 100% !important;'}
  };
`
