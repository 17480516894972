/**
 * InputUnity Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 */
import React, { Component } from 'react'
import styled from 'styled-components'
import { Input } from 'semantic-ui-react'
import { formatNumbersPt } from 'utils'
import FormError from 'components/FormError'

export default class InputUnity extends Component {
	render () {
		const {
			unity,
			width,
			value,
			unityWidth,
			disabled,
			error
		} = this.props

		const calcInputWidth = (width) => {
			if (!width > 0 && !width < 10) {
				return
			}

			return `${width * 10}%`
		}
		const calcUnityWidth = (width) => {
			if (!width > 0 && !width < 10) {
				return
			}

			return `${(10 - width) * 10}%`
		}

		let formatedValue = value
		if (/^[0-9]+(\.)[0-9]+$/.test(value)) {
			formatedValue = formatNumbersPt(value)
		}

		return (
			<Wrapper>
				<InputUnityWrapper>
					<InputContainer
						disabled={disabled}
						width={width && calcInputWidth(width)}
						className='input ui'
					>
						<Input
							{...this.props}
							value={formatedValue}
						/>
					</InputContainer>
					<UnityContainer
						width={width && calcUnityWidth(width)}
						unityWidth={unityWidth}
					>
						{unity}
					</UnityContainer>
				</InputUnityWrapper>
				<FormError>{error}</FormError>
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	> span {
		margin: 0;
	}
`

const InputUnityWrapper = styled.div`
  display: inline-block;
  width: 100%;
`

const InputContainer = styled.div`
  float: left;
  width: 70%!important;

  input{
    width: 100%;
    border-radius: 4px 0 0 4px!important;
      ${({ disabled }) => disabled && `
        background-color: rgba(155,155,155,0.15)!important;
        border: 1px solid #DDDDDD !important;
        opacity: 1 !important;
        color: rgba(0,0,0,0.4) !important;
      `}
  }

  .ui.disabled.input {
    opacity: 1;
  }
`

const UnityContainer = styled.div`
  position: relative;
  background-color: #9B9B9B;
  float: right;
  height: 38px !important;
  border-radius: 0 4px 4px 0;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  line-height: 38px;
  font-weight: bold;
  width: 30%;
  opacity: 1;
`
