import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import { pick, cloneDeep, mapValues } from 'lodash'
import moment from 'moment'

import API from 'api'
import { mapApiErrors } from 'api/errors'
import * as Check from 'validations'

import ToasterLogic from 'containers/Toaster/logic'
import BookingManagementLogic from 'screens/BookingManagement/logic'
import { DEFAULT_VALUES, VALIDATIONS, changeEdit, resetEdit } from './utils'
import { mapAPIFormStateToForm } from 'utils'
import { apiReservationInfo } from 'api/schemas'
import AppLogic from 'containers/App/logic'
import { handleApiErrorMessage } from '../../../utils'

export default kea({
	path: () => ['scenes', 'BookingDetails'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			],
			BookingManagementLogic, [
				'getServiceAreaList'
			]
		]
	},
	actions: () => ({
		setIsNewReservation: reservation => reservation,
		reset: () => true,
		saveForm: () => true,
		fetchForm: () => true,
		createReservation: () => true,
		setForm: form => ({ form }),
		changeArray: (field) => ({ field }),
		changeForm: field => ({ field }),
		changeEditForm: field => ({ field }),
		resetEditForm: () => true,
		setLoading: loading => loading,
		error: errors => errors,
		setPrices: prices => prices,
		simulateReservation: () => true,
		setPaymentMethods: methods => methods,
		getPaymentMethods: () => true,
		setReservationStatus: status => status,
		setPaymentInfo: paymentInfo => paymentInfo,
		setDatasheet: datasheet => datasheet 
	}),

	reducers: ({ actions }) => ({

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.setForm]: (_, payload) => payload.form,
			[actions.changeForm]: (state, payload) =>
				Check.setAndCheckValidation(
					state,
					payload,
					VALIDATIONS
				),
			[actions.changeArray]: (state, payload) => ({
				...state,
				...payload.field,
			}),
			[actions.changeEditForm]: (state, payload) =>
				changeEdit(state, payload),
			[actions.resetEditForm]: state => resetEdit(state),
			[actions.reset]: () => cloneDeep(DEFAULT_VALUES)
		}],
		
		dirty: [false, Proptypes.bool, {
			[actions.changeForm]: () => true,
			[actions.changeArray]: () => true,
			[actions.reset]: () => false
		}],

		loading: [true, Proptypes.bool, {
			[actions.setForm]: () => false,
			[actions.setLoading]: (state, payload) => payload,
			[actions.showToaster]: () => false,
			[actions.saveForm]: () => true,
			[actions.resetEditForm]: () => false,
			[actions.reset]: () => true
		}],

		errors: [[], Proptypes.array, {
			[actions.error]: (state, payload) => payload,
			[actions.saveForm]: () => [],
			[actions.createReservation]: () => [],
			[actions.reset]: () => ([])
		}],
		
		isNewReservation: [false, Proptypes.bool, {
			[actions.setIsNewReservation]: (_, payload) => payload,
			[actions.reset]: () => false
		}],
		
		prices: [{}, Proptypes.object, {
			[actions.setPrices]: (_, payload) => payload,
			[actions.changeForm]: (state, payload) => {
				if (Object.keys(payload.field)[0] !== 'paymentMethod') {
					return ({}) 
				}
				return state
			},
			[actions.changeArray]: () => ({}),
			[actions.reset]: () => ({})
		}],
		
		paymentMethods: [[], Proptypes.array, {
			[actions.setPaymentMethods]: (_, payload) => payload,
			[actions.reset]: () => ([])
		}],
		
		reservationStatus: [null, Proptypes.string, {
			[actions.setReservationStatus]: (_, payload) => payload,
			[actions.reset]: () => null
		}],
		
		isSubmitted: [false, Proptypes.bool, {
			[actions.setPrices]: () => true,
			[actions.reset]: () => false
		}],
		paymentInfo: [{}, Proptypes.object, {
			[actions.setPaymentInfo]: (state, payload) => payload,
		}],
		datasheet: [{}, Proptypes.object, {
			[actions.setDatasheet]: (state, payload) => payload,
		}]
	}),

	start: function * () {
		const {
			fetchForm,
			setLoading,
			setIsNewReservation,
			//setForm
		} = this.actions
		
		const form = yield this.get('form')
		
		const { match } = this.props
		
		const id = match.params.id
		
		if (id !== 'nova-reserva') {
			yield put(fetchForm())
		} else {
			Object
				.keys(form)
				.forEach(v => form[v].edit = true)
			
			yield put(setIsNewReservation(true))
			yield put(setLoading(false))
		}
		
	},
	
	stop: function * () {
		const { reset } = this.actions
		
		//yield put(resetEditForm())
		yield put(reset())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchForm]: workers.fetchForm,
		[actions.saveForm]: workers.saveForm,
		[actions.createReservation]: workers.createReservation,
		[actions.simulateReservation]: workers.simulateReservation,
		[actions.getPaymentMethods]: workers.getPaymentMethods
	}),

	workers: {
		* fetchForm () {
			const { setForm, showToaster, setReservationStatus, setDatasheet } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			
			if (!id) {
				return
			}

			try {
				let data = yield call(API.Reservations.getById, id)
				yield put(setReservationStatus(data.status))
				const newForm = yield call(
					mapAPIFormStateToForm,
					data,
					cloneDeep(form)
				)

				const datasheetData = yield call(
					API.Areas.steps.getDatasheet,
					data.serviceArea
				)

				yield put(setDatasheet(datasheetData))
				
				const { otherGuests } = newForm
				
				if (otherGuests.value.length > 0) {
					// eslint-disable-next-line max-len
					otherGuests.value = otherGuests.value.map(guest => ({
						name: {value: guest.name, edit: false},
						birthdate: {value: guest.birthdate, edit: false},
						nationality: {value: guest.nationality, edit: false}
					}))
				}
				
				yield put(setForm(newForm))

			} catch (err) {
				console.log(err)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}
		},
		
		* saveForm () {
			const { showToaster, resetEditForm } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			
			if (!id) {
				return
			}
			const params = mapValues(form, ({ value }) => value)
			const editedParams = {}
			
			Object.keys(form).map(prop => {
				if (form[prop].edit) editedParams[prop] = true
			})

			try {
				yield call(
					API.Reservations.patchById,
					id,
					apiReservationInfo(params, editedParams)
				)
				
				yield put(resetEditForm())
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				console.log(err)
				
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},
		
		* createReservation () {
			const {
				showToaster,
				navigate,
				error,
				setForm,
				getServiceAreaList
			} = this.actions
			const { t } = this.props
			const form = yield this.get('form')
			const prices = yield this.get('prices')
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, VALIDATIONS)
			
			if (dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}
			
			const params = mapValues(form, ({ value }) => value)
			const apiParams = apiReservationInfo(params)
			apiParams.waterPrice = prices.waterPrice
			apiParams.totalPrice = prices.totalPrice
			apiParams.spotPrice = prices.spotPrice
			apiParams.guestPrice = prices.guestPrice
			apiParams.electricityPrice = prices.electricityPrice
			
			try {
				yield call(
					API.Reservations.createReservation,
					apiParams
				)
				yield put(getServiceAreaList())
				yield put(navigate('/gestao-reservas/'))
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				console.log(err.response)
				if (err.response && err.response.data) {
					const errors = mapApiErrors(
						err.response.data,
						t
					)

					yield put(error(errors))
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},
		
		* simulateReservation () {
			const {
				showToaster,
				error,
				setForm,
				setPrices,
				getPaymentMethods,
				setReservationStatus,
			} = this.actions
			const form = yield this.get('form')
			const { t } = this.props
			
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, VALIDATIONS)
			
			if (dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}
			
			const splitDate = form.checkInOut.value.split(' - ')
			const checkIn = moment(splitDate[0], 'DD-MM-YYYY')
				.format('YYYY-MM-DD')
			const checkOut = moment(splitDate[1], 'DD-MM-YYYY')
				.format('YYYY-MM-DD')
			
			const formParams = pick(form, ['guests', 'serviceAreaIsAdapted'])
			const mapForm = mapValues(formParams, ({ value }) => value)
			const params = {...mapForm, checkIn, checkOut}
			const serviceAreaId = form.serviceArea.value
			
			try {
				const response = yield call(
					API.Reservations.simulateReservation,
					serviceAreaId,
					params
				)
				yield put(setPrices(response.prices))
				yield put(getPaymentMethods())
				yield put(error([]))
				yield put(setReservationStatus('simulated'))
			} catch (err) {
				console.log(err)
				if (err.response && err.response.data) {
					const { t } = this.props
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					yield put(error(errors))
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},
		
		* getPaymentMethods () {
			const {
				showToaster,
				error,
				setPaymentMethods,
				changeForm
			} = this.actions
			const { t } = this.props
			
			try {
				const params = { enabled: true }
				const response = yield call(
					API.Types.getPaymentMethods,
					{ params }
				)
				yield put(setPaymentMethods(response.results))
				yield put(changeForm({ paymentMethod: response.results[0].id }))
			} catch (err) {
				console.log(err)
				if (err.response && err.response.data) {
					const { t } = this.props
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					yield put(error(errors))
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}			
		}
	}
})
