// @flow

import { kea } from 'kea'
import Proptypes from 'prop-types'
import moment from 'moment'
import { put, call } from 'redux-saga/effects'
import { mapValues, values, cloneDeep } from 'lodash'

import API from 'api'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'

const DEFAULT_VISIBLE_VALUES = {
	visibleTimeStart: moment()
		.startOf('week')
		.valueOf(),
	visibleTimeEnd: moment()
		.endOf('week')
		.valueOf()
}
export default kea({
	path: () => ['scenes', 'BookingManagement'],

	connect: {
		actions: [
			AppLogic, [
				'navigate',
				'setCurrentUser'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		getReservations: params => params,
		setReservations: reservation => reservation,
		getParkingPlaces: () => true,
		setParkingPlaces: places => places,
		handleItemSelect: itemId => itemId,
		handleItemResize: (itemId, time, edge) => ({ itemId, time, edge }),
		// eslint-disable-next-line max-len
		handleItemMove: (itemId, dragTime, newGroupOrder) => ({ itemId, dragTime, newGroupOrder }),
		getServiceAreaList: () => true,
		setServiceAreas: areas => areas,
		setSelectedServiceArea: area => area,
		setVisibleTime: time => time,
		reset: () => true
	}),

	reducers: ({ actions }) => ({
		serviceAreas: [[], Proptypes.array, {
			[actions.setServiceAreas]: (_, payload) => payload,
			[actions.reset]: () => []
		}],
		
		selectedServiceArea: [null, Proptypes.number, {
			[actions.setSelectedServiceArea]: (_, payload) => payload,
			[actions.reset]: () => null 
		}],
		
		reservations: [[], Proptypes.array, {
			[actions.setReservations]: (_, payload) => payload,
			[actions.setSelectedServiceArea]: () => [],
			[actions.reset]: () => []
		}],
    
		parkingPlaces: [[], Proptypes.array, {
			[actions.setParkingPlaces]: (_, payload) => payload,
			[actions.setSelectedServiceArea]: () => [],
			[actions.reset]: () => null
		}],
    
		loading: [true, Proptypes.bool, {
			[actions.setReservations]: () => false,
			[actions.reset]: () => true
		}],
		
		visibleTime: [DEFAULT_VISIBLE_VALUES, Proptypes.object, {
			[actions.setVisibleTime]: (_, payload) => payload,
			[actions.reset]: () => null
		}]
	}),

	selectors: ({ selectors }) => ({

		serviceAreaOptions: [ () => [ selectors.serviceAreas],
			(serviceAreas) => values(mapValues(
				serviceAreas,
				area =>  { return { 
					text: area.name, value: area.id
				} }
			))
		]
	}),

	start: function * () {
		const { getServiceAreaList } = this.actions
	
		yield put(getServiceAreaList())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.handleItemResize]: workers.handleItemResize,
		[actions.handleItemMove]: workers.handleItemMove,
		[actions.handleItemSelect]: workers.handleItemSelect,
		[actions.getServiceAreaList]: workers.getServiceAreaList,
		[actions.getParkingPlaces]: workers.getParkingPlaces,
		[actions.getReservations]: workers.getReservations,
		[actions.setSelectedServiceArea]: workers.getParkingPlaces,
		[actions.setCurrentUser]: workers.getServiceAreaList
	}),

	workers: {
		* getServiceAreaList () {
			const {
				setServiceAreas,
				setSelectedServiceArea,
			} = this.actions
			
			const params = { status: 'homologated,registered' }
			
			try {
				const response = yield call(API.Areas.get, { params })
				const { results } = response
				yield put(setServiceAreas(results))
				if (results.length > 0) {
					console.log(results[0].id)
					yield put(setSelectedServiceArea(results[0].id))
				} else {
					yield put(setSelectedServiceArea(null))
				}
			} catch (e) {
				console.log(e)
			}
		},
		
		* getParkingPlaces () {
			const { setParkingPlaces, getReservations, setReservations } = this.actions
			try {
				const selectedServiceArea = yield this.get('selectedServiceArea')

				if (selectedServiceArea) {
					const response = yield call(API.Areas.getSpots, selectedServiceArea)
					const { results } = response
					
					const parkingPlace = results.map(result => { return {
						title: result.name,
						id: result.number,
						parkId: result.id,
						stackItems: true
					} })
					
					yield put(setParkingPlaces(parkingPlace))
					yield put(getReservations())

				} else {

					yield put(setParkingPlaces([]))
				}
			} catch (e) {
				yield put(setParkingPlaces([]))
				yield put(setReservations(null))
			}
		},
		
		* getReservations (actionPayload) {
			const  { payload } = actionPayload
			let params = payload
			
			if (!params) {
				const visibleTime = yield this.get('visibleTime')
				params = {
					//date__gte: moment(visibleTime.visibleTimeStart)
					//	.format('YYYY-MM-DD'),
					//date__lte: moment(visibleTime.visibleTimeEnd)
					//	.format('YYYY-MM-DD'),
					check_in__gte: moment(visibleTime.visibleTimeStart)
						.format('YYYY-MM-DD'),
					check_out__lte: moment(visibleTime.visibleTimeEnd)
						.format('YYYY-MM-DD'),
				}
			}

			const selectedServiceArea = yield this.get('selectedServiceArea')
			const { setReservations } = this.actions
			
			try {
				const response = yield call(
					API.Areas.getReservations,
					selectedServiceArea,
					params
				)
				const { results } = response
			
				const reservations = results.map(result => { return {
					id: result.id,
					group: result.spot.number,
					title: result.name,
					start_time: moment(result.checkIn, 'YYYY-MM-DD').set(
						{hour:12,minute:0,second:0,millisecond:0}
					),
					end_time:  moment(result.checkOut, 'YYYY-MM-DD').set(
						{hour:12,minute:0,second:0,millisecond:0}
					),
					tip: result.licensePlateNumber,
					itemProps: {
						className: 'reservationItem'
					},
					spot: result.spot.name
				} })
			
				yield put(setReservations(reservations))
			} catch (e) {
				console.log(e)
			}
		},
		
		* handleItemSelect (actionPayload) {
			const { payload } = actionPayload
			const { setReservations } = this.actions
			const reservations = yield this.get('reservations')
	
			const reservationSelected = reservations.map(reservation => {
				if (reservation.id === payload) {
					reservation.itemProps.className = 'selectedReservation'
				} else {
					reservation.itemProps.className = 'reservationItem'
				}
				return reservation
			})
	
			yield put(setReservations(reservationSelected))
		},
		// eslint-disable-next-line require-yield
		* handleItemResize (actionPayload) {
			const { itemId, time, edge } = actionPayload.payload || {}
			const reservations = yield this.get('reservations')
			const { setReservations, showToaster } = this.actions
			const { t } = this.props

			const noonTime = moment(time).set(
				{hour:12,minute:0,second:0,millisecond:0}
			)
			
			const beforeResizeReservations = cloneDeep(reservations)
			const resizeReservations = reservations.map(item =>
				item.id === itemId
					? Object.assign({}, item, {
						start_time: edge === 'left'
							? noonTime
							: item.start_time,
						end_time: edge === 'left' ? item.end_time : noonTime
					})
					: item
			)
			
			yield put(setReservations(resizeReservations))
			
			const movedItem = resizeReservations.find(
				item => item.id === itemId
			)
			const params = {
				checkIn: moment(movedItem.start_time).format('YYYY-MM-DD'),
				checkOut: moment(movedItem.end_time).format('YYYY-MM-DD'),
				spot: movedItem.group
			}
			
			try {
				yield call(
					API.Reservations.patchById,
					itemId,
					params
				)
				
			} catch (err) {
				console.log(err)
				yield put(setReservations(beforeResizeReservations))
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.change_booking_error')
				))
			}
		},
		
		* handleItemMove (actionPayload) {
			const {
				itemId,
				dragTime,
				newGroupOrder
			} = actionPayload.payload || {}
			const reservations = yield this.get('reservations')
			const parkingPlaces = yield this.get('parkingPlaces')
			
			const { setReservations, showToaster } = this.actions
			const { t } = this.props

			const group = parkingPlaces[newGroupOrder]
			
			const convertedTime = moment(dragTime).set(
				{ hour:12,minute:0,second:0,millisecond:0 }
			)
			const beforeMovReservations = cloneDeep(reservations)
			console.log(group)
			const movedReservations = reservations.map(item =>
				item.id === itemId
					? Object.assign({}, item, {
						start_time: convertedTime,
						end_time: convertedTime + (
							item.end_time - item.start_time
						),
						group: group.id
					})
					: item
			)
			yield put(setReservations(movedReservations))
			
			const movedItem = movedReservations.find(item => item.id === itemId)
			const params = {
				checkIn: moment(movedItem.start_time).format('YYYY-MM-DD'),
				checkOut: moment(movedItem.end_time).format('YYYY-MM-DD'),
				spot: group.parkId
			}
			
			try {
				yield call(
					API.Reservations.patchById,
					itemId,
					params
				)
				
			} catch (err) {
				console.log(err)
				yield put(setReservations(beforeMovReservations))
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.change_booking_error')
				))
			}
			
		}
	}

})
