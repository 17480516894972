import * as React from 'react'

const IcLavatorios = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-lavatorios</title>
			<g id="icons/servicos/ic-lavatorios" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M14.109375,21.1875 L14.109375,21.890625 C14.109375,23.0535938 13.1629688,24 12,24 C10.8370312,24 9.890625,23.0535938 9.890625,21.890625 L9.890625,21.890625 L9.890625,21.1875 L14.109375,21.1875 Z M14.109375,0 C15.6601875,0 16.921875,1.2616875 16.921875,2.8125 L16.921875,2.8125 L16.921875,4.921875 C16.921875,5.3101875 16.6070625,5.625 16.21875,5.625 C15.8304375,5.625 15.515625,5.3101875 15.515625,4.921875 L15.515625,4.921875 L15.515625,2.8125 C15.515625,2.03709375 14.8847812,1.40625 14.109375,1.40625 C13.3339688,1.40625 12.703125,2.03709375 12.703125,2.8125 L12.703125,2.8125 L12.703125,9.9375 L23.296875,9.9375 C23.6851875,9.9375 24,10.2523125 24,10.640625 C24,11.0289375 23.6851875,11.34375 23.296875,11.34375 L23.296875,11.34375 L22.5154688,11.34375 C22.1650781,15.3980625 18.8664844,19.78125 14.8125,19.78125 L14.8125,19.78125 L9.1875,19.78125 C5.13257812,19.78125 1.83478125,15.3980156 1.48448438,11.34375 L1.48448438,11.34375 L0.703125,11.34375 C0.3148125,11.34375 0,11.0289375 0,10.640625 C0,10.2523125 0.3148125,9.9375 0.703125,9.9375 L0.703125,9.9375 L11.296875,9.9375 L11.296875,7.03125 L9.890625,7.03125 L9.890625,7.734375 C9.890625,8.1226875 9.5758125,8.4375 9.1875,8.4375 C8.7991875,8.4375 8.484375,8.1226875 8.484375,7.734375 L8.484375,7.734375 L8.484375,4.921875 C8.484375,4.5335625 8.7991875,4.21875 9.1875,4.21875 C9.5758125,4.21875 9.890625,4.5335625 9.890625,4.921875 L9.890625,4.921875 L9.890625,5.625 L11.296875,5.625 L11.296875,2.8125 C11.296875,1.2616875 12.5585625,0 14.109375,0 Z" id="icon" fill="#000000"/>
			</g>
		</svg>
	)

export default IcLavatorios
