import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Logic from './logic'
/*import InputMask from 'react-input-mask'*/

import { 
	Form,
	Radio,
	Input as SemanticInput, 
	Dropdown as SemanticDropdown 
} from 'semantic-ui-react'
import { TimeInput } from 'semantic-ui-calendar-react'

import Label from 'components/Label'
import Tab from 'components/TabFilter'
import FormField from 'components/FormField'
import InputUnity from 'components/InputUnity'
/*import FormError from 'components/FormError'*/
import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import FormGroup from 'components/FormGroup'
import Errors from 'containers/ErrorList'
import Buttons from 'containers/ServiceAreaRequestForm/Buttons'

import { PANES, DEFAULT_TIME_YEAR } from './utils'

class Datasheet extends Component {
	render () {
		const { loading, t, form, errors, dirty } = this.props
		const { changeForm, patchForm } = this.actions

		const { 
			area,
			capacity,
			capacityNumber,
			capacityNumberReducedMobility,
			operatingHours,
			timeOfYear,
			electricitySupply,
			electricityPrice,
			potableWaterSupply,
			potableWaterPrice,
			spotPrice,
			checkInHours,
			checkOutHours,
			startingTime,
			endingTime,
			cleaningSlots,
			cleaningPrice,
		} = form

		return (
			<>
				<BoxSegment loading= {loading}>
					<Form>
						<SegmentTitle>
							{t('form.datasheet')}
						</SegmentTitle>
						<HorizontalLine />

						<SegmentTitle
							margin={'30px 0'}
						>
							{t('navigation.service_area')}
						</SegmentTitle>
						{errors && !!errors.length &&
						<Errors list={errors} />
						}
						<SubTitle>
							{t('form.general_information')}
						</SubTitle>
						<FormGroup widths='equal'>
							<FormField
								required
								error={(area.dirty && !area.valid) && area.message}
								label={t('form.area')}
								control={InputUnity}
								name="area"
								unity='m2'
								value={area.value || ''}
								onChange={(e, { value }) => changeForm({ area: value })}
								placeholder={
									t('form.area_placeholder')
								}
							/>
							<FormField
								required
								error={(capacity.dirty && !capacity.valid) && capacity.message}
								label={t('form.capacity')}
								control={InputUnity}
								unity='pax'
								name='capacity'
								value={capacity.value || ''}
								onChange={(e, { value }) => changeForm({ capacity: value })}
								placeholder={t('form.capacity_placeholder')}
							/>
						</FormGroup>
						<FormGroup widths='equal'>
							<FormField
								required
								error={(capacityNumber.dirty && !capacityNumber.valid) && capacityNumber.message}
								label={t('form.capacity_number')}
								control={InputUnity}
								unity='pax'
								name='capacity_number'
								value={capacityNumber.value || ''}
								onChange={(e, { value }) => changeForm({ capacityNumber: value })}
								placeholder={t('form.capacity_number_placeholder')}
							/>
							<FormField
								required
								error={
									(capacityNumberReducedMobility.dirty && !capacityNumberReducedMobility.valid) && 
									capacityNumberReducedMobility.message
								}
								label={t('form.capacity_number_reduce_mobility')}
								control={InputUnity}
								unity='pax'
								name='capacity_number'
								value={capacityNumberReducedMobility.value || ''}
								onChange={(e, { value }) => changeForm({ capacityNumberReducedMobility: value })}
								placeholder={t('form.capacity_number_placeholder')}
							/>
						</FormGroup>
						<FormGroup widths='equal'>

							<FormField
								required
								name='opening_hours'
								label={t('form.opening_hours')}
								error={(operatingHours.dirty && !operatingHours.valid) && operatingHours.message}
								control={Input}
								value={operatingHours.value || ''}
								onChange={(e, { value }) => changeForm({ operatingHours: value })}
							/>
							<FormField
								required
								name={'time_of_year'}
								control={Dropdown}
								label={t('form.time_of_year')}
								placeholder={t('form.time_of_year_option')}
								onChange={(e, data) => DEFAULT_TIME_YEAR && changeForm({ timeOfYear: data.value })}
								options={DEFAULT_TIME_YEAR}
								value={timeOfYear.value}
								selection
								error={(timeOfYear.dirty && !timeOfYear.valid) && timeOfYear.message}
							/>
						</FormGroup>
						<FormGroup widths={16}>
							<FormField>
								<Label>
									{t('form.electricity_supply')}
								</Label>
								<FormGroup inline>
									<FormField
										control={Radio}
										label={t('options.no')}
										name='electricity_supply_unavailable'
										checked={electricitySupply.value === 'unavailable'}
										value='unavailable'
	
										onChange={() => changeForm({ electricitySupply: 'unavailable', electricityPrice: '' })}
									/>
									<FormField
										control={Radio}
										label={t('options.included_price')}
										name='electricity_supply_included'
										value='included'
										checked={electricitySupply.value === 'included'}
										onChange={() => changeForm({ electricitySupply: 'included', electricityPrice: '' })}
									/>
									<FormField
										control={Radio}
										label='Paga no local'
										name='electricity_supply_paid_locally'
										value='paid locally'
										checked={electricitySupply.value === 'paid locally'}
	
										onChange={() => changeForm({ electricitySupply: 'paid locally', electricityPrice: '' })}
									/>
									<Label
										style={{
											borderLeft: '1px solid',
											paddingLeft: '6px'
										}}
									>
										{t('form.price')}
									</Label>
									<InputUnity 
										unity={'€'}
										name="price"
										disabled={electricitySupply.value !== 'paid locally'}
										value={electricityPrice.value || ''}
										placeholder={t('form.price_placeholder')}
										onChange={(e, { value }) => changeForm({ electricityPrice: value,  })}
										error={electricitySupply.value === 'paid locally' && electricityPrice.dirty && !electricityPrice.valid && electricityPrice.message }
									/>
								</FormGroup>
							</FormField>
						</FormGroup>
						<FormGroup widths={16}>
							<FormField>
								<Label>
									{t('form.water_supply')}
								</Label>
								<FormGroup inline>
									<FormField
										label={t('options.no')}
										name='water_supply_unavailable'
	
										control={Radio}
										value='unavailable'
										checked={potableWaterSupply.value === 'unavailable'}
	
										onChange={() => changeForm({ potableWaterSupply: 'unavailable', potableWaterPrice: '' })}
									/>
									<FormField
										control={Radio}
										label={t('options.included_price')}
										name='water_supply_included'
	
										value='included'
										checked={potableWaterSupply.value === 'included'}
	
										onChange={() => changeForm( { potableWaterSupply: 'included', potableWaterPrice: '' })}
									/>
									<FormField
										control={Radio}
										label='Paga no local'
										name='water_supply_paid_locally'
										value='paid locally'
										checked={potableWaterSupply.value === 'paid locally'}
	
										onChange={() => changeForm({ potableWaterSupply: 'paid locally', potableWaterPrice: '' })}
									/>
									<Label
										style={{
											borderLeft: '1px solid',
											paddingLeft: '6px'
										}}
									>
										{t('form.price')}
									</Label>
									<InputUnity 
										unity={'€'}
										name="price"
										disabled={potableWaterSupply.value !== 'paid locally'}
										value={potableWaterPrice.value || ''}
										placeholder={t('form.price_placeholder')}
										onChange={(e, { value }) => changeForm({ potableWaterPrice: value })}
										error={ potableWaterSupply.value === 'paid locally' && potableWaterPrice.dirty && !potableWaterPrice.valid && potableWaterPrice.message }
									/>
								</FormGroup>
							</FormField>
						</FormGroup>
						<SubTitle>
							{t('form.overnight_reservations')}
						</SubTitle>
						<FormGroup>
							<FormField
								width={5}
								error={spotPrice.dirty && !spotPrice.valid && spotPrice.message }
							>
								<Label>
									{t('form.spot_price')}
								</Label>
								<InputUnity
									unity={`€/${t('common.day')}`}
									name="spotPrice"
									value={spotPrice.value || ''}
									placeholder={t('form.price_placeholder')}
									onChange={(e, { value }) => changeForm({ spotPrice: value })}
									error={spotPrice.dirty && !spotPrice.valid && spotPrice.message }
								/>
							</FormField>
							{/*<FormField required width={5}>
								<Label>
									{t('form.guest_price')}
								</Label>
								<InputUnity 
									unity={`€/${t('common.day')}`}
									name='guestPrice'

									value={guestPrice.value}

									placeholder={t('form.price_placeholder')}

									onChange={(e, { value }) => changeForm({ guestPrice: value })}

									error={guestPrice.dirty && !guestPrice.valid && guestPrice.message }
								/>
								</FormField>*/}
						</FormGroup>
						<FormGroup>
							<FormField width={6}>
								<TimeInput
									required
									closable
									name='checkInHours'
									label={t('form.check_in_hours')}
									error={(checkInHours.dirty && !checkInHours.valid) && checkInHours.message}
									animation='none'
									value={checkInHours.value || ''}
									onChange={(e, { value }) => changeForm({ checkInHours: value })}
								/>
							</FormField>
							<FormField width={6}>
								<TimeInput
									required
									closable
									name='checkOutHours'
									label={t('form.check_out_hours')}
									error={(checkOutHours.dirty && !checkOutHours.valid) && checkOutHours.message}
									animation='none'
									value={checkOutHours.value || ''}
									onChange={(e, { value }) => changeForm({ checkOutHours: value })}
								/>
							</FormField>
						</FormGroup>
						<SubTitle>
							{t('form.usage_reservation')}
						</SubTitle>
						<FormGroup widths='equal'>
							<FormField
								error={cleaningPrice.dirty && !cleaningPrice.valid && cleaningPrice.message }
							>
								<Label>
									{t('form.price_for_service')}
								</Label>
								<InputUnity
									unity={'€'}
									name="cleaningPrice"
									value={cleaningPrice.value || ''}
									placeholder={t('form.price_placeholder')}
									onChange={(e, { value }) => changeForm({ cleaningPrice: value })}
									error={cleaningPrice.dirty && !cleaningPrice.valid && cleaningPrice.message }
								/>
							</FormField>
							<FormField
								required
								name={'cleaningSlots'}
								control={Input}
								label={t('form.places_cleaning')}
								placeholder={t('form.places_cleaning')}
								onChange={(e, data) => changeForm({ cleaningSlots: data.value })}
								value={cleaningSlots.value || ''}
								error={(cleaningSlots.dirty && !cleaningSlots.valid) && cleaningSlots.message}
							/>
						</FormGroup>
						<FormGroup>
							<FormField 
								required
								width={5}
								name='startingTime'
								label={t('form.service_opening_time')}
								error={(startingTime.dirty && !startingTime.valid) && startingTime.message}
								control={TimeInput}
								animation='none'
								value={startingTime.value || ''}
								onChange={(e, { value }) => changeForm({ startingTime: value })}
							/>
							<FormField 
								required 
								width={5}
								name='endingTime'
								label={t('form.service_closing_time')}
								error={(endingTime.dirty && !endingTime.valid) && endingTime.message}
								control={TimeInput}
								animation='none'
								value={endingTime.value || ''}
								onChange={(e, { value }) => changeForm({ endingTime: value })}
							/>
							{/*	<FormField required width={6}>
								<Label>
									{t('form.maximum_duration_service')}
								</Label>
								<InputMask 
									mask='99:99' 
									maskChar=''
									name='duration'

									value={duration.value}

									placeholder={'HH:MM'}

									onChange={(e) => changeForm({ duration: e.target.value })}
								/>
								<FormError>{duration.dirty && !duration.valid && duration.message}</FormError>
							</FormField>*/}
						</FormGroup>
						<Tab.Tab
							panes={PANES(t, form, this.actions)}
							onTabChange={() => patchForm()}
						/>
						
					</Form>
				</BoxSegment>
				<Buttons
					previousButtonClick={
						() => patchForm('previous')
					}
					nextButtonClick={() => patchForm('next')}
					saveButtonClick={() => patchForm()}
					dirty={dirty}
				/>
						
			</>
		)
	}
}

export default withTranslation()(Logic(Datasheet))

const Input = styled(SemanticInput)`
  width: 100%;
`

const Dropdown = styled(SemanticDropdown)`
  width: 100%;
`

const HorizontalLine = styled.hr`
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #F3F3F3;
  margin: 16px 0;
`
const SubTitle = styled.p`
	color: #102526;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
	text-transform: uppercase;
`