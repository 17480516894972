import axios from 'axios'
import ENDPOINTS from '../endpoints'

const getServiceAreas = params => axios.get(`${ENDPOINTS.DOWNLOADS}service-areas`, params)
	.then(res => res.data)
	.catch(err => { throw err })

const getCampSites = params => axios.get(`${ENDPOINTS.DOWNLOADS}campsites`, params)
	.then(res => res.data)
	.catch(err => { throw err })
	
export default {
	getServiceAreas,
	getCampSites
}