import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import _, { cloneDeep } from 'lodash'

import API from 'api'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import ServiceAreaLogic from 'screens/ServiceArea/logic'
import ServiceAreaRequestLogic from 'screens/ServiceAreaRequest/logic'

import { DEFAULT_VALUES } from './utils'
import { mapAPIFormStateToForm, getErrorMessagesAndScroll } from 'utils'

export default kea({
	path: () => ['scenes', 'containers', 'SAR_Photos'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			ServiceAreaLogic, [
				'serviceAreaProcessId',
				'serviceAreaProcess'
			],
			ServiceAreaRequestLogic, [
				'current'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			ServiceAreaRequestLogic, [
				'setStatus',
				'setCurrent'
			]
		]
	},

	actions: () => ({
		fetchForm: () => true,
		setForm: form => ({ form }),
		patchForm: action => action,
		unsetDirty: () => true,
		
		addFile: files => ({ files }),
		removeFile: index => ({ index }),

		error: errors => ({ errors }),
		loading: () => true,
		loaded: () => true,
		reset: () => true
	}),

	reducers: ({ actions }) => ({

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.setForm]: (_, payload) => payload.form,
			[actions.addFile]: (state, payload) => {
				let newState = _.cloneDeep(state)

				newState.images.value = [
					...newState.images.value,
					...payload.files
				]
        
				return newState
			},
			[actions.removeFile]: (state, { index }) => {
				let newState = _.cloneDeep(state)
				newState.images.value =
					newState.images.value.filter((file, i) => i !== index) 
				return newState
			},      
			[actions.reset]: () => DEFAULT_VALUES
		}],

		dirty: [false, Proptypes.bool, {
			[actions.addFile]: () => true,
			[actions.removeFile]: () => true,
			[actions.reset]: () => false,
			[actions.unsetDirty]: () => false,
		}],

		loading: [true, Proptypes.bool, {
			[actions.patchForm]: () => true,
			[actions.loading]: () => true,
			[actions.setForm]: () => false,
			[actions.loaded]: () => false, 
			[actions.error]: () => false,
			[actions.reset]: () => false
		}],

		errors: [[], Proptypes.array, {
			[actions.error]: (state, payload) => payload.errors,
			[actions.patchForm]: () => [],
			[actions.reset]: () => []
		}]

	}),

	start: function * () {
		const { fetchForm } = this.actions
		yield put(fetchForm())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.patchForm]: workers.patchForm,
		[actions.addFile]: workers.patchForm,
		[actions.removeFile]: workers.patchForm,
		[actions.fetchForm]: workers.fetchForm,
	}),

	workers: {

		* fetchForm () { 
			const { setForm, showToaster, loaded } = this.actions
			const { t } = this.props
			const form = yield this.get('form')
			const id = yield this.get('serviceAreaProcessId')

			try {
				let data = yield call(API.Areas.steps.getPhotos, id)
				data.images = data.images.map(image => ({ image })) 

				if (!data.images.length) {
					yield put(loaded())
					return
				}

				const newForm = yield call(
					mapAPIFormStateToForm, data, _.cloneDeep(form)
				)
				yield put(setForm(newForm))

			} catch (err) {
				yield put(loaded())
				yield put(
					showToaster(
						'error',
						t('common.error'),
						t('error.error_fetch')
					)
				)
			}

		},

		* patchForm ({ payload }) {
			const {
				showToaster,
				setStatus,
				loaded,
				error,
				setCurrent,
				unsetDirty,
			} = this.actions
			
			const { t } = this.props
			const form = yield this.get('form')
			let params = _.mapValues(form, ({ value }) => value)
			params.images = params.images.map(({ image }) => image.id)
     
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			const dirty = yield this.get('dirty')
			const current = yield this.get('current')

			if (!dirty) {
				yield put(loaded())
				
				switch (payload) {
					case 'next':
						yield put(setCurrent(current + 1))
						break
					case 'previous':
						yield put(setCurrent(current - 1))
						break
					default:
						break
				}
				return
			} 

			try {
				const response = yield call(
					API.Areas.steps.updatePhotos,
					serviceAreaProcessId,
					params
				)
				
				yield put(
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
				)
				yield put(setStatus('photos', response.status))
				yield put(loaded())
				yield put(unsetDirty())
				
				switch (payload) {
					case 'next':
						yield put(setCurrent(current + 1))
						break
					case 'previous':
						yield put(setCurrent(current - 1))
						break
					default:
						break
				}

			} catch (err) {
				if (err.response) {
					const errors = getErrorMessagesAndScroll(
						err.response.data,
						form
					)
					yield put(error(errors))
				}

				yield put(
					showToaster(
						'error',
						t('common.error'),
						t('error.error_save_data')
					)
				)
			}

		},

	}

})
