import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

class MemorizedDropdown extends Component {
	
	shouldComponentUpdate (nextProps) {

		const { value, options, disabled, loading } = this.props
		const { value: nextValue, options: nextOptions, disabled: nextDisabled, loading: nextLoading } = nextProps

		if (value === nextValue &&
				options.length === nextOptions.length &&
				disabled === nextDisabled &&
				loading === nextLoading) return false

		return true
	}

	render () { 
		return <Dropdown {...this.props} />
	}
}

export default MemorizedDropdown