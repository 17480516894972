import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import Logic from './logic'
import {
	openChildren,
	checkSelected,
	//checkParentSelected,
	isOpen
} from './utils'

import * as icons from 'components/SidebarIcons'
import {
	SidebarItem,
	SidebarSecondItem,
	SidebarLastItem
} from 'components/SidebarItem'

class Sidebar extends Component {
state = {
	SidebarItemOpen: []
}

preventAndNavigate = (e, to) => {
	const { navigate } = this.actions
	e.preventDefault()
	navigate(to)
}

handleSidebarClick = (e, to, index, isFather, keepChildrenPath, currentRoute, key) => {
	if (isFather) {
		openChildren(this, index, key === currentRoute.key || key === currentRoute.isChildrenOf)
		if (key === currentRoute.isChildrenOf && keepChildrenPath) {
			e.preventDefault()
			return
		}
	}

	this.preventAndNavigate(e, to)
}

componentDidMount () {
	let currentIndex = null
	this.props.sidebarRoutes && this.props.sidebarRoutes.forEach((route, i) => {
		if (route.isFather) {
			let childrens = this.props.sidebarRoutes.filter(r => {
				if (r.isChildrenOf) return r.isChildrenOf === route.key
				return false
			})

			childrens.forEach(({ path }) => {
				if (path === this.props.currentRoute.path) {
					currentIndex = i
				}
			})
		}
	})
	if (currentIndex) openChildren(this, currentIndex)
}

renderChildrenItems = (fatherIndex, fatherTag, routes) => {
	const {
		currentRoute,
		t,
	} = this.props

	const childrenRoutes = routes && routes.filter(
		({ isChildrenOf }) => isChildrenOf === fatherTag
	)
	
	return (
		<SidebarSecondList id='father' show={isOpen(this, fatherIndex)}>
			{childrenRoutes && childrenRoutes.map (
				({ defaultName, path, iconName, targetPath, name, key },
					index
				) => (
					<SidebarSecondItem
						selected={
							checkSelected(
								currentRoute,
								path,
								targetPath,
								key
							)
						}
						key={index}
						onClick={(e) => this.preventAndNavigate(e, path)}
					>
						<SecondItemIcon
							src={icons[iconName]}
							alt={defaultName}
						/>
						<SidebarLabel>{t(`navigation.${name}`)}</SidebarLabel>
					</SidebarSecondItem>
				))}
		</SidebarSecondList>
	)
}

renderFatherItems = (item, index) => {
	const { t, currentRoute, selectedServiceArea, user } = this.props

	if (!item || (item.needServicesArea && !selectedServiceArea)) {
		return null
	}
	if ((item.key === 'service_areas' || item.key === 'service_areas_management' ) && !user.isMotorhomeServiceAreaManager) {
		return null
	}
	if ((item.key === 'campsites' || item.key === 'campsite_management' ) && !user.isCampsiteManager) {
		return null
	}

	const { path, isFather, targetPath, iconName, name, key, keepChildrenPath } = item

	return (
		<SidebarItem
			selected={checkSelected(
				currentRoute,
				path,
				targetPath,
				key
			)}
			key={index}
			onClick={(e) => this.handleSidebarClick(
				e,
				path,
				index,
				isFather,
				keepChildrenPath,
				currentRoute,
				key
			)}
		> 
			<Icon src={icons[iconName]} />
			<SidebarLabel>{t(`navigation.${name}`)}</SidebarLabel>
		</SidebarItem>
	)
}

renderLastItems = (t) => {
	return (
		<SidebarLastList>
			<SidebarLastItem
				key={'FAQ'}
				onClick={(e) => this.preventAndNavigate(e, '/faqs')}
			>
				<Icon src={icons['FAQ']} alt={'FAQs'} />
				<SidebarLabel>{t('navigation.faqs')}</SidebarLabel>
			</SidebarLastItem>
			<SidebarLastItem
				key={'Contactos'}
				onClick={(e) => this.preventAndNavigate(e, '/contactos')}
			>
				<Icon src={icons['Contacts']} alt={'Contactos'} />
				<SidebarLabel>{t('navigation.contacts')}</SidebarLabel>
			</SidebarLastItem>
		</SidebarLastList>
	)
}

render () {
	const {
		sidebarRoutes,
		sidebarView,
		currentRoute,
		user,
		t
	} = this.props
	if (
		currentRoute.disableSidebar
    || !user
    || currentRoute.key === 'finalize_register'
	) {
		return null
	}

	return (
		<SidebarComponent showSidebar={sidebarView}>
			<TopList>
				{sidebarRoutes && sidebarRoutes.map((item, index) => (
					<SidebarList key={index}>
						{!item.isChildrenOf &&
							this.renderFatherItems(item, index)
						}
						{item.isFather &&
              this.renderChildrenItems(index, item.key, sidebarRoutes)
						}
					</SidebarList>
				))}
			</TopList>
			{this.renderLastItems(t)}
		</SidebarComponent>
	)
}
}

export default withTranslation()(Logic(Sidebar))

const SidebarComponent = styled.aside`
  ${({ showSidebar }) => showSidebar ? 'width: 250px;' : 'width: 48px;'}
  transition: 0.2s linear width;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  z-index: 99999999;
  top: 96px;
  display: flex;
  flex-direction: column;
  box-shadow: inset -1px 0 0 0 #EEEEEE;
  background-color: #FAFAFA;

  /*** Target main div ***/
  &&& + div {
		${({ showSidebar }) => showSidebar ? 'margin-left: 250px;' : 'margin-left: 48px;'}
		width: 100wv;
  }

  /*** Hide SidebarLabel ***/
  div span {
    ${({ showSidebar }) => showSidebar ? 'visibility: visible; opacity: 1;' : 'visibility: hidden; opacity: 0;'}
  }

  /*** Position Children div to the left when sidebar is minimized ***/
  ${({ showSidebar }) => !showSidebar && `
    #father div {
      padding-left: 15px;
      transition: 0.2s linear padding;
    }
  `}

  /*** When hover sidebar expand ***/
  &:hover {
    width: 250px;

    /*** Master div reacts to sidebar expand ***/
    &&& + div {
      margin-left: 250px;
    }

    /*** Children divs reacts to sidebar expand ***/
    #father div {
      padding-left: 45px;
      transition: 0.2s linear padding;
    }

    /*** Show span when sidebar expands***/
    div span {
      transition: 100ms linear visibility, opacity 100ms;
      transition-delay: 0.2s;
      opacity: 1;
      visibility: visible;
    }
  }
`

const TopList = styled.div`
  height: calc(100vh - (96px + 88px));
  overflow-y: auto;
  overflow-x: hidden;
`

const SidebarLabel = styled.span`
  user-select: none;
`

const Icon = styled.img`
  user-select: none;
  margin-right: 15px;
  vertical-align: middle;
  min-width: 24px;
  max-width: 24px;
  opacity: 0.4;
`

const SecondItemIcon = styled.img`
	user-select: none;
	margin-right: 14px;
	vertical-align: middle;
	min-width: 16px;
	max-width: 16px;
	opacity: 0.4;
`

const SidebarList = styled.div`
  display: flex;
  flex-direction: column;
`

const SidebarLastList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -95px;
`

const SidebarSecondList = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  /*** Transition effect for openning the children list ***/
  //transition: max-height .1s ease-in-out, opacity .3s;
  
  ${({ show }) => show ? `
    opacity: 1;
  ` : `
    max-height: 0;
    opacity: 0;
    visibility: hidden !important;
    div span {
        visibility: hidden !important;
    }
  `}
`
