import moment from 'moment'
import mapValues from 'lodash/mapValues'
import map from 'lodash/map'

export const reservationInfo = (
	{
		checkIn,
		checkOut,
		checkInOutInformations,
		nights,
		guests,
		characteristics,
		guestInformation,
		motorhomeInformation,
		serviceAreaIsAdapted,
		serviceArea,
		spot,
		paymentDetails,
		status
	}
) => ({
	status,
	checkIn: new Date(checkIn),
	checkOut: new Date(checkOut),
	checkInOutInformations: checkInOutInformations,
	checkInOut: `${moment(checkIn).format('DD-MM-YYYY')} - ${moment(checkOut).format('DD-MM-YYYY')}`,
	nights,
	guests,
	spotName: spot.id,
	needsElectricitySupply: characteristics.needsElectricitySupply,
	needsWaterSupply: characteristics.needsWaterSupply,
	serviceAreaIsAdapted,
	guest1Name: guestInformation.name,
	guest1Birthdate: guestInformation.birthdate,
	guest1Birthplace: guestInformation.birthPlace,
	guest1Nationality: guestInformation.nationality,
	guest1Email: guestInformation.email,
	guest1Mobile: guestInformation.mobile,
	guest1IdentityDocument: guestInformation.identityDocument,
	guest1IdentityDocumentCountry: guestInformation.identityDocumentCountry,
	guest1IdentityDocumentType: guestInformation.identityDocumentType,
	guest1Nif: guestInformation.nif,
	guest1ResidencePlace: guestInformation.residencePlace,
	guest1ResidenceCountry: guestInformation.residenceCountry,
	otherGuests: guestInformation.otherGuests,
	motorhomeModel: motorhomeInformation.model,
	motorhomeLicensePlate: motorhomeInformation.licensePlateNumber,
	motorhomeInfo: motorhomeInformation.otherInformations,
	serviceArea: serviceArea.id,
	paymentMethod: paymentDetails.paymentMethod,
	paymentDetails
})

export const apiReservationInfo = (form, editedParams) => {
	
	const { otherGuests, checkInOut, needsElectricitySupply, needsWaterSupply, serviceAreaIsAdapted } = form

	const splitDate = editedParams && !editedParams['checkInOut'] ? undefined : checkInOut.split(' - ')
	const checkIn = splitDate && moment(splitDate[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
	const checkOut = splitDate && moment(splitDate[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
	const spot = getResolvedParamEdit('spotName', form, editedParams)
	
	const characteristics = {
		needsElectricitySupply,
		needsWaterSupply
	}
	
	const changeOtherGuests = editedParams && !editedParams['otherGuests'] ? undefined : map(otherGuests, guest =>{
		const mapedValues = mapValues(guest, ({ value }) => value)
		mapedValues.nationality = mapedValues.nationality.id
		return mapedValues
	})
	
	const guestInformation = getResolvedPatchObject({
		name: 'guest1Name',
		birthdate: 'guest1Birthdate',
		birthPlace: 'guest1Birthplace',
		nationality: 'guest1Nationality.id',
		email: 'guest1Email',
		mobile: 'guest1Mobile',
		identityDocument: 'guest1IdentityDocument',
		identityDocumentCountry: 'guest1IdentityDocumentCountry.id',
		identityDocumentType: 'guest1IdentityDocumentType',
		nif: 'guest1Nif',
		residencePlace: 'guest1ResidencePlace',
		residenceCountry: 'guest1ResidenceCountry.id',
		otherGuests: changeOtherGuests
	}, form, editedParams)
	
	const motorhomeInformation = getResolvedPatchObject({
		model: 'motorhomeModel',
		licensePlateNumber: 'motorhomeLicensePlate',
		otherInformations: 'motorhomeInfo'
	}, form, editedParams)
	
	const paymentDetails = getResolvedPatchObject({
		paymentMethod: 'paymentMethod'
	}, form, editedParams)
	
	return {
		checkIn,
		checkOut,
		nights: getResolvedParamEdit('nights', form, editedParams),
		guests: getResolvedParamEdit('guests', form, editedParams),
		spot,
		characteristics,
		serviceAreaIsAdapted,
		guestInformation,
		motorhomeInformation,
		serviceArea: getResolvedParamEdit('serviceArea', form, editedParams),
		paymentDetails
	}
}

const getResolvedParamEdit = (key, form, editedParams) => !editedParams || editedParams[key] ? form[key] : undefined

const getResolvedPatchObject = (targetResult, data, editedParams, ignoreParams = []) => {
	let hasEdit = false
	let result = {}

	Object.keys(targetResult).map(key => {

		if (ignoreParams.includes(key) || typeof targetResult[key] !== 'string') {
			result[key] = targetResult[key]
			return false
		}

		const valueMapping = targetResult[key].split('.')
		if (!editedParams || editedParams[valueMapping[0]]) {
			hasEdit = true
			let targetValue = {...data}

			for (const currentProp of valueMapping) {
				targetValue = targetValue[currentProp]
			}
			result[key] = targetValue
		}
	})

	return hasEdit ? result : undefined

}

export const apiCleaningReservation = (
	{
		slotsArray,
		spotObj,
		guestName,
		guestBirthdate,
		guestNationality,
		guestEmail,
		guestMobile,
		guestIdentityDocument,
		guestIdentityDocumentType,
		guestNif,
		motorhomeModel,
		motorhomeLicensePlate,
		motorhomeInfo,
		serviceArea,
		date,
		paymentMethod,
		accompanyingGuestsNumber
	}
) => {
	let slots = slotsArray && []
	if (slotsArray) {
		// eslint-disable-next-line no-prototype-builtins
		if ( slotsArray[0].hasOwnProperty('startingTime')) {
			slots = [...slotsArray]
		} else {
			// transform to array of slots
			slots = slotsArray.map((item) => {
				const [startingTime, endingTime ] = item.split('-')
				return { startingTime, endingTime }
			})
		}
	}

	const spot = spotObj && spotObj.value
	const guestInformation = {
		name: guestName && guestName,
		birthdate: guestBirthdate && guestBirthdate,
		nationality: guestNationality && guestNationality.id,
		email: guestEmail && guestEmail,
		mobile:guestMobile && guestMobile,
		identityDocument: guestIdentityDocument && guestIdentityDocument,
		identityDocumentType: guestIdentityDocumentType && guestIdentityDocumentType,
		nif: guestNif && guestNif,
		accompanyingGuestsNumber: accompanyingGuestsNumber && accompanyingGuestsNumber
	}
	
	const motorhomeInformation = {
		model: motorhomeModel && motorhomeModel,
		licensePlateNumber: motorhomeLicensePlate && motorhomeLicensePlate,
		otherInformations: motorhomeInfo && motorhomeInfo
	}
	const paymentDetails = {
		paymentMethod
	}
	
	return {
		date,
		slots,
		spot,
		guestInformation,
		motorhomeInformation,
		serviceArea,
		paymentDetails
	}
}