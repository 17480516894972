import React, { PureComponent } from 'react'
import FatalError from 'screens/FatalError'
import { Route } from 'react-router-dom'

export default class ErrorBoundaryRoute extends PureComponent {
state = {
	hasError: false
}

componentDidCatch (error, info) {
	this.setState({
		hasError: true,
		error,
		info
	})
}

render () {   
	const {
		path,
		exact
	} = this.props

	// If Route failed with any error return a failed route so user can go back
	if (this.state.hasError) {

		return (
			<Route
				path={path}
				exact={exact}
				component={FatalError}
			/>
		)
	}

	return this.props.children
}
}
