
import React, { Component } from 'react'
import Buttons from '../Buttons'
import FormRequest from 'components/FormRequest'
import Equipments from './BaseEquipments'
import OtherEquipments from './OtherEquipments'
import { DEFAULT_VALUES as DEFAULT_VALUES_BASE_EQUIPMENTS, VALIDATIONS as VALIDATIONS_BASE_EQUIPMENTS, VALIDATIONS_NO_REQUIRED as VALIDATION_NO_REQUIRED_BASE_EQUIPMENTS } from './BaseEquipments/utils'
import { DEFAULT_VALUES as DEFAULT_VALUES_OTHER_EQUIPMENTS, VALIDATIONS as VALIDATIONS_OTHER_EQUIPMENTS, VALIDATIONS_NO_REQUIRED as VALIDATION_NO_REQUIRED_OTHER_EQUIPMENTS } from './OtherEquipments/utils'
import Logic from './logic'
import { withTranslation } from 'react-i18next'

class FormContainer extends Component {

	constructor (props) {
		super(props)
		this.baseEquipmentsRef = React.createRef()
		this.otherEquipmentsRef = React.createRef()

		const forms = ['baseEquipmentsSubmitSuccess', 'otherEquipmentsSubmitSuccess']
		const state = {forms}
		forms.map(prop => state[prop] = null)
		
		this.state = {
			...state,
			dirty: false,
		}
	}

	componentWillUnmount () {
		this.submitData(null, true)
	}

	allSubmitsSuccess = () => !this.state.forms.some(form => this.state[form] === false || this.state[form] === null)
	anySubmitToApi = () => this.state.forms.some(form => this.state[form])
	anySubmitsError = () => this.state.forms.some(form => !this.state[form])
	allSubmitted = () => !this.state.forms.some(form => this.state[form] === null)

	handleSubmit = (success, action) => {

		const { t, current } = this.props
		const { showToaster, setCurrent } = this.actions
		
		if (success === false) {
			showToaster(
				'error',
				t('common.error'),
				t('error.error_save_data')
			)

		}
		else if (this.allSubmitted()) {

			if (this.allSubmitsSuccess()) {
				
				if (this.anySubmitToApi()) {
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
				}
				
				switch (action) {
					case 'next':
						setCurrent(current + 1)
						break
					case 'previous':
						setCurrent(current - 1)
						break
					default:
						break
				}
			}
			else {
				const newForms = {}
				this.state.forms.map(prop => newForms[prop] = null)

				this.setState(newForms)
			}
		}
	}

	submitData = (action, forceSuccess) => {
		const { t } = this.props
		const { showToaster } = this.actions

		this.baseEquipmentsRef.current.submitData(success => {

			if (forceSuccess && success) {
				showToaster(
					'success',
					t('common.success'),
					t('error.success_save_data')
				)
				return
			}

			this.setState({
				[this.state.forms[0]]: success
			}, () => {
				this.handleSubmit(success, action)
			})
		})

		this.otherEquipmentsRef.current.submitData(success => {

			if (forceSuccess && success) {
				showToaster(
					'success',
					t('common.success'),
					t('error.success_save_data')
				)
				return
			}

			this.setState({
				[this.state.forms[1]]: success
			}, () => {
				this.handleSubmit(success, action)
			})
		})

	}

	setDirty = (status) => {
		this.setState({
			dirty: status,
		})
	}

	render () {

		const { campsite } = this.props
		const { status } = campsite || {}
		const { dirty } = this.state

		return (
			<>
			
				<FormRequest
					submitChangedDataOnly
					defaultForm={DEFAULT_VALUES_BASE_EQUIPMENTS}
					validations={status === 'draft' ? VALIDATION_NO_REQUIRED_BASE_EQUIPMENTS : VALIDATIONS_BASE_EQUIPMENTS}
					initialLoading
					setParentDirty={this.setDirty}
				>
					<Equipments forwardedRef={this.baseEquipmentsRef} {...this.props} />
				</FormRequest>

				<FormRequest
					submitChangedDataOnly
					defaultForm={DEFAULT_VALUES_OTHER_EQUIPMENTS}
					validations={status === 'draft' ? VALIDATION_NO_REQUIRED_OTHER_EQUIPMENTS : VALIDATIONS_OTHER_EQUIPMENTS}
					initialLoading
					setParentDirty={this.setDirty}
				>
					<OtherEquipments forwardedRef={this.otherEquipmentsRef} {...this.props} />
				</FormRequest>

				<Buttons
					previousButtonClick={() => this.submitData('previous')}
					nextButtonClick={() => this.submitData('next')}
					saveButtonClick={() => this.submitData('')}
					dirty={dirty}
				/>
			</>
		)
	}
}

export default withTranslation()(Logic(FormContainer))