import axios from 'axios'
import ENDPOINTS from '../endpoints'
import {noInterceptorAxios} from 'api'

const User = token => noInterceptorAxios.get(ENDPOINTS.AUTH.USER, { headers: { Authorization: `Bearer ${token}` }})
	.then(res => res.data)
	.catch(err => { throw err })

const UpdateUser = (params) => axios.patch(ENDPOINTS.AUTH.USER, params)
	.then(res => res.data)
	.catch(err => { throw err })

const Login = params => axios.post(ENDPOINTS.AUTH.LOGIN, params)
	.then(res => res.data)
	.catch(err => { throw err })

const Register = params => axios.post(ENDPOINTS.AUTH.REGISTER.ENTITY, params)
	.then(res => res.data)
	.catch(err => { throw err })

const Recover = params => axios.post(ENDPOINTS.AUTH.RECOVER_PASSWORD, params)
	.then(res => res.data)
	.catch(err => { throw err })

export default {
	User,
	Login,
	Recover,
	Register,
	UpdateUser
}