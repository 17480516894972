// @flow

import { kea } from 'kea'
import Proptypes from 'prop-types'
import moment from 'moment'
import { put, call } from 'redux-saga/effects'
import { transformReservations } from './utils'
import { mapValues, values } from 'lodash'

import API from 'api'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'

const DEFAULT_VISIBLE_VALUES = {
	visibleTimeStart: moment()
		.startOf('week')
		.valueOf(),
	visibleTimeEnd: moment()
		.endOf('week')
		.valueOf()
}
export default kea({
	path: () => ['scenes', 'UsageReservation'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		getReservations: params => params,
		setReservations: reservations => ({ reservations }),

		getServiceAreaList: () => true,
		setServiceAreas: areas => areas,

		setSelectedServiceArea: area => area,

		setVisibleTime: time => time,

		reset: () => true
	}),

	reducers: ({ actions }) => ({
		serviceAreas: [[], Proptypes.array, {
			[actions.setServiceAreas]: (state, payload) => payload,
			[actions.reset]: () => ([])
		}],
		
		selectedServiceArea: [null, Proptypes.number, {
			[actions.setSelectedServiceArea]: (state, payload) => payload,
			[actions.reset]: () => null 
		}],
		
		reservations: [[], Proptypes.array, {
			[actions.setReservations]: (state, payload) => payload.reservations,
			[actions.setSelectedServiceArea]: () => [],
			[actions.setVisibleTime]: () => [],
			[actions.reset]: () => []
		}],
    
		loading: [true, Proptypes.bool, {
			[actions.setReservations]: () => false,
			[actions.reset]: () => true
		}],
		
		visibleTime: [DEFAULT_VISIBLE_VALUES, Proptypes.object, {
			[actions.setVisibleTime]: (state, payload) => payload,
			[actions.reset]: () => null
		}]
	}),

	selectors: ({ selectors }) => ({
		serviceAreaOptions: [ () => [selectors.serviceAreas],
			(serviceAreas) => values(mapValues(
				serviceAreas,
				area =>  { return { 
					text: area.name, value: area.id
				} }
			))
		]
	}),

	start: function * () {
		const { getServiceAreaList } = this.actions
	
		yield put(getServiceAreaList())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.getServiceAreaList]: workers.getServiceAreaList,
		[actions.getReservations]: workers.getReservations,
		[actions.setSelectedServiceArea]: workers.getReservations,
		[actions.setVisibleTime]: workers.getReservations,
		[actions.getSpotsList]: workers.getSpotsAreaList
	}),

	workers: {
		* getServiceAreaList () {
			const {
				setServiceAreas,
				setSelectedServiceArea,
				showToaster,
				getReservations
			} = this.actions
			const { t } = this.props
			
			const params = { status: 'homologated,registered' }
			
			try {
				const response = yield call(API.Areas.get, { params })
				const { results } = response
				yield put(setServiceAreas(results))
				if (results.length > 0) {
					yield put(setSelectedServiceArea(results[0].id))
					yield put(getReservations())
				} else {
					yield put(setSelectedServiceArea(null))
					yield put(
						showToaster(
							'error',
							t('common.error'),
							t('api_error.no_data')
						))
				}
			} catch (e) {
				console.log(e)
			}
		},
		
		* getReservations (actionPayload) {

			const  { payload } = actionPayload
			let params = payload
			
			if (!params || !params.date__gte || !params.date__lte) {
				const visibleTime = yield this.get('visibleTime')
				params = {
					date__gte: moment(visibleTime.visibleTimeStart)
						.format('YYYY-MM-DD'),
					date__lte: moment(visibleTime.visibleTimeEnd)
						.format('YYYY-MM-DD')
				}
			}
			const selectedServiceArea = yield this.get('selectedServiceArea')
			const { setReservations } = this.actions

			try {
				const response = yield call(
					API.Reservations.getAreaCleaningReservation,
					selectedServiceArea,
					params
				)
				const { results } = response
				yield put(setReservations(transformReservations(results)))

			} catch (e) {

				console.log('error',e)
				yield put(setReservations([]))

			}
		},
	}

})
