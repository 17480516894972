import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import {
	mapWSErrorsToForm,
	scrollToFirstFormError,
	sortedAlphabetically
} from 'utils'

import API from 'api'
import Proptypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import * as Check from 'validations'

import AppLogic from 'containers/App/logic'
import { cloneDeep } from 'lodash'

const DEFAULT_VALUES = {
	address: {
		value: ''
	},
	postalCode: {
		value: ''
	},
	locality: {
		value: ''
	},
	district: {
		value: ''
	},
	municipality: {
		value: ''
	},
	website: {
		value: ''
	},
	email: {
		value: ''
	},
	telephone: {
		value: ''
	},
	mobile: {
		value: ''
	},
	representativeName: {
		value: ''
	},
	representativeRole: {
		value: ''
	},
	representativeAltPhone: {
		value: ''
	},
	representativeEmail: {
		value: ''
	}
}

const VALIDATIONS = {
	address: [
		Check.isRequired
	],
	postalCode: [
		Check.isRequired,
		Check.isValidPostalCode
	],
	locality: [
		Check.isRequired
	],
	district: [
		Check.isRequired
	],
	municipality: [
		Check.isRequired
	],
	email: [
		Check.isRequired,
		Check.Email
	],
	mobile: [
		Check.isRequired,
		Check.isValidPhoneNumber
	],
	representativeName: [
		Check.isRequired
	],
	representativeRole: [
		Check.isRequired
	],
	representativeAltPhone: [
		Check.isRequired,
		Check.isValidPhoneNumber
	],
	representativeEmail: [
		Check.isRequired,
		Check.Email
	]
}

export default kea({
	path: () => ['scenes', 'FinalizeRegister'],

	connect: {
		actions: [
			AppLogic, [
				'navigate',
				'setCurrentUser'
			]
		],
		props: [
			AppLogic, [
				'user'
			]
		]
	},

	actions: () => ({
		change: (field) => ({ field }),
		submit: () => ({}),
		response: (response) => ({ response }),
		error: (error) => ({ error }),
		setForm: (form) => ({ form }),
		changeForm: (form) => ({ form }),
		reset: () => true,

		setLoading: () => ({}),
		resetLoading: () => ({}),

		// Districts
		setDistricts: (districts) => ({ districts }),

		// Municipalities
		fetchDistrictsAndMunicipalities: () => true,
		setMunicipalities: (municipalities) => ({ municipalities }),

		// Localities
		getDataToPrefillFields: () => true
	}),

	reducers: ({ actions }) => ({
		districts: [[], Proptypes.array, {
			[actions.setDistricts]: (state, payload) => payload.districts
		}],

		municipalities: [[], Proptypes.array, {
			[actions.setMunicipalities]: (state, payload) =>
				payload.municipalities
		}],

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.change]: (state, payload) =>
				Check.setAndCheckValidation(state, payload, VALIDATIONS),
			[actions.setForm]: (state, payload) =>
				Check.checkValidation(payload.form, VALIDATIONS).form,
			[actions.changeForm]: (state, payload) => payload.form,
			[actions.reset]: () => cloneDeep(DEFAULT_VALUES)
		}],

		dirty: [false, Proptypes.bool, {
			[actions.change]: () => true,
			[actions.response]: () => false,
			[actions.error]: () => true,
			[actions.reset]: () => false
		}],

		submiting: [false, Proptypes.bool, {
			[actions.submit]: () => true,
			[actions.error]: () => false,
			[actions.response]: () => false,
			[actions.reset]: () => false
		}],

		response: [null, Proptypes.any, {
			[actions.response]: (state, payload) => payload.response,
			[actions.reset]: () => null
		}],

		error: [null, Proptypes.any, {
			[actions.error]: (state, payload) => payload.error,
			[actions.reset]: () => null
		}],

		loading: [false, Proptypes.bool, {
			[actions.resetLoading]: () => false,
			[actions.setLoading]: () => true
		}]
	}),

	start: function * () {
		const {
			fetchDistrictsAndMunicipalities
		} = this.actions

		yield put(fetchDistrictsAndMunicipalities())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.submit]: workers.submit,
		[actions.getDataToPrefillFields]: workers.getDataToPrefillFields,
		[actions.fetchDistrictsAndMunicipalities]:
			workers.fetchDistrictsAndMunicipalities,
	}),

	workers: {

		/**
     * Fetch Portugal's districts & municipalities
     * to feed dropdowns
     * @param {*} action
     */
		* fetchDistrictsAndMunicipalities () {
			const { setDistricts, setMunicipalities } = this.actions

			try {
				const params = { limit: 'max' }
				const request = yield call(API.Municipalities.get, { params })

				// Extract Districts from the request response
				// Get just the array of objects regarding the 'districts'
				let bulkedDistricts = request.results.map(
					result => result.district
				)
				// Extract the repeated results
				const districts = []
				const map = new Map()
				for (const item of bulkedDistricts) {
					if (!map.has(item.id)) {
						map.set(item.id, true)
						districts.push({
							id: item.id,
							name: item.name
						})
					}
				}
				yield put(setDistricts(districts))
				yield put(setMunicipalities(sortedAlphabetically(
					request.results,
					'name'
				)))
			} catch (er) {
				console.log(er)
			}
		},

		/**
     * Fetch data based on postal code
     * to feed the dropdowns
     * @param {*} action
     */
		* getDataToPrefillFields () {
			const {
				change,
				setLoading,
				resetLoading
			} = this.actions

			const form = yield this.get('form')
			const postalCodeValid = form.postalCode.valid
			const input = form.postalCode.value

			if (postalCodeValid) {
				try {
					yield put(setLoading())
					const params = { code: input }
					const request = yield call(
						API.Municipalities.byPostalCode,
						{ params }
					)
					if (!request.results.length) throw new Error('')

					// Set the district, municipality and locality
					const district = request.results[0].municipality.district.id
					const municipality = request.results[0].municipality.id
					const locality = request.results[0].localities[0].name

					yield put(change({ district }))
					yield put(change({ municipality }))
					yield put(change({ locality }))

					yield put(resetLoading())
				} catch (er) {
					console.log(er)
					yield put(resetLoading())
				}
			}
		},

		* submit () {
			const {
				error,
				response,
				setForm,
				changeForm,
				setCurrentUser,
				reset
			} = this.actions

			const user = yield this.get('user')
			const form = yield this.get('form')
			const dirty = yield this.get('dirty')

			// Check validations
			const validation = Check.checkValidation(form, VALIDATIONS)

			if (dirty && validation.invalid) {
				// try to scroll to first form field error
				scrollToFirstFormError(validation.form)
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				// try to scroll to first form field error
				scrollToFirstFormError(validation.form)
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			let params = mapValues(form, ({ value }) => value)
			delete params.district

			try {
				const request = yield call(API.Auth.UpdateUser, params)
				yield put(response(request))

				if (user) {
					user.registrationComplete = true
					yield put(setCurrentUser(user))
				}
        
				yield put(reset())
				window.location.href = '/gestao-processos'

			} catch (er) {
				if (er.response.data) {
					// map WS return errors to form format
					// put the errors on each field and changed them to invalid
					const newForm = mapWSErrorsToForm(er.response.data, form)
					yield put(changeForm(newForm))
					// try to scroll to first form field error
					scrollToFirstFormError(newForm)
				}
				yield put(error([]))
			}
		}

	}

})
