import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import Title from 'components/Title'

import Logic from './logic'
//import List from 'containers/ServiceLocationList'
import Button from 'components/Button'
import Map from 'components/Map'
import Tab from 'components/TabFilter'

import { PANES } from './utils'
import List from './List'

class CampsiteManagement extends Component {

	componentDidMount () {
		const { getCampsites, setCampsite, setCampsiteId } = this.actions
		
		getCampsites()
		setCampsite(null)
		setCampsiteId(null)
	}

	render () {
		const {
			t,
			campsites,
			submitting
		} = this.props

		const {createCampsite, setCurrentTab} = this.actions

		const markers = campsites.filter(campsite => campsite.gpsCoordinates)
			.map(campsite => ({position: [campsite.gpsCoordinates.latitude, campsite.gpsCoordinates.longitude]}))
		
		return (
			<Wrapper>
				<Container>
					<Row>
						<Grid.Column width={10}>
							<Title>
								{t('campsite_management.title')}
							</Title>
						</Grid.Column>

						<RightColumn width={6}>
							<Button primary onClick={() => createCampsite()} loading={submitting}>
								{t('options.create_campsite')}
							</Button>
						</RightColumn>
					</Row>

					<MapWrapper>
						<Map
							centerPosition={[39.557, -6.20]}
							markers={markers}
							//getCoordinates={this.onGetCoordinates}
							//withSearch
						/>
					</MapWrapper>

					<MenuWrapper>
						<Tab.Tab onTabChange={(e, {activeIndex}) => setCurrentTab(activeIndex)} panes={PANES(t)} />
						<List />
					</MenuWrapper>

					{/*<Row>
						<Grid.Column>
							<List status={''} />
						</Grid.Column>
					</Row>*/}
				</Container>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(CampsiteManagement))

const Wrapper = styled.div``

const RightColumn = styled(Grid.Column)`
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
`
const MenuWrapper = styled.div`
	width: 100%;
`
const MapWrapper = styled.div`
  width: 100%;
  border-radius: 7px;
	margin: 15px 0 25px;
	padding: 15px;
	background-color: #fff;
	box-shadow: 0 0 12px -5px #c1c1c1;

  .leaflet-container {
    height: 430px;
    width: 100%;
  }

  .leaflet-div-icon {
    background: #ACCF38;
  }
`

const Container = styled(Grid)`
  margin-top: 16px !important;
`

const Row = styled(Grid.Row)``

//const TopWrapper = styled.div`
//	display: flex;
//	align-items: center;
//	justify-content: space-between;
//	width: 100%;
//	margin-bottom: 32px;
	
//	> h1 {
//		margin: 0;
//	}
//`

//const InfoTitle = styled.span`
//	color: #616161;
//	font-family: "Open Sans";
//	font-size: 14px;
//	font-weight: 600;
//	-webkit-letter-spacing: 0;
//	-moz-letter-spacing: 0;
//	-ms-letter-spacing: 0;
//	letter-spacing: 0;
//	line-height: 14px;
//	padding: 12px 0!important;
//`

//const Link = styled(InfoTitle)`
//	text-decoration: underline;
//	cursor: pointer;
//`