import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import Button from 'components/Button'

class Buttons extends Component {
	render () {
		const {
			t,
			previousButtonClick,
			nextButtonClick,
			saveButtonClick,
			current,
			serviceAreaProcess,
			dirty,
			canSubmit,
		} = this.props

		const {
			navigate,
			toggleHomologation,
			openModal,
		} = this.actions

		const saveButtonText = () => {
			if (current === 4) {
				if (canSubmit) {
					return (
						<Button
							primary
							onClick={openModal}
							content={t('common.submit')}
						/>)
				} else {
					return null
				}
			} else {
				return (
					<Button
						primary
						onClick={saveButtonClick}
						content={t('common.buttons_save')}
						disabled={!dirty}
					/>)
			}
		}

		const nextButtonText = (status) => {
			if (current === 4) {
				switch (status) {
					case 'registered':
						return (
							<Button
								primary
								onClick={toggleHomologation}
								content={t('form.homologated_button')}
							/>
						)
					case 'draft':
						return null
					default:
						return (
							<Button
								primary
								onClick={nextButtonClick}
								content={t('common.buttons_next_step')}
								disabled={current === 4}
							/>
						)
				}
			} else {
				return (
					<Button
						primary
						onClick={nextButtonClick}
						content={t('common.buttons_next_step')}
					/>
				)
			}
		}

		return (
			<NavigationWrapper>
				<Button
					secondary
					onClick={() => navigate('/gestao-processos')}
				>
					{t('common.buttons_back')}
				</Button>
				<RightWrapper>
					<Button
						secondary
						onClick={previousButtonClick}
						content={t('form.buttons_previousStep')}
						icon={'angle left'}
						disabled={current === 1}
					/>
					{saveButtonText()}
					{nextButtonText(serviceAreaProcess.status)}
				</RightWrapper>
			</NavigationWrapper>
		)
	}
}

export default withTranslation()(Logic(Buttons))

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
`

const RightWrapper = styled.div`
  display: flex;
`