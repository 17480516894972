import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import {
	Grid,
	Dimmer,
	Loader,
	Input,
	Dropdown
} from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react'
import Logic from './logic'
import moment from 'moment'
import {
	serviceAreasOptions,
	findServiceAreaObject
} from './utils'

/** components */
import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import FormError from 'components/FormError'

class UsageDetailsCharacteristics extends Component {

	render () {
		const {
			t,
			loading,
			form,
			unavailabilityDates,
			serviceAreas,
			scheduleAvailability
		} = this.props

		const { 
			changeEditForm, 
			changeForm,
			setSelectedServiceArea,
			refreshUnavailableDates,
			setSelectCleaningDate
		} = this.actions

		const { serviceArea, date, slotsArray, spotObj } = form

		return (
			<Wrapper>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.booking_characteristics')}
				</SegmentTitle>
				{loading && 
				<Dimmer inverted active>
					<Loader inverted/>
				</Dimmer>
				}
				{!loading &&
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column width={8}>
							<Grid.Row>
								<InputLabel>
									{`${t('booking_details.service_area')} *`}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								{!serviceArea.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												serviceArea: !serviceArea.edit
											})
									}}
									value={findServiceAreaObject(
										serviceArea.value,
										serviceAreas
									).name || ''}
									disabled={!serviceArea.edit}
								/>
								}
								{serviceArea.edit &&
								<Dropdown
									placeholder={t('booking_details.service_area_placeholder')}
									fluid
									selection
									value={serviceArea.value}
									options={serviceAreasOptions(serviceAreas)}
									onChange={(e, {value}) => {
										changeForm({ serviceArea: value, date: moment().format('YYYY-MM-DD') })
										setSelectedServiceArea(value)
										refreshUnavailableDates(moment(date.value))
									}}
								/>
								}
								{!serviceArea.valid &&
									<FormError>
										{serviceArea.message}
									</FormError>	
								}	
							</Grid.Row>
						</Grid.Column>
						<Grid.Column width={8}>
							<Grid.Row>
								<InputLabel>
									{t('booking_details.reservation_date')}
								</InputLabel>
								{!date.edit &&
									<Input
										style={{ width: '100%' }}
										icon={{
											name: 'pencil',
											link: true,
											onClick: () =>
												changeEditForm({
													date: !date.edit
												})
										}}
										value={date.value}
										disabled={!date.edit}
									/>
								}
								{date.edit &&
									<DateInput
										disable={unavailabilityDates}
										style={{ width: '100%' }}
										name={'guest1birthdate'}
										placeholder={'DD-MM-YYYY'}
										minDate={moment()}
										disabled={!serviceArea.value}
										animation='none'
										value={moment(date.value).format('DD-MM-YYYY')}
										onMonthChange={(e, {value}) => refreshUnavailableDates(moment(value, 'DD-MM-YYYY'))}
										iconPosition={'left'}
										onChange={(e, { value }) => {
											changeForm({date: moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')})
											setSelectCleaningDate(moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD'))
										}}
									/>
								}
								{!date.valid &&
									<FormError>
										{date.message}
									</FormError>	
								}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2}>
						<Grid.Column>
							<InputLabel>
								{t('booking_details.period_use')} *
							</InputLabel>
							{!slotsArray.edit &&
								<Input
									style={{ width: '100%' }}
									icon={{
										name: 'pencil',
										link: true,
										onClick: () =>
											changeEditForm({
												slotsArray: !slotsArray.edit
											})
									}}
									value={slotsArray.value.name}
									disabled={!serviceArea.edit}
								/>
							}
							{slotsArray.edit &&
								<Dropdown
									placeholder={t('booking_details.period_use_placeholder')}
									fluid
									selection
									multiple
									value={slotsArray.value.value || slotsArray.value || []}
									options={scheduleAvailability}
									disabled={!scheduleAvailability.length}
									onChange={(e, {value}) => {
										changeForm({ slotsArray: value })
									}}
								/>
							}
							{!slotsArray.valid &&
							<FormError>
								{slotsArray.message}
							</FormError>	
							}	
						</Grid.Column>
						<Grid.Column>
							<InputLabel>
								{t('booking_details.spot')}
							</InputLabel>
							<Input
								placeholder={t('booking_details.random_spot')}
								style={{ width: '100%' }}
								value={spotObj.value.name}
								disabled
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				}
			</Wrapper>
		)
	}
}

export default withTranslation()(withRouter(Logic(UsageDetailsCharacteristics)))

const Wrapper = styled(Segment)`
	padding: 32px 40px !important;
`

const InputLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
	line-height: 14px;
	padding: 8px 0;
	margin: 0;
`