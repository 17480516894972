import { kea } from 'kea'
import Proptypes from 'prop-types'
import moment from 'moment'
import { put, call } from 'redux-saga/effects'
import { cloneDeep, mapValues } from 'lodash'

import API from 'api'
import AppLogic from 'containers/App/logic'
import { mapApiErrors } from 'api/errors'
import ToasterLogic from 'containers/Toaster/logic'
import { DEFAULT_VALUES, VALIDATIONS, changeEdit, resetEdit, getHours } from './utils'
import * as Check from 'validations'
import { mapAPIFormStateToForm } from 'utils'
import { apiCleaningReservation } from 'api/schemas'
import { handleApiErrorMessage } from '../../../utils'

let patch = {}

export default kea({
	path: () => ['scenes', 'UsageReservationDetails'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},

	actions: () => ({
		fetchForm: () => true,

		setStatusReservation: status => status,
		
		setLoading: loading => loading,

		getReservations: params => params,
		setReservations: reservation => reservation,

		getParkingPlaces: () => true,
		setParkingPlaces: places => places,

		setForm: form => ({ form }),
		saveForm: () => true,
		changeForm: field => ({ field }),
		changeEditForm: field => ({ field }),
		resetEditForm: () => true,
		error: errors => errors,

		setSelectedServiceArea: area => area,

		setVisibleTime: time => time,
		
		simulateReservation: () => true,
		setSimulation: prices => prices,

		createReservation: () => true,
		
		setPaymentMethods: methods => methods,
		getPaymentMethods: () => true,

		getSpotsList: () => true,
		
		cancelReservation: () => true,

		reset: () => true
	}),

	reducers: ({ actions }) => ({

		form: [cloneDeep(DEFAULT_VALUES), Proptypes.object, {
			[actions.setForm]: (state, payload) => payload.form,
			[actions.changeForm]: (state, payload) =>
				Check.setAndCheckValidation(
					state,
					payload,
					VALIDATIONS
				),
			[actions.changeEditForm]: (state, payload) =>
				changeEdit(state, payload),
			[actions.resetEditForm]: state => resetEdit(state),
			[actions.reset]: () => cloneDeep(DEFAULT_VALUES)
		}],
		
		dirty: [false, Proptypes.bool, {
			[actions.changeForm]: () => true,
			[actions.reset]: () => false
		}],

		loading: [true, Proptypes.bool, {
			[actions.setForm]: () => false,
			[actions.setLoading]: (state, payload) => payload,
			[actions.showToaster]: () => false,
			[actions.resetEditForm]: () => false,
			[actions.reset]: () => true
		}],

		errors: [[], Proptypes.array, {
			[actions.error]: (state, payload) => payload,
			[actions.saveForm]: () => [],
			[actions.createReservation]: () => [],
			[actions.reset]: () => ([])
		}],

		reservations: [[], Proptypes.array, {
			[actions.setReservations]: (state, payload) => payload,
			[actions.reset]: () => ([])
		}],

		selectedServiceArea: [null, Proptypes.number, {
			[actions.setSelectedServiceArea]: (state, payload) => payload,
			[actions.reset]: () => null 
		}],

		status: ['new', Proptypes.oneOf(['new', 'simulated', 'edit']), {
			[actions.setStatusReservation]: (state, payload) => payload,
			[actions.reset]: () => false
		}],

		price: [null, Proptypes.number, {
			[actions.setSimulation]: (state, payload) => payload,
			[actions.reset]: () => ({})
		}],

		paymentMethods: [[], Proptypes.array, {
			[actions.setPaymentMethods]: (_, payload) => payload,
			[actions.reset]: () => ([])
		}],
  
	}),

	start: function * () {
		const {
			fetchForm,
			setLoading,
			setStatusReservation,
			getPaymentMethods
		} = this.actions
		
		const form = yield this.get('form')
		
		const { match } = this.props
		const id = match.params.id
		
		if (id !== 'nova-reserva') {
			yield put(fetchForm())
			yield put(setStatusReservation('edit'))
		} else {
			Object
				.keys(form)
				.forEach(v => form[v].edit = true)
			yield put(setStatusReservation('new'))
			yield put(setLoading(false))
		}
		yield put(getPaymentMethods())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchForm]: workers.fetchForm,
		[actions.getParkingPlaces]: workers.getParkingPlaces,
		[actions.saveForm]: workers.saveForm,
		[actions.createReservation]: workers.createReservation,
		[actions.simulateReservation]: workers.simulateReservation,
		[actions.getPaymentMethods]: workers.getPaymentMethods,
		[actions.getSpotsList]: workers.getSpotsAreaList,
		[actions.cancelReservation] : workers.cancelReservation,
	}),

	workers: {

		* fetchForm () {
			const { 
				setForm,
				showToaster,
				setSelectedServiceArea,
				getSpotsList,
				setSimulation
			} = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			if (!id) {
				return
			}

			try {
				let data = yield call(API.Reservations.getCleaningReservationById, id)
				/*yield put(setReservationStatus(data.status))*/

				const newData = Object.assign({},{
					date: data.date,
					serviceArea: data.serviceArea.id,
					slotsArray: {value: getHours(data.slots, ':'), name: getHours(data.slots)},
					spotObj: {name: '', value: data.spot.number},
					guestName: data.guestInformation.name,
					guestBirthdate: data.guestInformation.birthdate,
					guestNationality: { ...data.guestInformation.nationality },
					guestEmail: data.guestInformation.email,
					guestMobile: data.guestInformation.mobile,
					guestIdentityDocumentType: data.guestInformation.identityDocumentType,
					guestIdentityDocument: data.guestInformation.identityDocument,
					guestNif: data.guestInformation.nif,
					motorhomeModel: data.motorhomeInformation.model,
					motorhomeLicensePlate: data.motorhomeInformation.licensePlateNumber,
					motorhomeInfo: data.motorhomeInformation.otherInformations,
					accompanyingGuestsNumber: data.guestInformation.accompanyingGuestsNumber,
					paymentMethod: { ...data.paymentDetails },
					paymentDetails: data.paymentDetails,
					status: data.status
				})
				const newForm = yield call(
					mapAPIFormStateToForm,
					newData,
					cloneDeep(form)
				)

				yield put(setForm(newForm))
				
				yield put(setSelectedServiceArea(data.serviceArea.id))
				yield put(setSimulation(data.cleaningPrice))
				// find spot name 
				yield put(getSpotsList())
			} catch (err) {
				console.log(err)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}
		},
		
		* getReservations (actionPayload) {
			const  { payload } = actionPayload
			let params = payload
			/*const { history } = this.props*/
			
			if (!params) {
				const visibleTime = yield this.get('visibleTime')
				params = {
					date__gte: moment(visibleTime.visibleTimeStart)
						.format('YYYY-MM-DD'),
					date__lte: moment(visibleTime.visibleTimeEnd)
						.format('YYYY-MM-DD')
				}
			}
			
			const { setReservations } = this.actions
			try {
				const response = yield call(
					API.Reservations.getAreaUnavailCleaning,
					params
				)
				console.log('response', response)
			} catch (e) {
				console.log('error',{...e})
				yield put(setReservations([]))
			}
		},

		* createReservation () {
			const {
				showToaster,
				navigate,
				error,
				setForm
			} = this.actions
			const { t } = this.props
			const form = yield this.get('form')
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, VALIDATIONS)

			if (dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			const mapForm = mapValues(form, ({ value }) => value)
			const params = {...apiCleaningReservation(mapForm)}
			try {
				yield call(
					API.Reservations.createCleaningReservation,
					params
				)
				yield put(navigate('/reserva-utilizacao/'))
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				console.log(err)
				if (err.response && err.response.data) {
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					yield put(error(errors))
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},

		* saveForm () {
			const { showToaster, resetEditForm, error } = this.actions
			const form = yield this.get('form')
			const { match, t } = this.props
			const id = match.params.id
			
			if (!id) {
				return
			}
			
			for (const key in form) {
				// eslint-disable-next-line no-prototype-builtins
				if (form.hasOwnProperty(key)) {
					const element = form[key]
					if (element.edit) {
						patch[key] = element.value
					}
				}
			}

			const params = patch
			
			try {
				yield call(
					API.Reservations.updateCleaningReservation,
					id,
					apiCleaningReservation(params)
				)
				
				yield put(resetEditForm())
				//traducao
				yield put(showToaster(
					'success',
					'Alterações guardadas com sucesso'
				))

			} catch (err) {
				console.log(err)
				const errors = mapApiErrors(
					err.response.data,
					t
				)
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
				yield put(error(errors))
			}
		},

		* simulateReservation () {
			const {
				showToaster,
				error,
				setForm,
				setSimulation,
				/*getPaymentMethods*/
				setStatusReservation,
				changeForm,
				getSpotsList
			} = this.actions
			const form = yield this.get('form')
			const { t } = this.props
			const dirty = yield this.get('dirty')
			const validation = Check.checkValidation(form, VALIDATIONS)

			const serviceArea = yield this.get('selectedServiceArea')
			
			if (dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}
			
			const slots = form.slotsArray.value.map((item) => {
				const [startingTime, endingTime ] = item.split('-')
				return { startingTime, endingTime }
			})
			const date = form.date.value
			const params = {date, slots}
			try {
				const response = yield call(
					API.Reservations.simulateAreaCleaningReservation,
					serviceArea,
					params
				)
				yield put(setSimulation(response.cleaningPrice))
				yield put(changeForm({ 
					spotObj: {
						name: '', value: response.slotNumber
					} 
				}))
				yield put(setStatusReservation('simulated'))
				yield put(getSpotsList())
				/*yield put(getPaymentMethods())*/
			} catch (err) {
				console.log(err)
				if (err.response && err.response.data) {
					const { t } = this.props
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					yield put(error(errors))
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					handleApiErrorMessage(err, t) || t('error.error_fetch')
				))
			}
		},

		* cancelReservation () {
			const { error, navigate } = this.actions
			const { match, t } = this.props
			const id = match.params.id
			try {
				yield call(API.Reservations.cancelAreaCleaningReservation, id)
				yield put(navigate('/reserva-utilizacao/'))
			} catch (err) {
				const errors = mapApiErrors(
					err.response.data,
					t
				)
				yield put(error(errors))
			}
		},

		* getPaymentMethods () {
			const {
				showToaster,
				error,
				setPaymentMethods,
				changeForm
			} = this.actions
			const { t } = this.props
			
			try {
				const params = { enabled: true }
				const response = yield call(
					API.Types.getPaymentMethods,
					{ params }
				)
				yield put(setPaymentMethods(response.results))
				yield put(changeForm({ paymentMethod: response.results[0].id }))
			} catch (err) {
				console.log(err)
				if (err.response && err.response.data) {
					const { t } = this.props
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					yield put(error(errors))
				}
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}			
		},

		* getSpotsAreaList () {
			const { changeForm } = this.actions
			const selectedServiceArea = yield this.get('selectedServiceArea')
			// spot number
			const form = yield this.get('form')
			const spotNumber = form.spotObj.value.value

			try {

				const params = { limit: 'max' }
				const response = yield call(API.Areas.getSpots, selectedServiceArea, params)
				const findSpot = response.results.find(item => item.number === spotNumber)

				yield put(changeForm({ 
					spotObj: {
						name: findSpot.name,
						value: findSpot.number
					} 
				}))

			} catch (e) {
				console.log(e)
			}
		}, 
	}

})
