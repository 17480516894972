import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import {
	Form,
	Input as SemanticInput,
	Dropdown as SemanticDropdown,
	Radio,
} from 'semantic-ui-react'

import BoxSegment from 'components/BoxSegment'
import SegmentTitle from 'components/SegmentTitle'
import FormField from 'components/FormField'
import Buttons from '../Buttons'
import Label from 'components/Label'
import InputUnity from 'components/InputUnity'
import FormGroup from 'components/FormGroup'
import { DEFAULT_VALUES, VALIDATIONS, VALIDATIONS_NO_REQUIRED } from './utils'
import FormRequest from 'components/FormRequest'
import { TimeInput } from 'semantic-ui-calendar-react'
import API from 'api'

class Characteristics extends Component {

	componentDidMount () {

		const { fetchForm } = this.actions

		fetchForm()
	}

	componentWillUnmount () {
		this.submitData()
	}

	submitData = (action) => {
		const { t, current, campsiteId, submit, languageOptions } = this.props
		const { setCurrent, showToaster, updateStep } = this.actions

		const navigate = () => {
			switch (action) {
				case 'next':
					setCurrent(current + 1)
					break
				case 'previous':
					setCurrent(current - 1)
					break
				case '':
					setCurrent(current)
					break
				default:
					break
			}
		}

		submit(data => API.Campsites.steps.updateCharacteristics(campsiteId, data), {
			resolveData: data => {

				if (data.operatingPeriodBegin || data.operatingPeriodEnd) {
					data.operatingPeriod = `${data.operatingPeriodBegin || ''}-${data.operatingPeriodEnd || ''}`
				}
				if (data.regularReceptionHoursBegin || data.regularReceptionHoursEnd) {
					data.regularReceptionHours = `${data.regularReceptionHoursBegin || ''}-${data.regularReceptionHoursEnd || ''}`
				}
				if (data.highSeasonReceptionHoursBegin || data.highSeasonReceptionHoursEnd) {
					data.highSeasonReceptionHours = `${data.highSeasonReceptionHoursBegin || ''}-${data.highSeasonReceptionHoursEnd || ''}`
				}

				for (const { value: language } of languageOptions) {
					if (data.languages.includes(language)) data[language] = true
					else data[language] = false
				}

				return data
			},
			callback: (data, success) => {
				if (success) {
					const { status, updatedAt } = data
					updateStep({ current, data: { status, updatedAt } })
					showToaster(
						'success',
						t('common.success'),
						t('error.success_save_data')
					)
					navigate()
				}
				else if (success === false) {
					showToaster(
						'error',
						t('common.error'),
						t('error.error_save_data')
					)
				}
				else navigate()
			}
		})
	}

	render () {

		const { 
			t,
			form,
			loading,
			changeForm,
			languageOptions,
			terrainTypeOptions,
			accommodationTypeOptions,
			formHasChanged
		} = this.props

		const {
			accommodationType,
			terrainType,
			area,
			capacity,
			openingYear,
			operatingPeriodBegin,
			operatingPeriodEnd,
			regularReceptionHoursBegin,
			regularReceptionHoursEnd,
			highSeasonReceptionHoursBegin,
			highSeasonReceptionHoursEnd,
			petsAllowed,
			languages
		} = form

		return (
			<>
				<BoxSegment loading={loading}>
					<Form>
						<SegmentTitle
							margin={'8px 0 30px 0'}
						>
							{t('form.features')}
						</SegmentTitle>

						<FormGroup widths='equal'>
							<FormField
								id='accommodationType'
								required
								name={'accommodation_type'}
								label={t('form.accommodation_type')}
								error={accommodationType.dirty && !accommodationType.valid && accommodationType.message}
								options={accommodationTypeOptions}
								control={Dropdown}
								value={accommodationType.value}
								onChange={(e, { value }) => changeForm(
									{ accommodationType: value })
								}
								multiple
								selection
							/>
							<FormField
								id='terrainType'
								required
								name={'terrain_type'}
								label={t('form.terrain_type')}
								error={terrainType.dirty && !terrainType.valid && terrainType.message}
								options={terrainTypeOptions}
								control={Dropdown}
								value={terrainType.value}
								onChange={(e, { value }) => changeForm(
									{ terrainType: value })
								}
								multiple
								selection
							/>
						</FormGroup>

						<FormGroup widths='equal'>
							<FormField
								id='area'
								required
								name={'area'}
								label={t('form.area')}
								error={area.dirty && !area.valid && area.message}
								control={InputUnity}
								value={area.value || ''}
								unity='m2'
								onChange={(e, { value }) => changeForm(
									{ area: value })
								}
							/>
							<FormField
								id='capacity'
								required
								name={'capacity'}
								label={t('form.capacity')}
								error={capacity.dirty && !capacity.valid && capacity.message}
								control={InputUnity}
								value={capacity.value || ''}
								unity='uni.'
								onChange={(e, { value }) => changeForm(
									{ capacity: value })
								}
							/>
							<FormField
								id='openingYear'
								required
								name={'opening_year'}
								label={t('form.opening_year')}
								error={openingYear.dirty && !openingYear.valid && openingYear.message}
								control={Input}
								value={openingYear.value || ''}
								onChange={(e, { value }) => changeForm(
									{ openingYear: value })
								}
							/>
						</FormGroup>

						<FormGroup style={{ width: '100%' }}>
							<FormLabel style={{ width: '50%', boxSizing: 'border-box', padding: '0 0.5em' }}>
								{t('form.operating_period')} *
							</FormLabel>
							<FormLabel style={{ width: '50%', boxSizing: 'border-box', padding: '0 0.5em' }}>
								{t('form.regular_reception_hours')} *
							</FormLabel>
						</FormGroup>

						<FormGroup style={{ width: '100%' }}>
							<FormGroup style={{ width: '50%' }}>
								<HalfFormField>
									<TimeInput
										closable
										id='openingYear'
										required
										name='operatingPeriodBegin'
										error={(operatingPeriodBegin.dirty && !operatingPeriodBegin.valid) && operatingPeriodBegin.message}
										animation='none'
										placeholder={t('form.begin')}
										value={operatingPeriodBegin.value}
										onChange={(e, { value }) => changeForm({ operatingPeriodBegin: value })}
									/>
								</HalfFormField>
								<HalfFormField>
									<TimeInput
										closable
										required
										name='operatingPeriodEnd'
										error={(operatingPeriodEnd.dirty && !operatingPeriodEnd.valid) && operatingPeriodEnd.message}
										animation='none'
										placeholder={t('form.end')}
										value={operatingPeriodEnd.value}
										onChange={(e, { value }) => changeForm({ operatingPeriodEnd: value })}
									/>
								</HalfFormField>
							</FormGroup>
							<FormGroup style={{ width: '50%' }}>
								<HalfFormField
									required
									name='regularReceptionHoursBegin'
									// eslint-disable-next-line max-len
									error={(regularReceptionHoursBegin.dirty && !regularReceptionHoursBegin.valid) && regularReceptionHoursBegin.message}
									control={TimeInput}
									animation='none'
									placeholder={t('form.begin')}
									value={regularReceptionHoursBegin.value}
									onChange={(e, { value }) => changeForm({ regularReceptionHoursBegin: value })}
								/>
								<HalfFormField
									required
									name='regularReceptionHoursEnd'
									// eslint-disable-next-line max-len
									error={(regularReceptionHoursEnd.dirty && !regularReceptionHoursEnd.valid) && regularReceptionHoursEnd.message}
									control={TimeInput}
									animation='none'
									placeholder={t('form.end')}
									value={regularReceptionHoursEnd.value}
									onChange={(e, { value }) => changeForm({ regularReceptionHoursEnd: value })}
								/>
							</FormGroup>
						</FormGroup>

						<FormLabel style={{ padding: '0 0.5em' }}>
							{t('form.high_season_reception_hours')} *
						</FormLabel>
						<FormGroup widths='equal'>
							<FormField
								required
								width={5}
								name='highSeasonReceptionHoursBegin'
								// eslint-disable-next-line max-len
								error={(highSeasonReceptionHoursBegin.dirty && !highSeasonReceptionHoursBegin.valid) && highSeasonReceptionHoursBegin.message}
								control={TimeInput}
								animation='none'
								placeholder={t('form.begin')}
								value={highSeasonReceptionHoursBegin.value}
								onChange={(e, { value }) => changeForm({ highSeasonReceptionHoursBegin: value })}
							/>
							<FormField
								width={5}
								name='highSeasonReceptionHoursEnd'
								// eslint-disable-next-line max-len
								error={(highSeasonReceptionHoursEnd.dirty && !highSeasonReceptionHoursEnd.valid) && highSeasonReceptionHoursEnd.message}
								control={TimeInput}
								animation='none'
								placeholder={t('form.end')}
								value={highSeasonReceptionHoursEnd.value}
								onChange={(e, { value }) => changeForm({ highSeasonReceptionHoursEnd: value })}
							/>
						</FormGroup>

						<FormGroup widths={16}>
							<FormField>
								<FormLabel style={{ marginBottom: 15 }}>
									{t('form.pets_allowed')} *
								</FormLabel>
								<FormGroup inline>
									<FormField
										control={Radio}
										label={t('options.no')}
										name='pets_allowed_no'
										checked={petsAllowed.value === false}
										onChange={() => changeForm({ petsAllowed: false })}
									/>
									<FormField
										control={Radio}
										label={t('options.yes')}
										name='pets_allowed_yes'
										checked={petsAllowed.value === true}
										onChange={() => changeForm({ petsAllowed: true })}
									/>
								</FormGroup>
							</FormField>
						</FormGroup>

						<FormField
							required
							name={'languages'}
							label={t('form.languages')}
							error={languages.dirty && !languages.valid && languages.message}
							options={languageOptions}
							control={Dropdown}
							value={languages.value}
							onChange={(e, { value }) => changeForm(
								{ languages: value })
							}
							multiple
							selection
						/>

					</Form>
				</BoxSegment>

				<Buttons
					previousButtonClick={() => this.submitData('previous')}
					nextButtonClick={() => this.submitData('next')}
					saveButtonClick={() => this.submitData('')}
					dirty={formHasChanged}
				/>
			</>
		)
	}
}

const CharacteristicsWithLogic = withTranslation()(Logic(Characteristics))

class FormContainer extends Component {
	render () {

		const { campsite } = this.props
		const { status } = campsite || {}

		return (
			<FormRequest submitChangedDataOnly defaultForm={DEFAULT_VALUES} validations={status === 'draft' ? VALIDATIONS_NO_REQUIRED : VALIDATIONS} initialLoading>
				<CharacteristicsWithLogic {...this.props} />
			</FormRequest>
		)
	}
}

export default FormContainer

const Input = styled(SemanticInput)`
  width: 100%;
`

const FormLabel = styled(Label)`
	color: #616161;
	margin-bottom: 2px;
`

const HalfFormField = styled(FormField)`
	width: 50%;
`

const Dropdown = styled(SemanticDropdown)`
  width: 100%;
`