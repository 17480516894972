import Auth from './calls/auth'
import Areas from './calls/areas'
import Reservations from './calls/reservations'
import Municipalities from './calls/municipalities'
import Campsites from './calls/campsites'
import Types from './calls/types'
import Downloads from './calls/downloads'
import axios from 'axios'

export { default as ENDPOINTS } from './endpoints'
export { default as Interceptor } from './interceptor'
export const noInterceptorAxios = axios.create()

export default {
	Auth,
	Areas,
	Municipalities,
	Reservations,
	Types,
	Downloads,
	Campsites
}