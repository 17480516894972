import { kea } from 'kea'

import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import CampsiteManagementRequestLogic from 'screens/CampsiteManagementRequest/logic'
import CampsiteManagementLogic from 'screens/CampsiteManagement/logic'
import API from 'api'
import { DEFAULT_CONTACT_VALUES } from './utils'

export default kea({
	path: () => ['scenes', 'containers', 'CampingManagementRequestForm', 'Contacts'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			CampsiteManagementRequestLogic, [
				'current',
			],
			CampsiteManagementLogic, [
				'campsiteId'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			CampsiteManagementRequestLogic, [
				'setCurrent',
				'updateStep'
			],
		]
	},

	actions: () => ({
		fetchForm: () => true
	}),

	reducers: () => ({
		
	}),

	start: function * () {
		
	},

	takeLatest: ({actions, workers}) => ({
		[actions.fetchForm]: workers.fetchForm
	}),

	workers: {

		* fetchForm () {
			
			const { fetchData } = this.props
			const campsiteId = yield this.get('campsiteId')

			fetchData((data, config) => API.Campsites.steps.getContacts(campsiteId, config), {
				resolveData: data => {

					if (!data.contacts || !data.contacts.length) {
						data.contacts = [{...DEFAULT_CONTACT_VALUES}]
					}

					return data
				}
			})

		},
	}
})
