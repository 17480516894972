/* eslint-disable react/display-name */
/**
 * Deletebutton Component
 *
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 * @author João Guiomar <jguiomar@ubiwhere.com>
 */
import React from 'react'
import styled from 'styled-components'
import DeleteIcon from './images/delete.svg'

export default (props) => (
	<Button {...props}>
		<img src={DeleteIcon} alt={'Remover'} />
	</Button>
)

const Button = styled.button`
  margin: 0 !important;
  padding: 0;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border: 1px solid #DDDDDD;
  border-radius: 100px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 2px 0 rgba(97, 97, 97, 0.2);
  color: #616161;
  position: relative;

  img {
    width: 10px;
    height: 10px;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`
