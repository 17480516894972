import * as React from 'react'

const IcMotorhome = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-motorhome</title>
			<g id="icons/servicos/ic-motorhome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M24,6.86171875 L24,4.02709375 L0,4.02709375 L0,18.2835625 L1.04273437,18.2835625 C1.3618125,16.9229219 2.58454688,15.9064375 4.04104688,15.9064375 C5.4973125,15.9064375 6.71985938,16.9229219 7.03884375,18.2835625 L8.08157813,18.2835625 L8.08157813,6.86171875 L14.90925,6.86171875 L14.90925,18.2835625 L16.1599219,18.2835625 C16.4789063,16.9229687 17.7015,15.9064844 19.1578125,15.9064844 C20.6142656,15.9064844 21.837,16.9229687 22.1560312,18.2835625 L22.9905469,18.2835625 L22.9905469,14.9244062 L16.6656094,14.9244062 L16.6656094,6.86171875 L24,6.86171875 Z M4.041,17.3126875 C4.96378125,17.3126875 5.71448438,18.063625 5.71448438,18.9866406 C5.71448438,19.9095625 4.96378125,20.6604062 4.041,20.6604062 C3.11798438,20.6604062 2.36704688,19.9095156 2.36704688,18.9866406 C2.36704688,18.063625 3.11798438,17.3126875 4.041,17.3126875 Z M19.1578125,17.3126875 C20.0807813,17.3126875 20.8317188,18.063625 20.8317188,18.9866406 C20.8317188,19.9095625 20.0807813,20.6604062 19.1578125,20.6604062 C18.2350313,20.6604062 17.4842813,19.9095156 17.4842813,18.9866406 C17.4842813,18.063625 18.2350313,17.3126875 19.1578125,17.3126875 Z M13.5032813,8.26796875 L13.5032813,18.28375 L9.48796875,18.28375 L9.48796875,8.26796875 L13.5032813,8.26796875 Z M20.2963594,8.26796875 L21.3264375,13.5181562 L18.0718594,13.5181562 L18.0718594,8.26796875 L20.2963594,8.26796875 Z M6.32521875,6.86171875 L6.32521875,11.430625 L1.75635937,11.430625 L1.75635937,6.86171875 L6.32521875,6.86171875 Z" id="icon" fill="#000000"/>
			</g>
		</svg>
	)

export default IcMotorhome
