import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import theme from 'src/globalStyles'
import Logic from './logic'
import moment from 'moment'

import {  Icon as SemanticIcon, Segment } from 'semantic-ui-react'
/** components */
import { CheckIcon } from 'components/Icons'
import Button from 'components/Button'
import CampsiteSubmit from 'containers/Modals/CampsiteSubmit'
import CampsiteSubmitResult from '../../Modals/CampsiteSubmitResult'
import ConfirmButton from 'containers/ConfirmButton'

class FormNavigation extends Component {

	getSubmitButton = status => {

		if (!status) return null

		const {t, canSubmitRegistration} = this.props
		const { setSubmitPopupOpen, archive, unarchive } = this.actions

		switch (status) {
			case 'draft':
				return (
					<SubmitButton
						style={{ marginTop: 20 }}
						onClick={() => setSubmitPopupOpen(true)}
						primary
						disabled={!canSubmitRegistration}
					>
						{t('common.submit')}
					</SubmitButton>
				)
			case 'disabled':
				return (
					<SubmitButton
						style={{ marginTop: 20 }}
						onClick={unarchive}
						primary
					>
						{t('form.unarchive')}
					</SubmitButton>
				)
			default:
				return (
					<ConfirmButton
						secondary
						label={t('form.to_file')}
						icon={<Icon color='grey' name='archive' />}
						content={t('form.to_file_info')}
						style={{ 
							width: 250,
							height: 40, 
							background: '#cc0000',
							color: 'white'
						}}
						onClick={archive}
					/>
				)
				
		}
	}

	render () {
		const {
			t,
			steps,
			current,
			campsite,
			submitPopupOpen,
			submitResult
		} = this.props

		const { setCurrent, submitRegistration, setSubmitPopupOpen, setSubmitResult } = this.actions
		const { updatedAt } = steps[current - 1] || {}
		const { status: campsiteStatus } = campsite || {}

		return (
			<Wrapper hide={0}>
				<ContainerStep>
					{steps.map(({ id, hide, linked, status, title }, index) => {
						if (hide) return null
						
						if (linked) return (
							<Linked
								key={`step-${id}`}
								selected={id === current}
								status={status}
							>
								<Line 
									numb={index}
									selected={id === current}
									status={status}
								/>
								<Container key={`step-${id}`}
									selected={id === current}
									onClick={() => setCurrent(id)}
									status={status}
								>
									<Bullet 
										selected={id === current}
										status={status}
									/>

									{t(`form.${title}`)}
								</Container>
							</Linked>
						)

						if (!linked) return (
							<Container key={`step-${id}`}
								onClick={() => setCurrent(id)}
								selected={id === current}
								status={status}
							>
								<Bullet 
									selected={id === current}
									status={status}
								>
									{status === 'complete' && (
										<CheckIcon width={12} height={12} />
									)}

									{status === 'pending' && (
										'i'
									)}

								</Bullet>

								{t(`form.${title}`)}
							</Container>
						)
					})}
				</ContainerStep>
				{updatedAt &&
					<LastChanged>
						<strong>{t('form.last_changed')}: </strong>
						{moment(updatedAt).format('YYYY-MM-DD')}
					</LastChanged>
				}

				{this.getSubmitButton(campsiteStatus)}

				<CampsiteSubmit
					open={submitPopupOpen}
					onClose={() => setSubmitPopupOpen(false)}
					onSubmit={submitRegistration}
				/>

				<CampsiteSubmitResult
					open={submitResult !== null}
					onClose={() => setSubmitResult(null)}
					success={submitResult}
					onTryAgain={() => {
						setSubmitResult(null)
						setSubmitPopupOpen(true)
					}}
				/>

			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(FormNavigation))

const Wrapper = styled(Segment)`
  &&&& {
    border: none;
    box-shadow: none;
    margin-top: 72px;
		padding: 0;
		max-width: 250px;
		background-color: #FAFAFA;

    ${({ hide }) => hide && `
      display: none;
    `}
  }
`

const LastChanged = styled.p`
  margin-bottom: 16px;
  font-size: 12px;
  color: #5B5B5B;
  font-weight: 400;
	font-family: ${theme.inputFontFamily};
	text-align: center;
`

const Linked = styled.div`
  display: flex;
  flex-direction: column;
	position: relative;
	height: 45px;
`

const SubmitButton = styled(Button)`
  &&&& {
    width: 100%;
    max-width: 250px;
    margin-top: 8px;
    text-align: center;
    max-height: 30px;
    padding: 18px 0;
  }
`

const Line = styled.div`
	width: 2px;
	${({ numb }) => numb && console.log('numb', numb) }
  height: calc(${({ numb }) => numb ? 45 : 0}px);
  position: absolute;
  top: 10px;
  left: 6px;

  ${({ selected }) => selected && `
    background-color: #000;
	`}
	
	${({ status }) => status  === 'pending' && `
		background-color: transparent;
		border-right: 2px dotted #000;
  `}
`

const ContainerStep = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	margin-bottom: 48px;
	align-items: center;
`

const Container = styled.div`
	width: 180px;
	border-radius: 25px;
	padding: 0 15px;
	background-color: none;
	display: flex;
	height: 45px;
	align-items: center;
  flex-direction: row;
  cursor: pointer;
  font-size: 12.8px;
  color: #102526;
  font-family: ${theme.inputFontFamily};
	transition: background-color ease-in-out 0.3s;

  ${({ selected }) => selected && `
    background-color: #eaeaea;
  `}
`

const Icon = styled(SemanticIcon)`
  &&&& {
    color: white !important;
  }  
`

const Bullet = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 15px;
  border: .5px solid #9B9B9B;
  background-color: #fff;  

  ${({ selected }) => selected && `
    border: 4px solid #102526;
  `}

  ${({ status }) => {
		if (status === 'pending') return `
      border: none;
      background-color: #102526;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 600;
    `
    
		if (status === 'complete') return `
      border: none;
      background-color: #102526;
      display: flex;
      align-items: center;
      justify-content: center;
    `
	}}
`