/**
 * PasswordMask Component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 * @author Nuno Gago <ngago@ubiwhere.com>
 * 
 */

import React, { Component } from 'react'
import styled from 'styled-components'

import  { EyeIcon, NoEyeIcon } from 'components/Icons'

export default class PasswordMask extends Component {
	constructor (props) {
		super(props)

		this.state = {
			loginPassword: false,
			registerPassword: false,
			registerConfirmationPassword: false
		}
	}

	handleClick (name) {
		this.setState(
			({ [name]: !this.state[name] }),
			() => this.props.getPasswordState(name, this.state[name])
		)
	}

	render () {
		const { name } = this.props
		return (
			<Wrapper onClick={() => this.handleClick(name)}>
				{this.state[name] ? <NoEyeIcon /> : <EyeIcon />}
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
    position: absolute;
    top: 32px;
    right: 20px;
    cursor: pointer;
`
