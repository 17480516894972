import * as Check from 'validations'

const DEFAULT_VALUES = {
	hasFacilitiesRegulationDocument: {
		value: null
	},
	facilitiesRegulationDocument: {
		value: null,
	},
	hasCityCouncilPermissionDocument: {
		value: null
	},
	cityCouncilPermissionDocument: {
		value: null
	},
	hasCampsiteBlueprint: {
		value: null
	},
	campsiteBlueprint: {
		value: null
	},
	hasPriceMenu: {
		value: null
	},
	priceMenu: {
		value: null
	},
	otherDocumentName: {
		value: ''
	},
	otherDescription: {
		value: ''
	},
	otherDocument: {
		value: ''
	}
}

const optionalIsRequired = (value, shouldValidate) => shouldValidate ? Check.isRequired(value) : {result: true}

const VALIDATIONS = {
	hasFacilitiesRegulationDocument: [
		Check.isRequired
	],
	facilitiesRegulationDocument: [
		(value, form) => optionalIsRequired(value, form.hasFacilitiesRegulationDocument.value)
	],
	hasCityCouncilPermissionDocument: [
		Check.isRequired
	],
	cityCouncilPermissionDocument: [
		(value, form) => optionalIsRequired(value, form.hasCityCouncilPermissionDocument.value)
	],
	hasCampsiteBlueprint: [
		Check.isRequired
	],
	campsiteBlueprint: [
		(value, form) => optionalIsRequired(value, form.hasCampsiteBlueprint.value)
	],
	hasPriceMenu: [
		Check.isRequired
	],
	priceMenu: [
		(value, form) => optionalIsRequired(value, form.hasPriceMenu.value)
	],
}

const VALIDATIONS_NO_REQUIRED = {

}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}