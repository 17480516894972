// @flow
import moment from 'moment'

export const getCalendarMonths = (visibleTimeStart, visibleTimeEnd) => {
	const timeStartMonth = moment(visibleTimeStart).month()
	const timeEndMonth = moment(visibleTimeEnd).month()
	
	if (timeStartMonth === timeEndMonth) {
		return moment(visibleTimeStart).format('MMM YYYY')
	} else {
		const startMonth = moment(visibleTimeStart).format('MMM')
		const endMonth = moment(visibleTimeEnd).format(' - MMM YYYY')
		
		return startMonth + endMonth
	}
}
