import React, { PureComponent } from 'react'
//import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import FCMP from './images/fcmp.svg'
//import IPDJ from './images/IPDJ.svg'
import TURISMO from './images/turismo_portugal.svg'

//import TEXT_PLACEHOLDER from './dummy'

export default class Footer extends PureComponent {
	render () {
		const { hide } = this.props
		return (
			<Wrapper hide={hide}>
				<Supporters>
					<SupportersWrapper>
						<Image src={FCMP} alt={'FCMP'} />
						{/* <Image src={IPDJ} alt={'IPDJ'} />*/}            
						<Image src={TURISMO} alt={'TURISMO_PORTUGAL'} />
					</SupportersWrapper>
				</Supporters>
				<Copyright>
					{'© 2020 Outdoor Routes. Todos os direitos reservados. '}
					{/*
            <Modal trigger={<Therms>Termos e Condições</Therms>} closeIcon>
              <Modal.Header>Termos e Condições</Modal.Header>
              <Modal.Content scrolling>
                <Text>
                  {TEXT_PLACEHOLDER}
                </Text>
              </Modal.Content>
            </Modal>
            {` | `}
            <Modal trigger={<Therms> Política de Privacidade</Therms>} closeIcon>
              <Modal.Header>Política de Privacidade</Modal.Header>
              <Modal.Content scrolling>
                <Text>
                  {TEXT_PLACEHOLDER}
                </Text>
              </Modal.Content>
            </Modal>
          */}
				</Copyright>
			</Wrapper>
		)
	}
}

const Wrapper = styled.footer`
  margin: 0px -80px 0px -80px;
  height: 190px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 0 0 #EEEEEE;

  ${({ hide }) => hide && `
    display: none;
  `}
`

const SupportersWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
`

const Supporters = styled.div`
  height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 30px;
`

const Copyright = styled.div`
  height: 60px;
  text-align: center;
  background-color: #f8f8f8;
  color: #7c7c7c;
  box-shadow: inset 0 1px 0 0 rgba(0,0,0,.1);
  font-size: 14px;
  padding: 20px 0;
  font-family: Open Sans;
`
/*
const Therms = styled.span`
  text-decoration: underline;
  cursor: pointer;
`*/

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-right: 45px;
`
/*
const Text = styled.p`

`*/
