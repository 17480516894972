import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { 
	Grid,
	Radio,
	Dimmer,
	Loader
} from 'semantic-ui-react'

import Logic from './logic'
// Components
import Segment from 'components/Segment'
import SegmentTitle from 'components/SegmentTitle'
import { CalendarRemove } from 'components/Icons'
import ConfirmButton from 'containers/ConfirmButton'
class UsagePayment extends Component {

	render () {
		const {	t, paymentMethods, price, form, status, loading } = this.props
		const { changeForm, cancelReservation } = this.actions
		const { paymentMethod, status: reservationStatus, paymentDetails } = form

		const { value } = paymentDetails

		let ccInfo = {}

		if (value) {
			const {
				ccLastDigits,
				ccName,
				expirationMonth,
				expirationYear,
				mbEntity,
				mbReference
			} = value

			ccInfo = {
				ccLastDigits,
				ccName,
				expirationMonth,
				expirationYear,
				mbEntity,
				mbReference
			}
									
		}

		if (loading) {
			return (
				<Segment>
					<SegmentTitle margin={'0 0 26px 0'}>
						{t('booking_details.payment')}
					</SegmentTitle>
					<Dimmer inverted active>
						<Loader inverted/>
					</Dimmer>
				</Segment>
			)
		}

		return (
			<Wrapper>
				<SegmentTitle
					margin={'0 0 26px 0'}
				>
					{t('booking_details.payment')}
				</SegmentTitle>
				<Grid>
					<Grid.Row columns={2}>
						{!reservationStatus.value && paymentMethods.length > 0 &&
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{t('booking_details.payment_methods')}
								</InputLabel>
							</Grid.Row>
							<Grid.Row>
								<RadioLabel>
									{t('booking_details.payment_methods_select')}
								</RadioLabel>
							</Grid.Row>
							{paymentMethods.map(method => (
								<Grid.Row
									key={method.id}
									style={{ marginTop: '16px' }}
								>
									<Radio
										label={method.name}
										name={method.name}
										value={method.id}
										// eslint-disable-next-line max-len
										checked={paymentMethod.value === method.id}
										onChange={() => changeForm({
											paymentMethod: method.id
										})}
									/>
								</Grid.Row>
							))}
						</Grid.Column>
						}
						<Grid.Column>
							<Grid.Row>
								<InputLabel>
									{reservationStatus.value === 'confirmed' ? t('booking_details.values_paid'): t('booking_details.values_pay')}
								</InputLabel>
							</Grid.Row>
							<Grid>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Label>
											{t('booking_details.price_motorhome')}
										</Label>
									</Grid.Column>
									<Grid.Column>
										<Value>{`${price || 0} €`}</Value>
									</Grid.Column>
								</Grid.Row>
								{/*<Grid.Row columns={2}>
									<Grid.Column>
										<Label>
											{t('booking_details.price_service')}
										</Label>
									</Grid.Column>
									<Grid.Column>
										<Value>{`${slotPrice || 0} € `}</Value>
									</Grid.Column>
								</Grid.Row>*/}
								<Grid.Row columns={2}>
									<Grid.Column>
										<TotalPriceLabel>
											{reservationStatus.value === 'confirmed' ? t('booking_details.total_paid') : t('booking_details.price_total')}
										</TotalPriceLabel>
									</Grid.Column>
									<Grid.Column>
										<Value>{`${price || 0} €`}</Value>
									</Grid.Column>
								</Grid.Row>
								{status === 'edit' && reservationStatus.value !== 'expired' && reservationStatus.value !== 'canceled' &&
								<Grid.Row
									width={16}
									style={{
										display: 'flex',
										justifyContent: 'flex-end'
									}}
								>
									<ConfirmButton
										secondary
										label={t('booking_details.cancel')}
										icon={
											<CalendarRemove
												width={20}
												height={20}
												style={{marginRight: 12}}
												fill='#616161'
											/>}
										content={t('booking_details.cancel_reservation_modal')}
										onClick={cancelReservation}
									/>
									
								</Grid.Row>
								}
							</Grid>
						</Grid.Column>
						{reservationStatus && reservationStatus !== 'simulated' && (
							<Grid.Column>
								<Grid.Row>
									<InputLabel>
										{t('booking_details.payment_details')}		
									</InputLabel>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Grid>
											{paymentDetails.value && paymentDetails.value.method !== '' &&
											paymentDetails.value.paymentMethod.name && paymentDetails.value.paymentMethod.name !== '' &&
											(
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>
															{t('booking_details.payment_methods')}
														</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>
															{paymentDetails.value ? paymentDetails.value.method ? paymentDetails.value.method
																: paymentDetails.value.paymentMethod.name ? paymentDetails.value.paymentMethod.name  : '' : ''}
														</Value>
													</Grid.Column>
												</Grid.Row>
											)}
											{ccInfo.ccLastDigits && ccInfo.ccLastDigits !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.cc_last_digits')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>XXXX-XXXX-XXXX-{ccInfo.ccLastDigits}</Value>
													</Grid.Column>
												</Grid.Row>)}
											{ccInfo.ccName && ccInfo.ccName !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.cc_name')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>{ccInfo.ccName}</Value>
													</Grid.Column>
												</Grid.Row>
											)}
											{ccInfo.expirationMonth && ccInfo.expirationYear && ccInfo.expirationMonth !== '' && ccInfo.expirationYear !== '' && 
										(
											<Grid.Row columns={2}>
												<Grid.Column>
													<Label>{t('booking_details.cc_expiration')}</Label>
												</Grid.Column>
												<Grid.Column>
													<Value>{ccInfo.expirationMonth} / {ccInfo.expirationYear}</Value>
												</Grid.Column>
											</Grid.Row>
										)}
											{ccInfo.mbEntity && ccInfo.mbEntity !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.mb_entity')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>{ccInfo.mbEntity}</Value>
													</Grid.Column>
												</Grid.Row>
											)}
											{ccInfo.mbReference && ccInfo.mbReference !== '' && (
												<Grid.Row columns={2}>
													<Grid.Column>
														<Label>{t('booking_details.mb_reference')}</Label>
													</Grid.Column>
													<Grid.Column>
														<Value>{ccInfo.mbReference}</Value>
													</Grid.Column>
												</Grid.Row>
											)}
										</Grid>
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
						)}
					</Grid.Row>
				</Grid>
			</Wrapper>
		)
	}
}

export default withTranslation()(Logic(UsagePayment))

const Wrapper = styled(Segment)`
	padding: 32px 40px !important;
`

const InputLabel = styled.p`
	color: #102526;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 18px;
	padding-bottom: 27px;
`

const Label = styled.p`
  opacity: 0.6;
  color: #102526;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
	line-height: 12px;
	text-transform: uppercase;
`

const Value = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
`

const TotalPriceLabel = styled.p`
  color: #102526;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
	line-height: 16px;
	font-weight: bold;
	text-transform: uppercase;
`

const RadioLabel = styled.p`
  color: #616161;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
`