/* eslint-disable react/display-name */
import React from 'react'
import * as Check from 'validations'
import Tab from 'components/TabFilter'

import Installation from './Installation'
import ServiceArea from './ServiceArea'
import Equipments from './Equipments'
import OtherEquipments from './OtherEquipments'

export const PANES = (t, form, actions) => [
	{
		menuItem: t('navigation.installation'),
		render: () => (
			<Tab.Pane>
				<Installation 
					key={1}
					form={form}
					changeForm={actions.changeForm}
				/>
			</Tab.Pane>
		)
	},
	{
		menuItem: t('navigation.service_area'),
		render: () => (
			<Tab.Pane>
				<ServiceArea 
					key={2}
					form={form}
					changeForm={actions.changeForm}
				/>
			</Tab.Pane>
		)
	},
	{
		menuItem: t('navigation.equipments'),
		render: () => (
			<Tab.Pane>
				<Equipments 
					key={3}
					form={form}
					changeForm={actions.changeForm}
				/>
			</Tab.Pane>
		)
	},
	{
		menuItem: t('navigation.other_equipments'),
		render: () => (
			<Tab.Pane>
				<OtherEquipments 
					key={4}
					form={form}
					actions={actions}
				/>
			</Tab.Pane>
		)
	}
] 

export const DEFAULT_VALUES = {
	area: {
		value: ''
	},
	capacity: {
		value: ''
	},
	capacityNumber: {
		value: ''
	},
	capacityNumberReducedMobility: {
		value: ''
	},
	operatingHours: {
		value: ''
	},
	timeOfYear: {
		value: ''
	},
	spotPrice: {
		value: ''
	},
	/*usagePrice: {
		value: ''
	},*/
	electricitySupply: {
		value: 'included'
	},
	electricityPrice: {
		value: ''
	},
	potableWaterSupply: {
		value: 'included'
	},
	potableWaterPrice: {
		value: ''
	},
	checkInHours: {
		value: ''
	},
	checkOutHours: {
		value: ''
	},
	startingTime: {
		value: ''
	},
	endingTime: {
		value: ''
	},
	duration: {
		value: ''
	},
	cleaningPrice: {
		value: ''
	},
	cleaningSlots: {
		value: ''
	},
	sanitaryFacilities: {
		value: false
	},
	reception: {
		value: false
	},
	receptionType: {
		value: ''
	},
	lobby: {
		value: false
	},
	gate: {
		value: false
	},
	fencing: {
		value: false
	},
	surveillanceService: {
		value: false
	},
	firstAidEquipment: {
		value: false
	},
	laundryPlaces: {
		value: false
	},
	laundryPlacesQuantity: {
		value: '0'
	},
	publicLightingSystem: {
		value: false
	},
	emergencySystem: {
		value: false
	},
	garbageContainers: {
		value: false
	},
	garbageContainersQuantity: {
		value: '0'
	},
	cafeRestaurant: {
		value: false,
	},
	vendingMachines: {
		value: false,
	},
	tablesChairs: {
		value: false,
	},
	wifi: {
		value: false,
	},
	equipmentName: {
		value: []
	},
	potableWaterSupplyServiceArea: {
		value: false
	},
	wastewaterRunoff: {
		value: false
	},
	disposalUrbanWaste: {
		value: false
	},
	quantity: {
		value: 0
	},
	disposalSanitaryCassettes: {
		value: false
	},
	emptyingChemicals: {
		value: false
	},
	washingSystem: {
		value: false
	},
	grids: {
		value: false
	}
}

export const VALIDATIONS = {
	area: [
		Check.isNumber,
		Check.isRequired,
		(value) => Check.maxNumber(value, 99999)
	],
	capacity: [
		Check.isRequired,
		Check.isNumber,
		(value) => Check.maxNumber(value, 100)
	],
	capacityNumber: [
		Check.isRequired,
		Check.isNumber,
		(value) => Check.maxNumber(value, 100)
	],
	capacityNumberReducedMobility: [
		Check.isRequired,
		Check.isNumber,
		(value) => Check.maxNumber(value, 100)
	],
	operatingHours: [
		Check.isRequired
	],
	timeOfYear: [
		Check.isRequired
	],
	spotPrice: [
		Check.isMonetaryNumber,
		(value) => Check.maxNumber(value, 9999)
	],
	/*usagePrice: [
		Check.isNumber
	],*/
	/*guestPrice: [
		Check.isNumber
	],*/
	checkInHours: [
		Check.validHHMMFormat,
		Check.isRequired,
		(value, form) => Check.isValidCheckInCheckOutHour({ value }, form.checkOutHours)
	],
	checkOutHours: [
		Check.validHHMMFormat,
		Check.isRequired,
		(value, form) => Check.isValidCheckInCheckOutHour(form.checkInHours, { value })
	],
	startingTime: [
		Check.validHHMMFormat
	],
	endingTime:[
		Check.validHHMMFormat
	],
	duration: [
		Check.validHHMMFormat,
		Check.checked5Hours
	],
	cleaningPrice: [
		Check.isMonetaryNumber
	],
	cleaningSlots: [
		Check.isNumber
	],
	electricityPrice: [
		Check.hasEletricity,
		Check.isMonetaryNumber,
		value => Check.maxNumber(value, 9999)
	],
	potableWaterPrice: [
		Check.hasWaterSypply,
		Check.isMonetaryNumber,
		value => Check.maxNumber(value, 9999)
	],
	laundryPlacesQuantity: [
		Check.hasLaundryPlaces,
		Check.isNumber,
	],
	garbageContainersQuantity: [
		Check.hasGarbageContainers,
		Check.isNumber
	],
	quantity: [
		Check.hasGrids,
		Check.isNumber,
	]
}

export const DEFAULT_TIME_YEAR = [
	{
		value: 1,
		text: 'Todo o Ano',
		key: 'all'
	},
	{ 
		value: 2,
		text: 'Apenas Inverno',
		key:	'winterOnly'
	},
	{ 
		value: 3,
		text: 'Apenas Verão',
		key:	'SummerOnly'
	},
	{ 
		value: 4,
		text: 'Primavera e Verão',
		key:	'springSummer'
	},
	{ 
		value: 5,
		text: 'Outono e Inverno',
		key:	'fallWinter'
	}
]

export const DEFAULT_RECEPTION_TYPES = [
	{
		value: 1,
		text: 'Presencial',
		key: 'presential'
	},
	{
		value: 2,
		text: 'Automática',
		key: 'automatic'
	}
]

export const convertBooleansForm = newForm => {
	const status = ['included', 'unavailable', 'paid locally']

	if (!status.includes(newForm.electricitySupply.value)) {
		newForm.electricitySupply.value= 'included'
		newForm.electricityPrice.value = ''
	}

	if (!status.includes(newForm.potableWaterSupply.value)) {
		newForm.potableWaterSupply.value = 'included'
		newForm.potableWaterPrice.value = ''
	}

	if (typeof newForm.sanitaryFacilities.value !== 'boolean') {
		newForm.sanitaryFacilities.value = false
	}

	if (typeof newForm.reception.value !== 'boolean') {
		newForm.reception.value = false
	}

	if (typeof newForm.lobby.value !== 'boolean') {
		newForm.lobby.value = false
	}

	if (typeof newForm.gate.value !== 'boolean') {
		newForm.gate.value = false
	}

	if (typeof newForm.fencing.value !== 'boolean') {
		newForm.fencing.value = false
	}
	
	if (typeof newForm.surveillanceService.value !== 'boolean') {
		newForm.surveillanceService.value = false
	}
	if (typeof newForm.firstAidEquipment.value !== 'boolean') {
		newForm.firstAidEquipment.value = false
	}

	if (typeof newForm.laundryPlaces.value !== 'boolean') {
		newForm.laundryPlaces.value = false
	}

	if (typeof newForm.publicLightingSystem.value !== 'boolean') {
		newForm.publicLightingSystem.value = false
	}

	if (typeof newForm.emergencySystem.value !== 'boolean') {
		newForm.emergencySystem.value = false
	}

	if (typeof newForm.garbageContainers.value !== 'boolean') {
		newForm.garbageContainers.value = false
	}
	
	if (typeof newForm.cafeRestaurant.value !== 'boolean') {
		newForm.cafeRestaurant.value = false
	}

	if (typeof newForm.vendingMachines.value !== 'boolean') {
		newForm.vendingMachines.value = false
	}

	if (typeof newForm.tablesChairs.value !== 'boolean') {
		newForm.tablesChairs.value = false
	}

	if (typeof newForm.wifi.value !== 'boolean') {
		newForm.wifi.value = false
	}

	if (typeof newForm.wastewaterRunoff.value !== 'boolean') {
		newForm.wastewaterRunoff.value = false
	}

	if (typeof newForm.disposalUrbanWaste.value !== 'boolean') {
		newForm.disposalUrbanWaste.value = false
	}

	if (typeof newForm.disposalSanitaryCassettes.value !== 'boolean') {
		newForm.disposalSanitaryCassettes.value = false
	}

	if (typeof newForm.emptyingChemicals.value !== 'boolean') {
		newForm.emptyingChemicals.value = false
	}

	if (typeof newForm.washingSystem.value !== 'boolean') {
		newForm.washingSystem.value = false
	}
	
	/*	if (typeof newForm.potableWaterSupply.value !== 'boolean') {
		newForm.potableWaterSupply.value = false
	}
	*/

	if (typeof newForm.grids.value !== 'boolean') {
		newForm.grids.value = false
	}
	
	newForm.equipmentName.value = newForm.equipmentName.value.map(name => ({
		name: { value: name }
	}))
	
	return newForm
}
