import { kea } from 'kea'

import ToasterLogic from 'containers/Toaster/logic'
import AppLogic from 'containers/App/logic'
import { call, put } from 'redux-saga/effects'
import API from 'api'

import Proptypes from 'prop-types'

export default kea({
	path: () => ['scenes', 'CampsiteManagement'],

	connect: {
		actions: [
			AppLogic, [
				'navigate'
			],
			ToasterLogic, [
				'show as showToaster'
			]
		]
	},
	actions: () => ({
		setCampsite: campsites => campsites,
		setCampsiteId: id => id,
		createCampsite: () => true,
		reset: () => true,

		setCampsites: campsites => campsites,
		getCampsites: () => true,
		changePage: (page) => ({ page }),
		changeLimit: (limit) => ({ limit }),
		setPagination: (pagination) => ({ ...pagination }),
		setCurrentTab: index => index,
		updateCampsite: data => data,
		setSubmitting: value => value
	}),

	reducers: ({actions}) => ({

		submitting: [false, Proptypes.bool, {
			[actions.setSubmitting]: (state, value) => value
		}],

		campsite: [null, Proptypes.object, {
			[actions.setCampsite]: (_, data) => data,
			[actions.updateCampsite]: (state, data) => ({...state, ...data}),
			[actions.reset]: () => null
		}],

		campsiteId: [null, Proptypes.number, {
			[actions.setCampsiteId]: (_, id) => id,
			[actions.reset]: () => null
		}],

		currentTab: [0, Proptypes.number, {
			[actions.setCurrentTab]: (_, index) => index,
			[actions.reset]: () => 0
		}],

		isFetchingData: [true, Proptypes.bool, {
			[actions.setCampsites]: () => false,
			[actions.setCurrentTab]: () => true,
			[actions.reset]: () => true
		}],

		campsites: [[], Proptypes.array, {
			[actions.setCampsites]: (_, campsites) => campsites,
			[actions.reset]: () => []
		}],

		limit: [5, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.limit,
			[actions.changeLimit]: (state, payload) => payload.limit
		}],

		offset: [0, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.offset,
			[actions.changeLimit]: () => 0
		}],

		count: [5, Proptypes.number, {
			[actions.setPagination]: (state, payload) => payload.count
		}],

		hasNext: [false, Proptypes.bool, {
			[actions.setPagination]: (state, payload) => payload.hasnext
		}],

		hasPrevious: [false, Proptypes.bool, {
			[actions.setPagination]: (state, payload) => payload.hasPrevious
		}],

		page: [0, Proptypes.number, {
			[actions.changePage]: (state, payload) =>
				payload.page === 'next' ? state + 1 : state - 1,
			[actions.setCurrentTab]: () => 0,
			[actions.changeLimit]: () => 0
		}],

	}),

	start: function * () {
		//const { getCampsites } = this.actions
		
		//yield put(getCampsites())
	},

	takeLatest: ({actions, workers}) => ({
		[actions.createCampsite]: workers.createCampsite,
		[actions.getCampsites]: workers.getCampsites,
		[actions.changeLimit]: workers.getCampsites,
		[actions.changePage]: workers.getCampsites,
		[actions.setCurrentTab]: workers.getCampsites,
	}),

	workers: {

		* getCampsites () {

			const {
				setCampsites,
				setPagination,
				showToaster
			} = this.actions

			const { t } = this.props
			const limit = yield this.get('limit')
			const page = yield this.get('page')
			const currentTab = yield this.get('currentTab')
			const offset = limit * page
			const sort = '-created_at'
			
			const filter = currentTab === 1 ? {has_clean_and_safe_stamp: true} : currentTab === 2 ? {status: 'draft'} : {}
			
			let params = { limit, offset, sort, ...filter }
			
			try {
				const {
					count,
					results,
					next,
					previous
				} = yield call(API.Campsites.get, { params })

				yield put(setPagination({
					...params,
					hasnext: !!next,
					hasPrevious: !!previous,
					count
				}))
				
				yield put(setCampsites(results))
				
			} catch (err) {
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.process_start_error')
				))
			}
		},

		* createCampsite () {
			const {
				//setCampsite,
				setCampsiteId,
				showToaster,
				navigate,
				setSubmitting
			} = this.actions

			const { t } = this.props

			try {
				yield put(setSubmitting(true))
				
				const request = yield call(API.Campsites.create)
				
				//const campsite = yield call(API.Campsites.getById, request.id)
				yield put(setCampsiteId(request.id))
				//yield put(setCampsite(campsite))
				yield put(navigate(`/parques-campismo/${request.id}`))

			} catch (err) {
				console.log(err)
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.process_start_error')
				))
			}
			yield put(setSubmitting(false))
		}
	}
})
