import { withTranslation } from 'react-i18next'
import React, { Component } from 'react'
import styled from 'styled-components'
import Logic from './logic'

import Main from 'components/Main'
import Container from 'components/ContainerWrapper'

import Header from 'containers/Header'
import Master from 'containers/Master'
import Loading from 'containers/Loading'
import Toaster from 'containers/Toaster'

class App extends Component {

	componentDidUpdate (prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	shouldComponentUpdate (nextProps) {
		return this.props.loading !== nextProps.loading
      || this.props.fatalError !== nextProps.fatalError
      || this.props.locations !== nextProps.location
	}

	render () {
		const {
			user,
			children,
			loading,
			fatalError,
			currentRoute,
		} = this.props
		if (loading) {
			return (
				<div>
					<Loading error={fatalError} />
				</div>
			)
		}
    
		return (
			<Main>
				<Header />
				<Master>
					<Wrapper>
						<RouteContainer
							noPadding={currentRoute.noPadding}
							fullWidth={false}
						>
							{children({ user })}
						</RouteContainer>
					</Wrapper>
				</Master>

				{user && <Toaster />}
			</Main>
		)
	}
}

export default withTranslation()(Logic(App))

const Wrapper = styled.div`
	display: flex;
	background-color: #FAFAFA;
  min-height: calc(100vh - 286px);
`

const RouteContainer = styled(Container)`
  padding: 80px;

  ${({ noPadding }) => noPadding && `
    height: calc(100vh - 96px);
    max-width: none;
    position: relative;
    padding: 0;
  `}

  ${({ fullWidth }) => fullWidth && `
    max-width: 1750px;
  `}
`
