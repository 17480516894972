import * as React from 'react'

const IcGym = (props) =>
	(
		<svg width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title>icons/servicos/ic-gym</title>
			<g id="icons/servicos/ic-gym" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path d="M1.69518724,18.9606977 L4.99651961,22.2620301 L3.34583692,23.9127127 L0.0445045447,20.6113804 L1.69518724,18.9606977 Z M3.51366537,11.5331206 L12.4286381,20.4480934 L10.1173541,22.7594241 L9.12681712,21.7688405 L7.80597665,23.0896809 L0.872077821,16.1557821 L2.19291829,14.8349883 L1.20238132,13.8444047 L3.51366537,11.5331206 Z M13.3931544,7.26474901 L16.6944868,10.5660814 L10.6102374,16.6503308 L7.308905,13.3489984 L13.3931544,7.26474901 Z M16.2024747,0.825385214 L23.1363735,7.75928405 L21.8155331,9.08012451 L22.8061167,10.0706615 L20.494786,12.3819455 L11.5798132,3.46697276 L13.8910973,1.15568872 L14.8816809,2.14622568 L16.2024747,0.825385214 Z M20.6575219,0.00240009338 L23.9588542,3.30373247 L22.3081715,4.95441516 L19.0068392,1.65308279 L20.6575219,0.00240009338 Z" id="icon" fill="#000000" fillRule="nonzero"/>
			</g>
		</svg>
	)

export default IcGym
