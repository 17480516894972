import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logic from './logic'

import Uploader from './uploader'
import { Form } from 'semantic-ui-react'

import Label from 'components/Label'
import BoxSegment from 'components/BoxSegment'
import FormField from 'components/FormField'
import SegmentTitle from 'components/SegmentTitle'
import Buttons from 'containers/ServiceAreaRequestForm/Buttons'

import { ENDPOINTS } from 'api'

class Photos extends Component {
	render () {
		const { loading, t, form, serviceAreaProcess } = this.props
		const { addFile, removeFile, patchForm } = this.actions
		console.log(serviceAreaProcess)
		return (
			<>
				<BoxSegment loading= {loading}>
					<Form>
						<SegmentTitle>
							{t('form.photos')}
						</SegmentTitle>
						<HorizontalLine />
            
						<DocumentWrapper>
							<FormField required>
								<Label
									color={'#616161'}
								>
									{t('form.upload_photos')}
								</Label>

								<Uploader
									limit={1}
									uploadEndpoint={ENDPOINTS.MEDIA}
									value={form.images.value}
									accept={'image/png, image/jpeg'}
									onChange={(files) => addFile(files)}
									onRemove={(index) => removeFile(index)}
								/>
							</FormField>
						</DocumentWrapper>
					</Form>
				</BoxSegment>
											
				<Buttons
					previousButtonClick={
						() => patchForm('previous')
					}
				/>
			</>
		)
	}
}

export default withTranslation()(Logic(Photos))

const HorizontalLine = styled.hr`
  width: 100%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #F3F3F3;
  margin: 16px 0;
`

const DocumentWrapper = styled.div`
	background-color: #FAFAFA;
	padding: 24px;
`
