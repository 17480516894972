import React, { Component } from 'react'
import * as Check from '../../../validations'
import {fetchData, formIsDifferent, getDifferentData, mapErrorMessages, submit} from './utils.js'
import {mapAPIFormStateToForm, scrollToFirstFormError} from 'utils'
import _ from 'lodash'
import axios from 'axios'

/**
 * Wrapper that handles most of the logic in forms
 * 
 * @author David Serôdio <dserodio@ubiwhere.com>
 *
 * @param {Object}		defaultForm											Data to be submitted
 * @param {boolean}		[initialLoading=false]					Starts the component in loading state
 * @param {function}	[onFetch] 											Callback when fetch is done (GET)
 * @param {function}	[onSubmit] 											Callback when submit is done (POST/PATCH/PUT)
 * @param {function}	[resolveFetchData]     					Resolves result after sending the fetch request
 * @param {function}	[resolveSubmitData]     				Resolves payload before sending the submit request
 * @param {Object}		[validations={}]     						Form validations
 * @param {boolean}		[submitChangedDataOnly=false]		Form should submit the whole object or just the changed values
 *
 * 
 * Returned values as props
 * @param {Object} 		form														Data to be submitted
 * @param {function} 	setHandler											Updates the event listeners - setHandler('onFetch', () => {})
 * 																										OPTIONS: onFetch, resolveFetchData, onSubmit, resolveSubmitData
 * @param {function}	submit													Submit the form
 * @param {function}	fetchData												Fetch form data to fill the fields
 * @param {boolean}		loading													Form is fetching data
 * @param {boolean}		submitting											Form is submitting data
 * @param {function}	changeForm											Updates the form values - changeForm({field: value})
 * @param {boolean}		formHasChanged									Form is different from the initial state or the last time it was submitted
 * @param {function}	setValidations									Updates the validation object
 */

class FormRequest extends Component {
	
	constructor (props) {
		super(props)
		this.state = {
			axiosSource: axios.CancelToken.source(),
			form: props.defaultForm,
			//error: null,
			loading: props.initialLoading || false,
			submitting: false,
			initialForm: props.defaultForm,
			onFetch: props.onFetch,
			resolveFetchData: props.resolveFetchData,
			onSubmit: props.onSubmit,
			resolveSubmitData: props.resolveSubmitData,
			validations: props.validations || {}
		}
		this._isMounted = true
	}

	componentDidMount () {

		const { url } = this.props

		if (url) this.doFetch(url)

	}

	componentWillUnmount () {
		const { axiosSource, loading } = this.state
		
		if (loading) axiosSource.cancel()
		this._isMounted = false
	}

	setStateMounted = (data) => {
		if (!this._isMounted) return

		this.setState(data)
	}

	doSubmit = async (urlOrFunction, options) => {

		const { type, resolveData, callback, skipWhenUnchanged = true } = options || {}
		const { onSubmit, resolveSubmitData, form, validations, initialForm } = this.state
		const { submitChangedDataOnly } = this.props
		
		const newInitialForm = {...form}

		const finish = (data, success) => {
			if (success) {
				this.setStateMounted({
					initialForm: newInitialForm
				})
			}
			onSubmit && onSubmit(data, success)
			callback && callback(data, success)
		}

		try {
			
			const validation = Check.checkValidation(form, validations)
			if (validation.invalid) {
				this.setStateMounted({
					form: validation.form
				})
				scrollToFirstFormError(validation.form)
				return
			}

			if (skipWhenUnchanged && !formIsDifferent(form, initialForm)) {
				finish()
				return
			}

			this.setStateMounted({
				submitting: true
			})
			
			let result = null
			
			const flatData = _.mapValues(form, ({ value }) => value)
			
			let data = resolveData ? resolveData(flatData) : resolveSubmitData ? resolveSubmitData(flatData) : flatData

			if (submitChangedDataOnly) {
				const flatInitialData = _.mapValues(initialForm, ({ value }) => value)
				const resolvedInitialData = resolveData ? resolveData(flatInitialData)
					: resolveSubmitData ? resolveSubmitData(flatInitialData) : flatInitialData

				data = getDifferentData(data, resolvedInitialData)
			}
			
			if (typeof urlOrFunction === 'string') {
				result = await submit(urlOrFunction, data, type)
			}
			else {
				result = await urlOrFunction(data)
			}
			
			finish(result, true)
			
			this.setStateMounted({
				submitting: false
			})
			
		} catch (error) {
			const data = error && error.response && error.response.data
			if (data && data.length) {
				const newForm = mapErrorMessages(data, form)
				this.setStateMounted({
					form: newForm
				})
				scrollToFirstFormError(newForm)

			}
			console.log(error)
			finish(data, false)
			//this.setState({
			//	error: {type: 'fetch', message: error}
			//})
		}

	}

	doFetch = async (urlOrFunction, options) => {
		
		const { resolveData, callback } = options || {}
		const { onFetch, resolveFetchData, form, axiosSource } = this.state
		
		try {

			this.setState({
				loading: true
			})

			let result = null

			let config = { cancelToken: axiosSource.token}
			if (typeof urlOrFunction === 'string') {

				result = await fetchData(urlOrFunction, config)
				
			}
			else {
				result = await urlOrFunction(_.mapValues(form, ({ value }) => value), config)
			}

			const data = resolveData ? resolveData(result) : resolveFetchData ? resolveFetchData(result) : result
			
			const newForm = mapAPIFormStateToForm(data, _.cloneDeep(form))

			onFetch && onFetch(newForm, true)
			callback && callback(newForm, true)

			this.setState({
				form: newForm,
				initialForm: newForm,
				loading: false
			})

		} catch (error) {
			const data = error && error.response && error.response.data

			onFetch && onFetch(data, false)
			callback && callback(data, false)
			//this.setState({
			//	error: {type: 'fetchData', message: error}
			//})
		}

	}

	changeForm = (payload) => {
		const { setParentDirty } = this.props
		const { form, validations = [] } = this.state

		const newForm = Check.setAndCheckValidation(
			form,
			{field: payload},
			validations
		)

		if (setParentDirty) setParentDirty(true)

		this.setState({
			form: newForm
		})

	}

	handlerSetter = (name, event) => {
		switch (name) {
			case 'onFetch':
				this.setState({
					onFetch: event
				})
				break
			case 'resolveFetchData':
				this.setState({
					resolveFetchData: event
				})
				break
			case 'onSubmit':
				this.setState({
					onSubmit: event
				})
				break
			case 'resolveSubmitData':
				this.setState({
					resolveSubmitData: event
				})
				break
			default:
				break
		}
	}

	setValidations = (validations) => {

		const { form } = this.props

		const { form: newForm } = Check.checkValidation(form, validations)

		this.setState({
			validations,
			form: newForm
		})
	}

	render () {

		const { children } = this.props
		const { form, loading, submitting, initialForm } = this.state
		
		const formComponent = React.cloneElement(children, {
			form,
			setHandler: this.handlerSetter,
			//clearError: () => this.setState({error: null}),
			submit: this.doSubmit,
			fetchData: this.doFetch,
			loading,
			submitting,
			changeForm: this.changeForm,
			formHasChanged: formIsDifferent(form, initialForm),
			setValidations: this.setValidations,
			ref: children.ref
		})

		return formComponent
	}
}

export default FormRequest
