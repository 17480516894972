import moment from 'moment'
import * as Check from 'validations'

const DEFAULT_VALUES = {
	status: {
		value: ''
	},
	serviceArea: {
		value: '',
		edit: false
	},
	date: {
		value: moment().format('YYYY-MM-DD'),
		edit: false
	},
	slotsArray: {
		value: [],
		edit: false
	},
	spotObj: {
		value: {name: '', value: ''},
		edit: false
	},
	guestName: {
		value: '',
		edit: false
	},
	guestBirthdate: {
		value: moment().subtract(18, 'Y').format('YYYY-MM-DD'),
		edit: false
	},
	guestNationality: {
		value: '',
		edit: false
	},
	guestEmail: {
		value: '',
		edit: false
	},
	guestMobile: {
		value: '',
		edit: false
	},
	guestIdentityDocumentType: {
		value: '',
		edit: false
	},
	guestIdentityDocument: {
		value: '',
		edit: false
	},
	guestNif: {
		value: '',
		edit: false
	},
	motorhomeModel: {
		value: '',
		edit: false
	},
	motorhomeLicensePlate: {
		value: '',
		edit: false
	},
	motorhomeInfo: {
		value: '',
		edit: false
	},
	paymentMethod: {
		value: null,
		edit: false
	},
	paymentDetails: {
		value: null,
		edit: false
	},
	accompanyingGuestsNumber: {
		value: null,
		edit: false
	}
}

const VALIDATIONS = {
	serviceArea: [
		Check.isRequired
	],
	date: [
		Check.isRequired
	],
	slotsArray: [
		Check.isRequired
	],
	guestName: [
		Check.isRequired
	],
	guestBirthdate: [
		Check.isRequired
	],
	guestNationality: [
		Check.isRequired
	],
	guestEmail: [
		Check.isRequired,
		Check.Email
	],
	guestNif: [
		Check.isRequired
	],
	guestMobile: [
		Check.isRequired,
		Check.isValidPhoneNumber
	],
	guestIdentityDocument: [
		Check.isRequired
	],
	guestIdentityDocumentCountry: [
		Check.isRequired
	],
	guestIdentityDocumentType: [
		Check.isRequired
	],
	motorhomeModel: [
		Check.isRequired
	],
	motorhomeLicensePlate: [
		Check.isRequired
	],
}

// Checks the validation of a field
export const changeEdit = (state, payload) => {
	let newStateChanges = {}
	Object.keys(payload.field).forEach((fieldName) => {
		const field = payload.field[fieldName]

		let settedField = {
			...state[fieldName],
			edit: field
		}

		newStateChanges = { ...newStateChanges, [fieldName]: settedField }
	})

	return { ...state, ...newStateChanges }
}

export const resetEdit = (state) => {
	for (var key in state) {
		if (Object.prototype.hasOwnProperty.call(state, key)) {
			state[key].edit = false
		}
	}
	
	return state
}

export {
	DEFAULT_VALUES,
	VALIDATIONS
}

export const getHours = (data, separator = '[h]') => {
	const format = `HH${separator}mm`
	return data.map(item => `${moment(item.startingTime, 'HH:mm').format(format)}-${moment(item.endingTime, 'HH:mm').format(format)}`)
}