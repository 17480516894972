/**
 * Master Container Logic
 * Please write a description
 *
 * @author Mário Madeira <mmadeira@ubiwhere.com>
 */

import { kea } from 'kea'

import AppLogic from 'containers/App/logic'

export default kea({
	path: () => ['scenes', 'containers', 'Master'],

	connect: {
		actions: [],
		props: [
			AppLogic, [
				'currentRoute'
			]
		]
	}
})
