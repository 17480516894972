import { kea } from 'kea'
import { put, call } from 'redux-saga/effects'
import Proptypes from 'prop-types'
import { cloneDeep, mapValues } from 'lodash'

import API from 'api'
import { mapApiErrors } from 'api/errors'
import * as Check from 'validations'
import AppLogic from 'containers/App/logic'
import ToasterLogic from 'containers/Toaster/logic'
import ServiceAreaLogic from 'screens/ServiceArea/logic'
import ServiceAreaRequestLogic from 'screens/ServiceAreaRequest/logic'
import { DEFAULT_RECEPTION_TYPES } from 'containers/ServiceAreaRequestForm/Datasheet/Installation/utils'
import {
	changeEquipment,
	addEquipment,
	removeEquipment,
	convertEquipment
} from 'containers/ServiceAreaRequestForm/Datasheet/OtherEquipments/utils'

import {
	DEFAULT_VALUES,
	VALIDATIONS,
	DEFAULT_TIME_YEAR,
	convertBooleansForm
} from './utils'
import {
	mapAPIFormStateToForm,
	getErrorMessagesAndScroll,
	scrollToFirstFormError
} from 'utils'
import { resolveDecimalString } from '../../../../utils'

export default kea({
	path: () => ['scenes', 'containers', 'SAR_Datasheet'],

	connect: { 
		props: [
			AppLogic, [
				'user'
			],
			ServiceAreaLogic, [
				'serviceAreaProcessId'
			],
			ServiceAreaRequestLogic, [
				'current'
			]
		],
		actions: [
			ToasterLogic, [
				'show as showToaster'
			],
			ServiceAreaRequestLogic, [
				'setStatus',
				'setCurrent'
			]
		]
	},

	actions: () => ({
		fetchForm: () => true,
		setForm: form => ({ form }),
		changeForm: field => ({ field }),
		patchForm: action => action,
		unsetDirty: () => true,

		addEquipment: () => true,
		changeEquipment: (index, field) => ({ index, field }),
		removeEquipment: (index) => ({ index }),
		
		saveInstallationForm: action => action,
		saveEquipmentsForm: action => action,
		saveOtherEquipmentsForm: action => action,
		saveServiceAreaForm: action => action,
		saveCleaningReservationRules: action => action,
		error: errors => ({ errors }),
		loading: () => true,
		loaded: () => true,
		reset: () => true
	}),

	reducers: ({ actions }) => ({

		form: [DEFAULT_VALUES, Proptypes.object, {
			[actions.setForm]: (_, payload) => payload.form,
			[actions.addEquipment]: addEquipment,
			[actions.changeEquipment]: changeEquipment,
			[actions.removeEquipment]: removeEquipment,
			[actions.changeForm]: (state, payload) =>
				Check.setAndCheckValidation(state, payload, VALIDATIONS),
			[actions.reset]: () => DEFAULT_VALUES
		}],

		dirty: [false, Proptypes.bool, {
			[actions.changeForm]: () => true,
			[actions.addEquipment]: () => true,
			[actions.changeEquipment]: () => true,
			[actions.removeEquipment]: () => true,
			[actions.reset]: () => false,
			[actions.unsetDirty]: () => false,
		}],

		loading: [true, Proptypes.bool, {
			[actions.patchForm]: () => true,
			[actions.setForm]: () => false,
			[actions.loading]: () => true,
			[actions.loaded]: () => false, 
			[actions.error]: () => false,
			[actions.reset]: () => false
		}],

		errors: [[], Proptypes.array, {
			[actions.error]: (state, payload) => payload.errors,
			[actions.patchForm]: () => [],
			[actions.reset]: () => []
		}]

	}),

	start: function * () {
		const { fetchForm } = this.actions
		yield put(fetchForm())
	},

	takeLatest: ({ actions, workers }) => ({
		[actions.fetchForm]: workers.fetchForm,
		[actions.patchForm]: workers.patchForm,
		[actions.saveInstallationForm]: workers.saveInstallationForm,
		[actions.saveEquipmentsForm]: workers.saveEquipmentsForm,
		[actions.saveServiceAreaForm]: workers.saveServiceAreaForm,
		[actions.saveCleaningReservationRules]: workers.saveCleaningReservationRules,
		[actions.saveOtherEquipmentsForm]: workers.saveOtherEquipmentsForm
	}),

	workers: {

		* fetchForm () { 
			const { setForm, showToaster, loaded } = this.actions
			const { t } = this.props
			const form = yield this.get('form')
			const id = yield this.get('serviceAreaProcessId')

			try {
				/** Get datasheet form values */
				const datasheetData = yield call(
					API.Areas.steps.getDatasheet,
					id
				)

				if (datasheetData.timeOfYear) {
					const newTimeOfYear = DEFAULT_TIME_YEAR.find(
						({ key }) => key === datasheetData.timeOfYear
					)
          
					datasheetData.timeOfYear = newTimeOfYear.value
				}
				
				/** Get installations form values */
				const installationsData = yield call(
					API.Areas.steps.getFacilities,
					id
				)

				if (installationsData.receptionType) {
					const newReceptionType = DEFAULT_RECEPTION_TYPES.find(
						({ key }) => key === installationsData.receptionType
					)
					installationsData.receptionType = newReceptionType.value
				}

				/** Get service area form values */
				let serviceStationData = yield call(
					API.Areas.steps.getServiceStation,
					id
				)

				serviceStationData.potableWaterSupplyServiceArea = serviceStationData.potableWaterSupply
				serviceStationData.potableWaterSupply = null

				/** Get equipments form values */
				const equipmentsData = yield call(
					API.Areas.steps.getEquipments,
					id
				)
				
				/** Get other equipments form values */
				let otherEquipmentsData = yield call(
					API.Areas.steps.getOtherEquipments,
					id
				)

				if (!otherEquipmentsData.equipmentName.length) {
					otherEquipmentsData = {
						equipmentName: []
					}
				} else {
					otherEquipmentsData = {
						equipmentName: otherEquipmentsData.equipmentName
					}
				}

				/** Get Cleaning Reservation Rules form values */
				let cleaningReservationsRules = yield call(
					API.Areas.steps.getCleaningReservationsRules,
					id
				)

				const data = Object.assign(
					{},
					installationsData,
					serviceStationData,
					datasheetData,
					equipmentsData,
					otherEquipmentsData,
					cleaningReservationsRules
				)

				data.checkInHours = data.checkInHours && data.checkInHours.replace('h', ':')
				data.checkOutHours = data.checkOutHours && data.checkOutHours.replace('h', ':')

				const newForm = yield call(
					mapAPIFormStateToForm,
					data, cloneDeep(form)
				)

				const formConverted = convertBooleansForm(newForm)
				//formConverted.contacts.value = formConverted.contacts.value.map(name => ({
				//	name: { value: name }
				//}))

				yield put(setForm(formConverted))

			} catch (err) {
				console.log(err)
				yield put(loaded())
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_fetch')
				))
			}
		},

		* patchForm (actionPayload) {
			const {
				showToaster,
				setStatus,
				loaded,
				error,
				setCurrent,
				saveInstallationForm,
				setForm,
				unsetDirty
			} = this.actions
			
			const { payload } = actionPayload
			const { t } = this.props
			const form = yield this.get('form')
			let params = mapValues(form, ({ value }) => value)
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			const dirty = yield this.get('dirty')
			const current = yield this.get('current')
			const validation = Check.checkValidation(form, VALIDATIONS)

			if (dirty && validation.invalid) {
				// try to scroll to first form field error
				scrollToFirstFormError(validation.form)
				yield put(error([]))
				return false
			}

			if (!dirty && validation.invalid) {
				// try to scroll to first form field error
				scrollToFirstFormError(validation.form)
				yield put(setForm(validation.form))
				yield put(error([]))
				return false
			}
			
			if (!dirty) {
				yield put(loaded())
				
				switch (payload) {
					case 'next':
						yield put(setCurrent(current + 1))
						break
					case 'previous':
						yield put(setCurrent(current - 1))
						break
					default:
						break
				}
				
				return
			}

			const newTimeOfYear = DEFAULT_TIME_YEAR.find(
				({ value }) => value === params.timeOfYear
			)
      
			if (newTimeOfYear) {
				params.timeOfYear = 'all'
			}
			try {
				let response = yield call(
					API.Areas.steps.updateDatasheet,
					serviceAreaProcessId,
					params
				)
				
				yield put(setStatus('datasheet', response.status))
				yield put(saveInstallationForm(payload))
				yield put(unsetDirty())

			} catch (err) {
				if (err.response) {
					const { t } = this.props
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					yield put(error(errors))
				}

				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}

		},

		* saveInstallationForm (actionPayload) {
			const {
				showToaster,
				error,
				saveServiceAreaForm,
			} = this.actions
			
			const { payload } = actionPayload
			const { t } = this.props
			const form = yield this.get('form')
			const params = mapValues(form, ({ value }) => value)
			if (params.reception === false) params.receptionType = null
      
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')

			try {
				const newReceptionType = DEFAULT_RECEPTION_TYPES.find(
					({ value }) => value === params.receptionType
				)
        
				if (newReceptionType) {
					params.receptionType = newReceptionType.key
				}

				yield call(
					API.Areas.steps.updateFacilities,
					serviceAreaProcessId,
					params
				)
				
				yield put(saveServiceAreaForm(payload))

			} catch (err) {
				if (err.response) {
					const errors = mapApiErrors(
						err.response.data,
						t
					)
					
					yield put(error(errors))
				}

				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}
		},
		
		* saveServiceAreaForm (actionPayload) {
			const {
				showToaster,
				saveEquipmentsForm,
				error
			} = this.actions
			const { t } = this.props
			const { payload } = actionPayload

			const form = yield this.get('form')
			const params = mapValues(form, ({ value }) => value)
			params.potableWaterSupply = params.potableWaterSupplyServiceArea
			params.potableWaterSupplyServiceArea = null
			if (params.quantity === '') params.quantity = 0

			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
      
			try {
				yield call(
					API.Areas.steps.updateServiceStation,
					serviceAreaProcessId,
					params
				)
				yield put(saveEquipmentsForm(payload))
			} catch (err) {
				if (err.response) {
					const errors = getErrorMessagesAndScroll(
						err.response.data,
						form
					)
					yield put(error(errors))
				}

				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}

		},
		
		* saveEquipmentsForm (actionPayload) {
			const {
				showToaster,
				error,
				saveCleaningReservationRules
			} = this.actions
			
			const { payload } = actionPayload
			const { t } = this.props
			const form = yield this.get('form')
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			
			const params = mapValues(form, ({ value }) => value)
			if (params.laundryPlaces === false) params.laundryPlacesQuantity = null
			if (params.garbageContainers === false) params.garbageContainersQuantity = null
			
			try {
				yield call(
					API.Areas.steps.updateEquipments,
					serviceAreaProcessId,
					params
				)
					
				yield put(saveCleaningReservationRules(payload))
					
			} catch (err) {
				if (err.response) {
					const errors = getErrorMessagesAndScroll(
						err.response.data,
						form
					)
					yield put(error(errors))
				}
	
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}
	
		},

		* saveCleaningReservationRules (action) {
			const {
				showToaster,
				error,
				saveOtherEquipmentsForm
			} = this.actions
			
			const { payload } = action
			const { t } = this.props
			const form = yield this.get('form')
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')
			const params = mapValues(form, ({ value }) => value)
			
			params.spotPrice = resolveDecimalString(params.spotPrice)
			params.electricitySupply = resolveDecimalString(params.electricitySupply)
			params.potableWaterPrice = resolveDecimalString(params.potableWaterPrice)
			params.cleaningPrice = resolveDecimalString(params.cleaningPrice)

			try {
				yield call(
					API.Areas.steps.updateCleaningReservationsRules,
					serviceAreaProcessId,
					params
				)
					
				yield put(saveOtherEquipmentsForm(payload))
					
			} catch (err) {
				if (err.response) {
					const errors = getErrorMessagesAndScroll(
						err.response.data,
						form
					)
					yield put(error(errors))
				}
	
				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}
	
		},
		
		* saveOtherEquipmentsForm (actionPayload) {
			const {
				showToaster,
				loaded,
				setCurrent,
				error
			} = this.actions
			
			const { payload } = actionPayload
			const { t } = this.props
			const form = yield this.get('form')
			const current = yield this.get('current')
			const params = convertEquipment(form)
			const serviceAreaProcessId = yield this.get('serviceAreaProcessId')

			try {
				yield call(
					API.Areas.steps.updateOtherEquipments,
					serviceAreaProcessId,
					params
				)
				yield put(showToaster(
					'success',
					t('common.success'),
					t('error.success_save_data'))
				)
				yield put(loaded())
				switch (payload) {
					case 'next':
						yield put(setCurrent(current + 1))
						break
					case 'previous':
						yield put(setCurrent(current - 1))
						break
					default:
						break
				}

			} catch (err) {
				if (err.response) {
					const errors = getErrorMessagesAndScroll(
						err.response.data, form
					)
					yield put(error(errors))
				}

				yield put(showToaster(
					'error',
					t('common.error'),
					t('error.error_save_data')
				))
			}
		}
	}

})
