import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { pure } from 'recompose'
import { Form, Input, Message } from 'semantic-ui-react'

import Debouncer from 'components/Debouncer'
import Title from 'components/Title'
import Segment from 'components/Segment'
import FormField from 'components/FormField'
import Button from 'components/Button'
import Errors from 'containers/ErrorList'

const DebouncedInput = pure(Debouncer(Input))

class RecoverPassword extends PureComponent {
handleEmailChange = (value) => {
	const { onChange } = this.props
	onChange({ email: value })
}

render () {
	const {
		email,
		t,
		back,
		error,
		submit,
		submiting,
		response,
		contacts
	} = this.props

	return (
		<Wrapper>
			<Title>{t('recover_password.screen_title')}</Title>

			<BoxSegment>
				{!response &&
					<Form loading={submiting}>
						<FormField require={1}>
							<Label>Email</Label>
							<DebouncedInput
								value={email && email.value}
								onChange={this.handleEmailChange}
							/>
						</FormField>

						{error && !!error.length && <Errors list={error} />}

						<SubmitButton primary onClick={submit}>
							{t('options.password_recover')}
						</SubmitButton>
						{contacts &&
						<Help>
							{/* eslint-disable-next-line max-len */}
							{t('common.contact_info')} <a href={`mailto:${contacts.CONTACT_US_EMAIL}`}>{contacts.CONTACT_US_EMAIL}</a>
						</Help>
						}
					</Form>
				}

				{response &&
					<Message
						success
						header={t('success.name')}
						content={t('success.recovery_email_sent')}
					/>
				}
			</BoxSegment>

			<FloatLeft>
				<RegisterLink>
					<span onClick={back}>{t('options.login')}</span>
				</RegisterLink>
			</FloatLeft>
		</Wrapper>
	)
}
}

export default withTranslation()(RecoverPassword)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const BoxSegment = styled(Segment)`
  &&&& {
    width: 544px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05);
    border-radius: 4px;
    margin-top: 80px;
    padding: 40px;
    position: relative;
  }
`

const SubmitButton = styled(Button)`
  &&&& {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    max-height: 48px;
    padding: 18px 0;
  }
`

const FloatLeft = styled.div`
  display: flex;
  align-items: flex-start;
  width: 544px;
`

const Label = styled.label`
  &&&& {
    color: #616161;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 14px;
    margin: 0!important;
    margin-bottom: 4px!important;
  }
`

const RegisterLink = styled.a`
  color: #9B9B9B;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  width: 100%;
  display: block;

  span {
    border-bottom: 1px dashed #102526;
    color: #102526;
    text-transform: uppercase;
  }

  span:last-child {
    float: right;
  }

  &:hover {
    color: #00A651;
  }
`

const Help = styled.div`
  margin-top: 40px;
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  text-align: center;
  font-family: Open sans;
`