/* eslint-disable max-len */
import React, { Component } from 'react'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import { withTranslation } from 'react-i18next'

class Homologation extends Component {
	render () {
		const { open, onClose, onSubmit, t } = this.props
		return (
			<Modal.Modal 
				open={open}
				closeIcon
				onClose={onClose}
				size={'tiny'}
			>
				<Modal.Content>
					<Title>
						Pretende Submeter o pedido de homologação da ASA?			
					</Title>
					<Description>
						Ao submeter este pedido irá iniciar o processo de homologação da sua ASA. A partir desse momento entraremos em contacto consigo para se proceder ao pedido efetuado por si.
					</Description>
				</Modal.Content>

				<Modal.Actions>
					<Button
						secondary
						onClick={onClose}
					>
						{t('common.buttons_back')}
					</Button>

					<Button
						primary
						onClick={(() => onSubmit())}
					>
						{t('common.submit')}
					</Button>
				</Modal.Actions>
			</Modal.Modal>
		)
	}
}

export default withTranslation()(Homologation)

const Title = styled.p`
	color: #102526;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
	margin-top: 105px !important;
`
const Description = styled.p`
  color: #000000;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
	margin: 0 26px 70px 26px !important;
`