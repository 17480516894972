import styled from 'styled-components'

const SegmentTitle = styled.span`
  color: #000000;
  font-family: "Open Sans";
  font-size: 24px;
  line-height: 24px;
  display: block;
  margin: ${({ margin }) => margin};
`
export default SegmentTitle
