import * as Check from 'validations'

const DEFAULT_VALUES = {
	description: {
		value: null
	},
	trails: {
		value: null
	},
	cycling: {
		value: null
	},
	horseRides: {
		value: null
	},
	jeepTours: {
		value: null
	},
	canoeing: {
		value: null
	},
	climbingOrAbseiling: {
		value: null
	},
	sailing: {
		value: null
	},
	diving: {
		value: null
	},
	surf: {
		value: null
	},
	golf: {
		value: null
	},
	padel: {
		value: null
	},
	birdwatching: {
		value: null
	},
	yoga: {
		value: null
	}
}

const VALIDATIONS = {
	description: [
		Check.isRequired
	]
}

const VALIDATIONS_NO_REQUIRED = {
	
}

export {
	DEFAULT_VALUES,
	VALIDATIONS,
	VALIDATIONS_NO_REQUIRED
}