/* eslint-disable max-len */
import React from 'react'
import Proptypes from 'prop-types'

const ArrowFowardIcon = ({ width, height, fill, ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 24 24'
		{...props}
	>
		<polygon fill={fill} points='8 16.59 9.41 18 15.41 12 9.41 6 8 7.41 12.58 12' />
	</svg>
)

ArrowFowardIcon.propTypes = {
	width: Proptypes.number,
	height: Proptypes.number,
	fill: Proptypes.string
}

ArrowFowardIcon.defaultProps = {
	width: 24,
	height: 24,
	fill: '#ABD037'
}

export default ArrowFowardIcon
